import { isMathPracticeSignedTip } from '../modules/signed-tip/signed-tip';
import type {
  Agrupation,
  Extension,
  Formation,
  Management,
  PageBreak,
  PartDivider,
  PasoNumerado,
  SignedTip,
  Solution,
  StepMedia,
  StepType,
  Support,
  TextItem,
  WhatToObserve,
} from '../types/SmartGuide';
import type { TableSmartguides } from '../types/Table';
import { mapMedia } from './mapMedia';
import { mapTable } from './mapTable';
import { mapWhatToObserve } from './mapWhatToObserve';

export function mapSteps(
  data: any,
  partNumber: number,
  regionParameters: any,
  isCaliforniaStandard: boolean,
  locale: string
): StepType[] {
  return data
    .map((item: any) => {
      switch (item.__typename) {
        case 'PasoNumerado':
          return {
            __typename: 'PasoNumerado',
            content: mapSteps(
              item.content,
              partNumber,
              regionParameters,
              isCaliforniaStandard,
              locale
            ),
            evaluable: {
              isEvaluable: item.paso_evaluable,
              tooltipText: item.nombre_del_evaluable,
              linkTo: item.enlace_al_evaluable,
              dimension: +item.dimensin_de_evaluacin,
            },
            stepNumber: item.numero_del_paso,
          } as PasoNumerado;
        case 'Agrupacin':
          return {
            __typename: 'Agrupacin',
            part: partNumber,
            text: `digitalguides.agrupation.${item.agrupacin}`,
          } as Agrupation;
        case 'Text':
          return {
            __typename: 'Text',
            text: item.body,
          } as TextItem;
        case 'Solucin':
          return {
            __typename: 'Solucin',
            media: mapMedia(
              item.media,
              item.ancho_de_imagen,
              regionParameters,
              locale
            ),
            part: partNumber,
            text: item.descripcin,
            tables: mapTable(item, regionParameters, locale),
          } as Solution;
        case 'Subactividad':
          return {
            __typename: 'Subactividad',
            text: item.texto,
          } as PartDivider;
        case 'QuePodemosObservar':
          return {
            __typename: 'QuePodemosObservar',
            items: mapWhatToObserve(item, regionParameters, locale),
          } as WhatToObserve;
        case 'Apoyo':
          return {
            __typename: 'Apoyo',
            media: mapMedia(
              item.media,
              item.ancho_de_imagen,
              regionParameters,
              locale
            ),
            text: item.descripcin,
            tables: mapTable(item, regionParameters, locale),
          } as Support;
        case 'Formacin':
          return {
            __typename: 'Formacin',
            media: mapMedia(
              item.media,
              item.ancho_de_imagen,
              regionParameters,
              locale
            ),
            text: item.descripcin,
            tables: mapTable(item, regionParameters, locale),
          } as Formation;
        case 'Ampliacin':
          return {
            __typename: 'Ampliacin',
            media: mapMedia(
              item.media,
              item.ancho_de_imagen,
              regionParameters,
              locale
            ),
            text: item.descripcin,
            tables: mapTable(item, regionParameters, locale),
          } as Extension;
        case 'ConsejoDeGestin':
          return {
            __typename: 'ConsejoDeGestin',
            media: mapMedia(
              item.media,
              item.ancho_de_imagen,
              regionParameters,
              locale
            ),
            text: item.descripcin,
            tables: mapTable(item, regionParameters, locale),
          } as Management;
        case 'Signedtip':
          if (!isCaliforniaStandard && isMathPracticeSignedTip(item.tip_type)) {
            return null;
          }

          return {
            __typename: 'Signedtip',
            tip_type: item.tip_type,
            icon: item.btc_icon,
            media: mapMedia(
              item.media,
              item.ancho_de_imagen,
              regionParameters,
              locale
            ),
            text: item.description,
            counselor: item.counselor,
            tables: mapTable(item, regionParameters, locale),
          } as SignedTip;
        case 'ZzImagen':
          return {
            __typename: 'ZzImagen',
            media: mapMedia(
              item.media,
              item.ancho_de_imagen,
              regionParameters,
              locale
            ),
          } as StepMedia;
        case 'TableSmartguides':
          return {
            __typename: 'TableSmartguides',
            tables: mapTable(item, regionParameters, locale),
          } as TableSmartguides;
        case 'PageBreak':
          return {
            __typename: 'PageBreak',
          } as PageBreak;
        default:
          return null;
      }
    })
    .filter((step: any) => step !== null);
}
