import { useQueryParams } from '@innovamat/hooks';
import { useUser } from '../../user-management';

type LocalizationParams = {
  language: string;
  region: string;
};

function useLocalizationParams(): LocalizationParams {
  const language = useQueryParams().get('language');
  const region = useQueryParams().get('region');

  const { user } = useUser();

  return {
    language: language || user?.locale || 'es',
    region: region || user?.region || 'ES',
  };
}

export { useLocalizationParams };
