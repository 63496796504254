import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';

import { useEventLogging } from '@innovamat/event-logging';
import { authAxiosInstance } from '@innovamat/ga-features';
import { Button, ToggleButton } from '@innovamat/glimmer-components';
import { useResource } from '@innovamat/glow-api-client';
import { usePrint, useQueryParams } from '@innovamat/hooks';
import type { Region } from '@innovamat/localization';
import type { Session } from '@innovamat/resource-viewer';
import {
  EarlyYearPrintable,
  SmartGuidePrintable,
} from '@innovamat/smart-guides';

import { useOrganization } from '../../../classrooms-management';
import { useEnvVariables } from '../../../env-variables-manager';
import { useCurrentNavigationValues, useNavigation } from '../../../navigation';
import { useUser } from '../../../user-management';
import {
  useAppletInfo,
  useResourceParams,
  useStandard,
  useUpdateI18nLanguage,
} from '../../hooks';
import useGetPreprResource from '../../hooks/use-get-prepr-resource';
import { moveContentToPrint } from './move-content-to-print';

const Wrapper = styled.div`
  padding: 24px;
  width: 100%;
  height: var(--realVh);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 58px;

  @page {
    margin: 12.5mm;
  }
`;

const BreakPage = styled.div`
  page-break-after: always;
`;

const PrintSection = styled.div`
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #e5e5e5;
  padding: 8px;
  width: 280px;
  position: fixed;
  bottom: 10px;
  gap: 4px;
  left: 50%;
  border-radius: 8px;
  transform: translateX(-50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const Disclaimer = styled.div<{ show: boolean }>`
  font-size: 12px;
  color: #888;

  max-height: ${({ show }) => (show ? '100px' : '0px')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

type Props = {
  onPrint: () => void;
  loading: boolean;
};

export function PrintFooter({ onPrint, loading }: Props) {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  return (
    <PrintSection
      onMouseEnter={() => setShowDisclaimer(true)}
      onMouseLeave={() => setShowDisclaimer(false)}
    >
      <>
        <Button fill onClick={onPrint} variant="accent" loading={loading}>
          Imprimir
        </Button>
        <Disclaimer show={showDisclaimer}>
          Recuerda modificar las opciones para los márgenes y la leyenda a la
          hora de imprimir
        </Disclaimer>
      </>
    </PrintSection>
  );
}

function PrintGuide({
  id,
  hasBreakPage,
  isEditMode,
}: {
  id: string;
  hasBreakPage: boolean;
  isEditMode: boolean;
}) {
  const { t, i18n } = useTranslation();
  const { isRole, user, isLoggedIn } = useUser();
  const { courseOrder } = useCurrentNavigationValues();
  const { organization } = useOrganization();
  const { goToHome } = useNavigation();
  const { setEventData } = useEventLogging();

  const { region, language } = useResourceParams();
  const appletInfo = useAppletInfo();
  const { APOLLO_SERVER } = useEnvVariables();

  const academicYearId = organization?.academicYearId!;
  const isStudent = isRole.Student;

  useUpdateI18nLanguage({ language });

  const { standard } = useStandard();

  const { resource, isUnauthorized } = useResource({
    id: id,
    type: 'session',
    classroomId: undefined,
    language,
    academicYearId,
    isStudent,
    isLoggedIn,
    standard: standard,
    courseOrder: courseOrder ?? undefined,
    region: region,
  });

  useEffect(() => {
    if (isUnauthorized) {
      goToHome();
    }
  }, [isUnauthorized]);

  const session = resource as Session;

  const { data: preprResource } = useGetPreprResource({
    id: session?.guide?.id!,
    locale: language,
    enabled: !!session,
    session,
    region: region! as Region,
  });

  const i18nTranslations =
    i18n.getDataByLanguage(i18n.language) || i18n.getDataByLanguage('es');

  const { translations } = i18nTranslations as any;

  if (preprResource?.type === 'smartGuide')
    return (
      <>
        <SmartGuidePrintable
          isEditMode={isEditMode}
          playerInfo={{
            isStudent,
            setEventData,
            onResourceViewerEvents: () => {},
            resource: session,
            appletInfo: appletInfo,
            school: organization?.id!,
            uid: user?.id!,
            axiosInstance: authAxiosInstance,
            apolloServer: APOLLO_SERVER,
          }}
          smartGuide={preprResource}
          session={session}
          translations={translations}
          t={t}
          setEventData={setEventData}
          region={region}
          hasBreakPage={hasBreakPage}
        />
        <BreakPage />
      </>
    );

  if (
    preprResource?.type === 'digitalGuide' &&
    preprResource?.preprType === 'earlyYear'
  ) {
    return (
      <>
        <EarlyYearPrintable
          guide={preprResource}
          resource={{ ...session, language: language || 'es' }}
          t={t}
          onEvent={setEventData}
          translations={translations}
        />
        <BreakPage />
      </>
    );
  }

  return null;
}

const LegendContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LegendTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
`;

function Legend({
  isEditMode,
  setIsEditMode,
}: {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}) {
  return (
    <LegendContainer>
      <LegendTitle>Manual Mode</LegendTitle>
      <ToggleButton
        checked={isEditMode}
        onChange={() => setIsEditMode(!isEditMode)}
      />
    </LegendContainer>
  );
}

export function PrintGuides() {
  const { ids } = useParams();
  const params = useQueryParams();
  const hasBreakPage = !!params.get('breakPage') as boolean;
  const { componentRef, onPrint, loading } = usePrint('Smart Guides');
  const isContentMoved = useRef(false);
  const idsArray = ids?.split(',');

  const [isEditMode, setIsEditMode] = useState(false);

  const handlePrint = () => {
    if (!hasBreakPage || isContentMoved.current || isEditMode) {
      onPrint();
    } else {
      moveContentToPrint();
      isContentMoved.current = true;
      setTimeout(() => {
        onPrint();
      }, 500);
    }
  };

  return (
    <Wrapper>
      <PrintFooter onPrint={handlePrint} loading={loading} />
      <Legend isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
      <div ref={componentRef}>
        {idsArray?.map((id) => (
          <PrintGuide
            id={id}
            hasBreakPage={hasBreakPage}
            isEditMode={isEditMode}
          />
        ))}
      </div>
    </Wrapper>
  );
}
