import type { Column, CustomColumnRenderConfig } from '../table.types';

export const useColumnsCustomRenderConfig = <T,>(
  customRenderConfig: CustomColumnRenderConfig<T>,
  configurationWithoutRender: Column<T>[]
): Column<T>[] => {
  return configurationWithoutRender.map((column) => {
    const renderFunction =
      customRenderConfig[column.id as keyof CustomColumnRenderConfig<T>];
    return {
      ...column,
      render: renderFunction ? renderFunction : column.render,
    };
  });
};
