import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { authAxiosInstance } from '../../../utils';
import { useEnvVariables } from './../../env-variables-manager/env-variables-provider';

export interface GeoLocation {
  country: string;
  region: string;
}

function useGetGeoLocation(): UseQueryResult<GeoLocation, Error> {
  const { CORPORATE_WEBSITE } = useEnvVariables();

  const getGeoLocation = async () => {
    const url = `${CORPORATE_WEBSITE}/api/geolocation`;
    const response = await authAxiosInstance({ url, method: 'GET' });
    return response.data as GeoLocation;
  };

  return useQuery<GeoLocation, Error>({
    queryKey: ['geolocation'],
    queryFn: async () => getGeoLocation(),
    retry: false,
  });
}

export { useGetGeoLocation };
