import { useTranslation } from 'react-i18next';

import { Button } from '@innovamat/glimmer-components';

type Props = {
  testRoundId: string;
  onClick: (testRoundId: string) => void;
};

const OpenTestReportCell = ({ testRoundId, onClick }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button
      className="open-drawer-button"
      leftIcon="SideDrawerIcon"
      variant="secondary"
      size="S"
      onClick={() => onClick(testRoundId)}
    >
      {t('common.open')}
    </Button>
  );
};

export { OpenTestReportCell };
