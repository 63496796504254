import styled from '@emotion/styled';
import { Tooltip } from '@innovamat/glimmer-components';
import { useEffect, useRef, useState } from 'react';

type EllipsisCellProps = {
  text: string;
  dataTestId?: string;
};

const EllipsableSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;

  * {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledTooltip = styled(Tooltip)`
  display: block;
`;

export function EllipsisCell({
  text,
  dataTestId,
}: EllipsisCellProps): JSX.Element {
  const [hasOverflow, setHasOverflow] = useState<boolean>();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOverflow = () => {
    if (containerRef.current) {
      const isOverflowing =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setHasOverflow(isOverflowing);
    }
  };

  useEffect(() => {
    handleOverflow();
  }, [text]);

  return (
    <StyledTooltip content={hasOverflow ? text : ''}>
      <EllipsableSpan
        data-testid={dataTestId || 'ellipsis-cell'}
        ref={containerRef}
      >
        {text}
      </EllipsableSpan>
    </StyledTooltip>
  );
}
