import styled from '@emotion/styled';
import {
  BannerToDisplay,
  NAV_TYPE,
  useNavigation,
} from '@innovamat/ga-features';
import { Navigate, useParams } from 'react-router-domv6';
import { useSitemapNavigation } from '../../hooks/use-sitemap-navigation';
import { Header } from '../layout/header';
import { MainLayout } from '../layout/main-layout';
import { ContentPageRouter } from './content-page-router';
import { LessonsProviderWrapper } from './lessons-provider';
import { NavBreadcrumb } from './nav-breadcrumb';

const StyledBanners = styled(BannerToDisplay)`
  padding: 0 32px 24px 0;
`;

export function CourseNavigationWrapper(): JSX.Element {
  const { menu, courseId, submenu } = useParams();
  const { goBackOrCourseSelector } = useNavigation();

  const {
    actualCourse,
    pageName,
    isPending,
    sitemap,
    sitemapTree,
    getFirstSitemapRoute,
    isPageIdAsSubmenu,
  } = useSitemapNavigation();

  if (!menu || (!sitemap && !isPending && !isPageIdAsSubmenu)) {
    const firstSitemapRoute = getFirstSitemapRoute();
    const route = `/${NAV_TYPE.Course}/${courseId}${firstSitemapRoute}/page`;
    return <Navigate to={route} replace />;
  }

  const title = sitemap?.value || '';
  const pageId = isPageIdAsSubmenu
    ? submenu
    : sitemap?.fullPageId || sitemap?.demoPageId;

  return (
    <>
      <Header
        sitemapTree={sitemapTree}
        leftActions={
          <NavBreadcrumb
            fromPageName={pageName}
            pageName={actualCourse?.name || ''}
            onGoBack={goBackOrCourseSelector}
          />
        }
        hasBackButton
        middleText={pageName}
      />
      <MainLayout sitemapTree={sitemapTree}>
        <LessonsProviderWrapper>
          <StyledBanners />
          <ContentPageRouter title={title} pageId={pageId} />
        </LessonsProviderWrapper>
      </MainLayout>
    </>
  );
}
