import styled from '@emotion/styled';
import { Loader } from '@innovamat/glimmer-components';
import { Button } from '@innovamat/innova-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-domv6';
import { useUser } from '../../../user-management';
import TwoColumnLayout from '../../components/two-columns-layout/two-columns-layout';
import { useLinkSchool } from '../../hooks/use-link-school';

const SuccessBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

const Title = styled.h3`
  margin-bottom: 0;
  font-size: 28px;
  font-weight: normal;
`;

const StyledLink = styled(Link)`
  margin-top: 32px;
`;

const Error = styled.span`
  color: ${(props) => props.theme.colors.semantic['03']};
`;

const LinkSchool = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const url = new URL(window.location.href);
  const requestId = url.searchParams.get('id');
  const token = url.searchParams.get('token');
  const [schoolName] = useState(url.searchParams.get('school_name'));
  const { isLoggedIn, onClearUserSession } = useUser();
  const { linkSchool, isSuccess, isError } = useLinkSchool();

  useEffect(() => {
    if (token && requestId) {
      linkSchool({ requestId, token });
    } else if (!isError && !isSuccess) navigate('/');
  }, [requestId, token]);

  useEffect(() => {
    if (isLoggedIn) {
      onClearUserSession();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  return (
    <>
      <TwoColumnLayout
        isSuccess={isSuccess}
        onSuccessBody={
          <SuccessBody>
            <Title>{t('Tu usuario ha sido vinculado a la escuela:')}</Title>
            <h3>{schoolName}</h3>

            <StyledLink to="/">
              <Button fill>{t('Iniciar sesión')}</Button>
            </StyledLink>
          </SuccessBody>
        }
      >
        <>
          {isError ? (
            <Error>
              <h3>{t('Ha ocurrido un error al vincular con la escuela')}</h3>
            </Error>
          ) : (
            <Loader />
          )}
        </>
      </TwoColumnLayout>
    </>
  );
};

export { LinkSchool };
