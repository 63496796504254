import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { resetPassword } from './api';
import type { ResetPassword } from './reset-password';

export function useResetPassword() {
  const { apiUsers } = useRegionalConfigVariables();
  const mutation = useMutation<void, AxiosError, ResetPassword>({
    mutationFn: async (data) => {
      await resetPassword(data, apiUsers);
    },
  });

  return { ...mutation, resetPassword: mutation.mutate };
}
