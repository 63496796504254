import styled from '@emotion/styled';
import { Skeleton } from '@innovamat/glimmer-components';
import { MockImage } from '../../contents/components/content-list/content-list.styled';
import { DescriptionContainer } from './unblocked-applet-item';

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 12px;
  width: 100%;
`;

const ThumbnailSkeleton = styled.div`
  width: 151px;
  border-radius: 4px;
`;

const UnblockedAppletsDrawerSkeleton: React.FC = () => {
  const items = Array.from({ length: 6 });

  return (
    <>
      {items.map((_, index) => (
        <Container key={index}>
          <ThumbnailSkeleton>
            <MockImage>
              <Skeleton width="100%" height="88px" />
            </MockImage>
          </ThumbnailSkeleton>
          <DescriptionContainer>
            <Skeleton width="100%" height="88px" />
          </DescriptionContainer>
        </Container>
      ))}
    </>
  );
};

export default UnblockedAppletsDrawerSkeleton;
