import type { AppletsBySessionCodeQuery } from '@innovamat/glow-api-client';
import { useUnblockedApplets } from '@innovamat/glow-api-client';
import { useCurrentNavigationValues } from '../../contents';
import { useLocalizationParams } from '../../contents/hooks/use-localization-params';

type Props = {
  sessionCode: string;
};

type UseUnblockedAppletsDrawerValues = {
  isLoading: boolean;
  unblockedApplets:
    | AppletsBySessionCodeQuery['appletsBySessionCode']
    | undefined;
};

export const useUnblockedAppletsDrawer = ({
  sessionCode,
}: Props): UseUnblockedAppletsDrawerValues => {
  const { language, region } = useLocalizationParams();
  const { currentClassroom, currentCourse } = useCurrentNavigationValues();

  const {
    data: unblockedApplets,
    isFetching,
    isLoading: isUnblockedAppletsLoading,
  } = useUnblockedApplets({
    classroomId: currentClassroom?.id || '',
    language: language,
    courseId: currentClassroom?.courseId || currentCourse?.id || '',
    courseOrder: currentClassroom?.courseOrder || currentCourse?.order || 0,
    sessionCode,
    region: region,
  });

  const isLoading = isFetching || isUnblockedAppletsLoading;

  return {
    isLoading,
    unblockedApplets: unblockedApplets?.appletsBySessionCode,
  };
};
