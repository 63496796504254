import {
  TableCellSkeleton,
  TableComponent,
  generateRowsForTableSkeleton,
  useColumnsCustomRenderConfig,
} from '@innovamat/glimmer-components';

import { TEST_STATUS } from '../../../../../../modules/test/test';
import type { MockExamsStudentRow } from '../../../../../../types/mock-exams';
import { useMockExamsDrawerTableColumnsConf } from '../../hooks/use-drawer-table-columns-conf';

function MockExamsDrawerTableSkeleton(): JSX.Element {
  const mockObject: MockExamsStudentRow = {
    id: '',
    firstName: '',
    lastName: '',
    status: TEST_STATUS.NOT_STARTED,
    score: 0,
    inClassroom: true,
    statements: [],
  };

  const rowsNumber = 6;

  const configurationWithoutRender = useMockExamsDrawerTableColumnsConf(12);

  const customRenderConfig = {
    firstName: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    lastName: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    status: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    score: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    statements: (_: any, row: any) => (
      <div style={{ display: 'flex', gap: '1rem' }}>
        {Array.from({ length: 12 }).map((_, index) => (
          <div style={{ minWidth: '32px' }}>
            <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
          </div>
        ))}
      </div>
    ),
  };

  const columns = useColumnsCustomRenderConfig<MockExamsStudentRow>(
    customRenderConfig,
    configurationWithoutRender
  );

  const rows = generateRowsForTableSkeleton(rowsNumber, mockObject);

  return (
    <TableComponent
      columns={columns}
      id="tests-and-results-table-skeleton"
      key="tests-and-results-table-skeleton"
      rows={rows}
      style={{ overflow: 'hidden' }}
    />
  );
}

export { MockExamsDrawerTableSkeleton };
