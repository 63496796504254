import { useTranslation } from 'react-i18next';
import type { Params } from 'react-router-domv6';
import { useParams } from 'react-router-domv6';

import { Grounder, HeadingSection } from '@innovamat/glimmer-components';
import { useTestsAndResultsQuery } from '@innovamat/glow-api-client';

import { useUser } from '../../../user-management';

import type { Status, TestType } from '../../modules/test/test';

import { EMPTY_CELL_VALUE } from '../../utils';
import { MockExamsProvider } from './components/mock-exams-drawer/context/mock-exams-provider';
import { TestsAndResultsTable } from './components/test-and-results-table/tests-and-results-table';
import { TypesOfTestsAnnouncementPanel } from './components/types-of-tests-announcement-panel/types-of-tests-announcement-panel';
import { TestsAndResultsEmptyState } from './tests-and-results.empty-state';
import { TestsAndResultsSkeleton } from './tests-and-results.skeleton';

const TestsAndResults = (): JSX.Element => {
  const { t } = useTranslation();

  const { classroomId } = useParams<Params>();
  const { user } = useUser();

  const {
    data,
    isLoading: isLoadingTests,
    isError,
  } = useTestsAndResultsQuery(
    {
      classroomId: classroomId ?? '',
      academicYearId: user?.organization?.academicYearId ?? '',
    },
    { enabled: !!classroomId || !!user?.organization?.academicYearId }
  );

  const testsAndResults = data?.testsAndResults ?? [];

  const testAndResultsRows = testsAndResults.map((test) => {
    const { test: testDetails, ...restTestProps } = test;

    return {
      ...restTestProps,
      id: test.classroomTestRoundId,
      rowName: t(`${test.roundName}`, EMPTY_CELL_VALUE),
      rowDescription: t(`${test.roundName}.desc`, ''),
      type: testDetails?.type as TestType,
      status: test.status as Status,
    };
  });

  const getViewContent = (): JSX.Element => {
    if (isLoadingTests || !testsAndResults || isError) {
      return <TestsAndResultsSkeleton />;
    }

    if (!testsAndResults.length) {
      return <TestsAndResultsEmptyState />;
    }

    return (
      <MockExamsProvider rows={testAndResultsRows}>
        <TypesOfTestsAnnouncementPanel
          classroomId={classroomId}
          userId={user?.id}
        />
        <TestsAndResultsTable rows={testAndResultsRows} />
      </MockExamsProvider>
    );
  };

  return (
    <>
      <HeadingSection title={t('testsAndResultsView.title')} />
      {getViewContent()}

      <Grounder />
    </>
  );
};

export { TestsAndResults };
