import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { Content, PortraitPage, Text, Title } from '../common';

type Props = {
  name: string;
};

export function Introduction({ name }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <PortraitPage id="page-2-portrait">
      <Content>
        <Title>{t('print-tests-and-results.introduction.title')}</Title>
        <Text>
          <Typography.Body2>
            {t('mock-exams-print-intro', {
              'test-title': name,
            })}
          </Typography.Body2>
        </Text>
      </Content>
    </PortraitPage>
  );
}
