/* eslint-disable */
import { Trans, useTranslation } from 'react-i18next';
import { ButtonProps, Modal } from '@innovamat/glimmer-components';

type Props = {
  showErrorModal: boolean;
  closeErrorModal: () => void;
  userExistsError: boolean;
  expiredLinkError: boolean;
  email: string | undefined;
  loadingReenvitation: boolean;
  goToLogin: () => void;
  goToForgotPassword: () => void;
  onResendLink: () => void;
};

export default function ModalError({
  showErrorModal,
  userExistsError,
  expiredLinkError,
  loadingReenvitation,
  goToForgotPassword,
  goToLogin,
  onResendLink,
  closeErrorModal,
}: Props) {
  const { t } = useTranslation();

  const getTitle = () => {
    if (userExistsError) {
      return t('teacher.onboarding.createProfileFilled.errorExists.title');
    }

    if (expiredLinkError) {
      return t('teacher.onboarding.createProfileFilled.errorExpired.title');
    }

    return t('teacher.onboarding.createProfileFilled.error.title');
  };

  const getBody = () => {
    if (userExistsError) {
      return (
        <>{t('teacher.onboarding.createProfileFilled.error.errorExists')}</>
      );
    }

    if (expiredLinkError) {
      return (
        <Trans
          i18nKey="teacher.onboarding.createProfileFilled.error.expired"
          components={{
            br: <br />,
          }}
        />
      );
    }

    return <>{t('teacher.onboarding.createProfileFilled.error.body')}</>;
  };

  const actionButtons = (): ButtonProps[] => {
    if (userExistsError) {
      return [
        {
          children: t('Iniciar sesión'),
          onClick: goToLogin,
          variant: 'accent',
        },
        {
          children: t('forgotPassword', 'He olvidado mi contraseña'),
          onClick: goToForgotPassword,
          variant: 'tertiary',
        },
      ];
    }

    if (expiredLinkError) {
      return [
        {
          children: t('teacher.onboarding.createProfileFilled.resendLink'),
          onClick: onResendLink,
          variant: 'accent',
          loading: loadingReenvitation,
        },
        {
          children: t('Settings.Cancel'),
          onClick: closeErrorModal,
          variant: 'tertiary',
        },
      ];
    }
    return [
      {
        children: t('teacher.onboarding.createProfileFilled.error.button'),
        onClick: closeErrorModal,
        variant: 'accent',
      },
    ];
  };

  return (
    <Modal
      isOpen={showErrorModal}
      onClose={closeErrorModal}
      closeOnClickOutside={!userExistsError && !expiredLinkError}
      title={getTitle()}
      modalWidth="650px"
      closeButton="inner"
      buttons={actionButtons()}
    >
      {getBody()}
    </Modal>
  );
}
