import { useTestQuestionsAvailableLanguagesQuery } from '@innovamat/glow-api-client';
import type {
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';

type TestQuestionsAvailableLanguagesData = {
  testQuestionsAvailableLanguages?: string[] | null;
};

type TestQuestionsAvailableLanguages = {
  data: string[];
  isError: boolean;
  isRefetching: boolean;
  isSuccess: boolean;
  refetch: (
    options?: RefetchOptions
  ) => Promise<
    QueryObserverResult<TestQuestionsAvailableLanguagesData, unknown>
  >;
};

type Props = {
  testId: string;
  enabled?: boolean;
};

function useGetTestQuestionsAvailableLanguages({
  testId,
  enabled = false,
}: Props): TestQuestionsAvailableLanguages {
  const { data, isRefetching, isSuccess, refetch, isError } =
    useTestQuestionsAvailableLanguagesQuery(
      {
        testId: testId,
      },
      {
        retry: false,
        enabled: enabled,
        staleTime: 0,
        gcTime: 0,
      }
    );

  return {
    data: data?.testQuestionsAvailableLanguages
      ? (data.testQuestionsAvailableLanguages.filter(
          (lang): lang is string => lang !== null
        ) as string[])
      : [],
    isError,
    isRefetching,
    isSuccess,
    refetch: refetch as (
      options?: RefetchOptions
    ) => Promise<
      QueryObserverResult<TestQuestionsAvailableLanguagesData, unknown>
    >,
  };
}

export { useGetTestQuestionsAvailableLanguages };
