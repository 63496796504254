import { useLocalStorage } from '@innovamat/hooks';
import { useLocation } from 'react-router-domv6';
import { usePermissionCourses } from '../../classrooms-management/hooks/use-permission-courses';
import { useCurrentNavigationValues } from '../../navigation';
import { useUser } from '../../user-management';
import type { BannersFilter, FormatBanner } from '../types';
import { VISIBLE_OPTIONS } from '../types';
import { languageMapper } from '../utils/language-mapper';
import { useGetBanners } from './use-get-banners';

type DisplayBanners = {
  banners: FormatBanner[];
  onCloseBanner: (id: string) => void;
};

function useDisplayBanners(): DisplayBanners {
  const { user } = useUser();
  const { currentPage, courseOrder, courseId, isClassroomFetching } =
    useCurrentNavigationValues();
  const {
    hasSomePermissionsForAssessment,
    hasSomePermissionsForIntervention,
    permissionsByCurrentCourse,
  } = usePermissionCourses({ courseId });
  const language = user?.locale;
  const region = user?.region;
  const roles = user?.roles;

  const location = useLocation();

  const visbileAt =
    location.pathname === '/'
      ? VISIBLE_OPTIONS.LAUNCHER
      : location.pathname.includes('course')
      ? VISIBLE_OPTIONS.COURSE_NAVIGATION
      : VISIBLE_OPTIONS.INSIDE_CLASS;

  const [closedBanners, setClosedBanners] = useLocalStorage<string[]>(
    'closedBanners',
    []
  );

  const getBannerFilters = () => {
    if (!language || !region || !roles) return undefined;
    return {
      locale: languageMapper(language),
      region,
      roles,
      course: courseOrder,
      permissions: {
        assessment: courseId
          ? permissionsByCurrentCourse.assessment
          : hasSomePermissionsForAssessment(),
        intervention: courseId
          ? permissionsByCurrentCourse.earlyIntervention
          : hasSomePermissionsForIntervention(),
      },
    } as BannersFilter;
  };

  const hasDemo = !currentPage?.fullPageId;

  const query = useGetBanners({
    filters: getBannerFilters(),
    hasDemo,
  });

  const onCloseBanner = (id: string) => {
    const newBanners = [...closedBanners, id];
    setClosedBanners(newBanners);
  };

  const filterByVisibleAt = (banner: FormatBanner): boolean => {
    return banner.visibleAt === visbileAt;
  };

  const filterByClosed = (banner: FormatBanner): boolean => {
    return !closedBanners.includes(banner.id);
  };

  const sortByImportance = (a: FormatBanner, b: FormatBanner): number => {
    return a.importance - b.importance;
  };

  const banners =
    (!isClassroomFetching &&
      query.data
        ?.filter(filterByClosed)
        .filter(filterByVisibleAt)
        .sort(sortByImportance)) ||
    [];

  return { banners, onCloseBanner };
}

export { useDisplayBanners };
