import styled from '@emotion/styled';
import { Button } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useCheckIsUpdatePeriod } from '../../../../components/update-period-announcement-panel';
import { useUser } from '../../../../user-management';
import { AddClassroomDropdown } from '../../../components/add-classroom-dropdown';
import { useDownloadClassroomCsv } from '../../../hooks/use-download-classroom-csv';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;

function SchoolClassroomsHeader() {
  const { t } = useTranslation();
  const { user } = useUser();
  const isUpdatePeriod = useCheckIsUpdatePeriod();

  const { onDownload, isLoading } = useDownloadClassroomCsv();

  return (
    <Wrapper>
      <Button
        leftIcon="FileCsvIcon"
        loading={isLoading}
        dataTestId="school-export-button"
        variant="secondary"
        onClick={onDownload}
      >
        {t('common.export')}
      </Button>
      <AddClassroomDropdown
        size="M"
        disabled={!user || isUpdatePeriod}
        organizationId={user?.organizationId!}
        region={user?.region!}
        options={['add', 'import', 'join']}
      />
    </Wrapper>
  );
}

export { SchoolClassroomsHeader };
