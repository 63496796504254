import styled from '@emotion/styled';
import { Card } from '../card';
import { Typography } from '../typography';

const DetailsCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Header = styled.div<{ hasCount: boolean }>`
  display: flex;
  padding: ${({ hasCount }) =>
    hasCount ? ' 1.5rem 1rem 0 0' : ' 1.5rem 1rem 0 3.75rem'};
  align-items: flex-start;
`;

const Count = styled.h4`
  text-align: center;
  width: 3.75rem;
`;

const Body = styled.div<{ hasFooter: boolean }>`
  padding: ${({ hasFooter }) =>
    hasFooter ? '0rem 3.75rem 1rem 3.75rem' : '0rem 3.75rem 3.75rem 3.75rem;'};
`;

const Footer = styled.div`
  display: flex;
  padding: 0rem 3.75rem 3.75rem 3.75rem;
  width: 100%;
`;

type HeaderProps = {
  count?: string | number;
  title: string;
};

type Props = {
  body: React.ReactNode;
  header?: HeaderProps;
  footer?: React.ReactNode;
};

function DetailsCard({ header, body, footer }: Props): JSX.Element {
  return (
    <DetailsCardWrapper borderRadius="md" elevation="elevation 2">
      {header && (
        <Header hasCount={!!header.count}>
          {header?.count && <Count>{header.count}</Count>}
          {header.title && <Typography.H4>{header.title}</Typography.H4>}
        </Header>
      )}
      {body && <Body hasFooter={!!footer}>{body}</Body>}
      {footer && <Footer>{footer}</Footer>}
    </DetailsCardWrapper>
  );
}

export { DetailsCard };
