type Props = {
  base64: string;
  name: string;
};

function base64toCSVFile({ base64, name }: Props): void {
  try {
    const csvBase64 = `data:application/csv;base64,${base64}`;
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    const fileName = `${name}_${formattedDate}.csv`;
    const link = document.createElement('a');
    link.href = csvBase64;
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error('Error downloading file', error);
  }
}

const openBase64PdfInNewTab = (
  base64Content: string,
  windowRef?: Window | null
): void => {
  const byteArray = Buffer.from(base64Content, 'base64');
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const blobUrl = URL.createObjectURL(blob);

  windowRef && !windowRef.closed
    ? (windowRef.location.href = blobUrl)
    : window.open(blobUrl, '_blank');
};

const downloadBase64PdfToDevice = (
  base64Content: string,
  fileName: string
): Promise<void> => {
  return new Promise((resolve) => {
    const pdfBase64 = `data:application/pdf;base64,${base64Content}`;
    const link = document.createElement('a');
    link.href = pdfBase64;
    link.download = fileName;

    // We need to wait a bit to ensure the download starts
    link.addEventListener('click', () => {
      setTimeout(resolve, 50);
    });

    link.click();
  });
};

const downloadBase64PdfFile = async ({
  base64Content,
  fileName,
  openInNewTab = false,
  windowRef,
}: {
  base64Content: string;
  fileName: string;
  openInNewTab?: boolean;
  windowRef?: Window;
}): Promise<void> => {
  if (openInNewTab) {
    openBase64PdfInNewTab(base64Content, windowRef);
    return Promise.resolve();
  } else {
    return downloadBase64PdfToDevice(base64Content, fileName);
  }
};

export { base64toCSVFile, downloadBase64PdfFile };
