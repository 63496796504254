import { usePage } from '@innovamat/glow-api-client';
import { useParams } from 'react-router-domv6';
import { Page } from '..';
import { useUser } from '../../user-management';

export function useGetPageFromUrl() {
  const { classroomId } = useParams();
  const { user, isRole, isStudentViewActive } = useUser();
  const academicYearId = user?.organization?.academicYearId;
  const url = new URL(window.location.href);
  const pageId = url.searchParams.get('fromPage');
  const urlRegion = url.searchParams.get('region');
  const urlLanguage = url.searchParams.get('language');

  const language = urlLanguage || user?.locale;
  const region = urlRegion || user?.region;

  const { data } = usePage({
    pageId: pageId!,
    academicYearId: academicYearId!,
    classroomId: classroomId!,
    isStudentView: Boolean(isStudentViewActive || isRole.Student),
    region: region!,
    language: language!,
  });

  const page = data as unknown as Page | undefined;

  const getResourceById = (resourceId: string) => {
    if (!classroomId) return;

    return page?.content_lists
      ?.flatMap((list) => list.resources)
      .find((resource) => resource.id === resourceId);
  };

  return { page, getResourceById };
}
