import { css, SerializedStyles } from '@emotion/react';
import { BreakPageMode } from '../hooks/useBreakPageMode';

interface StyledBreakPageProps {
  isEditing: boolean;
  breakPageMode?: BreakPageMode;
}

export const BREAK_MODE_COLORS = {
  after: '#33A1FF',
};

const BREAK_MODE_ICONS = {
  after: '↓',
};

const BREAK_MODE_LABELS = {
  after: 'Break After',
};

export const getEditModeStyles = ({
  isEditing,
}: {
  isEditing: boolean;
}): SerializedStyles | null => {
  if (!isEditing) return null;

  return css`
    &:hover {
      outline: 3px solid ${BREAK_MODE_COLORS.after};
      cursor: pointer;
    }
  `;
};

export const getActiveBreakModeStyles = ({
  isEditing,
  breakPageMode,
}: StyledBreakPageProps): SerializedStyles | null => {
  if (!isEditing || !breakPageMode) return null;

  const borderColor = BREAK_MODE_COLORS[breakPageMode];
  const breakIcon = BREAK_MODE_ICONS[breakPageMode];
  const label = BREAK_MODE_LABELS[breakPageMode];

  return css`
    outline: 3px dashed ${borderColor};
    position: relative;

    &::before {
      content: '${breakIcon} ${label}';
      position: absolute;
      bottom: 4px;
      right: 4px;
      background-color: ${borderColor};
      color: white;
      padding: 2px 6px;
      border-radius: 4px;
      font-size: 10px;
      z-index: 1;
    }
  `;
};

export const getBreakPrintStyles = ({
  breakPageMode,
}: {
  breakPageMode?: BreakPageMode;
}): SerializedStyles => {
  return css`
    @media print {
      border: none !important;
      outline: none !important;

      &::before,
      &::after {
        display: none !important;
      }

      ${breakPageMode === 'after' &&
      css`
        break-after: column;
      `}

      ${breakPageMode === undefined &&
      css`
        break-before: auto;
        break-after: auto;
        break-inside: auto;
      `}
    }
  `;
};
