import { sortBy } from '@innovamat/radiance-utils';
import { useMemo } from 'react';

import {
  useEducationalPlansQuery,
  useOrganizationQuery,
} from '@innovamat/glow-api-client';

type Props = {
  filterOrderCourse?: number | undefined | null;
  organizationId: string;
};

type Option = {
  value: string;
  label: string;
};

function useEducationalPlans({ filterOrderCourse, organizationId }: Props) {
  const { data: { organization } = {} } = useOrganizationQuery(
    { id: organizationId },
    { staleTime: Infinity, enabled: !!organizationId }
  );

  const { data, isLoading } = useEducationalPlansQuery(
    {
      regionCode: organization?.operativeRegion!,
      courseOrder: filterOrderCourse || null,
    },
    {
      enabled: !!organization,
    }
  );

  const educationPlans = useMemo(
    () => data?.educationalPlans?.concat().sort(sortBy('courseOrder')) || [],
    [data]
  );

  const educationOptions: Option[] = useMemo(() => {
    return educationPlans.map((plan) => ({
      label: plan?.educationalPlanName!,
      value: plan?.id!,
    }));
  }, [educationPlans]);

  const getEducationalPlanByCourse = (courseId: string) => {
    return (
      educationPlans.find((ed) => ed?.courseId === courseId) ||
      educationPlans[0]
    );
  };

  return {
    educationOptions,
    educationPlans,
    loading: isLoading,
    getEducationalPlanByCourse,
  };
}

export { useEducationalPlans };
