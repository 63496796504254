import styled from '@emotion/styled';
import { useFlag } from '@innovamat/flags';
import {
  Grounder,
  InnerHtml,
  Skeleton,
  Typography,
} from '@innovamat/glimmer-components';
import {
  useGetAppletsOfTheWeekQuery,
  usePage,
} from '@innovamat/glow-api-client';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import type { Params } from 'react-router-domv6';
import { useParams } from 'react-router-domv6';
import DigitalPracticeInformation from '../../../digital-practice-information/digital-practice-information';
import type { MarkSessionMethodParams } from '../../../session-done';
import { useShowAnimatedDigitalPractice } from '../../../user-management/hooks/use-show-digital-practice-information';
import {
  ContentList,
  ContentListSkeleton,
} from '../../components/content-list';
import { SkeletonContainer } from '../../components/content-list/content-list.styled';
import { useCurrentNavigationValues } from '../../hooks';
import type { ContentListType, ContentResource, Page } from '../../types/page';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type ContentListsPageProps = {
  academicYearId: string;
  banners?: JSX.Element;
  classroomId?: string;
  imgOptimizationUrl?: string;
  isPaginationEnabled: boolean;
  isStudentView?: boolean;
  leadAnnouncementPanel?: JSX.Element;
  pageId?: string;
  title?: string;
  yearUpdateAnnouncementPanel?: JSX.Element;
  region: string;
  language: string;
  onError: () => void;
  onMarkSession?: (params: MarkSessionMethodParams) => void;
  onResourceClick: (
    resource: ContentResource,
    list: ContentListType,
    page: Page
  ) => void;
  onShowMoreClick?: (newPageId: string, listId: string) => void;
  onToggleSolutions?: (params: MarkSessionMethodParams) => void;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
};

function ContentListPageSkeleton(): JSX.Element {
  return (
    <SkeletonContainer>
      <Skeleton height="32" width="140" />
      <ContentListSkeleton />
      <ContentListSkeleton />
      <ContentListSkeleton />
    </SkeletonContainer>
  );
}

export function ContentPage({
  academicYearId,
  banners,
  classroomId,
  imgOptimizationUrl,
  isPaginationEnabled,
  isStudentView,
  leadAnnouncementPanel,
  pageId,
  title,
  yearUpdateAnnouncementPanel,
  region,
  language,
  onError,
  onMarkSession,
  onResourceClick,
  onShowMoreClick,
  onToggleSolutions,
}: ContentListsPageProps): JSX.Element {
  const hasPageId = !!pageId;
  const { menu } = useParams<Params>();
  const { courseOrder } = useCurrentNavigationValues();
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = usePage({
    pageId: pageId!,
    academicYearId: academicYearId!,
    classroomId: classroomId!,
    isStudentView: Boolean(isStudentView),
    paginated: isPaginationEnabled,
    region,
    language,
  });

  const { value: showDigitalPracticeInformationFeatureFlag } = useFlag(
    'showDigitalPracticeInformation',
    false,
    { identifier: '', custom: { classroomId: classroomId || '' } }
  );

  const { showAnimatedDigitalPractice } = useShowAnimatedDigitalPractice({
    courseOrder,
    menu,
  });

  const {
    data: appletsOfTheWeek,
    isLoading: isAppletsOfTheWeekLoading,
    isFetching: isAppletsOfTheWeekFetching,
    refetch: refetchAppletsOfTheWeek,
  } = useGetAppletsOfTheWeekQuery(
    {
      classroomId: classroomId!,
      locale: language,
    },
    {
      enabled:
        showAnimatedDigitalPractice &&
        showDigitalPracticeInformationFeatureFlag,
    }
  );

  const page = data as unknown as Page;

  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const isInitialLoading =
    isFirstRender.current &&
    (isAppletsOfTheWeekLoading || isAppletsOfTheWeekFetching);

  if (isLoading || isInitialLoading || !hasPageId || !page) {
    return <ContentListPageSkeleton />;
  }

  if (isError || !page) {
    onError();
  }

  const handleOnMarkSession = (params: MarkSessionMethodParams): void => {
    const onMarkSessionFinished = (): void => {
      if (params.value === false) {
        queryClient.invalidateQueries({ queryKey: ['getAppletsOfTheWeek'] });
      }
    };

    const paramsWithOnMarkSessionFinished = {
      ...params,
      onMarkSessionFinished,
    };

    if (onMarkSession) {
      onMarkSession(paramsWithOnMarkSessionFinished);
    }
  };

  return (
    <>
      <Container>
        {leadAnnouncementPanel}
        <Typography.H3 data-cy="contentTitle">
          <InnerHtml text={title || page.name} />
        </Typography.H3>
        {banners}
        {yearUpdateAnnouncementPanel}

        {page?.content_lists?.map((list, index) => {
          return (
            <ContentList
              isPaginationEnabled={isPaginationEnabled}
              key={index}
              list={list}
              onResourceClick={(resource) => {
                onResourceClick(resource, list, page);
              }}
              onShowMoreClick={
                list.page_id && onShowMoreClick
                  ? () => onShowMoreClick(list.page_id, list.id)
                  : undefined
              }
              imgOptimizationUrl={imgOptimizationUrl}
              onMarkSession={handleOnMarkSession}
              onToggleSolutions={onToggleSolutions}
              academicYearId={academicYearId}
              classroomId={classroomId}
              isStudentView={isStudentView}
            />
          );
        })}
        {showDigitalPracticeInformationFeatureFlag && (
          <DigitalPracticeInformation
            appletsOfTheWeek={appletsOfTheWeek?.getAppletsOfTheWeek}
            courseOrder={courseOrder || 0}
            onConfigurationChange={refetchAppletsOfTheWeek}
            isVisible={showAnimatedDigitalPractice}
          />
        )}
      </Container>
      <Grounder />
    </>
  );
}
