import { useEffect, useState } from 'react';

import { ButtonCircle } from '../button-circle';
import {
  Container,
  ContentWrapper,
  CounterWrapper,
} from './material-card-controls.styles';
import { Tooltip } from '../../tooltip';

type Props = {
  counterValue: number;
  isDecrementDisabled: boolean;
  tooltipMessage: string;
  isOpened: boolean;
  onDecrement: () => void;
  onIncrement: () => void;
};

const MaterialCardControls = ({
  counterValue,
  isDecrementDisabled,
  isOpened,
  tooltipMessage,
  onDecrement,
  onIncrement,
}: Props) => {
  const [render, setRender] = useState(isOpened);

  useEffect(() => {
    if (isOpened) setRender(true);
  }, [isOpened]);

  const onAnimationEnd = () => {
    if (!isOpened) setRender(false);
  };

  return render ? (
    <Container
      isOpened={isOpened}
      borderRadius="sm"
      elevation="elevation 2"
      height="2.75rem"
      width="100%"
      onAnimationEnd={onAnimationEnd}
    >
      <ContentWrapper>
        <Tooltip
          content={tooltipMessage}
          placement="top"
          popperOptions={{ strategy: 'fixed' }}
        >
          <ButtonCircle
            disabled={isDecrementDisabled}
            icon="SubtractIcon"
            onClick={onDecrement}
          />
        </Tooltip>
        <CounterWrapper>{counterValue}</CounterWrapper>
        <ButtonCircle icon="AddUpIcon" onClick={onIncrement} />
      </ContentWrapper>
    </Container>
  ) : (
    <></>
  );
};

export { MaterialCardControls };
