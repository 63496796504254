import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { EmptySession } from '@innovamat/glimmer-assets';
import { AnnouncementPanel, EmptyState } from '@innovamat/glimmer-components';
import type { AppletsBySessionCodeQuery } from '@innovamat/glow-api-client';
import { useTranslation } from 'react-i18next';
import type { UnblockedApplet } from './unblocked-applet-item';
import UnblockedAppletItem from './unblocked-applet-item';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DrawerContent = ({
  applets,
}: {
  applets: AppletsBySessionCodeQuery['appletsBySessionCode'];
}): EmotionJSX.Element => {
  const { t } = useTranslation();

  if (applets === null || applets === undefined) {
    return (
      <AnnouncementPanel
        text={t('session.sessionApplets.messageError')}
        canClose={false}
        type="info"
      />
    );
  }

  if (applets.length === 0) {
    return (
      <EmptyState
        Image={EmptySession}
        title={t('unblockedAppletsDrawer.emptyState.title')}
      />
    );
  }

  return (
    <CardContainer>
      {applets?.map((applet) => (
        <UnblockedAppletItem
          key={applet?.data?.id}
          applet={applet as UnblockedApplet}
        />
      ))}
    </CardContainer>
  );
};

export default DrawerContent;
