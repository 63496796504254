const COURSE_GRADE_MAPPING: Record<string, string> = {
  '60a4d803-6cac-4bce-ab4b-3d32bcd9ecc8': 'K',
  '6feacabd-d5d2-4c1b-ac99-7e62aaed90df': '6Y',
  'bcb07522-47a5-4296-858b-1fbd96446340': '7Y',
  'ac16b7cb-9fdc-427b-a2f1-94f9867a8e24': '8Y',
  'd1512af7-c205-45cb-841e-100c4ea19f76': '9Y',
  '3581911f-b5a2-40ef-9c70-36a2d7380375': '10Y',
  '72fb339a-2bfd-4544-a7dc-0c010d34637d': '11Y',
  'fe60be20-ccc8-41cb-af15-b00ce0af44d6': '12Y',
  '1f8516be-fdf6-4a66-b2ac-9c7da908277e': '13Y',
};

export const getPacingGuideFileName = ({
  courseId,
  stretch,
}: {
  courseId: string;
  stretch?: string | null;
}): string => {
  const grade = COURSE_GRADE_MAPPING[courseId];
  const isKinder = grade === 'K';

  if (!grade) {
    return 'PacingGuide_en_US';
  }

  if (isKinder) {
    return `PacingGuide_${grade}${stretch ? `_S${stretch}` : ''}_en_US`;
  }

  return `Pacing Guide_${grade}${stretch ? `_Stretch ${stretch}` : ''}_en_US`;
};

export const getPersonalizedPracticeFileName = ({
  courseId,
  stretch,
  module,
}: {
  courseId: string;
  stretch?: string | null;
  module?: string | null;
}): string => {
  const grade = COURSE_GRADE_MAPPING[courseId];

  if (!grade) {
    return 'Personalized Practice_en_US';
  }

  return `Personalized Practice_${grade}${
    stretch ? `_Stretch ${stretch}` : ''
  }${module ? `_Module ${module}` : ''}_en_US`;
};
