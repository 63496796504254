import styled from '@emotion/styled';
import { type TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import type { CustomColumnRenderConfig } from '@innovamat/glimmer-components';
import {
  Icon,
  TableComponent,
  Tooltip,
  Typography,
  useColumnsCustomRenderConfig,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { ScoreCell } from '../../../../../components/score-cell';
import { StatementCell } from '../../../../../components/statement-cell/statement-cell';
import {
  MockExamsStudentRow,
  Statement,
  StatementMetadata,
} from '../../../../../types/mock-exams';

import {
  StatementsCol,
  StatementsContainer,
} from '../../../../../components/statements-table-header/statements-table-header';
import { TestStatusCell } from '../../../../test-and-results/components/test-and-results-table/components';
import { useMockExamsPrintTableColumnsConf } from '../hooks/use-print-table-columns-conf';

type MockExamsDrawerTableProps = {
  students: MockExamsStudentRow[];
  numStatements: number;
  statementsMetadata?: StatementMetadata[];
  statementOffset?: number;
  id: string;
};

const StudentName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoTooltip = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameTypography = styled(Typography.Body2)<{ isDisabled: boolean }>`
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? theme.tokens.color.alias.cm.text['text-disabled'].value
      : theme.tokens.color.alias.cm.text['text'].value};
`;

const StudentNameCell = ({
  value,
  isDisabled = false,
  hasTooltip = false,
  tooltipText,
}: {
  value: string;
  isDisabled?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
}): JSX.Element => {
  const theme = useGlimmerTheme();

  return (
    <StudentName>
      <NameTypography isDisabled={isDisabled}>{value}</NameTypography>
      {hasTooltip && (
        <InfoTooltip>
          <Tooltip content={tooltipText ?? ''} placement="top">
            <Icon
              icon="InfoIcon"
              size="S"
              iconColor="white"
              hasStateLayer
              bgColor={theme.tokens.color.alias.cm.icon['icon-info'].value}
            />
          </Tooltip>
        </InfoTooltip>
      )}
    </StudentName>
  );
};

const customColumnsRenderConfig = (
  t: TFunction,
  statementsMetadata?: StatementMetadata[]
): CustomColumnRenderConfig<MockExamsStudentRow> => ({
  firstName: (value: string, row: MockExamsStudentRow) => {
    return (
      <StudentNameCell
        value={value || t('common.unknown-student')}
        isDisabled={!row.inClassroom}
      />
    );
  },
  lastName: (value: string, row: MockExamsStudentRow) => {
    return (
      <StudentNameCell
        value={value}
        isDisabled={!row.inClassroom}
        hasTooltip={!!value && !row.inClassroom}
        tooltipText={t(
          'mock-exams-drawer.table.student-changed-classroom.label'
        )}
      />
    );
  },
  status: (_, { status }) => {
    return <TestStatusCell status={status} />;
  },
  score: (value: number) => {
    return (
      <ScoreCell
        score={value}
        decimalPlaces={1}
        isPercentage
        fitContentHeight
      />
    );
  },
  statements: (value: Statement[]) => {
    return (
      <StatementsContainer>
        {value.map((statement, index) => {
          const width =
            statementsMetadata?.find((s) => s.position === index)
              ?.answersMetrics?.largestAnswerWidth ?? 0;

          return (
            <StatementsCol
              width={width}
              key={statement.id || 'statement-row-' + index}
            >
              <StatementCell
                answer={statement.answer}
                isCorrect={statement.gradePercentage === 1}
              />
            </StatementsCol>
          );
        })}
      </StatementsContainer>
    );
  },
});

function MockExamsPrintTable({
  students,
  numStatements,
  statementsMetadata,
  statementOffset,
  id,
}: MockExamsDrawerTableProps): JSX.Element {
  const { t } = useTranslation();
  const columnsWithoutRender = useMockExamsPrintTableColumnsConf(
    numStatements,
    statementsMetadata ?? [],
    statementOffset
  );
  const columns = useColumnsCustomRenderConfig<MockExamsStudentRow>(
    customColumnsRenderConfig(t, statementsMetadata ?? []),
    columnsWithoutRender
  );

  return (
    <TableComponent
      tableSize="Small"
      columns={columns}
      rows={students}
      id={id}
    />
  );
}

export { MockExamsPrintTable };
