// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SVkNMIjtFoTAQpun{padding:12px 8px;border-radius:8px;display:flex;justify-content:space-between;align-items:center}.SVkNMIjtFoTAQpun div{display:flex;gap:8px;align-items:center}.SVkNMIjtFoTAQpun.rQQso4vKi8bNeXMT{background-color:#d9f0ee}.SVkNMIjtFoTAQpun.XvsfMz9da1k7hbrQ{background-color:#ffefd6}.SVkNMIjtFoTAQpun.jb9XwjAzH60gZRBA{background-color:#fbdee3}.SVkNMIjtFoTAQpun .FMTHT6Z7uA_m_JwT{cursor:pointer}.SVkNMIjtFoTAQpun span{font-size:12px;line-height:16px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/Notification/Notification.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CAGF,mCACE,wBCMU,CDHZ,mCACE,wBCGU,CAAA,mCDCV,wBAAA,CAGF,oCACE,cAAA,CAGF,uBACE,cCSK,CDRL,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "SVkNMIjtFoTAQpun",
	"info": "rQQso4vKi8bNeXMT",
	"warning": "XvsfMz9da1k7hbrQ",
	"error": "jb9XwjAzH60gZRBA",
	"closeIcon": "FMTHT6Z7uA_m_JwT"
};
export default ___CSS_LOADER_EXPORT___;
