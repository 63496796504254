import styled from '@emotion/styled';
import { Link } from 'react-router-domv6';

export const LinkStyled = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.dark['01']};
`;

export const AnchorStyled = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.dark['01']};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  a {
    cursor: pointer;
  }
`;

export const KnowMore = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  bottom: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.dark['02']};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  svg g path {
    stroke: ${({ theme }) => theme.colors.dark['02']};
  }
`;

export const SeparatorWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: center;
  span {
    margin: 0 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Separator = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark['05']};
  width: 100%;
  height: 1px;
`;

export const SignInWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
