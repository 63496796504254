import styled from '@emotion/styled';
import { useParams } from 'react-router-domv6';

import {
  BannerToDisplay,
  ERROR_TYPE,
  ErrorPage,
  useCurrentNavigationValues,
  useUser,
} from '@innovamat/ga-features';

import { useSitemapNavigation } from '../../hooks/use-sitemap-navigation';
import { Header } from '../layout/header';
import { MainLayout } from '../layout/main-layout';

import { ContentPageRouter } from './content-page-router';
import { DigitalPracticeContent } from './digital-practice-content';
import { LessonsProviderWrapper } from './lessons-provider';
import { Logo } from './logo';

const StyledBanners = styled(BannerToDisplay)`
  padding: 0 32px 24px 0;
`;

type ClassroomStaticMenus =
  | 'teachers'
  | 'students'
  | 'reports'
  | 'general'
  | 'tests-and-results';

type Params = {
  classroomId: string;
  menu?: ClassroomStaticMenus;
  submenu?: string;
};

export function DigitalPracticeNavigationWrapper(): JSX.Element {
  const { user } = useUser();
  const { menu, submenu } = useParams<Params>();
  const { currentClassroom } = useCurrentNavigationValues();
  const { sitemap, isPending, sitemapTree, isPageIdAsSubmenu } =
    useSitemapNavigation();

  const title = sitemap?.value || '';

  const pageId = isPageIdAsSubmenu
    ? submenu
    : sitemap?.fullPageId || sitemap?.demoPageId;

  if (!user) return <></>;

  const sitemapTreeError = !isPending && sitemapTree.length === 0;

  const getPageComponent = (): JSX.Element => {
    switch (menu) {
      case 'general':
        return <DigitalPracticeContent />;

      default:
        return (
          <>
            <StyledBanners />
            <ContentPageRouter title={title} pageId={pageId} />;
          </>
        );
    }
  };

  return (
    <>
      <Header
        leftActions={<Logo />}
        sitemapTree={sitemapTree}
        middleText={currentClassroom?.name!}
      />
      <MainLayout sitemapTree={sitemapTree} hideLateralMenu={sitemapTreeError}>
        <LessonsProviderWrapper>
          {sitemapTreeError ? (
            <ErrorPage errorType={ERROR_TYPE.ERROR_500} />
          ) : (
            getPageComponent()
          )}
        </LessonsProviderWrapper>
      </MainLayout>
    </>
  );
}
