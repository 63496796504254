import { Button, Select } from '@innovamat/glimmer-components';
import type { Classroom, Teacher } from '@innovamat/glow-api-client';
import {
  useClassroomsQuery,
  useOrganizationQuery,
} from '@innovamat/glow-api-client';
import { useOnClickOutside } from '@innovamat/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../../../user-management';
import { useAssignTeachersToClassroom } from '../../../../hooks/use-assign-teachers-to-classroom';

type Props = {
  teacher: Teacher | undefined;
  onSuccess: (classroom: Classroom) => void;
  displayClassrooms: Classroom[];
};

function AssignClassroomToTeacherButton({
  teacher,
  onSuccess,
  displayClassrooms,
}: Props): JSX.Element {
  const { user } = useUser();
  const { t } = useTranslation();
  const { data: organizationQuery } = useOrganizationQuery(
    { id: user?.organizationId! },
    { staleTime: Infinity, enabled: !!user?.organizationId }
  );
  const [showInput, setShowInput] = useState(false);
  const ref = useRef(null);

  const { handleAssignTeacherToClassrooms } = useAssignTeachersToClassroom({
    inviterId: user?.id!,
    organization: organizationQuery?.organization!,
    onSuccess: (data) => {
      const classroom = classroomsQuery?.classrooms?.find(
        (classroom) => classroom.id === data.classroomId
      );
      if (!classroom) return;
      onSuccess(classroom);
    },
  });

  const { data: classroomsQuery } = useClassroomsQuery<{
    classrooms: Classroom[];
  }>({ mine: false, orgId: user?.organizationId! }, { staleTime: Infinity });

  const handleSelectClassroom = (classroomId: string): void => {
    if (!teacher) return;
    handleAssignTeacherToClassrooms({
      classroomId: classroomId,
      classroomName: classroomId,
      emails: [teacher.email],
    });

    setShowInput(false);
  };

  const classroomOptions = useMemo(() => {
    return (
      classroomsQuery?.classrooms
        ?.map((classroom) => ({
          label: classroom.name!,
          value: classroom.id,
        }))
        .filter(
          ({ value }) =>
            !displayClassrooms?.some(
              (teacherClassroom) => teacherClassroom?.id === value
            )
        ) || []
    );
  }, [classroomsQuery?.classrooms, displayClassrooms]);

  useEffect(() => {
    setShowInput(false);
  }, [teacher]);

  const handleShowInput = (): void => {
    setShowInput(true);
  };

  useOnClickOutside(ref, () => {
    if (showInput) {
      setShowInput(false);
    }
  });

  if (showInput) {
    return (
      <div ref={ref}>
        <Select
          defaultMenuIsOpen
          leftIcon="SearchIcon"
          isSearchable
          onChange={(option) =>
            option?.value && handleSelectClassroom(option?.value)
          }
          options={classroomOptions}
          placeholder={t('classroom.input.label.classname')}
          dataTestId="assign-classroom-select"
        />
      </div>
    );
  }

  return (
    <Button
      fill
      variant="secondary"
      onClick={handleShowInput}
      dataTestId="assign-classroom-button"
    >
      {t('manager.assign-classroom')}
    </Button>
  );
}

export { AssignClassroomToTeacherButton };
