export function convertToParagraphList(material: string[] | string): string[] {
  let materialWithParagraphs;
  if (!material) {
    return [];
  } else if (typeof material === 'string') {
    materialWithParagraphs = material.replace(/<br>/g, '</p><p>');
  } else {
    materialWithParagraphs = material.map((item) =>
      item.replace(/<br>/g, '</p><p>')
    );
  }

  let arrays = [];

  if (Array.isArray(materialWithParagraphs)) {
    arrays = materialWithParagraphs;
  } else {
    arrays.push(materialWithParagraphs);
  }

  const regex = /<p>([\s\S]*?)<\/p>/g;
  const matches = [];

  for (let i = 0; i < arrays.length; i++) {
    const array = arrays[i];
    const temporalMatches = array.match(regex) || [];
    matches.push(...(temporalMatches as string[]));
  }

  return matches;
}
