// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W9FSZqgjpMe6Jsjy{display:flex;flex-direction:column-reverse;gap:8px;position:relative}.W9FSZqgjpMe6Jsjy.gmL0Q1r6iGsoFkSu .FzzrsCwP_lwLYerc{border-color:#e8304f}.W9FSZqgjpMe6Jsjy.gmL0Q1r6iGsoFkSu .FzzrsCwP_lwLYerc:focus~.k5GM3IUwfeEHDRzh{color:#e8304f}.W9FSZqgjpMe6Jsjy.gmL0Q1r6iGsoFkSu .k5GM3IUwfeEHDRzh{color:#e8304f}.W9FSZqgjpMe6Jsjy.gmL0Q1r6iGsoFkSu span{color:#e8304f}.LlnzIIHEksu8zOkZ>div{width:auto;min-width:100%;outline:none;border:none;border-color:#949494;border-radius:4px;font-size:16px;font-family:\"Roboto\",sans-serif;color:#333}.LlnzIIHEksu8zOkZ>div>div{outline:none}.VdGg0KXqY8DDxT0K{margin-left:4px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/SelectOption/SelectOption.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,6BAAA,CACA,OAAA,CACA,iBAAA,CAGE,qDACE,oBCSO,CDPP,6EACE,aCMK,CDFT,qDACE,aCCO,CDET,wCACE,aCHO,CDSX,sBACE,UAAA,CACA,cAAA,CACA,YAAA,CACA,WAAA,CACA,oBC1BK,CD2BL,iBAAA,CACA,cAAA,CACA,+BCSK,CDRL,UChCI,CDkCJ,0BACE,YAAA,CAKN,kBACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": "W9FSZqgjpMe6Jsjy",
	"hasError": "gmL0Q1r6iGsoFkSu",
	"input": "FzzrsCwP_lwLYerc",
	"label": "k5GM3IUwfeEHDRzh",
	"selector": "LlnzIIHEksu8zOkZ",
	"optionalText": "VdGg0KXqY8DDxT0K"
};
export default ___CSS_LOADER_EXPORT___;
