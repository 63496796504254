import React, { useEffect, useState } from 'react';
import { LottieAnimation } from '@innovamat/glimmer-components';

type Props = {
  width?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function ImportCSVAnimation({ width }: Props): JSX.Element {
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    // Dynamically import the animation JSON from the given path
    import('./importcsvanimation.json')
      .then((data) => {
        setAnimationData(data.default);
      })
      .catch((error) => console.error('Error loading animation data:', error));
  }, []);

  return (
    <LottieAnimation
      animationData={animationData}
      width={width}
      loop
      autoplay
    />
  );
}
