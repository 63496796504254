// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DSVRV0n3CfMm3vHZ{background-color:#ccc;border-radius:5px;box-shadow:0 10px 20px rgba(0,0,0,.25);box-sizing:content-box;display:flex;flex-direction:row;height:30px;justify-content:space-around;left:25%;padding:7px;position:fixed;top:calc(var(--vh, 1vh)*70);width:430px}.DSVRV0n3CfMm3vHZ svg,.DSVRV0n3CfMm3vHZ path{stroke:#333}.DSVRV0n3CfMm3vHZ input{border:none;border-radius:5px;font-size:16px;height:30px;padding:0 8px;text-align:center;width:56px}.DSVRV0n3CfMm3vHZ input:focus,.DSVRV0n3CfMm3vHZ select:focus,.DSVRV0n3CfMm3vHZ textarea:focus,.DSVRV0n3CfMm3vHZ button:focus{outline:none}.jcw4gbPRVtAsBEcP{background-color:rgba(0,0,0,0);border:none;border-radius:5px;height:30px;margin:0 4px;text-align:center;text-decoration:none}.jcw4gbPRVtAsBEcP:hover{background-color:#949494;cursor:pointer}.jcw4gbPRVtAsBEcP:disabled{background-color:rgba(0,0,0,0);cursor:not-allowed}.atoeqiOPUk82AVOz{background-color:#fff;height:30px;margin:0 4px;width:1px}.Bs6eq4gGIPxRzUhW{align-items:center;display:flex;height:30px;justify-content:center;margin-right:6px;width:30px}.Bs6eq4gGIPxRzUhW:hover{cursor:move}.t4g7ig6ZRVEYXwHL:disabled{background-color:#fff}", "",{"version":3,"sources":["webpack://./../../libs/resource-viewer/src/lib/resources/SinglePdfViewer/PdfToolbar/PdfToolbar.module.scss"],"names":[],"mappings":"AAAA,kBACE,qBAAA,CACA,iBAAA,CACA,sCAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,WAAA,CACA,4BAAA,CACA,QAAA,CACA,WAAA,CACA,cAAA,CACA,2BAAA,CACA,WAAA,CAEA,6CAEE,WAAA,CAGF,wBACE,WAAA,CACA,iBAAA,CACA,cAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CAGF,6HAIE,YAAA,CAIJ,kBACE,8BAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,oBAAA,CAEA,wBACE,wBAAA,CACA,cAAA,CAGF,2BACE,8BAAA,CACA,kBAAA,CAIJ,kBACE,qBAAA,CACA,WAAA,CACA,YAAA,CACA,SAAA,CAGF,kBACE,kBAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,gBAAA,CACA,UAAA,CAEA,wBACE,WAAA,CAKF,2BACE,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolBar": "DSVRV0n3CfMm3vHZ",
	"toolbarButton": "jcw4gbPRVtAsBEcP",
	"separator": "atoeqiOPUk82AVOz",
	"dragButton": "Bs6eq4gGIPxRzUhW",
	"disabled": "t4g7ig6ZRVEYXwHL"
};
export default ___CSS_LOADER_EXPORT___;
