import { Skeleton } from '@innovamat/glimmer-components';
import {
  ClassroomGrid,
  CourseBlock,
  ListContainer,
} from '../school-classrooms';

const CourseTitleSkeleton = (): JSX.Element => (
  <Skeleton width="25%" height="28" />
);
const ClassCardSkeleton = (): JSX.Element => (
  <Skeleton width="100%" height="126" radius="8" />
);

export function SchoolClassroomsSkeleton(): JSX.Element {
  const renderClassCardSkeletons = (count: number): JSX.Element[] =>
    Array.from({ length: count }, (_, index) => (
      <ClassCardSkeleton key={index} />
    ));

  return (
    <ListContainer>
      <CourseBlock>
        <CourseTitleSkeleton />
        <ClassroomGrid>{renderClassCardSkeletons(6)}</ClassroomGrid>
      </CourseBlock>
      <CourseBlock>
        <CourseTitleSkeleton />
        <ClassroomGrid>{renderClassCardSkeletons(6)}</ClassroomGrid>
      </CourseBlock>
    </ListContainer>
  );
}
