import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import {
  APP_PATHS,
  ClassNavigator,
  CourseNavigator,
  LateralMenu,
  NAV_TYPE,
  useCurrentNavigationValues,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import {
  Drawer,
  IconButton,
  ProfileButton,
  Skeleton,
  Typography,
} from '@innovamat/glimmer-components';
import { roles } from '@innovamat/radiance-utils';
import { LOGOUT_WEBVIEW, useWebviewBridge } from '@innovamat/webview-bridge';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-domv6';

export const HEADER_HEIGHT = 4;

export const Container = styled.div<{ hasBackButton?: boolean }>`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}rem;
  padding: ${({ hasBackButton }) =>
    hasBackButton ? '0 1.5rem 0 0.875rem' : '0 1.5rem'};
  gap: 0.5rem;
  z-index: 20;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 1rem;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 0;
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .profile-button-desktop-skeleton {
    display: block;
  }

  .profile-button-mobile-skeleton {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .profile-button-desktop-skeleton {
      display: none;
    }

    .profile-button-mobile-skeleton {
      display: block;
    }
  }
`;

export const MobileAction = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

export const MobileDrawer = styled.div`
  padding: 0 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .navigator-toggle {
    height: auto;
    outline: 1px solid
      ${({ theme }) =>
        theme.tokens.color.alias.cm.border['border-subtle'].value};
    outline-offset: -1px;
  }

  .navigator-content {
    width: 100%;
  }
`;

const RightActions = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Divider = styled.div`
  width: 1px;
  height: 1.25rem;
  border-radius: 100px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.border['border-subtle'].value};
  margin-right: 1rem;
  margin-left: 0.5rem;
`;

export const MobileDrawerHeader = styled.div`
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem;
  }
  padding-top: 0.75rem;
`;

const MiddleText = styled(Typography.SuperTitle)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

type Props = {
  middleText?: string;
  leftActions?: JSX.Element;
  sitemapTree?: any;
  rightActions?: JSX.Element;
  hasBackButton?: boolean;
  noProfileButton?: boolean;
};

export function Header({
  middleText,
  leftActions,
  sitemapTree,
  rightActions,
  hasBackButton,
  noProfileButton,
}: Props): JSX.Element {
  const { user, onClearUserSession } = useUser();
  const { t } = useTranslation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();
  const { classroomId, courseId } = useParams();
  const { pathname } = useLocation();
  const { setEventData } = useEventLogging();

  const { navType } = useCurrentNavigationValues();
  const { navigateBetweenClassesOrCourses } = useNavigation();
  const { sendEventToWebview, isInWebview } = useWebviewBridge();
  const displayName = `${user?.name} ${user?.familyName.split(' ')[0]}`;

  const isNavigating = !!navType;

  const handleLogout = async () => {
    setEventData('sys_logout', {});

    onClearUserSession();
    if (isInWebview) {
      setTimeout(() => {
        sendEventToWebview(LOGOUT_WEBVIEW);
      }, 1000);
    } else {
      navigate('/');
    }
  };

  const isStudent = roles.hasStudentRole(user?.roles);
  const isParent = roles.hasParentRole(user?.roles);

  return (
    <Container hasBackButton={hasBackButton}>
      <LeftWrapper>{leftActions}</LeftWrapper>
      <MiddleText>{middleText}</MiddleText>
      <RightWrapper>
        {rightActions && (
          <RightActions>
            {rightActions}
            {!noProfileButton && <Divider />}
          </RightActions>
        )}

        {noProfileButton ? null : user ? (
          <ProfileButton
            name={displayName}
            email={user.email}
            items={[
              {
                id: 'settings',
                text: t('settings'),
                icon: 'SettingsIcon',
                hasDivider: true,
                hidden: isStudent,
                onClick: () => {
                  setEventData('sys_profile_click', {});
                  navigate(APP_PATHS.SETTINGS_URL, {
                    state: { from: pathname },
                  });
                },
              },
              {
                id: 'assessor',
                text: t('Contacta con tu asesor'),
                onClick: () => {
                  setEventData('sys_advisor_email_click', {});
                },
                hidden: isStudent || isParent,
              },
              {
                id: 'help-center',
                text: t('topbar.helpCenter.button'),
                rightIcon: 'OpenNewTabIcon',
                hasDivider: true,
                state: 'active',
                hidden: isStudent || isParent,
                onClick: () => {
                  window.open(t('common.link.help-center-zendesk'), '_blank');
                },
              },
              {
                id: 'logout',
                text: t('Cerrar sesión'),
                icon: 'LogoutIcon',
                onClick: () => handleLogout(),
              },
            ]}
          />
        ) : (
          <>
            <Skeleton
              className="profile-button-desktop-skeleton"
              width="122"
              height="32"
              radius="16"
            />
            <Skeleton
              className="profile-button-mobile-skeleton"
              width="32"
              height="32"
              radius="16"
            />
          </>
        )}
        {user && isNavigating ? (
          <MobileAction>
            <IconButton
              icon={'MenuListIcon'}
              onClick={() => setShowMobileMenu(true)}
            />
            <Drawer open={showMobileMenu} customStyles={{ width: '100vw' }}>
              <MobileDrawerHeader>
                <IconButton
                  icon={'CloseIcon'}
                  onClick={() => setShowMobileMenu(false)}
                />
              </MobileDrawerHeader>
              <MobileDrawer>
                {classroomId &&
                  navType !== NAV_TYPE.Student &&
                  navType !== NAV_TYPE.AdminClassroom && (
                    <ClassNavigator
                      classroomId={classroomId as string}
                      organizationId={user.organizationId}
                      onSelectClassroom={(classroomId) => {
                        navigateBetweenClassesOrCourses(classroomId);
                        setShowMobileMenu(false);
                      }}
                    />
                  )}
                {courseId && (
                  <CourseNavigator
                    courseId={courseId as string}
                    onSelectCourse={(courseId) => {
                      navigateBetweenClassesOrCourses(courseId);
                      setShowMobileMenu(false);
                    }}
                  />
                )}
                <LateralMenu
                  isMobile
                  sitemapTree={sitemapTree}
                  onNavigate={() => setShowMobileMenu(false)}
                />
              </MobileDrawer>
            </Drawer>
          </MobileAction>
        ) : (
          ''
        )}
      </RightWrapper>
    </Container>
  );
}
