import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import { normalizeImgSource } from '@innovamat/ga-features';
import { Typography } from '@innovamat/glimmer-components';
import { useFluencyMultiplicationReportQuery } from '@innovamat/glow-api-client';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LegendFluency from '../../components/legend-fluency';
import ReportsLoader from '../../components/reports-loader';
import StudentsFluencyTableDropdown from '../../components/students-fluency-table-dropdown';
import TableFluency from '../../components/table-fluency';
import { FluencyDashboardSkeleton } from './fluency-dashboard-skeleton';
import fluencyImage from './fluency.png';
import { useFluencyDashboard } from './hooks/use-fluency-dashboard';

type Props = {
  classroomId: string;
  allCellsLocked?: boolean;
};

export type FluencyStatusTooltipProp = {
  allGroupsLocked: boolean | undefined;
  noStudentsConnected: boolean | undefined;
};

const FluencyDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-top: 16px;
  padding-bottom: 32px;
  max-width: 879px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FluencyText = styled.div`
  flex: 1;
`;

const FluencyDescriptionContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    gap: 16px;
  }
`;

const FluencyDescriptionContentExpandable = styled(FluencyDescriptionContent)<{
  isExpanded: boolean;
}>`
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? '400px' : '0')};
  transition: max-height 0.4s ease-in-out;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-height: ${({ isExpanded }) => (isExpanded ? '500px' : '0')};
  }
`;

const FluencyDescriptionText = styled(Typography.Body1)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FluencyImage = styled.img<{ isExpanded: boolean }>`
  width: 200px;
  height: auto;
  align-self: flex-end;
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  transition: opacity 0.4s ease-in-out;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 70%;
    align-self: center;
  }
`;

const GraphicWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const MultiplicationTableTitle = styled(Typography.H4)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Paragraph = styled(Typography.Body1)`
  margin-bottom: 8px;
`;

const GlobalStyles = () => (
  <Global
    styles={css`
      html {
        scrollbar-gutter: stable;
      }
    `}
  />
);

export const FluencyDashboard = ({ classroomId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { data: fluencyDashboardReport, isLoading } =
    useFluencyMultiplicationReportQuery({
      id: classroomId!,
    });

  const {
    mode,
    selectedStudent,
    handleChange,
    students,
    multiplicationTableTitle,
    operator,
    tableFluencyData,
    showFluencyStatusTooltip,
    allCellsLocked,
    tableUpdateLoading,
  } = useFluencyDashboard(fluencyDashboardReport);

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [descriptionStatus, setDescriptionStatus] = useState(
    'my-classes.show-more'
  );

  const fluencyImageSrc = normalizeImgSource(fluencyImage);

  const viewMore = 'my-classes.show-more';
  const viewLess = 'my-classes.show-less';

  const handleDescriptionExpand = (isExpanded: boolean) => {
    setDescriptionStatus(isExpanded ? viewLess : viewMore);
    setIsDescriptionExpanded(isExpanded);
  };

  const { setEventData } = useEventLogging();
  const eventSentRef = useRef(false);

  useEffect(() => {
    if (!isLoading && tableFluencyData && !eventSentRef.current) {
      setEventData('report_fluency', {});
      eventSentRef.current = true;
    }
  }, [isLoading, tableFluencyData, setEventData]);

  if (isLoading) return <ReportsLoader />;

  return (
    <>
      <GlobalStyles />
      {tableFluencyData && (
        <>
          <FluencyDescriptionWrapper>
            <FluencyText>
              <FluencyDescriptionContent>
                <FluencyDescriptionText>
                  <Trans
                    i18nKey="fluency.reports.description"
                    components={{
                      b: <b />,
                      br: <br />,
                      p: <Paragraph />,
                    }}
                  />
                </FluencyDescriptionText>
              </FluencyDescriptionContent>
              <FluencyDescriptionContentExpandable
                isExpanded={isDescriptionExpanded}
              >
                <FluencyDescriptionText>
                  <Trans
                    i18nKey="fluency.reports.description.expanded"
                    components={{
                      b: <b />,
                      br: <br />,
                      p: <Paragraph />,
                    }}
                  />
                </FluencyDescriptionText>
                <FluencyImage
                  src={fluencyImageSrc}
                  alt="Fluency Example"
                  isExpanded={isDescriptionExpanded}
                />
              </FluencyDescriptionContentExpandable>
              <Typography.Link1
                onClick={() => handleDescriptionExpand(!isDescriptionExpanded)}
              >
                {t(descriptionStatus)}
              </Typography.Link1>
            </FluencyText>
          </FluencyDescriptionWrapper>
          <StudentsFluencyTableDropdown
            students={students}
            onChange={handleChange}
            selected={selectedStudent}
          />
          <MultiplicationTableTitle>
            {t(`${multiplicationTableTitle}`)}
          </MultiplicationTableTitle>
          <GraphicWrapper>
            {tableUpdateLoading ? (
              <FluencyDashboardSkeleton />
            ) : (
              <ContentWrapper>
                <TableFluency
                  operator={operator}
                  tableFluencyData={tableFluencyData}
                  showFluencyStatusTooltip={showFluencyStatusTooltip}
                />
                <LegendFluency allCellsLocked={allCellsLocked} mode={mode} />
              </ContentWrapper>
            )}
          </GraphicWrapper>
        </>
      )}
    </>
  );
};
