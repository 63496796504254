import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { ReasonForEmptyList } from '../../types';
import InformationCard from '../cards/information-card';

const CardContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;

  > div {
    width: 13.5rem;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const DefaultCards = (): EmotionJSX.Element => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <InformationCard
        percentage={70}
        title={t('homeWeeklyPractice.70unlocked')}
      />
      <InformationCard
        percentage={30}
        title={t('homeWeeklyPractice.30personalized')}
      />
    </CardContainer>
  );
};

const EmptyCard = (): EmotionJSX.Element => (
  <CardContainer>
    <InformationCard showTouchIcon />
  </CardContainer>
);

type EmptyStateConfig = {
  title: string;
  subtitle: string;
  renderCards: () => JSX.Element;
};

const defaultConfig: EmptyStateConfig = {
  title: '',
  subtitle: '',
  renderCards: () => <CardContainer />,
};

const EMPTY_STATE_CONFIG: Record<
  Exclude<ReasonForEmptyList, null>,
  EmptyStateConfig
> = {
  no_lessons_done_last_week: {
    title: 'homeWeeklyPractice.noSessions.title',
    subtitle: 'homeWeeklyPractice.noSessions.subtitle',
    renderCards: () => <DefaultCards />,
  },
  no_related_activities_unlocked: {
    title: 'homeWeeklyPractice.noActivities.title',
    subtitle: 'homeWeeklyPractice.noActivities.subtitle',
    renderCards: () => <DefaultCards />,
  },
  no_lessons_done_in_academic_year: {
    title: 'homeWeeklyPractice.emptyState.title',
    subtitle: 'homeWeeklyPractice.emptyState.subtitle',
    renderCards: () => <EmptyCard />,
  },
};

export const getEmptyStateConfig = (
  reason: ReasonForEmptyList
): EmptyStateConfig => {
  if (!reason || !(reason in EMPTY_STATE_CONFIG)) {
    return defaultConfig;
  }

  return EMPTY_STATE_CONFIG[reason as Exclude<ReasonForEmptyList, null>];
};
