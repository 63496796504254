import styled from '@emotion/styled';
import type { ButtonVariant } from '@innovamat/glimmer-components';
import { Modal, RadioButton, Typography } from '@innovamat/glimmer-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentContainer } from '../../mock-exams-drawer';

const RadioButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

type Props = {
  value?: string;
  languages: string[];
  isOpen?: boolean;
  isDownloading?: boolean;
  onClose?: () => void;
  onDownload?: (language: string) => void;
};

const DownloadTestQuestionsModal = ({
  value,
  languages,
  isOpen = false,
  isDownloading = false,
  onClose,
  onDownload,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    value ?? (languages.length === 1 ? languages[0] : undefined)
  );

  return (
    <Modal
      zIndex={32}
      isOpen={isOpen}
      onClose={onClose}
      title={t('mock-exams-drawer.test-questions-modal.title')}
      closeOnEsc
      closeOnClickOutside
      buttons={[
        ...(languages?.length
          ? [
              {
                children: t('common.download'),
                variant: 'accent' as ButtonVariant,
                disabled: !selectedLanguage || isDownloading,
                loading: isDownloading,
                onClick: () =>
                  selectedLanguage && onDownload?.(selectedLanguage),
              },
            ]
          : []),
        {
          children: t('common.cancel'),
          variant: 'tertiary',
          onClick: onClose,
        },
      ]}
    >
      <ContentContainer>
        <Typography.Body1>
          {t('mock-exams-drawer.test-questions-modal.subtitle')}:
        </Typography.Body1>
        <RadioButtonGroup>
          {languages.map((language) => (
            <RadioButton
              key={language}
              groupBy={`languages-${language}`}
              label={t(`language.${language}`)}
              value={language}
              checked={language === selectedLanguage}
              onClick={() => setSelectedLanguage(language)}
            />
          ))}
        </RadioButtonGroup>
      </ContentContainer>
    </Modal>
  );
};

export { DownloadTestQuestionsModal };
