import React from 'react';
import cx from 'classnames';
import Avatar from '../Avatar';
import Icon from '../Icon';
import styles from './Chip.module.scss';

type Props = {
  children: JSX.Element | string;
  onClick?: () => void;
  className?: string;
};

function Chip({ children, className = '', onClick }: Props) {
  const chipClass = cx(styles.chip, { className: !!className });
  return (
    <div role="presentation" onClick={onClick} className={chipClass}>
      <Avatar size={24} className={styles.avatar} />
      <span className="h6">{children}</span>
      <Icon size="sm" icon="Close" />
    </div>
  );
}

export default Chip;
