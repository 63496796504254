import { Dropdown, InnerHtml, type State } from '@innovamat/glimmer-components';
import { Button, Tooltip } from '@innovamat/glimmer-components';
import { useDevice } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useHorizontalMenuTabs } from '../../providers/session-section-provider';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import type { Option } from '../../types/option';

const StyledButton = styled(Button)`
  * {
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
    fill: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value} !important;
  }

  .buttonText {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: min-content;
      line-height: inherit;
      text-align: right;
    }
  }
`;

const StyledDropdownContent = styled.div`
  max-height: 320px;
  overflow-y: auto;
`;

type Props = {
  printables?: Option[];
  handleDownloadOption: (option?: Option | undefined) => void;
  showAssessables: boolean;
};

export default function NewLayoutPrintables({
  printables,
  handleDownloadOption,
  showAssessables,
}: Props): JSX.Element | null {
  const { isMobile } = useDevice();
  const { t } = useTranslation();

  const { isSectionActive, changeSectionActive } = useHorizontalMenuTabs();

  const { isInIOS } = useWebviewBridge();

  const showPrintables = printables && printables?.length > 0;

  if (!showPrintables) return null;

  const mapPrintablesToOptions = () => {
    const options = printables?.map((printable) => {
      return {
        key: printable.subtitle,
        href: printable.href,
        children: <InnerHtml text={printable.label} />,
        subtitle: <InnerHtml text={printable.subtitle} />,
        onSelectItem: () => {
          if (!isInIOS) {
            handleDownloadOption(printable);
          }
        },
        icon: printable.icon,
        state: printable.isDisabled
          ? ('disabled' as State)
          : ('active' as State),
      };
    });

    if (showAssessables) {
      options.push({
        key: 'assesables',
        href: '',
        children: <InnerHtml text={`<u>${t('session.assessment.link')}</u>`} />,
        subtitle: <InnerHtml />,
        onSelectItem: () => changeSectionActive('Assessment'),
        icon: undefined,
        state: isSectionActive('Assessment') ? 'disabled' : 'active',
      });
    }

    return options;
  };

  type ConditonalWrapperProps = {
    children: React.ReactElement;
    condition: boolean;
    wrapper: (children: React.ReactElement) => JSX.Element;
  };
  const ConditonalWrapper: React.FC<ConditonalWrapperProps> = ({
    condition,
    wrapper,
    children,
  }) => (condition ? wrapper(children) : children);

  return (
    <Dropdown openOnHover={true} data-testid="printablesDropdown">
      <Dropdown.Toggle>
        <Tooltip content={t('Descarregar per imprimir')} disabled={!isMobile}>
          <StyledButton
            variant="tertiary"
            loading={false}
            onClick={() => {}}
            leftIcon="DownloadFilesIcon"
            rightIcon="ExpandMoreIcon"
            data-testid="buttonPrintables"
          >
            {!isMobile && t('Descarregar per imprimir')}
          </StyledButton>
        </Tooltip>
      </Dropdown.Toggle>
      <Dropdown.Content position="bottomRight">
        <StyledDropdownContent>
          {mapPrintablesToOptions().map((option) => (
            <ConditonalWrapper
              condition={option.href !== '' && isInIOS}
              wrapper={(children) => <a href={option.href}>{children}</a>}
            >
              <Dropdown.Item {...option} customStyles={'gap: 0'} />
            </ConditonalWrapper>
          ))}
        </StyledDropdownContent>
      </Dropdown.Content>
    </Dropdown>
  );
}
