// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zu0_KDzPVHImjeGx{position:fixed;height:100vh;background-color:#fff;z-index:1000;box-shadow:0 4px 8px rgba(0,0,0,.24)}.zu0_KDzPVHImjeGx.ndtPdZidBLQaK6Rx{right:0}.zu0_KDzPVHImjeGx.Rp9Cidzdgm8wei2X{left:0}.IYjgdPux8mMcmiGD{flex:1;gap:8px;display:flex;flex-direction:column}.RUNbelxPf5wSdPKT{margin-bottom:8px}.iNHyL22ZnuAkGmER{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,.08);z-index:1000}.Clv4vVNy_lUkatv1{display:flex;justify-content:flex-end;gap:16px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/Drawer/Drawer.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,cAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,oCCmDW,CDjDX,mCACE,OAAA,CAGF,mCACE,MAAA,CAIJ,kBACE,MAAA,CACA,OAAA,CACA,YAAA,CACA,qBAAA,CAGF,kBACE,iBAAA,CAGF,kBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,gCAAA,CACA,YAAA,CAGF,kBACE,YAAA,CACA,wBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": "zu0_KDzPVHImjeGx",
	"right": "ndtPdZidBLQaK6Rx",
	"left": "Rp9Cidzdgm8wei2X",
	"content": "IYjgdPux8mMcmiGD",
	"title": "RUNbelxPf5wSdPKT",
	"overlay": "iNHyL22ZnuAkGmER",
	"footer": "Clv4vVNy_lUkatv1"
};
export default ___CSS_LOADER_EXPORT___;
