import { compressQuery, standards } from '@innovamat/radiance-utils';
import type { BigIdea, StandardContent } from '@innovamat/resource-viewer';
import { GraphQLClient } from 'graphql-request';
import { mapSmartGuide } from '../mappers/mapSmartGuide';
import type { Printable } from '../types/Materials';
import type { PreprModel } from '../types/SmartGuide';
import { Stage } from '../types/Stage';
import { getSmartGuideDoc, getSmartGuidePartDoc } from './graphql';

type SmartGuideParams = {
  graphEndpoint: string;
  locales: string[];
  id: string;
  model: PreprModel;
  printables: Printable[];
  regionParameters: any;
  assessables?: string[];
  userId?: string;
  standardContent?: StandardContent[];
  mathPractices?: string[];
  bigIdeas?: BigIdea[];
  orgStandard?: string;
};

const getParts = async (client: any, data: any, locales: string[]) => {
  const dataParts = data.partes || data.parte;
  return Promise.all(
    dataParts.map(async (partData: any) => {
      const model = partData.__typename;
      const doc = compressQuery(getSmartGuidePartDoc(model));

      const partsResponse: any = await client.request(doc, {
        locales: locales,
        id: partData._id,
      });

      return Object.values(partsResponse)[0];
    })
  );
};

export const getSmartGuide = async ({
  graphEndpoint,
  locales,
  id,
  model,
  printables,
  regionParameters,
  assessables,
  userId,
  standardContent,
  mathPractices,
  bigIdeas,
  orgStandard,
}: SmartGuideParams) => {
  const graphQLClient = new GraphQLClient(graphEndpoint, {
    headers: {
      'Prepr-Customer-Id': `${userId}`,
    },
  });

  const doc = compressQuery(getSmartGuideDoc(model));

  const smartGuideResponse: any = await graphQLClient.request(doc, {
    locales: locales,
    id: id,
  });

  const data: any = Object.values(smartGuideResponse)[0];

  const parts = await getParts(graphQLClient, data, locales);

  const smartGuideWithParts = {
    ...data,
    partes: parts,
  };

  delete smartGuideWithParts.parte;

  if (!smartGuideWithParts) {
    throw new Error(`SmartGuide not found with id ${id} and model ${model}`);
  }

  const stageByModel = {
    SmartGuideTaller: Stage.EarlyYear,
    SmartGuidePrimaria: Stage.Primary,
    SmartGuideSecundaria: Stage.Secondary,
    SmartGuideOpen: Stage.Secondary,
  };

  const stage = stageByModel[model as keyof typeof stageByModel];

  const isCaliforniaStandard = orgStandard === standards.CALIFORNIA;
  const isBubbleTreeStandard = orgStandard === standards.BUBBLE_TREE;

  const smartGuide = mapSmartGuide({
    data: smartGuideWithParts,
    stage,
    model,
    printables,
    regionParameters,
    isCaliforniaStandard,
    isBubbleTreeStandard,
    assessables,
    standardContent,
    mathPractices,
    bigIdeas,
  });

  return smartGuide;
};
