import { useMutation } from '@tanstack/react-query';
import { forgotEmail } from './api';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';

export function useForgotPassword() {
  const { apiUsers } = useRegionalConfigVariables();
  return useMutation({
    mutationFn: (email: string) => {
      return forgotEmail(email, apiUsers);
    },
  });
}
