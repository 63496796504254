import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import { Page, useCurrentNavigationValues } from '../..';
import { NAV_TYPE } from '../../../navigation';
import { useMarkSessionDone, useSessionDone } from '../../../session-done';
import { useUser } from '../../../user-management';
import useGetNavigationList from '../../hooks/use-get-navigation-list';
import { ResourceNavigation } from '../resource-navigation';

type NewLayoutNavigationProps = {
  page: Page | undefined;
};

export function NewLayoutNavigation({
  page: pageResources,
}: NewLayoutNavigationProps) {
  const { isRole } = useUser();
  const { t } = useTranslation();
  const { isModalOpen } = useSessionDone();
  const { onMarkSession } = useMarkSessionDone();
  const { classroomId, courseId, menu } = useParams();
  const { navType } = useCurrentNavigationValues();
  const {
    title: pageTitle,
    navigationList,
    onNavigate,
  } = useGetNavigationList({ pageResources });

  const onCheckListElement = (id: string, value: boolean) => {
    const resource = pageResources?.content_lists
      ?.flatMap((list) => list.resources)
      .find((resource) => resource.id === id);

    if (!resource) return;

    onMarkSession({ resource, value, dontOpenModal: true });
  };

  const isCheckDisabled = false;

  const topMenu = useMemo(() => {
    if (navType === NAV_TYPE.Classroom) {
      return `${NAV_TYPE.Classroom}/${classroomId}/${menu}`;
    } else if (navType === NAV_TYPE.Course) {
      return `${NAV_TYPE.Course}/${courseId}/${menu}`;
    } else if (navType === NAV_TYPE.Student) {
      return `${NAV_TYPE.Student}/${classroomId}/${menu}`;
    }
    return `${NAV_TYPE.Classroom}/${classroomId}/${menu}`;
  }, [navType]);

  return (
    <ResourceNavigation
      title={pageTitle}
      navigationList={navigationList}
      topMenu={topMenu}
      undefinedRoute={t('routes::/undefined')}
      onNavigate={onNavigate}
      onCheckListElement={onCheckListElement}
      isCheckDisabled={isCheckDisabled}
      hasCheckbox={Boolean(isRole.Teacher && classroomId)}
      isCloseWhenClickOutsideDisabled={isModalOpen}
      translations={{
        previous: t('navigation.previous'),
        next: t('navigation.next'),
        menu: t('navigation.menu'),
      }}
    />
  );
}
