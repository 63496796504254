import styled from '@emotion/styled';
import { Button } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useDownloadStudentsCsv } from '../../../hooks/use-download-student-csv';
import { useOrganization } from '../../../hooks/use-organization';
import { useSchoolStudents } from '../providers/school-students-provider';
import { AddStudentDropdown } from './add-student-dropdown';
import { StudentSearchInput } from './student-search-input';

const HeaderWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-self: normal;
  align-items: center;
`;

type Props = {
  isDisabled?: boolean;
};

function SchoolStudentsHeader({ isDisabled }: Props): JSX.Element {
  const { isDeletedStudentsPage, search, handleSearch } = useSchoolStudents();
  const { t } = useTranslation();
  const { onDownload, isLoading } = useDownloadStudentsCsv();
  const { isOrgAnonymized } = useOrganization();

  const showExportButton = !isDeletedStudentsPage && !isOrgAnonymized;

  return (
    <HeaderWrapper>
      <StudentSearchInput
        handleSearch={handleSearch}
        isDisabled={isDisabled}
        search={search}
      />
      {showExportButton && (
        <Button
          leftIcon="FileCsvIcon"
          variant="secondary"
          onClick={onDownload}
          loading={isLoading}
        >
          {t('common.export')}
        </Button>
      )}

      <AddStudentDropdown
        isDeletedStudentsPage={isDeletedStudentsPage}
        isDisabled={isDisabled}
      />
    </HeaderWrapper>
  );
}

export { SchoolStudentsHeader };
