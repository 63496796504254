import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import type { State } from '@innovamat/glimmer-components';
import {
  Button,
  InnerHtml,
  Popover,
  STATES,
} from '@innovamat/glimmer-components';
import type { IconType } from '@innovamat/glimmer-icons';
import { downloadBase64PdfFile } from '@innovamat/radiance-utils';

import {
  useClassroomTestRoundAvailableLanguagesQuery,
  useClassroomTestRoundReportQuery,
} from '@innovamat/glow-api-client';

import type { Test } from '../../../../../../modules/test/test';

import { isAvailableForDownload } from '../../../../../../modules/report/report';
import { TestReportCellDeliveryDate } from './components/test-report-cell-delivery-date';

type Props = {
  testRoundId: string;
  testRoundName: string;
  report: Test['report'];
};

const DownloadTestReportCell = ({
  testRoundId,
  testRoundName,
  report,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<string>('');

  const {
    data,
    isFetching: isFetchingLanguages,
    refetch: fetchReportLanguages,
  } = useClassroomTestRoundAvailableLanguagesQuery(
    {
      testRoundId,
    },
    {
      retry: false,
      enabled: false,
      staleTime: Infinity,
    }
  );

  const {
    data: reportFile,
    isFetching: isFetchingReport,
    isSuccess,
    refetch: fetchReport,
  } = useClassroomTestRoundReportQuery(
    {
      testRoundId,
      language: language,
    },
    {
      retry: false,
      enabled: false,
      staleTime: Infinity,
    }
  );

  const handleReportLanguageItemClick = (languageSelected: string): void => {
    setLanguage(languageSelected);
  };

  const handleButtonClick = (): void => {
    fetchReportLanguages();
  };

  const downloadReport = async (reportContent: string): Promise<void> => {
    const reportTitle = t('reports.title');
    const year = new Date().getFullYear();
    const fileName = `${reportTitle}_${testRoundName}_${year}.pdf`;

    await downloadBase64PdfFile({
      base64Content: reportContent,
      fileName,
    });
  };

  const languages = data?.classroomTestRoundAvailableLanguages
    ? data.classroomTestRoundAvailableLanguages.filter(
        (lang): lang is string => lang !== null
      )
    : [];

  const reportTranslations = languages.map((language) => ({
    key: `${testRoundId}_report_${language}`,
    children: <InnerHtml text={t(`common.language.${language}`)} />,
    onSelectItem: () => handleReportLanguageItemClick(language),
    icon: 'FilePdfIcon' as IconType,
    state: STATES.ACTIVE as State,
  }));

  if (!isFetchingReport && language) {
    fetchReport();
  }

  if (isSuccess && language && reportFile) {
    const reportContent = reportFile?.classroomTestRoundReport?.pdfBase64;

    if (reportContent) {
      downloadReport(reportContent);
    }

    setLanguage('');
  }

  if (isAvailableForDownload(report?.deliveryDate)) {
    return (
      <Popover
        trigger={
          <Button
            variant="secondary"
            leftIcon="DownloadIcon"
            rightIcon="ExpandMoreIcon"
            size="S"
            onClick={handleButtonClick}
          >
            {t('common.download')}
          </Button>
        }
      >
        {isFetchingLanguages ? (
          <Popover.Item key="retrieving-reports" state="disabled">
            {t('common.loadingContent')}
          </Popover.Item>
        ) : (
          <>
            {reportTranslations.length !== 0 ? (
              <>
                {reportTranslations.map(
                  ({ children, key, state, icon, onSelectItem }) => (
                    <Popover.Item
                      key={key}
                      icon={icon}
                      state={state}
                      onSelectItem={onSelectItem}
                    >
                      {children}
                    </Popover.Item>
                  )
                )}
              </>
            ) : (
              <Popover.Item key="no-reports" state="disabled">
                {t('reports.message.notAvailable')}
              </Popover.Item>
            )}
          </>
        )}
      </Popover>
    );
  }

  return <TestReportCellDeliveryDate deliveryDate={report?.deliveryDate} />;
};

export { DownloadTestReportCell };
