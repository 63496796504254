import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import type { CustomColumnRenderConfig } from '@innovamat/glimmer-components';
import {
  TableComponent,
  Typography,
  useColumnsCustomRenderConfig,
} from '@innovamat/glimmer-components';
import {
  AnswersCol,
  AnswersContainer,
} from '../../../../../components/answers/answers';
import { ScoreCell } from '../../../../../components/score-cell';
import { StatementCell } from '../../../../../components/statement-cell/statement-cell';
import type {
  Answer,
  MockExamsStatementRow,
} from '../../../../../types/mock-exams';
import { usePrintMockExamsStatementsConfig } from '../hooks/use-print-mock-exams-statements-config';

type PrintMockExamsStatementsTableProps = {
  statements: MockExamsStatementRow[];
  multipleChoiceLabels: string[];
};

const customColumnsRenderConfig = (
  t: TFunction
): CustomColumnRenderConfig<MockExamsStatementRow> => ({
  position: (_: string, row: MockExamsStatementRow) => {
    return `${t('mock-exams-drawer.table.statements.sublabel')}${
      Number(row.position) + 1
    }`;
  },
  type: (value: string) => {
    return value;
  },
  correctlyAnswered: (value: number) => {
    return <ScoreCell score={value} isPercentage fitContentHeight />;
  },
  answers: (value: Answer[]) => {
    return (
      <AnswersContainer isSmall>
        {value?.map((answer, index) => {
          return (
            <AnswersCol key={answer.positionLabel || 'answer-row-' + index}>
              <StatementCell
                answer={answer.answered?.toString()}
                isCorrect={answer.isCorrect}
                isPercentage
              />
            </AnswersCol>
          );
        })}
      </AnswersContainer>
    );
  },
  notAnswered: (value: number) => {
    return <Typography.Subtitle2> {value}% </Typography.Subtitle2>;
  },
});

function PrintMockExamsStatementsTable({
  statements,
  multipleChoiceLabels,
}: PrintMockExamsStatementsTableProps): JSX.Element {
  const { t } = useTranslation();

  const columnsWithoutRender =
    usePrintMockExamsStatementsConfig(multipleChoiceLabels);
  const columns = useColumnsCustomRenderConfig<MockExamsStatementRow>(
    customColumnsRenderConfig(t),
    columnsWithoutRender
  );

  return (
    <TableComponent
      columns={columns}
      rows={statements}
      id="print-mock-exams-statements-table"
      tableSize={'Small'}
    />
  );
}

export { PrintMockExamsStatementsTable };
