import {
  TableCellSkeleton,
  TableComponent,
  generateRowsForTableSkeleton,
  useColumnsCustomRenderConfig,
} from '@innovamat/glimmer-components';

import { TEST_STATUS, TestRow } from '../../../../modules/test/test';
import { useTestsAndResultsColumnsConfiguration } from './hooks/use-tests-and-results-columns-configuration';
import { Container } from './tests-and-results-table';

function TestsAndResultsTableSkeleton(): JSX.Element {
  const configurationWithoutRender = useTestsAndResultsColumnsConfiguration();

  const mockObject: TestRow = {
    classroomTestRoundId: '',
    testId: '',
    roundName: '',
    rowName: '',
    status: TEST_STATUS.NOT_STARTED,
    rowDescription: '',
    password: '',
    startDate: '',
    endDate: '',
    type: null,
    id: '',
  };

  const rowsNumber = 4;

  const customRenderConfig = {
    name: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    status: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    password: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    startDate: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    endDate: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    participation: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    report: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
  };

  const columns = useColumnsCustomRenderConfig<TestRow>(
    customRenderConfig,
    configurationWithoutRender
  );

  const rows = generateRowsForTableSkeleton(rowsNumber, mockObject);

  return (
    <Container>
      <TableComponent
        columns={columns}
        id="tests-and-results-table-skeleton"
        key="tests-and-results-table-skeleton"
        rows={rows}
      />
    </Container>
  );
}

export { TestsAndResultsTableSkeleton };
