import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import type { AppletsOfTheWeek } from '@innovamat/glow-api-client';
import WeekInformation from './week-information';

const TextWrapper = styled.div`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const Subtitle = styled.div`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  display: flex;
  gap: 0.3rem;
  align-items: baseline;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

type Props = {
  title: string;
  subtitle: string;
  weekToShow: AppletsOfTheWeek['weekToShow'];
  startDate: AppletsOfTheWeek['startDate'];
  endDate: AppletsOfTheWeek['endDate'];
};

const TextInformation = ({
  title,
  subtitle,
  weekToShow,
  startDate,
  endDate,
}: Props) => {
  return (
    <TextWrapper>
      <Typography.H4>{title}</Typography.H4>
      <Subtitle>
        <Typography.Body2>{subtitle}</Typography.Body2>
        <WeekInformation
          weekToShow={weekToShow}
          startDate={startDate}
          endDate={endDate}
        />
      </Subtitle>
    </TextWrapper>
  );
};

export default TextInformation;
