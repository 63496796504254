import { Checkbox, SelectOption } from '@innovamat/innova-components';
import { validators } from '@innovamat/radiance-utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-domv6';

import { InputPhone } from '@innovamat/glimmer-components';
import { useQueryParams } from '@innovamat/hooks';
import {
  ButtonV2 as Button,
  InputField,
  ThemeProvider as ThemeProviderInnovaComponents,
} from '@innovamat/innova-components';
import { getRegisterLanguagesByRegion } from '@innovamat/localization';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useUser } from '../../../user-management';
import LoginRegisterLayout from '../../components/login-register-layout/login-register-layout';
import { SuccessMessage } from '../../components/success-message';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCompleteInvitation } from '../../hooks/use-complete-invitation';
import {
  InvitationType,
  UserInvitation,
} from '../../hooks/use-complete-invitation/invitation';
import { useResendRegisterInvitation } from '../../hooks/use-resend-register-invitation';
import { useGetUserRegion } from '../../hooks/use-user-region';
import ModalError from './modal-error';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Container = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  > div {
    width: 100%;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.dark['03']};
    a {
      color: ${({ theme }) => theme.colors.dark['01']};
      text-decoration: underline;
    }
  }
`;

const StyledInputPhone = styled(InputPhone)<{ isFocus: boolean }>`
  .react-tel-input {
    height: 40px;
    > input {
      padding: 0px 8px;
      border: 1px solid rgb(148, 148, 148) !important;
      border-radius: 4px !important;
      height: 40px !important;
      &:focus {
        border: 2px solid rgb(51, 51, 51) !important;
      }
    }
    .flag-dropdown {
      border: ${({ theme }) =>
        `1px solid ${theme.colors.dark['03']} !important`};
      border-radius: 4px 0 0 4px !important;
      ${({ isFocus, theme }) =>
        isFocus &&
        css`
          border-top: ${`2px solid ${theme.colors.dark['01']} !important`};
          border-bottom: ${`2px solid ${theme.colors.dark['01']} !important`};
          border-left: ${`2px solid ${theme.colors.dark['01']} !important`};
        `}
    }
  }
`;

const StyledInput = styled.input`
  width: 0;
  height: 0;
  border: 0px;
  position: absolute;
`;

const getUserType = (path: string, params: URLSearchParams) => {
  const type = path.includes('complete-parent-invitation')
    ? 'parent'
    : (params.get('type') as InvitationType);

  return {
    type,
    isParent: type === 'parent',
    isStudent: type === 'student',
    isTeacher: type === 'teacher',
  };
};

export function RegisterUser() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useQueryParams();

  const {
    resendRegisterInvitation,
    isSuccessResendInvitation,
    isPendingResendInvitation,
  } = useResendRegisterInvitation();

  const invitationId = params.get('id') || undefined;
  const invitationToken = params.get('token') || undefined;
  const email = params.get('email') || undefined;
  const region = useGetUserRegion();
  const languages = getRegisterLanguagesByRegion(region);

  const getLanguage = () => {
    const urlLang = params.get('language');
    if (urlLang && languages.includes(urlLang)) return urlLang;
    if (languages.includes(i18n.language)) return i18n.language;
    return languages[0];
  };

  const defaultLanguage = getLanguage();

  const { type, isParent, isStudent, isTeacher } = getUserType(
    location.pathname,
    params
  );

  const { isError, error, isPending, isSuccess, mutate } =
    useCompleteInvitation({
      type,
    });

  const defaultInvitationState: UserInvitation = {
    firstName: '',
    lastName: '',
    email,
    phoneNumber: null,
    password: '',
    invitationToken,
    invitationId,
    language: defaultLanguage,
  };

  const [userInvitation, setUserInvitation] = useState({
    ...defaultInvitationState,
  });
  const [phoneDialCode, setPhoneDialCode] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isInputPhoneFocus, setIsInputPhoneFocus] = useState(false);
  const [acceptCommunications, setAcceptCommunications] = useState(false);

  const { isLoggedIn, onClearUserSession } = useUser();

  const { firstName, lastName, phoneNumber, password, language } =
    userInvitation;

  const userExistsError = error?.response?.status === 409;
  const expiredLinkError = error?.response?.status === 404;
  const genericError = isError && !userExistsError && !expiredLinkError;

  const goToLogin = () => {
    navigate('/');
  };

  const goToForgotPassword = () => {
    navigate(`/${i18n.language}/forgot-password`);
  };

  const handleResendLink = () => {
    if (!invitationId || !invitationToken) return;

    resendRegisterInvitation({
      id: invitationId,
      token: invitationToken,
      type,
    });
  };

  useEffect(() => {
    if (isSuccessResendInvitation) {
      setShowErrorModal(false);
    }
  }, [isSuccessResendInvitation]);

  useEffect(() => {
    if (isLoggedIn) {
      onClearUserSession();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!(invitationId && invitationId && email)) {
      if (!isSuccess) goToLogin();
    }
  }, [invitationId, invitationId]);

  useEffect(() => {
    if (isError) {
      setShowErrorModal(true);
    }
  }, [isError]);

  const invitationIsValid = () =>
    firstName.trim() &&
    lastName.trim() &&
    validators.passwordIsValid(password) &&
    validators.isPhoneValid(phoneNumber, phoneDialCode) &&
    acceptTerms;

  const handleChangePhone = (value: string, country: any) => {
    if (country.dialCode !== phoneDialCode) {
      setPhoneDialCode(country.dialCode);
    }
    setUserInvitation((prevState) => ({
      ...prevState,
      phoneNumber: `+${value}`,
    }));
  };

  const handleChangeInput = (value: any, key: any) => {
    setUserInvitation((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleCompleteUserInvitation = (e: React.FormEvent) => {
    e.preventDefault();
    const body = userInvitation;
    if (!isParent) {
      if (!body.phoneNumber || !isValidPhoneNumber(body.phoneNumber)) {
        body.phoneNumber = null;
      }
    } else {
      body.parentAcceptCommunication = acceptCommunications;
    }
    mutate(userInvitation);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const langOptions = languages.map((lang) => ({
    value: lang,
    label: t(`language.${lang}`),
    key: lang,
  }));

  return (
    <>
      <ThemeProviderInnovaComponents>
        <LoginRegisterLayout
          isRegisterLayout
          isSuccess={isSuccess}
          title={t('teacher.onboarding.createProfileFilled.title')}
          description={t(
            'teacher.onboarding.createProfileFilled.subtitle.personalData'
          )}
          error={
            genericError ? (
              <>
                {t(
                  'teacher.onboarding.createProfileFilled.error',
                  'Ha ocurrido un error al completar el registro. Inténtalo de nuevo.'
                )}
              </>
            ) : undefined
          }
          onSuccessBody={
            <SuccessMessage
              body={t('teacher.onboarding.createProfileFilled.success.body')}
              title={t('teacher.onboarding.createProfileFilled.success.title')}
              actionText={t(
                'teacher.onboarding.createProfileFilled.button.access'
              )}
              action={goToLogin}
            />
          }
        >
          <Form onSubmit={handleCompleteUserInvitation}>
            <Container>
              <InputField
                label={t(
                  'teacher.onboarding.createProfileFilled.field.firstName'
                )}
                required
                onChange={(e) => handleChangeInput(e.target.value, 'firstName')}
                value={firstName}
                placeholder={t(
                  'teacher.onboarding.createProfileFilled.placeholder.firstName'
                )}
                type="text"
                name="name"
              />
              <InputField
                label={t(
                  'teacher.onboarding.createProfileFilled.field.lastName'
                )}
                required
                onChange={(e) => handleChangeInput(e.target.value, 'lastName')}
                value={lastName}
                placeholder={t(
                  'teacher.onboarding.createProfileFilled.placeholder.lastName'
                )}
                type="text"
                name="surname"
              />
            </Container>

            {isTeacher && (
              <InputField
                label={t('teacher.onboarding.createProfileFilled.field.email')}
                disabled
                value={email}
                type="email"
                name="email"
              />
            )}
            {
              <div>
                {/* Needed so that Google's password manager detects there's an email input field and doesn't pick
              the surname field as the email field and autocompletes it there */}
                <StyledInput type="email" name="email" />
                <InputField
                  label={t(
                    'teacher.onboarding.createProfileFilled.field.password'
                  )}
                  description={t(
                    'teacher.onboarding.createProfileFilled.password.tagline'
                  )}
                  required
                  type={'password'}
                  value={password}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, 'password')
                  }
                  errorMessage={
                    password && !validators.passwordIsValid(password)
                      ? t(
                          'teacher.onboarding.createProfileFilled.password.error'
                        )
                      : undefined
                  }
                  placeholder={t(
                    'teacher.onboarding.createProfileFilled.placeholder.password'
                  )}
                  name="password"
                />
              </div>
            }
            {isTeacher && (
              <div
                onMouseEnter={() => setIsInputPhoneFocus(true)}
                onMouseLeave={() => setIsInputPhoneFocus(false)}
              >
                <StyledInputPhone
                  isFocus={isInputPhoneFocus}
                  label={t(
                    'teacher.onboarding.createProfileFilled.field.phone'
                  )}
                  phone={phoneNumber || ''}
                  onChange={handleChangePhone}
                  error={!validators.isPhoneValid(phoneNumber, phoneDialCode)}
                  description={
                    !validators.isPhoneValid(phoneNumber, phoneDialCode)
                      ? t(
                          'teacher.onboarding.createProfileFilled.phoneNumber.error'
                        )
                      : t(
                          'teacher.onboarding.createProfileFilled.phoneNumber.tagline'
                        )
                  }
                  optionalText={t('input.optional')}
                  country={region.toLowerCase()}
                />
              </div>
            )}
            {!isStudent && (
              <SelectOption
                value={language}
                options={langOptions}
                label={t(
                  'teacher.onboarding.createProfileFilled.field.platformLanguage'
                )}
                onChange={(opt) => handleChangeInput(opt.value, 'language')}
                noBgColor
                hasBorder
              />
            )}
            <CheckboxContainer>
              <Checkbox
                align="end"
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    'register.personal_data.terms_and_privacy'
                    // 'Registrándote aceptas los <a href="https://www.innovamat.com/aviso-legal" target="__blank"> Términos y condiciones</a> y la <a href="https://www.innovamat.com/politica-de-privacidad" target="__blank"> Política de privacidad</a>.'
                  ),
                }}
              />
            </CheckboxContainer>
            {isParent && (
              <CheckboxContainer>
                <Checkbox
                  align="end"
                  checked={acceptCommunications}
                  onChange={() =>
                    setAcceptCommunications(!acceptCommunications)
                  }
                />
                <span>{t('parent_register_form.accept_communications')}</span>
              </CheckboxContainer>
            )}

            <Button
              type="submit"
              disabled={!invitationIsValid()}
              onClick={handleCompleteUserInvitation}
              loading={isPending}
            >
              {t('teacher.onboarding.createProfileFilled.button.send')}
            </Button>
          </Form>

          {/* <TermsAndConditions /> */}
        </LoginRegisterLayout>
        <ModalError
          loadingReenvitation={isPendingResendInvitation}
          goToLogin={goToLogin}
          goToForgotPassword={goToForgotPassword}
          onResendLink={handleResendLink}
          expiredLinkError={expiredLinkError}
          showErrorModal={showErrorModal}
          userExistsError={userExistsError}
          closeErrorModal={closeErrorModal}
          email={email}
        />
      </ThemeProviderInnovaComponents>
    </>
  );
}
