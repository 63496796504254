import { getAcceptLanguage } from '@innovamat/localization';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { ContentListsQuery } from '../../generated/types';
import { ContentListsDocument } from '../../generated/types';
import { useGlowApiClient } from '../../graphql-provider';
import { useCompletedResources } from '../use-completed-resources';
import { mapContentListResponse } from '../use-page/mapper';
import type { ContentListType } from '../use-page/types';
import type { ContentList, ContentListsResponse } from './types';

type Props = {
  contentListsIds: string[];
  academicYearId: string;
  classroomId: string;
  isStudentView?: boolean;
  region?: string;
  language: string;
};

type Return = {
  isLoading: boolean;
  isError: boolean;
  data: ContentListType[];
};

export function useContentLists({
  contentListsIds,
  classroomId,
  academicYearId,
  isStudentView,
  region,
  language,
}: Props): Return {
  const completedResources = useCompletedResources({
    classroomId,
    academicYearId,
  });

  const variables = {
    ids: contentListsIds,
  };

  const { graphqlClient, getAuthToken } = useGlowApiClient();

  const { data, isLoading, isError } = useQuery<ContentListsQuery>({
    queryKey: ['ContentLists', variables, region, language],
    queryFn: () => {
      const headers = {
        'accept-language': getAcceptLanguage(region, language) ?? '*',
        authorization: getAuthToken() ?? '',
      };
      return graphqlClient.request(ContentListsDocument, variables, headers);
    },
    enabled: Boolean(contentListsIds.length > 0 && region && language),
    retry: false,
  });

  const contentListsData =
    data?.contentLists as ContentListsResponse['contentLists'];

  const contentLists: ContentListType[] = useMemo(() => {
    if (!contentListsData) return [];
    return contentListsData.map((contentListData: ContentList) =>
      mapContentListResponse(
        contentListData,
        completedResources,
        Boolean(isStudentView)
      )
    );
  }, [completedResources, isStudentView, contentListsData]);

  return {
    isLoading,
    isError,
    data: contentLists,
  };
}
