import { t } from 'i18next';

import type { Report } from '../../../../../../../modules/report/report';

import { formatDate } from '../../../../../../../utils/date';

type Props = {
  deliveryDate?: Report['deliveryDate'];
};

const TestReportCellDeliveryDate = ({ deliveryDate }: Props): JSX.Element => {
  return deliveryDate ? (
    <>{formatDate(deliveryDate)}</>
  ) : (
    <>{t('common.notAvailable')}</>
  );
};

export { TestReportCellDeliveryDate };
