import { useTranslation } from 'react-i18next';

import { useDevice, type Column } from '@innovamat/glimmer-components';

import { StatementsTableHeader } from '../../../../../components/statements-table-header/statements-table-header';
import type {
  MockExamsStudentRow,
  StatementMetadata,
} from '../../../../../types/mock-exams';

const useMockExamsDrawerTableColumnsConf = (
  numStatements: number,
  statementsMetadata?: StatementMetadata[]
): Column<MockExamsStudentRow>[] => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  return [
    {
      id: 'firstName',
      label: t('mock-exams-drawer.table.first-name.label'),
      sortable: true,
      sortIgnoreCase: true,
      width: 112,
      minWidth: 112,
      sticky: !isMobile,
    },
    {
      id: 'lastName',
      label: t('mock-exams-drawer.table.last-name.label'),
      sortable: true,
      sortIgnoreCase: true,
      width: 112,
      minWidth: 112,
      sticky: !isMobile,
    },
    {
      id: 'status',
      label: t('common.status'),
      width: 145,
      minWidth: 145,
      sticky: !isMobile,
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
      sticky: !isMobile,
    },
    {
      id: 'score',
      label: t('mock-exams-drawer.table.score.label'),
      sortable: true,
      width: 76,
      minWidth: 76,
      sticky: !isMobile,
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
      sticky: !isMobile,
    },
    {
      id: 'statements',
      subLabel: () => {
        return (
          <StatementsTableHeader
            numStatements={numStatements}
            statementsMetadata={statementsMetadata}
          />
        );
      },
      label: t('mock-exams-drawer.table.statements.label'),
      isLargeLabel: false,
      minWidth: 100,
      width: '100%',
    },
  ];
};

export { useMockExamsDrawerTableColumnsConf };
