import { useEventLogging } from '@innovamat/event-logging';
import {
  ContentListType,
  ContentResource,
  LinkedListsPage,
  useCurrentNavigationValues,
  useMarkSessionDone,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import { useLocation, useParams } from 'react-router-domv6';

type RelatedResourcesListProps = {
  linkedLists: string[];
  sessionName: string | undefined;
  sessionId: string;
  region: string;
  language: string;
};

export function RelatedResourcesList({
  linkedLists,
  sessionName,
  sessionId,
  region,
  language,
}: RelatedResourcesListProps) {
  const { goToResource } = useNavigation();
  const { pathname } = useLocation();
  const { setEventData } = useEventLogging();
  const { user, isStudentViewActive, isRole } = useUser();
  const { onMarkSession, onToggleSolutions } = useMarkSessionDone();
  const { classroomId } = useParams();
  const { getTypeOfPagePermission, currentPage } = useCurrentNavigationValues();

  const handleResourceClick = (
    resource: ContentResource,
    list: ContentListType
  ) => {
    const permissionType = currentPage
      ? getTypeOfPagePermission(currentPage)
      : undefined;

    setEventData('resource_accessed_from_list', {
      page_type: permissionType,
      list_id: list.id,
      list_name: list.name,
      list_type: list.list_type,
      list_order: linkedLists.indexOf(list.id) + 1,
      lists_total: linkedLists.length,
      from_url: pathname,
      resource_id: resource.id,
      resource_name: resource.name,
      resource_type: resource.type,
      resource_order_in_list: list.resources.indexOf(resource) + 1,
      resources_total_in_list: list.resources.length,
      is_list_preview: Boolean(list.page_id),
      from_session_id: sessionId,
      from_session_name: sessionName,
    });

    goToResource({
      resourceId: resource.id,
      type: resource.type,
      listId: list.id,
      listType: list.list_type,
      isDigitalGuide: resource.has_digital_guide,
    });
  };

  return (
    <LinkedListsPage
      linkedListsIds={linkedLists}
      onResourceClick={(resource, list) => {
        handleResourceClick(resource, list);
      }}
      onMarkSession={onMarkSession}
      onToggleSolutions={onToggleSolutions}
      classroomId={classroomId!}
      academicYearId={user?.organization?.academicYearId!}
      isStudentView={isStudentViewActive || isRole.Student}
      region={region}
      language={language}
    />
  );
}
