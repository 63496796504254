import styled from '@emotion/styled';
import { IconButton } from '../icon-button';
import { Typography } from '../typography';
import { useDevice } from '../../hooks/useDevice';
import { Tooltip } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const TitleHeaderWrapper = styled.div<{ hasMargin?: boolean }>`
  position: relative;
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: ${({ hasMargin }) => hasMargin && '24px'};
`;

const Content = styled.div<{ hasSeparator?: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ hasSeparator, theme }) =>
    hasSeparator
      ? `1px solid
    ${theme.tokens.color.alias.cm.border['border-subtle'].value}`
      : 'none'};
  padding: 0.5rem 0;
  width: 100%;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.5rem;
`;

const iconWidth = 2.5;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  left: -${iconWidth + 1.5}rem;
  top: 0.3rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    position: relative;
    left: 0;
    top: 0;
  }
`;

type Props = {
  onGoBack?: () => void;
  title: string;
  subtitle?: string | JSX.Element;
  actions?: JSX.Element;
  hasSeparator?: boolean;
  hasMargin?: boolean;
  dataTestId?: string;
  hasFullScreenButton?: boolean;
  fullScreenButtonAction?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export function TitleHeader({
  onGoBack,
  title,
  actions,
  hasSeparator = true,
  subtitle,
  dataTestId,
  hasFullScreenButton,
  fullScreenButtonAction,
  ...rest
}: Props): JSX.Element {
  const { isMobile } = useDevice();
  const { t } = useTranslation();

  return (
    <TitleHeaderWrapper {...rest} data-testid={dataTestId}>
      {onGoBack && (
        <StyledIconButton
          size={isMobile ? 'M' : 'L'}
          icon="BackIcon"
          onClick={onGoBack}
        />
      )}
      <Content hasSeparator={hasSeparator}>
        <Typography.H2>
          {title}{' '}
          {hasFullScreenButton && (
            <Tooltip content={t('reports.tooltip.fullWindow')}>
              <IconButton
                icon="FullScreenIcon"
                onClick={() =>
                  fullScreenButtonAction && fullScreenButtonAction()
                }
              />
            </Tooltip>
          )}
        </Typography.H2>
        {subtitle && subtitle}
      </Content>
      {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
    </TitleHeaderWrapper>
  );
}
