import type { Material } from '../cart.types';
import { Tooltip } from '../../tooltip';

import { Container, MaterialItem, List, Title } from './cart-section.styles';

export type CartSectionProps = {
  materials: Material[];
  title: string;
  isPositive?: boolean;
};

function CartSection({
  materials,
  title,
  isPositive,
}: CartSectionProps): JSX.Element {
  return (
    <Container>
      <Title>{title}</Title>
      <List>
        {materials.map((material) => (
          <MaterialItem key={material.id} isPositive={!!isPositive}>
            <span>{material.course}</span>
            <Tooltip content={material.name} placement="auto">
              <span className="materialName">{material.name}</span>
            </Tooltip>
            <span>{material.language}</span>
            <span className="totalOperationQuantity">
              <span className="sign">{isPositive ? '+' : '-'}</span>
              {Math.abs(material.totalOperationQuantity)}
            </span>
          </MaterialItem>
        ))}
      </List>
    </Container>
  );
}

export default CartSection;
