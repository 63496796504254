import styled from '@emotion/styled';
import { Button, Loader } from '@innovamat/glimmer-components';
import { useClassroomTestRoundDetailQuery } from '@innovamat/glow-api-client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import { PrintStyles } from './components/common';
import { Cover } from './components/cover';
import { Introduction } from './components/introduction';

import { useEventLogging } from '@innovamat/event-logging';
import { ErrorPage } from '../../../navigation';
import { MockExamsStudentRow, StatementMetadata } from '../../types/mock-exams';
import { ResultsByStudent } from './components/results-by-student';
import { ResultsClassScoreByQuestion } from './components/results-class-score-by-question';
import { ResultsStudentOverallScore } from './components/results-student-overall-score';

import { useState } from 'react';
import { generatePDF } from '../../utils/generate-pdf';

const Wrapper = styled.div`
  padding: 24px;
  background-color: #f5f5f5;
  padding-bottom: 84px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PrintSection = styled.div`
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #e5e5e5;
  padding: 8px;
  width: 280px;
  position: fixed;
  bottom: 10px;
  gap: 4px;
  left: 50%;
  border-radius: 8px;
  transform: translateX(-50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

function PrintMockExams(): JSX.Element {
  const { classroomId, testRoundId, organizationName, classroomName } =
    useParams();
  const { t } = useTranslation();
  const { data, isLoading, isError } = useClassroomTestRoundDetailQuery(
    {
      testRoundId: testRoundId ?? '',
      classroomId: classroomId ?? '',
    },
    { enabled: !!testRoundId && !!classroomId, staleTime: Infinity }
  );

  const { setEventData } = useEventLogging();

  const [loading, setLoading] = useState(false);

  const handlePrint = async () => {
    setLoading(true);
    setEventData('print-mock-exams', {
      testRoundId,
    });

    const fileName = t(data?.classroomTestRoundDetail?.title ?? 'mock-exams');

    await generatePDF(fileName);
    setLoading(false);
  };

  const totalQuestions =
    data?.classroomTestRoundDetail?.numberOfStatements ?? 0;

  const evaluations =
    data?.classroomTestRoundDetail?.statements?.evaluations ?? [];

  const multipleChoicePositionLabels =
    data?.classroomTestRoundDetail?.statements?.multipleChoicePositionLabels ??
    [];

  const name = t(data?.classroomTestRoundDetail?.title ?? '');

  const students = data?.classroomTestRoundDetail
    ?.students as MockExamsStudentRow[];

  const statementsMetadata = data?.classroomTestRoundDetail
    ?.statementsMeta as StatementMetadata[];

  if (isError) {
    return <ErrorPage errorType="ERROR_500" />;
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size="lg" />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <PrintSection>
        <Button fill onClick={handlePrint} variant="accent" loading={loading}>
          {t('print-tests-and-results.footer.print')}
        </Button>
      </PrintSection>
      <div>
        <PrintStyles>
          <Cover
            name={name}
            classroomName={classroomName ?? ''}
            organizationName={organizationName ?? ''}
          />
          <Introduction name={name} />
          <ResultsStudentOverallScore
            totalQuestions={totalQuestions}
            students={students}
          />
          <ResultsClassScoreByQuestion
            evaluations={evaluations}
            multipleChoicePositionLabels={multipleChoicePositionLabels}
          />
          <ResultsByStudent
            students={students}
            numStatements={totalQuestions}
            statementsMetadata={statementsMetadata}
          />
        </PrintStyles>
      </div>
    </Wrapper>
  );
}

export { PrintMockExams };
