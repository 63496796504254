import styled from '@emotion/styled';

export const PageBreak = styled.div`
  border: 5px solid red;
  height: 40px;
  display: block;
  cursor: pointer;

  @media print {
    display: block;
    border: none;
    height: 0;
    span {
      display: none;
    }
    break-after: column;
  }
`;
