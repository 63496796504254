import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { Drawer, DrawerHeader } from '@innovamat/glimmer-components';

import { useUnblockedAppletsDrawerContext } from '../context/unblocked-applets-drawer-provider';
import { useUnblockedAppletsDrawer } from '../hooks/use-unblocked-applets-drawer';
import DrawerContent from './unblocked-applets-drawer-content';
import UnblockedAppletsDrawerSkeleton from './unblocked-applets-drawer-skeleton';

const Container = styled.div`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px 40px 24px;
  width: 501px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const UnblockedAppletsDrawer = (): EmotionJSX.Element => {
  const { isDrawerOpen, resourceSelected, onCloseDrawer } =
    useUnblockedAppletsDrawerContext();

  const { isLoading, unblockedApplets } = useUnblockedAppletsDrawer({
    sessionCode: resourceSelected?.code || '',
  });

  return (
    <Drawer
      key="unblocked-applets-drawer"
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      roundedBorder
      closeOnClickOutside
    >
      <Container>
        <DrawerHeader
          title={resourceSelected?.name}
          onCloseDrawer={onCloseDrawer}
        />
        {isLoading ? (
          <UnblockedAppletsDrawerSkeleton />
        ) : (
          <DrawerContent applets={unblockedApplets} />
        )}
      </Container>
    </Drawer>
  );
};

export default UnblockedAppletsDrawer;
