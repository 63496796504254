import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import type { AppletsOfTheWeek } from '@innovamat/glow-api-client';
import { dates } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';
import { getBackgroundAccordingToWeekAndCourseOrder } from '../digital-practice/utils/getImagesSecundaria';
import { useCurrentNavigationValues } from '../navigation';
import DigitalPracticeEmptyState from './components/empty-state/digital-practice-empty-state';
import DigitalPracticeSlider from './components/slider/digital-practice-slider';
import ConfiguredDateInformation from './configured-date-information/configured-date-information';
import TextInformation from './text-information/text-information';
import type { ReasonForEmptyList } from './types';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const DigitalPracticeWrapper = styled.div`
  width: 95%;
  overflow-x: hidden;
  overflow-y: hidden;
`;

type Props = {
  appletsOfTheWeek?: AppletsOfTheWeek;
  courseOrder: number;
  onConfigurationChange?: () => void;
  isVisible?: boolean;
};

const DigitalPracticeInformation = ({
  appletsOfTheWeek,
  courseOrder,
  onConfigurationChange,
  isVisible,
}: Props): EmotionJSX.Element => {
  const { t } = useTranslation();
  const { currentClassroom } = useCurrentNavigationValues();

  if (!isVisible || !appletsOfTheWeek) {
    return <> </>;
  }

  const addWeek = appletsOfTheWeek.weekToShow === 'current' ? 0 : 1;
  const background = getBackgroundAccordingToWeekAndCourseOrder({
    weekOfTheYear: dates.getCurrentWeekNumber() + addWeek,
    courseOrder,
  });

  const hasActivities =
    appletsOfTheWeek.activities.length > 0 &&
    !appletsOfTheWeek.reasonForEmptyList;
  const hasDateConfigured =
    currentClassroom?.digitalPracticeFirstDayOfWeek &&
    currentClassroom?.digitalPracticeFirstHourOfWeek;

  return (
    <Container>
      <TextInformation
        title={t('weeklyPractice.title')}
        subtitle={t('homeWeeklyPractice.subtitle')}
        weekToShow={appletsOfTheWeek.weekToShow}
        startDate={appletsOfTheWeek.startDate}
        endDate={appletsOfTheWeek.endDate}
      />
      <DigitalPracticeWrapper>
        {hasActivities ? (
          <DigitalPracticeSlider
            activities={appletsOfTheWeek?.activities || []}
            backgroundImg={background}
            canEnableDisableActivities={
              appletsOfTheWeek.weekToShow !== 'current'
            }
          />
        ) : (
          <DigitalPracticeEmptyState
            reasonForEmptyList={
              appletsOfTheWeek.reasonForEmptyList as ReasonForEmptyList
            }
            backgroundImg={background}
          />
        )}
      </DigitalPracticeWrapper>
      {hasDateConfigured && (
        <ConfiguredDateInformation
          classroom={currentClassroom}
          prevText={t('homeWeeklyPractice.startPractice.text')}
          onConfigurationChange={onConfigurationChange}
        />
      )}
    </Container>
  );
};

export default DigitalPracticeInformation;
