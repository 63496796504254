import { Region } from '@innovamat/localization';
import { IconRecord } from '../../types/icons';
import { getItemsFromLang } from '../../utils/common';
import { mapMoments } from '../../utils/mapMoments';
import { SecondaryDigitalGuide } from './types/secondary-digital-guide';

export const secondaryDigitalGuideMapper = async (
  response: any,
  locale: string,
  region: Region
) => {
  const items = getItemsFromLang(response.items, locale, region);

  const digitalGuide: SecondaryDigitalGuide = {
    moments: await mapMoments(items.moments, locale, region),
    material: items.material,
    prerequisites: items.prerequisites,
    sessionObjectives: items.session_objectives,
    contentBlocks: items.content_blocks,
    subtitle: items.subtitle,
    title: items.title,
    dimensions: items.dimensions,
    type: 'digitalGuide',
    summary: items.summary,
    preprType: 'secondary',
  };

  return digitalGuide;
};

export const SECONDARY_CONTENT_BLOCKS = {
  '1': 'digitalguides.blocks.numbers_and_operations.se',
  '2': 'digitalguides.blocks.geometry',
  '3': 'digitalguides.blocks.statistics_and_probability',
  '4': 'digitalguides.blocks.algebra',
};

export const SECONDARY_MOMENT_TYPES_ICONS: IconRecord = {
  '1': 'Depart',
  '2': 'Fly',
  '3': 'Land',
  '4': 'SamVideo',
};

export const SECONDARY_CONTENT_BLOCKS_ICONS: IconRecord = {
  '1': 'Numeration',
  '2': 'Geometry',
  '3': 'AnalyticsGraph',
  '4': 'SchoolBoardMaths',
};
