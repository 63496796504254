import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Tooltip } from '../tooltip';

export type EllipsisProps = {
  text: string;
};

const Text = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;

  * {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledTooltip = styled(Tooltip)`
  display: block;
`;

export function EllipsisText({ text }: EllipsisProps): JSX.Element {
  const [hasOverflow, setHasOverflow] = useState<boolean>();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOverflow = (): void => {
    if (containerRef.current) {
      const isOverflowing =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setHasOverflow(isOverflowing);
    }
  };

  useEffect(() => {
    handleOverflow();
  }, [text]);

  return (
    <StyledTooltip content={hasOverflow ? text : ''}>
      <Text ref={containerRef}>{text}</Text>
    </StyledTooltip>
  );
}
