import { useEffect, useMemo } from 'react';
import { BREAK_MODE_COLORS } from '../components/withBreakPageStyles';
import type { BreakPageMode } from './useBreakPageMode';
import { useBreakPageMode } from './useBreakPageMode';

interface UseBreakPageHandlerProps {
  isEditMode: boolean;
  initialMode?: BreakPageMode;
  onBreakModeChange?: (mode: BreakPageMode) => void;
}

interface BreakPageAttributes {
  breakPageMode: BreakPageMode;
  handleBreakPageClick: () => void;
  breakModeClass: string;
  breakModeData: {
    'data-break-mode': string;
    'data-break-color': string;
  };
}

export function useBreakPageHandler({
  isEditMode,
  initialMode,
  onBreakModeChange,
}: UseBreakPageHandlerProps): BreakPageAttributes {
  const { breakPageMode, handleBreakPageClick, setBreakPageMode } =
    useBreakPageMode({
      isEditMode,
      initialMode,
    });

  useEffect(() => {
    if (onBreakModeChange && breakPageMode !== initialMode) {
      onBreakModeChange(breakPageMode);
    }
  }, [breakPageMode, initialMode, onBreakModeChange]);

  useEffect(() => {
    if (initialMode !== undefined && initialMode !== breakPageMode) {
      setBreakPageMode(initialMode);
    }
  }, [initialMode, setBreakPageMode, breakPageMode]);

  const breakModeClass = useMemo(() => {
    if (!breakPageMode) return '';
    return `break-mode-${breakPageMode}`;
  }, [breakPageMode]);

  const breakModeData = useMemo(
    () => ({
      'data-break-mode': breakPageMode || 'none',
      'data-break-color': breakPageMode
        ? BREAK_MODE_COLORS[breakPageMode]
        : 'none',
    }),
    [breakPageMode]
  );

  return {
    breakPageMode,
    handleBreakPageClick,
    breakModeClass,
    breakModeData,
  };
}
