// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NnSjHr8vpT8MsOWD{position:relative;display:inline-block;width:100%}.NnSjHr8vpT8MsOWD .inQL6mzeh0oPmY63{display:none;position:absolute;background-color:#fff;min-width:180px;box-shadow:0 4px 8px rgba(0,0,0,.24);padding:4px 0;z-index:100;border-radius:4px;right:0}.NnSjHr8vpT8MsOWD .inQL6mzeh0oPmY63:not(.GkjiUMADgwROkhST){display:none}.NnSjHr8vpT8MsOWD .inQL6mzeh0oPmY63:not(.plfpTdLreUGNiEIP).m4coqN2xYgMlrXad{display:block}.NnSjHr8vpT8MsOWD:hover .inQL6mzeh0oPmY63:not(.plfpTdLreUGNiEIP):not(.GkjiUMADgwROkhST){display:block}.Y9GAkVP6ADkdGElK{align-items:center;color:#333;cursor:pointer;font-size:14px;min-height:40px;text-decoration:none;white-space:nowrap;display:flex;flex-direction:row;padding:4px 24px;gap:8px}.Y9GAkVP6ADkdGElK:visited{color:#333}.Y9GAkVP6ADkdGElK.aAvaMfKUdG06b4l4{padding-left:16px}.Y9GAkVP6ADkdGElK:hover{background-color:#f5f5f5}.Y9GAkVP6ADkdGElK .jNsBEoghnfECydKQ{color:#949494;font-size:12px;padding:4px 0}.Y9GAkVP6ADkdGElK.plfpTdLreUGNiEIP{pointer-events:none;opacity:.5}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/Dropdown/Dropdown.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,iBAAA,CACA,oBAAA,CACA,UAAA,CAEA,oCACE,YAAA,CAUA,iBAAA,CACA,qBCRI,CDSJ,eAAA,CACA,oCCqCS,CDpCT,aAAA,CACA,WAAA,CACA,iBAAA,CACA,OAAA,CAfA,2DACE,YAAA,CAGF,4EACE,aAAA,CAcF,wFACE,aAAA,CAKN,kBACE,kBAAA,CACA,UChCM,CDiCN,cAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,OAAA,CAKA,0BACE,UC/CI,CDkDN,mCAEE,iBAAA,CAQF,wBACE,wBCxDK,CD2DP,oCACE,aC/DK,CDgEL,cC9BK,CD+BL,aAAA,CAGF,mCACE,mBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "NnSjHr8vpT8MsOWD",
	"content": "inQL6mzeh0oPmY63",
	"clickable": "GkjiUMADgwROkhST",
	"disabled": "plfpTdLreUGNiEIP",
	"display": "m4coqN2xYgMlrXad",
	"item": "Y9GAkVP6ADkdGElK",
	"active": "aAvaMfKUdG06b4l4",
	"subtitle": "jNsBEoghnfECydKQ"
};
export default ___CSS_LOADER_EXPORT___;
