import { Chip, Typography } from '@innovamat/glimmer-components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { dates } from '@innovamat/radiance-utils';

const Date = styled(Typography.Caption)`
  color: ${(props) =>
    props.theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const formatDate = (date: string): string => {
  if (!date) return '';

  return DateTime?.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').toFormat(
    dates.getDateFormat()
  );
};

const hasDateExpired = (dateString: string): boolean => {
  const inputDate = DateTime.fromSQL(dateString, { zone: 'utc' });

  const now = DateTime.utc();

  return now > inputDate;
};

const hasMoreThanAMonthPassed = (dateString: string): boolean => {
  const inputDate = DateTime.fromSQL(dateString, { zone: 'utc' });

  const datePlusOneMonth = inputDate.plus({ months: 1 });

  const now = DateTime.utc();

  return now > datePlusOneMonth;
};

type Props = {
  expiresAt?: string | null | undefined;
  lastLogin?: string | null | undefined;
  dataTestId?: string;
  isInvitation: boolean | null | undefined;
  isSchoolInvitation?: boolean | null | undefined;
};

function StatusCell({
  expiresAt,
  lastLogin,
  dataTestId,
  isInvitation,
  isSchoolInvitation,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const isNonExistingTeacherInOrganization = isSchoolInvitation || isInvitation;

  const getTextNonExistingTeacher = (): string => {
    if (isSchoolInvitation) {
      return t('generic.status.invitationSent', 'Invitacion enviada');
    }

    const isExpiredInvitation = hasDateExpired(expiresAt!);

    if (isExpiredInvitation) {
      return t('generic.status.invitationExpired', 'Invitacion expirada');
    }

    if (expiresAt) {
      return `${t('generic.status.expiresAt', 'Expira')}: ${formatDate(
        expiresAt ?? ''
      )}`;
    }

    return '';
  };

  const getTextExistingTeacher = (): string => {
    if (!lastLogin) {
      return t('dates.never', 'Nunca');
    }

    const hasMoreThanAMonth = hasMoreThanAMonthPassed(lastLogin);

    if (hasMoreThanAMonth) {
      return t('dates.moreThanMonth', 'Más de un mes');
    }

    return formatDate(lastLogin ?? '');
  };

  return (
    <Date data-testid={dataTestId || 'status-cell'}>
      {isNonExistingTeacherInOrganization ? (
        <StatusContainer>
          <Chip text={t('generic.status.pending', 'Pendiente')} />
          <span>{getTextNonExistingTeacher()}</span>
        </StatusContainer>
      ) : (
        <StatusContainer>
          <Chip
            text={t('generic.status.activeAccess', 'Acceso activo')}
            type="success"
          />
          <span>
            {t('dates.lastActivity', 'Ultima actividad')}:{' '}
            {getTextExistingTeacher()}
          </span>
        </StatusContainer>
      )}
    </Date>
  );
}

export { StatusCell };
