import styled from '@emotion/styled';
import { FunctionComponent, SVGProps } from 'react';

const StyledButton = styled.button`
  align-items: center;
  background: #fff;
  border: 1px solid #949494;
  color: #333333;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 16px;
  gap: 12px;
  justify-content: center;
  padding: 12px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  font-weight: bold;
  svg {
    position: absolute;
    left: 15px;
  }
`;

type Props = {
  dataCy: string;
  onClick: () => void;
  text: string;
  Logo: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  dataTestid?: string;
};

function SignInButton({ dataCy, onClick, text, Logo, dataTestid }: Props) {
  return (
    <StyledButton data-cy={dataCy} onClick={onClick} data-testid={dataTestid}>
      <Logo title={text} />
      {text}
    </StyledButton>
  );
}

export { SignInButton };
