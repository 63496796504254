import type { SemanticType } from '@innovamat/glimmer-components';
import { Chip, SEMANTIC_TYPE } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { Status, TEST_STATUS } from '../../../../../../modules/test/test';

const getChipType = (status: Status): SemanticType => {
  if (status === TEST_STATUS.STARTED) {
    return SEMANTIC_TYPE.INFO;
  }

  if (status === TEST_STATUS.PLANNED || status === TEST_STATUS.NOT_STARTED) {
    return SEMANTIC_TYPE.DEFAULT;
  }

  if (status === TEST_STATUS.COMPLETED) {
    return SEMANTIC_TYPE.SUCCESS;
  }

  return SEMANTIC_TYPE.DEFAULT;
};

const TestStatusCell = ({ status }: { status: Status }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Chip
      type={getChipType(status)}
      text={t(`testsAndResultsView.statusTableCell.${status}`)}
    />
  );
};

export { TestStatusCell };
