import { useEffect, useState } from 'react';
import type { FullScreenHandle } from 'react-full-screen';
import styled from '@emotion/styled';
import LoaderContainer from '../../components/LoaderContainer';
import { UnityWebGL } from '../UnityWebGL';
import { resolveBuildPath } from '../../utils/buildsResolver';
import type { AppletsInfo } from '../../types/resourceViewer';
import { purgedSession } from './sessionPurger';
import PlayIcon from './PlayIcon';
import type { AxiosInstance } from 'axios';
import type { UnityPayload } from '../../types/applet';

export type Tokens = {
  accessToken: string;
  refreshToken: string;
  idToken: string;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
`;

type Props = {
  sceneName: string;
  packOrVariation: string;
  payload?: UnityPayload;
  onLoad?: () => void;
  onCloseApp?: () => void;
  onAppletIsCompleted?: () => void;
  style?: {} | string;
  language: string;
  fullscreen?: FullScreenHandle;
  buildLog?: boolean;
  unityActivity?: any;
  appletInfo: AppletsInfo;
  thumbnail?: string;
  title?: string;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
  axiosInstance: AxiosInstance;
  apolloServer: string;
  onUpdateTokens?: (tokens: Tokens) => void;
  school: string;
  uid: string;
  onException?: () => void;
};

export function AppletResource({
  sceneName,
  packOrVariation,
  payload,
  onLoad,
  onCloseApp,
  onAppletIsCompleted,
  style,
  language,
  fullscreen,
  buildLog,
  unityActivity,
  appletInfo,
  thumbnail,
  title,
  setEventData,
  axiosInstance,
  apolloServer,
  onUpdateTokens,
  school,
  uid,
  onException,
}: Props) {
  const {
    regionParameters,
    unityBuild,
    unityStandaloneBuildDomain,
    educationalLevel,
    refreshToken,
    accessToken,
    onlyShowPlayButton,
    handlePlayButton,
    clientID,
    idToken,
    platformUrl,
    slackApiTokens,
    slackApiUrl,
    solverUrl,
  } = appletInfo;

  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(0);
  const [resolvedBuildPath, setResolvedBuildPath] = useState('');
  const [buildRoutesLoading, setBuildRoutesLoading] = useState(true);
  const isIsolatedBuild = packOrVariation === 'ISOLATED';

  useEffect(() => {
    if (sceneName !== '' && packOrVariation !== '') {
      setId(id + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneName, packOrVariation]);

  useEffect(() => {
    if (!isIsolatedBuild) {
      resolveBuildPath(
        sceneName,
        educationalLevel,
        unityBuild,
        unityStandaloneBuildDomain
      ).then((path) => {
        setResolvedBuildPath(
          buildLog ? path.replace('index.html', 'BuildLog.html') : path
        );
        if (buildLog) setLoading(false);
        setBuildRoutesLoading(false);
      });
    }
  }, [
    unityBuild,
    unityStandaloneBuildDomain,
    buildLog,
    educationalLevel,
    sceneName,
  ]);

  if (isIsolatedBuild) {
    return (
      <Wrapper>
        <Iframe
          key={id}
          src={`${unityStandaloneBuildDomain}/Primary/${sceneName}/index.html`}
          title={title || ''}
        />
      </Wrapper>
    );
  } else if (isIsolatedBuild === false) {
    const getUnityInputs = () => {
      const defaultSessionEntity = {
        activities: [
          {
            codename: '',
            is_motor: sceneName?.startsWith('M'),
            max_duration: 180000,
            min_duration: 60000,
            min_statements: 1200,
            pack: packOrVariation,
            scene_name: sceneName,
            variation: packOrVariation,
          },
        ],
        bufferingSessions: [],
        name: 'Default session name',
        surname: 'Default session surname',
        schoolYear: 10,
        accomplishedContents: 0,
        totalContents: 100,
        hasReachedMaxMultiplier: false,
        currentMultiplier: 0,
      };

      console.log(`LOADING APPLET: SCENE ${sceneName} PACK ${packOrVariation}`);
      return {
        sessionEntity: defaultSessionEntity,
        isSecondaryRequest: true, // TODO: Change that to the correct business logic when possible
        stage: educationalLevel,
        ...payload,
        packOrVariation,
        sceneName,
        regionParameters,
        clientID,
        refreshToken,
        accessToken,
        idToken,
        platformUrl,
        solverUrl,
        school,
        uid,
        Tokens: {
          AccessToken: accessToken,
          RefreshToken: refreshToken,
          IdToken: idToken,
          clientID,
        },
      };
    };

    const handleOnLoad = () => {
      setLoading(false);
      onLoad?.();
    };

    const unityInputs = getUnityInputs();

    if (unityActivity) unityInputs.sessionEntity.activities = [unityActivity];

    unityInputs.sessionEntity = purgedSession(
      unityInputs.sessionEntity,
      unityInputs.sceneName!,
      unityInputs.packOrVariation!
    );

    const hasRegionParameters = regionParameters.currency;

    return (
      <Wrapper>
        {onlyShowPlayButton && handlePlayButton ? (
          <div>
            <PlayIcon
              thumbnail={thumbnail || ''}
              title={title || ''}
              handleClick={() => {
                handlePlayButton(packOrVariation, sceneName);
              }}
            />
          </div>
        ) : (
          <>
            {(buildRoutesLoading || loading) && <LoaderContainer />}
            {resolvedBuildPath !== '' && hasRegionParameters && (
              <UnityWebGL
                isApplet
                key={id}
                onLoad={handleOnLoad}
                style={style || { height: '100%' }}
                unityInputs={unityInputs}
                onCloseApp={onCloseApp}
                buildPath={resolvedBuildPath}
                onAppletIsCompleted={onAppletIsCompleted}
                refreshToken={refreshToken}
                accessToken={accessToken}
                language={language}
                fullscreen={fullscreen}
                setEventData={setEventData}
                axiosInstance={axiosInstance}
                apolloServer={apolloServer}
                onUpdateTokens={onUpdateTokens}
                slackApiTokens={slackApiTokens}
                slackApiUrl={slackApiUrl}
                unityStandaloneBuildDomain={unityStandaloneBuildDomain}
                onException={onException}
              />
            )}
          </>
        )}
      </Wrapper>
    );
  }
  return null;
}
