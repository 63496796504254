import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import type { Classroom } from '@innovamat/glow-api-client';
import { dates } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';
import ModifyDigitalPracticeLink from './modify-digital-practice-link';

const Container = styled.div`
  display: flex;
  gap: 16px;
`;

const TextWrapper = styled.div<{ isWeeklyResults?: boolean }>`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  display: flex;
  gap: ${({ isWeeklyResults }) => (isWeeklyResults ? '8px' : '4px')};
  align-items: baseline;
`;

type Props = {
  prevText: string;
  classroom: Classroom;
  source?: string;
  onConfigurationChange?: () => void;
};

const ConfiguredDateInformation = ({
  prevText,
  classroom,
  source,
  onConfigurationChange,
}: Props): EmotionJSX.Element => {
  const { t } = useTranslation();

  const { digitalPracticeFirstDayOfWeek, digitalPracticeFirstHourOfWeek } =
    classroom;

  const isWeeklyResults = source === 'weeklyResults';

  const TextComponent = isWeeklyResults ? Typography.Body1 : Typography.Body2;
  const SubtitleComponent = isWeeklyResults
    ? Typography.Subtitle1
    : Typography.Subtitle2;

  return (
    <Container>
      <TextWrapper isWeeklyResults={isWeeklyResults}>
        <TextComponent>{prevText}</TextComponent>
        <SubtitleComponent>
          {`${t(`common.${digitalPracticeFirstDayOfWeek}`)} ${
            digitalPracticeFirstHourOfWeek &&
            `${dates.removeSecondsFromCompleteHour(
              digitalPracticeFirstHourOfWeek
            )}h`
          }`}
        </SubtitleComponent>
      </TextWrapper>
      <ModifyDigitalPracticeLink
        classroom={classroom}
        source={source}
        onConfigurationChange={onConfigurationChange}
      />
    </Container>
  );
};

export default ConfiguredDateInformation;
