import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-domv6';

import { authService, roles, validators } from '@innovamat/radiance-utils';
import type { LoginResponse } from '@innovamat/social-login';
import { useAuthState } from '@innovamat/social-login';
import { useWebviewBridge } from '@innovamat/webview-bridge';

import { useEventLogging } from '@innovamat/event-logging';
import { useFlag } from '@innovamat/flags';
import { useQueryParams } from '@innovamat/hooks';
import { useEnvVariables } from '../../env-variables-manager';
import { useUser } from '../../user-management';
import type { NotificationType } from '../types/notification-type';

function useLogin() {
  const { WEBAPP } = useEnvVariables();
  const { t } = useTranslation();
  const [isUSA, setIsUSA] = useState(false);
  const { setEventData } = useEventLogging();
  const { onLogin, loading, error, loginResponse } = useAuthState();
  const { isInWebview, isGreenApp } = useWebviewBridge();
  const [showWebViewModal, setShowWebViewModal] = useState(false);
  const { onSetUserLogin } = useUser();

  const getIsLoading = () => loading && Object.values(loading).some(Boolean);
  const isLoading = getIsLoading();

  const [showPassword, setShowPassword] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const [showEmailError, setShowEmailError] = useState(false);

  const navigate = useNavigate();
  const referral = useQueryParams().get('referral');

  const { value: showCleverLoginButton } = useFlag(
    'showCleverLoginButton',
    false
  );

  useEffect(() => {
    const retryInterval = 100;
    const maxRetries = 50;
    let retries = 0;

    const checkAndLogin = (): void => {
      if (isInWebview && window.uniWebViewAccessToken) {
        onLogin({
          type: 'login_from_webview',
          payload: window.uniWebViewAccessToken,
        });
      } else if (retries < maxRetries) {
        setTimeout(checkAndLogin, retryInterval);
        retries++;
      }
    };

    checkAndLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInWebview]);

  useEffect(() => {
    if (!loginResponse) return;

    // Si es junior.
    if (getIsStudentJunior(loginResponse)) {
      if (isInWebview) {
        setShowWebViewModal(true);
      } else {
        const urlWithParams = `${WEBAPP}${window.location.search}`;
        window.location.replace(urlWithParams);
        return;
      }
      // Si no es junior.
    } else {
      // set the token in the storage
      onSetUserLogin(loginResponse);

      if (isInWebview && isGreenApp && getIsStudent(loginResponse)) {
        navigate('/practice/home');
      }

      if (referral) {
        navigate(referral);
      } else {
        navigate('/');
      }

      return;
    }
  }, [loginResponse, isInWebview, isGreenApp, history]);

  const handleCheckEmail = (email: string): void => {
    if (email) {
      setShowEmailError(true);
    }
  };

  const handleHideWebViewModal = () => {
    setShowWebViewModal(false);
  };

  const getIsStudentJunior = (tokenData: LoginResponse) => {
    const user = authService.decodeToken(tokenData);
    return roles.hasStudentJuniorRole(user?.roles);
  };

  const getIsStudent = (tokenData: LoginResponse) => {
    const user = authService.decodeToken(tokenData);
    return roles.hasStudentRole(user?.roles);
  };

  const mslWithNoAccOnInnovamat = error?.title === 'msal_auth_invalid';
  const isUserDisabled = error?.title === 'user.is_disabled';
  const hasExistingAccount = window.location.search.includes(
    'warning=already_registered'
  );

  useEffect(() => {
    setDisplayError(!!error);
    if (error && credentials.username) {
      setEventData(
        'login_attempt_failed',
        {
          username: credentials.username,
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleSetCredentials = (value: string, name: string) => {
    setCredentials((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };

  const getNotification = (): NotificationType => {
    if (hasExistingAccount) {
      return {
        message: t(
          'Login.Esta dirección de correo electrónico ya tiene una cuenta activa asignada. Ingresa con ella para acceder al Gestor de aula.',
          'El email que has puesto ya tiene una cuenta. Accede a tu cuenta en esta página.'
        ),
        type: 'warning',
      };
    }

    if (!displayError) return undefined;

    if (isUserDisabled) {
      return {
        message: t(
          'Login.messageError.user.is_disabled',
          'Tu cuenta ha sido desactivada por tu escuela. Habla con ellos para poder acceder.'
        ),
        type: 'error',
      };
    }

    if (mslWithNoAccOnInnovamat) {
      return {
        message: t(
          'Login.messageError.user.msal_auth_invalid',
          'Has accedido con tu cuenta Microsoft, pero no eres usuario de Innovamat. Habla con tu escuela para registrarte.'
        ),
        type: 'error',
      };
    }

    return {
      message: (
        <Trans
          i18nKey="Login.messageError.incorrectUser"
          components={{
            b: <b />,
            a: (
              <a
                href={t('common.accountProblems.url')}
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      ),
      type: 'error',
    };
  };

  const notification = getNotification();

  const handleDisplayError = (value: boolean) => {
    setDisplayError(value);
  };

  const handleToggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const isValid = (): boolean => {
    const { username, password } = credentials;

    if (!username.trim() || !password.trim()) return false;
    if (!validators.isEmailValid(username)) return false;

    return true;
  };

  const handleIsUsa = (value: boolean) => {
    setIsUSA(value);
  };

  const showCleverBtn = isUSA && showCleverLoginButton;

  return {
    credentials,
    displayError,
    error,
    isLoading,
    notification,
    showEmailError,
    showPassword,
    showWebViewModal,
    handleCheckEmail,
    handleDisplayError,
    handleHideWebViewModal,
    handleSetCredentials,
    handleToggleShowPassword,
    isValid,
    onLogin,
    handleIsUsa,
    showCleverBtn,
  };
}

export { useLogin };
