import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';

const PrintStyles = styled.div`
  br {
    content: ' ';
    display: block;
    margin: 4px;
  }

  @page first {
    size: A4 landscape;
    margin: 12.5mm;
  }

  @page second {
    size: A4 portrait;
    margin: 12.5mm;
  }
`;

const LandscapePage = styled.div`
  page: first;

  margin: auto;
  width: 29.7cm;
  height: 21cm;
  border: 1px solid
    ${(props) => props.theme.tokens.color.global.neutral.neutral400.value};
  background-color: white;

  @media print {
    border: none;
    page-break-after: always;
  }
`;

const PortraitPage = styled.div`
  page: second;

  margin: auto;
  background-color: white;
  width: 21cm;
  height: 29.7cm;
  border: 1px solid
    ${(props) => props.theme.tokens.color.global.neutral.neutral400.value};

  @media print {
    border: none;
    page-break-after: always;
  }
`;

const BreakPage = styled.div`
  page-break-after: always;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Subtitle = styled(Typography.Subtitle1)`
  margin-bottom: 16px;
`;

const Subtitle2 = styled(Typography.Subtitle2)`
  margin-bottom: 16px;
`;

const Content = styled.section`
  padding: 48px;
`;

const Title = styled(Typography.H4)`
  margin-bottom: 24px;
`;

const ChartContainer = styled.div`
  margin-top: 48px;
`;

const ScaledChartContainer = styled.div`
  transform: scale(0.8);
  margin-top: -46px;
  width: 24cm;
  margin-left: -106px;
`;

export {
  BreakPage,
  ChartContainer,
  Content,
  LandscapePage,
  PortraitPage,
  PrintStyles,
  ScaledChartContainer,
  Subtitle,
  Subtitle2,
  Text,
  Title,
};
