// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dptDiLZ5xoAzouwt{height:100%;position:relative;width:100%}.dptDiLZ5xoAzouwt:not(.yLS2hi73uQAqJDxC) div[class=react-pdf__Document],.dptDiLZ5xoAzouwt:not(.yLS2hi73uQAqJDxC) div[class=react-pdf__Page]{display:block;height:100% !important;width:100% !important}.dptDiLZ5xoAzouwt:not(.yLS2hi73uQAqJDxC).HCSVnGaVmKgezkEC canvas{display:block;height:auto !important;width:100% !important}.dptDiLZ5xoAzouwt.yLS2hi73uQAqJDxC{overflow:hidden}.dptDiLZ5xoAzouwt.yLS2hi73uQAqJDxC div[class=react-pdf__Document],.dptDiLZ5xoAzouwt.yLS2hi73uQAqJDxC div[class=react-pdf__Page]{display:block;height:100% !important;width:100% !important}.dptDiLZ5xoAzouwt.yLS2hi73uQAqJDxC div[class=react-pdf__Document] canvas,.dptDiLZ5xoAzouwt.yLS2hi73uQAqJDxC div[class=react-pdf__Page] canvas{display:block;height:auto !important;margin:0 auto;max-height:100% !important;max-width:100% !important;width:auto !important}div.bsXsi6Kvtmc6ZcD0{height:100%}.v_ITH3syKQILkaQB{display:flex;width:100%}.WhkkBc6alccXcoeu{width:100%}.KMU0Rm_zAcMHkzI9{border-top:2px solid #f5f5f5}.dZMBMt0Xz0M_GMae{border-left:2px solid #f5f5f5}.JFVaST7qwoXzpJmH{display:flex}.mIUuQDLjRBIyzq8p{position:absolute;top:0;left:0;background:#fff;height:100%;width:100%}", "",{"version":3,"sources":["webpack://./../../libs/resource-viewer/src/lib/resources/SinglePdfViewer/SinglePdfViewer.module.scss"],"names":[],"mappings":"AAAA,kBACE,WAAA,CACA,iBAAA,CACA,UAAA,CAGE,4IAEE,aAAA,CACA,sBAAA,CACA,qBAAA,CAIA,iEACE,aAAA,CACA,sBAAA,CACA,qBAAA,CAKN,mCACE,eAAA,CAEA,gIAEE,aAAA,CACA,sBAAA,CACA,qBAAA,CAEA,8IACE,aAAA,CACA,sBAAA,CACA,aAAA,CACA,0BAAA,CACA,yBAAA,CACA,qBAAA,CAMR,qBACE,WAAA,CAGF,kBACE,YAAA,CACA,UAAA,CAGF,kBACE,UAAA,CAGF,kBACE,4BAAA,CAGF,kBACE,6BAAA,CAGF,kBACE,YAAA,CAGF,kBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,eAAA,CACA,WAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "dptDiLZ5xoAzouwt",
	"fitHeight": "yLS2hi73uQAqJDxC",
	"zeroZoom": "HCSVnGaVmKgezkEC",
	"fullscreen": "bsXsi6Kvtmc6ZcD0",
	"doublePage": "v_ITH3syKQILkaQB",
	"landscape": "WhkkBc6alccXcoeu",
	"topBorder": "KMU0Rm_zAcMHkzI9",
	"leftBorder": "dZMBMt0Xz0M_GMae",
	"zoomOut": "JFVaST7qwoXzpJmH",
	"bloquedPage": "mIUuQDLjRBIyzq8p"
};
export default ___CSS_LOADER_EXPORT___;
