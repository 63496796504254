import styled from '@emotion/styled';
import { InnerHtml, Typography } from '@innovamat/glimmer-components';
import { useLayoutEffect, useRef, useState } from 'react';

const DIN_A4_WIDTH = '710px';
const HEIGHT_LIMIT = 60;

const Wrapper = styled.div<{ isTextTooLarge: boolean }>`
  display: flex;
  padding: 16px 0 4px 0;
  align-items: baseline;
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark['06']};
  margin-bottom: 12px;

  ${({ isTextTooLarge }) =>
    isTextTooLarge &&
    `
    flex-direction: column;
    gap: 4px;
    padding-bottom: 8px;
  `}
`;

const Title = styled(Typography.H3)`
  color: ${({ theme }) => theme.colors.dark['00']};
`;

const Subtitle = styled(Typography.Body1)`
  color: ${({ theme }) => theme.colors.dark['02']};
`;

type Props = {
  title?: string;
  subtitle: string;
};

export function TitlePrintable({ title, subtitle }: Props): JSX.Element {
  const [isTextTooLarge, setIsTextTooLarge] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // Set wrapper max-width to 710px to calculate if the text is too large to fit in one line in DIN A4

    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.style.maxWidth = DIN_A4_WIDTH;
      const isTooLarge = wrapper.clientHeight > HEIGHT_LIMIT;
      setIsTextTooLarge(isTooLarge);
    }

    return () => {
      if (wrapper) {
        wrapper.style.maxWidth = 'none';
      }
    };
  }, [title, subtitle]);

  return (
    <Wrapper ref={wrapperRef} isTextTooLarge={isTextTooLarge}>
      <Title>
        <InnerHtml text={title} />
      </Title>
      <Subtitle>
        <InnerHtml text={subtitle} />
      </Subtitle>
    </Wrapper>
  );
}
