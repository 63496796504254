import {
  Option,
  Resource,
  ResourceViewer,
  Session,
  getIconByType,
} from '@innovamat/resource-viewer';
import { useFullScreenHandle } from 'react-full-screen';

import {
  LOAD_ACTIVITY,
  detectIsInWebview,
  useWebviewBridge,
} from '@innovamat/webview-bridge';

import { ScrollPosition } from '@innovamat/smart-guides';

import { useEventLogging } from '@innovamat/event-logging';
import { saveFile, stripHTMLTags } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';
import { authAxiosInstance } from '../../../../utils';
import { useEnvVariables } from '../../../env-variables-manager';
import { useUser } from '../../../user-management';
import { Layout } from '../../components/resource-layout/layout';
import { PlayerSectionWrapper } from '../../components/resource-layout/player-section-wrapper';
import { useAppletInfo } from '../../hooks/use-applets-info';
import { onResourceViewerEvents } from '../../utils/resource-viewer-events';
import './_react-pdf.scss';

type Props = {
  translations: {};
  resource: Exclude<Resource, Session>;
  setScrollPosition: (value: ScrollPosition) => void;
  onGoBack: () => void;
};

export function SingleResource({
  translations,
  resource,
  setScrollPosition,
  onGoBack,
}: Props) {
  const appletInfo = useAppletInfo();
  const handleFS = useFullScreenHandle();
  const { APOLLO_SERVER } = useEnvVariables();
  const { setEventData } = useEventLogging();
  const { user, tokenInfo, isRole } = useUser();

  const checkIfPrintable = (tags: string[]) => {
    const strings = ['Imprimible', 'Printable'];
    let value = 0;
    strings.forEach((word: string) => {
      value += tags.includes(word) ? 1 : 0;
    });
    return value >= 1;
  };

  const isPrintable = checkIfPrintable(resource.tags);
  const pdfResource = (): Option[] | [] => {
    const allPrintables = [...(resource.printables || [])];

    if (isPrintable && 'path' in resource) {
      const splitOriginalFile = resource.path.split('.');
      const extension = splitOriginalFile[splitOriginalFile.length - 1];
      allPrintables.unshift({
        key: resource?.title || '',
        id: resource.id,
        label: resource?.title || '',
        value: resource.fileId,
        href: stripHTMLTags(resource?.path || ''),
        icon: getIconByType(extension),
        subtitle: stripHTMLTags(resource.title),
      });
    }
    return allPrintables;
  };

  const printables = pdfResource();

  const handleDownloadOption = (option?: Option | undefined) => {
    if (!resource) return;
    if (!option) return;
    const uuids = String(option.value).split('-');
    uuids.forEach((uuid) => {
      const printableToSave = printables.find((o: Option) => uuid === o.value);
      if (printableToSave) {
        const splitOriginalFile = printableToSave?.subtitle?.split('.');
        const extension = splitOriginalFile?.slice(-1).pop();
        saveFile(
          printableToSave.href,
          stripHTMLTags(printableToSave.subtitle),
          detectIsInWebview
        );
        setEventData('content_printable_download', {
          resource_name: stripHTMLTags(printableToSave.subtitle),
          resource_id: resource.id,
          resource_type: extension,
          file_id: printableToSave.value,
        });
      }
    });
  };

  const isGenially = resource.type === 'genially';

  const { isInWebview, sendEventToWebview } = useWebviewBridge();
  const { i18n } = useTranslation();
  const { regionParameters, platformUrl } = useAppletInfo();

  const handleOpenApplet = (
    packOrVariation: string | number,
    sceneName: string
  ) => {
    isInWebview &&
      sendEventToWebview(LOAD_ACTIVITY, {
        sceneName: sceneName,
        packOrVariation: packOrVariation,
        regionParameters,
        platformApiUrl: platformUrl,
        accessToken: tokenInfo?.access_token,
        refreshToken: tokenInfo?.refresh_token,
        idToken: tokenInfo?.id_token,
        language: i18n.language,
        isSecondary: true,
      });
  };

  return (
    <Layout
      title={resource.title}
      description={resource.description}
      id={resource.id}
      printables={printables}
      handleDownloadOption={handleDownloadOption}
      setScrollPosition={setScrollPosition}
      isSessionLayout={true}
      onFullScreenButtonClick={isGenially ? () => handleFS.enter() : undefined}
      onGoBack={onGoBack}
      isStudent={isRole.Student}
    >
      <div>
        <PlayerSectionWrapper>
          <ResourceViewer
            resource={resource}
            translations={translations}
            appletInfo={{
              ...appletInfo,
              onlyShowPlayButton: isInWebview,
              handlePlayButton: handleOpenApplet,
            }}
            onDispatchEvent={(prevState, state, action) =>
              onResourceViewerEvents({
                prevState,
                state,
                action,
                setEventData,
              })
            }
            fullScreen={handleFS}
            apolloServer={APOLLO_SERVER}
            axiosInstance={authAxiosInstance}
            setEventData={setEventData}
            videoOptions={{
              transparent: !isInWebview,
              playsinline: isInWebview ? 1 : 0,
            }}
            school={user?.organization?.id!}
            uid={user?.id!}
          />
        </PlayerSectionWrapper>
      </div>
    </Layout>
  );
}
