type Report = { deliveryDate?: string | null };

type Participation = {
  completed?: number | null | undefined;
  total?: number | null | undefined;
};

const isAvailableForDownload = (
  deliveryDate: Report['deliveryDate']
): boolean => {
  return deliveryDate ? new Date() > new Date(deliveryDate) : false;
};

export { isAvailableForDownload };
export type { Participation, Report };
