import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  MockExamsStudentRow,
  StatementMetadata,
} from '../../../../types/mock-exams';
import {
  Content,
  LandscapePage,
  ScaledChartContainer,
  Text,
  Title,
} from '../common';
import { MockExamsPrintTable } from './components/mock-exams-print-table';

type Props = {
  numStatements: number;
  students: MockExamsStudentRow[];
  statementsMetadata: StatementMetadata[];
};

const CustomContainer = styled(ScaledChartContainer)`
  width: 32cm;
`;

const STUDENTS_PER_PAGE = 18;
const STATEMENTS_PER_PAGE = 16;

function chunkArray<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

export function ResultsByStudent({
  numStatements,
  students,
  statementsMetadata,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const studentChunks = chunkArray(students, STUDENTS_PER_PAGE);
  const totalStatementPages = Math.ceil(numStatements / STATEMENTS_PER_PAGE);

  return (
    <>
      {studentChunks
        .filter((chunk) => chunk.length > 0)
        .map((studentChunk, studentChunkIndex) => {
          const pages = Array.from({ length: totalStatementPages }).map(
            (_, statementPageIndex) => {
              const startStatement = statementPageIndex * STATEMENTS_PER_PAGE;
              const endStatement = Math.min(
                (statementPageIndex + 1) * STATEMENTS_PER_PAGE,
                numStatements
              );

              const currentStatementsMetadata = statementsMetadata
                .filter((meta) => {
                  const position = meta.position ?? 0;
                  return position >= startStatement && position < endStatement;
                })
                .map((meta) => ({
                  ...meta,
                  position: (meta.position ?? 0) - startStatement,
                }));

              const currentStudents = studentChunk.map((student) => ({
                ...student,
                statements: student.statements.slice(
                  startStatement,
                  endStatement
                ),
              }));

              if (
                !currentStudents.length ||
                !currentStatementsMetadata.length
              ) {
                return null;
              }

              return (
                <LandscapePage
                  key={`${studentChunkIndex}-${statementPageIndex}`}
                  id={`page-${4 + studentChunkIndex}-landscape`}
                >
                  <Content>
                    {statementPageIndex === 0 && studentChunkIndex === 0 && (
                      <>
                        <Title>
                          {t(
                            'print-tests-and-results.results-by-student.title'
                          )}
                        </Title>
                        <Text>
                          <Typography.Body2>
                            <Trans
                              i18nKey="print-tests-and-results.results-by-student.description"
                              components={{ br: <br /> }}
                            />
                          </Typography.Body2>
                        </Text>
                      </>
                    )}
                    <CustomContainer>
                      <MockExamsPrintTable
                        numStatements={endStatement - startStatement}
                        students={currentStudents}
                        statementsMetadata={currentStatementsMetadata}
                        id={`mock-exams-print-table-${studentChunkIndex}-${statementPageIndex}`}
                        statementOffset={startStatement}
                      />
                    </CustomContainer>
                  </Content>
                </LandscapePage>
              );
            }
          );

          return pages.filter(Boolean);
        })}
    </>
  );
}
