import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { Typography } from '../typography';
import { type SemanticType, useSemantic } from '../../hooks/useSemantic';

const Container = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  gap: 0.375rem !important;
  background-color: ${({ bgColor }) => bgColor};
  color: #1c1c1c;
  padding: 0 0.5rem;
  width: fit-content;
  min-width: fit-content;
  height: 1.25rem;
  border-radius: 1.25rem;
`;

const Dot = styled.div<{ color?: string }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export type ChipProps = {
  text: string;
  type?: SemanticType | undefined;
};

export function Chip({ type, text }: ChipProps): JSX.Element {
  const { getBackground, getColor } = useSemantic();
  const theme = useTheme();
  const textColor = theme.tokens.color.alias.cm.text.text.value;

  return (
    <Container
      aria-live="polite"
      bgColor={getBackground(type)}
      data-testid="chip"
      role="status"
    >
      <Dot data-testid="dot" color={getColor(type)} />
      <Typography.Body2 color={textColor}>{text}</Typography.Body2>
    </Container>
  );
}
