import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  language: string | undefined;
};

function useUpdateI18nLanguage({ language }: Props): void {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(language);
  }, [language]);
}

export { useUpdateI18nLanguage };
