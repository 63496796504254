// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CwiCaFs7uoYmnwUD{display:flex;align-items:center;gap:16px}.CwiCaFs7uoYmnwUD h5{color:#14a395}.CwiCaFs7uoYmnwUD img{height:24px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/Logo/Logo.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,qBACE,aCNM,CDSR,sBACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "CwiCaFs7uoYmnwUD"
};
export default ___CSS_LOADER_EXPORT___;
