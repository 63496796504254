// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bJEfjkpIqM6F5voB{display:flex;gap:16px;cursor:pointer}.rwfAuY_saAqGIY3x{display:flex;flex:1;flex-direction:column;justify-content:center}.rwfAuY_saAqGIY3x .TdWcBoYUzuAn6nvT{display:flex;color:#333}.gqUzqhJExCgOb61R{display:flex;color:#757575;font-size:12px;line-height:14px;margin-top:4px}.vRxN3x3XCaQAyXmN{display:flex;color:#949494;font-size:14px;font-weight:400;line-height:20px}.KEcyFukCiAkhhrJc{display:flex;color:#757575;font-size:12px;line-height:14px;margin-top:4px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/AvatarWithInfo/AvatarWithInfo.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,QAAA,CACA,cAAA,CAGF,kBACE,YAAA,CACA,MAAA,CACA,qBAAA,CACA,sBAAA,CAEA,oCACE,YAAA,CACA,UCXI,CDeR,kBACE,YAAA,CACA,aChBO,CDiBP,cAAA,CACA,gBAAA,CACA,cAAA,CAGF,kBACE,YAAA,CACA,aCvBO,CDwBP,cAAA,CACA,eAAA,CACA,gBAAA,CAGF,kBACE,YAAA,CACA,aChCO,CDiCP,cAAA,CACA,gBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "bJEfjkpIqM6F5voB",
	"infoContainer": "rwfAuY_saAqGIY3x",
	"h5": "TdWcBoYUzuAn6nvT",
	"courseM": "gqUzqhJExCgOb61R",
	"courseL": "vRxN3x3XCaQAyXmN",
	"courseS": "KEcyFukCiAkhhrJc"
};
export default ___CSS_LOADER_EXPORT___;
