import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import {
  Dropdown,
  Icon,
  NavItem,
  Skeleton,
  Typography,
} from '@innovamat/glimmer-components';
import { useClassroomsQuery } from '@innovamat/glow-api-client';
import { Classroom, sortClassrooms } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';
import { getAvatarImage } from '../../../../utils';

type ClassNavigatorProps = {
  classroomId: string;
  organizationId: string;
  onSelectClassroom: (classroomId: string) => void;
};

const DropdownContainer = styled(Dropdown.Content)`
  width: 310px;
  padding: 8px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  gap: 4px;
  z-index: 20;
`;

const ContentContainer = styled.div`
  max-height: 278px;
  gap: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const FooterLink = styled.div`
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  a {
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};

    :hover {
      color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
    }
  }

  svg,
  path {
    fill: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
  }
`;

const NavItemWrapper = styled(Dropdown.Item)`
  display: block;
  padding: 0;
  width: 100%;

  :hover {
    .dropdownItem-stateLayer {
      background-color: transparent;
    }
  }
`;

export function ClassNavigator({
  classroomId,
  organizationId,
  onSelectClassroom,
}: ClassNavigatorProps): JSX.Element {
  const { t } = useTranslation();
  const { data, isSuccess } = useClassroomsQuery(
    { orgId: organizationId, mine: true },
    { staleTime: Infinity }
  );

  const { setEventData } = useEventLogging();

  const classroomsOrdered = sortClassrooms(
    data?.classrooms?.filter(
      (classroom): classroom is Classroom => classroom !== null
    ) || []
  );

  const selectedClassroom = data?.classrooms?.find(
    (classroom) => classroom?.id === classroomId
  );

  if (isSuccess && selectedClassroom) {
    return (
      <Dropdown closeOnSelectItem>
        <Dropdown.Toggle>
          <NavItem
            dataTestId="classroom-navigator"
            className="navigator-toggle"
            name={selectedClassroom.name!}
            description={selectedClassroom.courseName!}
            avatar={getAvatarImage({
              avatar: selectedClassroom.avatar,
              icon: selectedClassroom.icon,
              courseOrder: selectedClassroom.courseOrder!,
            })}
            icon="ArrangeIcon"
          />
        </Dropdown.Toggle>

        {classroomsOrdered && (
          <DropdownContainer
            className="navigator-content"
            contentSpace="none"
            elevation="elevation 4"
            borderRadius="8px"
          >
            <ContentContainer>
              {classroomsOrdered.map((classroom) => (
                <NavItemWrapper
                  state="active"
                  onSelectItem={() => onSelectClassroom(classroom?.id!)}
                >
                  <NavItem
                    key={classroom?.id}
                    name={classroom?.name!}
                    description={classroom?.courseName!}
                    avatar={getAvatarImage({
                      avatar: classroom?.avatar,
                      icon: classroom?.icon,
                      courseOrder: classroom?.courseOrder!,
                    })}
                    selected={classroom?.id === selectedClassroom.id}
                  />
                </NavItemWrapper>
              ))}
            </ContentContainer>
            <FooterLink>
              <Typography.Link3
                href="/course-selector/course"
                target="_blank"
                onClick={() =>
                  setEventData('class_browsing_catalogue_click', {})
                }
                data-testid="course-selector-link"
              >
                {t('course-navigation.link')}
              </Typography.Link3>
              <Icon icon="OpenNewTabIcon" size="S" />
            </FooterLink>
          </DropdownContainer>
        )}
      </Dropdown>
    );
  }

  return <Skeleton width="146px" height="48px" />;
}
