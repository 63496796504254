import { useTestQuestionsPdfQuery } from '@innovamat/glow-api-client';
import type {
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';

type TestQuestionsPdfData = {
  testQuestionsPdf?: {
    pdfBase64?: string | null;
  } | null;
};

type TestQuestionsPdf = {
  data: string;
  isError: boolean;
  isRefetching: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<TestQuestionsPdfData, unknown>>;
};

type Props = {
  testId: string;
  language: string;
  enabled?: boolean;
};

function useGetTestQuestionsPdf({
  testId,
  language,
  enabled = false,
}: Props): TestQuestionsPdf {
  const { data, isRefetching, isSuccess, isLoading, refetch, isError } =
    useTestQuestionsPdfQuery(
      {
        testId: testId,
        language: language,
      },
      {
        retry: false,
        enabled: enabled,
        staleTime: 0,
        gcTime: 0,
      }
    );

  return {
    data: data?.testQuestionsPdf?.pdfBase64 ?? '',
    isError: isError,
    isRefetching,
    isSuccess: isSuccess,
    isLoading,
    refetch,
  };
}

export { useGetTestQuestionsPdf };
