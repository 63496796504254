import { axiosInstance } from '@innovamat/ga-features';
import type {
  RefreshResetPasswordToken,
  ResetPassword,
} from './reset-password';

export const forgotEmail = async (email: string, apiUsers: string) => {
  const url = `${apiUsers}/users/new-password-requests`;
  const response = await axiosInstance({
    url,
    method: 'POST',
    data: { email },
  });
  return response;
};

export const resetPassword = async (data: ResetPassword, apiUsers: string) => {
  const url = `${apiUsers}/user/password`;
  const response = await axiosInstance({ url, method: 'POST', data });
  return response;
};

export const refreshResetPasswordToken = async (
  { requestId, token }: RefreshResetPasswordToken,
  apiUsers: string
) => {
  const url = `${apiUsers}/users/new-password-requests/${requestId}`;
  const response = await axiosInstance({
    url,
    method: 'PATCH',
    data: { token },
  });

  return response;
};
