import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import {
  getElevation,
  ImageComponent,
  InnerHtml,
  Typography,
} from '@innovamat/glimmer-components';
import type { AppletData } from '@innovamat/glow-api-client';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import { useSessionDone } from '../../session-done';

export type UnblockedApplet = {
  sceneName: string;
  pack: number;
  data: AppletData;
};

type Props = {
  applet: UnblockedApplet | undefined | null;
};

const Container = styled.div<{ isDisabled: boolean }>`
  align-items: flex-start;
  display: flex;
  gap: 12px;
  width: 100%;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  :hover img {
    ${({ theme, isDisabled }) =>
      !isDisabled && getElevation(theme, 'elevation 4')}
  }
`;

const Thumbnail = styled(ImageComponent)`
  aspect-ratio: 12/7;
  cursor: pointer;
  border-radius: 4px;
  object-fit: cover;
  width: 151px;
`;

export const DescriptionContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: flex-start;
  gap: 4px;
`;

const UnblockedAppletItem = ({ applet }: Props): EmotionJSX.Element => {
  const { isInWebview } = useWebviewBridge();
  const { onGoToApplet } = useSessionDone();

  const handleAppletClick = (appletId: string): void => {
    onGoToApplet(appletId);
  };
  return (
    <Container isDisabled={isInWebview}>
      <Thumbnail
        src={applet?.data?.thumbnail || ''}
        alt={applet?.data?.name || ''}
        onClick={() => handleAppletClick(applet?.data?.id || '')}
      />
      <DescriptionContainer>
        <Typography.Subtitle2>
          <InnerHtml text={applet?.data?.name || ''} />
        </Typography.Subtitle2>
      </DescriptionContainer>
    </Container>
  );
};

export default UnblockedAppletItem;
