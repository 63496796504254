import type { Classroom, ClassroomBody } from '@innovamat/glow-api-client';
import { newId } from '@innovamat/radiance-utils';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClassroomScheduleValues } from '../classrooms-management/hooks/use-classroom-schedule-values';
import { usePutClassroom } from '../classrooms-management/hooks/use-put-classroom';
import { useUser } from '../user-management';

type Props = {
  classroom: Classroom | undefined;
  onClose: () => void;
  onConfigurationChange?: () => void;
};

const useModifyDigitalPracticeModal = ({
  classroom,
  onClose,
  onConfigurationChange,
}: Props) => {
  const { t } = useTranslation();
  const schedule = useClassroomScheduleValues();
  const { user } = useUser();
  const queryClient = useQueryClient();

  const [configuredDate, setConfiguredDate] = useState({
    digitalPracticeFirstDayOfWeek: classroom?.digitalPracticeFirstDayOfWeek,
    digitalPracticeFirstHourOfWeek: classroom?.digitalPracticeFirstHourOfWeek,
  });

  const hours = Array.from({ length: 24 }, (_, i) => ({
    value: `${i.toString().padStart(2, '0')}:00:00`,
    label: `${i.toString().padStart(2, '0')}:00h`,
  }));

  const daysOfWeek = [
    { value: 'monday', label: t('common.monday') },
    { value: 'tuesday', label: t('common.tuesday') },
    { value: 'wednesday', label: t('common.wednesday') },
    { value: 'thursday', label: t('common.thursday') },
    { value: 'friday', label: t('common.friday') },
    { value: 'saturday', label: t('common.saturday') },
    { value: 'sunday', label: t('common.sunday') },
  ];

  const handleOnChangeSelect = (
    value: string | undefined,
    key: string
  ): void => {
    setConfiguredDate((prev) => ({ ...prev, [key]: value }));
  };

  const handleOnClose = (): void => {
    setConfiguredDate({
      digitalPracticeFirstDayOfWeek: classroom?.digitalPracticeFirstDayOfWeek,
      digitalPracticeFirstHourOfWeek: classroom?.digitalPracticeFirstHourOfWeek,
    });
    onClose();
  };

  const handleOnSave = (): void => {
    if (isPending) {
      return;
    }

    const body: ClassroomBody = {
      id: classroom?.id || newId(),
      icon: classroom?.icon || 0,
      organizationId: user?.organizationId!,
      level: classroom?.level,
      name: classroom?.name,
      courseId: classroom?.courseId,
      avatar: classroom?.avatar,
      scheduleEnd: classroom?.scheduleEnd,
      scheduleStart: classroom?.scheduleStart,
      ...configuredDate,
    };

    handlePutClassroom(body, schedule.values.timeZone!);
  };

  const { handlePutClassroom, isPending } = usePutClassroom({
    onError: () => {},
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Classroom'] });
      onConfigurationChange?.();
      handleOnClose();
    },
  });

  return {
    configuredDate,
    daysOfWeek,
    hours,
    isPending,
    handleOnChangeSelect,
    handleOnClose,
    handleOnSave,
  };
};

export default useModifyDigitalPracticeModal;
