import type { Participation, Report } from '../report/report';

type Test = {
  classroomTestRoundId: string;
  testId: string;
  roundName: string;
  status: Status;
  password: string;
  startDate: string;
  endDate: string;
  report?: Report | null;
  participation?: Participation | null;
};

type TestRow = Test & {
  type?: TestType | null;
  rowName: string;
  rowDescription: string;
  id: string;
};

const TEST_TYPE = {
  CONMAT: 'CONMAT',
  COSMOS: 'COSMOS',
  MOCK: 'MOCK',
} as const;

type TestType = (typeof TEST_TYPE)[keyof typeof TEST_TYPE];

const TEST_STATUS = {
  STARTED: 'started',
  PLANNED: 'planned',
  COMPLETED: 'completed',
  NOT_STARTED: 'not_started',
} as const;

type Status = (typeof TEST_STATUS)[keyof typeof TEST_STATUS];

export { TEST_STATUS, TEST_TYPE };
export type { Participation, Report, Status, Test, TestRow, TestType };
