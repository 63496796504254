import styled from '@emotion/styled';
import React from 'react';
import { useBreakPageHandler } from '../hooks/useBreakPageHandler';
import { BreakPageMode } from '../hooks/useBreakPageMode';
import {
  getActiveBreakModeStyles,
  getBreakPrintStyles,
  getEditModeStyles,
} from './withBreakPageStyles';

interface WithBreakPageProps {
  isEditMode: boolean;
  initialBreakMode?: BreakPageMode;
  onBreakModeChange?: (mode: BreakPageMode) => void;
  [key: string]: any;
}

export function withBreakPage<P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P & WithBreakPageProps> {
  return function WithBreakPageComponent({
    isEditMode,
    initialBreakMode,
    onBreakModeChange,
    ...props
  }: WithBreakPageProps) {
    const {
      breakPageMode,
      handleBreakPageClick,
      breakModeClass,
      breakModeData,
    } = useBreakPageHandler({
      isEditMode,
      initialMode: initialBreakMode,
      onBreakModeChange,
    });

    const breakPageStyles = {
      position: 'relative' as const,
      ...getEditModeStyles({ isEditing: isEditMode }),
      ...getActiveBreakModeStyles({ isEditing: isEditMode, breakPageMode }),
      ...getBreakPrintStyles({ breakPageMode }),
    };

    return (
      <div
        className={`break-page-container ${breakModeClass}`}
        style={breakPageStyles}
        onClick={isEditMode ? handleBreakPageClick : undefined}
        {...breakModeData}
      >
        <WrappedComponent {...(props as P)} breakPageMode={breakPageMode} />
      </div>
    );
  };
}

const StyledBreakPageContainer = styled.div<{
  isEditing: boolean;
  breakPageMode?: BreakPageMode;
}>`
  position: relative;
  ${(props) => getEditModeStyles(props)}
  ${(props) => getActiveBreakModeStyles(props)}
  ${(props) => getBreakPrintStyles(props)}
`;

export const BreakPageContainer: React.FC<
  WithBreakPageProps & { children: React.ReactNode }
> = ({
  isEditMode,
  initialBreakMode,
  onBreakModeChange,
  children,
  ...props
}) => {
  const { breakPageMode, handleBreakPageClick, breakModeClass, breakModeData } =
    useBreakPageHandler({
      isEditMode,
      initialMode: initialBreakMode,
      onBreakModeChange,
    });

  return (
    <StyledBreakPageContainer
      className={`break-page-container ${breakModeClass}`}
      isEditing={isEditMode}
      breakPageMode={breakPageMode}
      onClick={isEditMode ? handleBreakPageClick : undefined}
      {...breakModeData}
      {...props}
    >
      {children}
    </StyledBreakPageContainer>
  );
};
