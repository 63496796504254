import type { MockExamsStudentRow } from '../../types/mock-exams';

type ChartsData = {
  name: string;
  score: number;
}[];

function mockExamsStudentRowsToChartData(
  students: MockExamsStudentRow[],
  t: Function
): ChartsData {
  return (
    students?.map((student) => ({
      name: student?.firstName
        ? `${student.firstName} ${student.lastName}`
        : t('common.unknown'),
      score: student?.score ?? 0,
    })) ?? []
  );
}

export { mockExamsStudentRowsToChartData };
