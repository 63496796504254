import { Icon } from '../icon';

const FLAG = {
  ca: 'CatFlag',
  en_US: 'UsaFlag',
  en: 'UkFlag',
  es_419: 'EspFlag',
  es_CO: 'EspFlag',
  es_EC: 'EspFlag',
  es_ES: 'EspFlag',
  es_MX: 'EspFlag',
  eu: 'EusFlag',
  fr_FR: 'FraFlag',
  it_IT: 'ItaFlag',
  it: 'ItaFlag',
  pt: 'PorFlag',
  va: 'VlcFlag',
  vl: 'VlcFlag',
} as const;

type FlagType = keyof typeof FLAG;

type Props = {
  flagName: FlagType;
};

const LanguageFlag = ({ flagName }: Props) => {
  if (!flagName) {
    return <></>;
  }

  const flagIcon = FLAG[flagName] || FLAG.es_ES;

  return <Icon icon={flagIcon} size="L" />;
};

export { LanguageFlag };

export type { FlagType };
