import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import {
  Button,
  Input,
  ThemeProvider as ThemeProviderInnovaComponents,
} from '@innovamat/innova-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-domv6';
import { useUser } from '../../../user-management';
import { SuccessMessage } from '../../components/success-message';
import TwoColumnLayout from '../../components/two-columns-layout/two-columns-layout';
import { useForgotPassword } from '../../hooks/use-reset-password';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export function ForgotPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const [value, setValue] = useState(email || '');
  const navigate = useNavigate();
  const { mutate, isPending, isError } = useForgotPassword();
  const { setEventData } = useEventLogging();
  const { isLoggedIn, onClearUserSession } = useUser();

  const handleSend = (e: React.FormEvent) => {
    e.preventDefault();
    mutate(value, {
      onSuccess: () => {
        navigate({ search: 'success=true' });
        setEventData(
          'sys_password_lost',
          {
            username: value,
          },
          false
        );
      },
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      onClearUserSession();
    }
  }, [isLoggedIn]);

  const invitationIsValid = () => !value.trim();
  const isSuccess = window.location.search.includes('success=true');

  return (
    <ThemeProviderInnovaComponents>
      <TwoColumnLayout
        canGoBack
        isSuccess={isSuccess}
        error={
          isError
            ? t(
                'Login.Hi ha hagut un error al recuperar la contrasenya. Prova-ho de nou més tard.'
              )
            : undefined
        }
        title={t('Login.Recupera la teva contrasenya')}
        titleId="recover-password"
        onSuccessBody={
          <SuccessMessage
            title={t(
              "Login.S'ha enviat un enllaç de recuperació al compte indicat. Revisa la safata de spam."
            )}
            dataTestId="forgot-password-success"
          />
        }
        buttonId="forgotPasswordSelectLang"
        goBackId="goBackForgotPassword"
        dropdownId="forgotPasswordDropdownLang"
      >
        <>
          <Form onSubmit={handleSend}>
            <Input
              label={t('Login.Nom d’usuari o email')}
              placeholder={t('Login.Nom d’usuari o email')}
              required
              onChange={(e) => setValue(e.target.value)}
              value={value}
              data-testid="forgotPasswordEmail"
            />
            <Button
              type="submit"
              fill
              disabled={invitationIsValid()}
              onClick={handleSend}
              loading={isPending}
              data-testid="sendLinkButton"
            >
              {t('Login.Enviar enllaç')}
            </Button>
          </Form>
        </>
      </TwoColumnLayout>
    </ThemeProviderInnovaComponents>
  );
}
