import styled from '@emotion/styled';
import { Typography } from '../typography';
import { IconButton } from '../icon-button';

type PageTitleProps = {
  title?: string;
  description?: string | JSX.Element;
  onGoBack?: () => void;
  rightActions?: React.ReactNode;
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BackButton = styled(IconButton)`
  position: absolute;
  left: -44px;
  transform: translateX(-50%);

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    left: 0;
    transform: none;
    position: relative;
  }
`;

const Title = styled(Typography.H2)`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 8px 0;
  gap: 8px;
`;

const Description = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function StepTitle({
  title,
  description,
  onGoBack,
  rightActions,
}: PageTitleProps) {
  return (
    <Container>
      <TitleContainer>
        <Title>
          {onGoBack && <BackButton icon="BackIcon" onClick={onGoBack} />}
          {title}
        </Title>
        {rightActions && rightActions}
      </TitleContainer>
      <Description>{description}</Description>
    </Container>
  );
}
