import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const generatePDF = async (fileName: string): Promise<void> => {
  const pages: HTMLElement[] = Array.from(
    document.querySelectorAll('[id^="page-"]')
  );

  if (pages.length === 0) return;

  const doc = new jsPDF({
    compress: true,
  });
  let firstPage = true;

  for (const page of pages) {
    const match = page.id.match(/^page-(\d+)-(landscape|portrait)$/);
    if (!match) continue;

    const [, , type] = match;

    const canvas = await html2canvas(page, {
      scale: 1.5,
      useCORS: true,
    });

    const image = canvas.toDataURL('image/jpeg', 0.7);

    const pdfWidth = type === 'portrait' ? 210 : 297;
    const pdfHeight = type === 'portrait' ? 297 : 210;

    const imgWidth = pdfWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    if (!firstPage) {
      doc.addPage([pdfWidth, pdfHeight], type as 'landscape' | 'portrait');
    } else {
      firstPage = false;
    }

    doc.addImage(image, 'JPEG', 0, 0, imgWidth, imgHeight);
  }

  doc.save(`${fileName}.pdf`);
};

export { generatePDF };
