import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import { useFlag } from '@innovamat/flags';
import { useUser } from '@innovamat/ga-features';
import {
  Password,
  TableComponent,
  Typography,
  useColumnsCustomRenderConfig,
  type CustomColumnRenderConfig,
} from '@innovamat/glimmer-components';

import { EllipsisCell } from '../../../../../classrooms-management/components/common-table-components/ellipsis-cell';
import type { TestRow } from '../../../../modules/test/test';
import { TEST_TYPE } from '../../../../modules/test/test';
import { formatDate } from '../../../../utils/date';
import { useMockExams } from '../mock-exams-drawer/context/mock-exams-provider';
import { MockExamsDrawer } from '../mock-exams-drawer/mock-exams-drawer';
import { TestParticipationCell } from './components/test-participation-cell/test-participation-cell';
import { DownloadTestReportCell } from './components/test-report-cell/download-test-report-cell';
import { OpenTestReportCell } from './components/test-report-cell/open-test-report-cell';
import { TestStatusCell } from './components/test-status-cell/test-status-cell';
import { useTestsAndResultsColumnsConfiguration } from './hooks/use-tests-and-results-columns-configuration';

export const Container = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
`;

type Props = {
  rows: TestRow[];
};

const customColumnsRenderConfig = (
  t: TFunction,
  openTestDrawer: (rowId: string) => void
): CustomColumnRenderConfig<TestRow> => ({
  rowName: (_, row) => (
    <Typography.Body1>
      <EllipsisCell
        key={`name_${row.classroomTestRoundId}`}
        text={row.rowName}
      />
    </Typography.Body1>
  ),
  status: (_, row) => (
    <TestStatusCell
      key={`status_${row.classroomTestRoundId}`}
      status={row.status}
    />
  ),
  password: (_, row) => (
    <Password
      key={`password_${row.classroomTestRoundId}`}
      value={row.password}
    />
  ),
  startDate: (_, row) => formatDate(row.startDate),
  endDate: (_, row) => formatDate(row.endDate),
  report: (_, { classroomTestRoundId, roundName, type, report }) => {
    switch (type) {
      case TEST_TYPE.COSMOS:
      case TEST_TYPE.CONMAT:
        return (
          <DownloadTestReportCell
            key={`report_${classroomTestRoundId}`}
            testRoundId={classroomTestRoundId}
            testRoundName={roundName}
            report={report}
          />
        );
      case TEST_TYPE.MOCK:
        return (
          <OpenTestReportCell
            key={`report_${classroomTestRoundId}`}
            testRoundId={classroomTestRoundId}
            onClick={openTestDrawer}
          />
        );
      default:
        return <></>;
    }
  },
  participation: (_, { participation, status }) => (
    <TestParticipationCell participation={participation} status={status} />
  ),
});

const TestsAndResultsTable = ({ rows }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useUser();

  const { value: showMockExamsDetailButton } = useFlag(
    'mockExamsDetail',
    false,
    {
      identifier: '',
      email: user?.email ?? '',
      custom: {},
    }
  );

  const { mockExamRowIds, onOpenDrawer } = useMockExams();

  const columnsWithoutRender = useTestsAndResultsColumnsConfiguration();
  const columns = useColumnsCustomRenderConfig<TestRow>(
    customColumnsRenderConfig(t, onOpenDrawer),
    columnsWithoutRender
  );

  return (
    <Container>
      <TableComponent
        columns={columns}
        id="tests-and-results-table"
        key="tests-and-results-table"
        clickableRows={showMockExamsDetailButton ? mockExamRowIds : []}
        rows={rows}
      />
      <MockExamsDrawer key="mock-test-drawer" />
    </Container>
  );
};

export { TestsAndResultsTable };
