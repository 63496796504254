import styled from '@emotion/styled';
import { IconsFaces, LogoInnovamat } from '@innovamat/glimmer-assets';
import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { PortraitPage } from '../common';

const Wrapper = styled.div`
  padding: 48px;
  position: relative;
  height: 100vh;
`;

const Content = styled.div`
  text-align: center;
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h5,
  h2 {
    margin-bottom: 4px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled(IconsFaces)`
  margin-bottom: 24px;
`;

const Title = styled(Typography.H2)`
  font-weight: 700;
`;

const Subtitle = styled(Typography.H4)`
  font-weight: bold;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Classroom = styled(Typography.H4)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type Props = {
  name: string;
  classroomName: string;
  organizationName: string;
};

export function Cover({
  name,
  classroomName,
  organizationName,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <PortraitPage id="page-1-portrait">
      <Wrapper>
        <LogoWrapper>
          <LogoInnovamat />
        </LogoWrapper>
        <Content>
          <Image />
          <Typography.Subtitle1>
            {t('print-tests-and-results.cover.title')}
          </Typography.Subtitle1>
          <Title>{name}</Title>
          <Subtitle>{organizationName}</Subtitle>
          <Classroom>{classroomName}</Classroom>
        </Content>
      </Wrapper>
    </PortraitPage>
  );
}
