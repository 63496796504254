import styled from '@emotion/styled';

import { getTypography, Typography } from '@innovamat/glimmer-components';

import { css } from '@emotion/react';
import { useSmartGuides } from '../../context';
import { groupConsecutiveTips } from '../../digital-guides/utils/steps';
import { usePartColors } from '../../hooks/usePartColors';
import { PrintableBody1 } from '../../printable-styles/printable-typography';
import type {
  Content,
  Evaluable,
  PartType,
  StepMedia,
} from '../../types/SmartGuide';
import { IconEvaluable, IconEvaluablePrintable } from '../IconEvaluable';
import { ManagementComment } from '../ManagementComment';
import { Media } from '../Media';
import { PageBreak } from '../page-break';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { Solution } from '../Solution';
import { GroupedStep } from '../step/grouped-step';
import { Table } from '../Table';
import { SignedTip } from '../tips/signed-tip';
import { Tip } from '../tips/tip';
import { WhatToObserve } from '../WhatToObserve';
import { BreakPageContainer } from '../withBreakPageComponent';

const NumContainer = styled.div`
  width: 24px;
  min-width: 24px;
  display: grid;
  place-items: center;
  margin: 8px 4px 0 0;
  color: ${({ color }) => color};

  @media print {
    margin: -2px 4px 0 0;
  }
`;

const StepContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 100%;
`;

const StepContent = styled.div<{ hasBreakPage: boolean }>`
  padding: 8px 0 8px 16px;
  border-left: 1px solid;
  border-color: ${({ color }) => color};
  display: flex;
  flex: 1;
  width: 1%;
  flex-direction: column;
  gap: 16px;

  @media print {
    padding: 0 0 0 16px;
    gap: 8px;
  }
`;

const NumberText = styled(Typography.Body1)`
  ${({ theme }) => getTypography(theme, 'Subtitle 2')}
`;

const StepText = styled(PrintableBody1)`
  gap: 16px;
  display: flex;
  justify-content: space-between;

  @media print {
    break-inside: avoid;
    color: #000000;
    gap: 8px;
  }
`;

const TextImageContainer = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ hasImage }) =>
    hasImage &&
    css`
      @media print {
        break-inside: avoid;
      }
    `}
`;

const TextComponent = styled(PrintableBody1)``;

const StyledIconEvaluable = styled(IconEvaluable)`
  height: auto !important;
`;

const StyledIconEvaluablePrintable = styled(IconEvaluablePrintable)`
  height: auto;

  > div {
    height: auto;
  }
`;

type Props = {
  num?: number | null;
  part: PartType;
  content: Content[];
  evaluable?: Evaluable;
  isPrintable?: boolean;
};

export function NumberedStep({
  num,
  part,
  content,
  evaluable,
  isPrintable,
}: Props): JSX.Element {
  const { getElement } = usePartColors();
  const color = getElement(part);
  const { hasBreakPage, isEditMode } = useSmartGuides();

  const handleRemovePageBreak = (e: any) => {
    e.target.parentElement?.remove();
  };

  const renderFirstText = (
    firstChild: Content,
    mediaNextChild: StepMedia | null
  ) => {
    if (firstChild.__typename !== 'Text') return null;
    if (typeof firstChild.text !== 'string') {
      return (
        <TextImageContainer key={`first-child`} hasImage={!!mediaNextChild}>
          <StepText className="step-text">
            <p>{firstChild.text}</p>
          </StepText>
          {renderImageInTextChild(mediaNextChild, 0)}
        </TextImageContainer>
      );
    }

    return (
      <TextImageContainer key={`first-child`} hasImage={!!mediaNextChild}>
        <StepText className="step-text">
          <PreprInnerHtml text={firstChild.text} />
          {evaluable?.isEvaluable && !isPrintable && (
            <StyledIconEvaluable dimension={evaluable.dimension} />
          )}
          {evaluable?.isEvaluable && isPrintable && (
            <StyledIconEvaluablePrintable dimension={evaluable.dimension} />
          )}
        </StepText>
        {renderImageInTextChild(mediaNextChild, 0)}
      </TextImageContainer>
    );
  };

  const renderImageInTextChild = (child: StepMedia | null, index: number) => {
    if (!child || !hasBreakPage) return null;
    return <Media key={`${child.__typename}-${index}`} media={child.media} />;
  };

  const renderChildren = () => {
    if (!content) return null;

    let isImageRendered = false;
    return groupConsecutiveTips(content)
      .map((group, i: number) => {
        const children = group.steps
          .map((child, index: number) => {
            if (!child) return null;

            const nextChild =
              group.steps[index + 1] ||
              groupConsecutiveTips(content)[i + 1]?.steps[0];

            let nextChildType: StepMedia | null = null;
            let renderedContent: React.ReactNode = null;

            switch (child.__typename) {
              case 'Text':
                if (nextChild?.__typename === 'ZzImagen') {
                  nextChildType = nextChild;
                  isImageRendered = true;
                } else {
                  isImageRendered = false;
                }

                if (index === 0) {
                  renderedContent = renderFirstText(child, nextChildType);
                } else {
                  renderedContent = (
                    <TextImageContainer hasImage={!!nextChildType}>
                      <TextComponent>
                        {typeof child.text === 'string' ? (
                          <PreprInnerHtml
                            key={`${child.__typename}-${index}`}
                            text={child.text}
                          />
                        ) : (
                          child.text
                        )}
                      </TextComponent>
                      {renderImageInTextChild(nextChildType, index)}
                    </TextImageContainer>
                  );
                }
                break;

              case 'ConsejoDeGestin':
                renderedContent = (
                  <ManagementComment
                    key={`${child.__typename}-${index}`}
                    text={child.text}
                    media={child.media}
                    tables={child.tables}
                  />
                );
                break;

              case 'Formacin':
                renderedContent = (
                  <ManagementComment
                    key={`${child.__typename}-${index}`}
                    text={child.text}
                    media={child.media}
                    tables={child.tables}
                  />
                );
                break;

              case 'Ampliacin':
                renderedContent = (
                  <Tip
                    isPrintable={isPrintable}
                    key={`${child.__typename}-${index}`}
                    type="ampliation"
                    media={child.media}
                    tables={child.tables}
                  >
                    {child.text}
                  </Tip>
                );
                break;

              case 'Apoyo':
                renderedContent = (
                  <Tip
                    isPrintable={isPrintable}
                    key={`${child.__typename}-${index}`}
                    type="support"
                    media={child.media}
                    tables={child.tables}
                  >
                    {child.text}
                  </Tip>
                );
                break;

              case 'Signedtip':
                renderedContent = (
                  <SignedTip
                    key={`${child.__typename}-${index}`}
                    icon={child.icon}
                    isPrintable={isPrintable}
                    media={child.media}
                    tables={child.tables}
                    type={child.tip_type}
                    counselor={child.counselor}
                  >
                    {child.text}
                  </SignedTip>
                );
                break;

              case 'Solucin':
                renderedContent = (
                  <Solution
                    isPrintable={isPrintable}
                    key={`${child.__typename}-${index}`}
                    part={part}
                    media={child.media}
                    tables={child.tables}
                  >
                    {child.text}
                  </Solution>
                );
                break;

              case 'QuePodemosObservar':
                renderedContent = (
                  <WhatToObserve
                    isPrintable={isPrintable}
                    key={`${child.__typename}-${index}`}
                    whatToObserve={child.items}
                  />
                );
                break;

              case 'ZzImagen':
                if (!child.media) return null;
                if (isImageRendered && hasBreakPage) {
                  isImageRendered = false;
                  return null;
                }
                renderedContent = (
                  <Media
                    key={`${child.__typename}-${index}`}
                    media={child.media}
                  />
                );
                break;

              case 'TableSmartguides':
                renderedContent = <Table tables={child.tables} />;
                break;

              case 'PageBreak':
                renderedContent =
                  isPrintable && !isEditMode ? (
                    <PageBreak onClick={handleRemovePageBreak}>
                      <span>PAGE BREAK</span>
                    </PageBreak>
                  ) : null;
                break;

              default:
                return null;
            }

            if (!renderedContent) return null;

            return isEditMode ? (
              <BreakPageContainer
                key={`break-container-${i}-${index}`}
                isEditMode={true}
              >
                {renderedContent}
              </BreakPageContainer>
            ) : (
              renderedContent
            );
          })
          .filter((child) => child !== null);

        if (children.length === 0) return null;

        return (
          <GroupedStep
            className="grouped-step"
            key={`${group.__typename}-${i}`}
            typename={group.__typename}
          >
            {children}
          </GroupedStep>
        );
      })
      .filter(Boolean);
  };

  return (
    <StepContainer className="step-container">
      <NumContainer className="step-number" color={color}>
        <NumberText>{num !== null ? num : ''}</NumberText>
      </NumContainer>
      <StepContent
        className="step-content"
        color={color}
        hasBreakPage={!!hasBreakPage}
      >
        {renderChildren()}
      </StepContent>
    </StepContainer>
  );
}
