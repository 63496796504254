// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Omq6YRiPW3Me_skG{position:fixed;justify-content:space-between;flex-direction:column;display:flex;top:50%;left:50%;transform:translate(-50%, -50%);background-color:#fff;z-index:1000;box-shadow:0 8px 16px rgba(0,0,0,.24);border-radius:8px;padding:24px;gap:20px}.Omq6YRiPW3Me_skG.GYy4dnEVWAIEbnGc{max-width:512px;min-height:480px}.Omq6YRiPW3Me_skG.BaxEPn0IMyXUQJmY{max-width:512px;min-height:256px}.ixCrPKSOPIodwlLF{flex:1;gap:8px;display:flex;flex-direction:column}.DoLjIAA5zOARviYH{margin-bottom:8px}.U8dAZx8SI0PQQaS_{position:fixed;top:0;left:0;right:0;bottom:0;z-index:1000}.iYusVedhzLoyrJFI{background-color:rgba(0,0,0,.5)}.kqAOi0fMm0IenveT{display:flex;justify-content:flex-end;gap:16px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/Modal/Modal.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,cAAA,CACA,6BAAA,CACA,qBAAA,CACA,YAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,qBCCM,CAAA,YAAA,CDCN,qCAAA,CACA,iBAAA,CACA,YAAA,CACA,QAAA,CAEA,mCACE,eAAA,CACA,gBAAA,CAGF,mCACE,eAAA,CACA,gBAAA,CAIJ,kBACE,MAAA,CACA,OAAA,CACA,YAAA,CACA,qBAAA,CAGF,kBACE,iBAAA,CAGF,kBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,YAAA,CAGF,kBACE,+BAAA,CAGF,kBACE,YAAA,CACA,wBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Omq6YRiPW3Me_skG",
	"normal": "GYy4dnEVWAIEbnGc",
	"small": "BaxEPn0IMyXUQJmY",
	"content": "ixCrPKSOPIodwlLF",
	"title": "DoLjIAA5zOARviYH",
	"overlay": "U8dAZx8SI0PQQaS_",
	"overlayBg": "iYusVedhzLoyrJFI",
	"footer": "kqAOi0fMm0IenveT"
};
export default ___CSS_LOADER_EXPORT___;
