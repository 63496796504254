import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useFlag } from '@innovamat/flags';
import { Typography } from '@innovamat/glimmer-components';
import type { Classroom } from '@innovamat/glow-api-client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModifyDigitalPracticeModal from '../../modify-digital-practice-modal/modify-digital-practice-modal';

type Props = {
  classroom: Classroom | undefined;
  source?: string;
  onConfigurationChange?: () => void;
};

const ModifyDigitalPracticeLink = ({
  classroom,
  source,
  onConfigurationChange,
}: Props): EmotionJSX.Element => {
  const { value: canModifyDigitalPracticeDate } = useFlag(
    'canModifyDigitalPracticeDate',
    false,
    { identifier: '', custom: { classroomId: classroom?.id || '' } }
  );

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const LinkComponent =
    source === 'weeklyResults' ? Typography.Link1 : Typography.Link2;

  return (
    <>
      {canModifyDigitalPracticeDate && (
        <>
          <LinkComponent onClick={() => setIsModalOpen(true)}>
            {t('common.modify')}
          </LinkComponent>
          <ModifyDigitalPracticeModal
            key={`modal-${classroom?.id}-${classroom?.digitalPracticeFirstDayOfWeek}-${classroom?.digitalPracticeFirstHourOfWeek}`}
            classroom={classroom}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfigurationChange={onConfigurationChange}
          />
        </>
      )}
    </>
  );
};

export default ModifyDigitalPracticeLink;
