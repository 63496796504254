import { Breadcrumb, IconButton } from '@innovamat/glimmer-components';

type NavBreadcrumbProps = {
  fromPageName: string;
  pageName: string;
  onGoBack: () => void;
};

export function NavBreadcrumb({
  fromPageName,
  pageName,
  onGoBack,
}: NavBreadcrumbProps): JSX.Element {
  return (
    <>
      <IconButton
        icon="BackIcon"
        dataTestId="nav-breadcrumb-back-button"
        onClick={onGoBack}
      />
      <Breadcrumb breadcrumbs={[fromPageName, pageName]} goFrom={onGoBack} />
    </>
  );
}
