import type { ReactNode } from 'react';

import styled from '@emotion/styled';

import { Typography } from '@innovamat/glimmer-components';

type InfoTagProps = {
  title?: string;
  text: string | ReactNode;
};

const InfoTagContainer = styled(Typography.Subtitle2)`
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  padding: 4px 16px;
  border-radius: 4px;

  p {
    display: inline;
  }
`;

export function InfoTag({ title, text }: InfoTagProps): JSX.Element {
  return (
    <InfoTagContainer>
      {title && <Typography.Body2>{title}:</Typography.Body2>} {text}
    </InfoTagContainer>
  );
}
