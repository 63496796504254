import type { EnvVariablesValues } from '@innovamat/ga-features';
import type { RegionalConfigVariablesType } from '@innovamat/regional-config-variables';

export function initEnvVariables(
  regionalConfigVariables: RegionalConfigVariablesType
): EnvVariablesValues {
  return {
    APOLLO_SERVER: regionalConfigVariables.apolloServer,
    API_USERS: regionalConfigVariables.apiUsers,
    USERS_CLIENT_ID: process.env.NEXT_PUBLIC_USERS_CLIENT_ID as string,
    CONFIG_CAT_KEY: process.env.NEXT_PUBLIC_CONFIG_CAT_KEY as string,
    CONFIG_CAT_CACHE_TIME: process.env
      .NEXT_PUBLIC_CONFIG_CAT_CACHE_TIME as string,
    WEBAPP: process.env.NEXT_PUBLIC_WEBAPP as string,
    INTEGROMAT_GET_CRM_SCHOOL: process.env
      .NEXT_PUBLIC_INTEGROMAT_GET_CRM_SCHOOL as string,
    POEDITOR_API_URL: process.env.NEXT_PUBLIC_POEDITOR_API_URL as string,
    POEDITOR_API_TOKEN: process.env.NEXT_PUBLIC_POEDITOR_API_TOKEN as string,
    POEDITOR_PROJECT_ID: process.env.NEXT_PUBLIC_POEDITOR_PROJECT_ID as string,
    RUDDERSTACK_APIKEY: process.env.NEXT_PUBLIC_RUDDERSTACK_APIKEY as string,
    RUDDERSTACK_URL: process.env.NEXT_PUBLIC_RUDDERSTACK_URL as string,
    VERSION: process.env.NEXT_PUBLIC_VERSION as string,
    ITERABLE_APIKEY: process.env.NEXT_PUBLIC_ITERABLE_APIKEY as string,
    INLINE_MANUAL: process.env.NEXT_PUBLIC_INLINE_MANUAL as string,
    ORGANIZATIONS: regionalConfigVariables.apiOrganization,
    API_PLATFORM: process.env.NEXT_PUBLIC_API_PLATFORM as string,
    STANDALONE_BUILD_DOMAIN: process.env
      .NEXT_PUBLIC_STANDALONE_BUILD_DOMAIN as string,
    UNITY_BUILD: process.env.NEXT_PUBLIC_UNITY_BUILD as string,
    WEBCONTENTS_URL: process.env.NEXT_PUBLIC_WEBCONTENTS_URL as string,
    API_SOLVER_URL: process.env.NEXT_PUBLIC_API_SOLVER_URL as string,
    CORPORATE_WEBSITE: process.env.NEXT_PUBLIC_CORPORATE_WEBSITE as string,
    INTEGROMAT_SESSION_ERROR: process.env
      .NEXT_PUBLIC_INTEGROMAT_SESSION_ERROR as string,
    INTEGROMAT_SESSION_REVIEW: process.env
      .NEXT_PUBLIC_INTEGROMAT_SESSION_REVIEW as string,
    GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
    GOOGLE_SECRET: process.env.NEXT_PUBLIC_GOOGLE_SECRET as string,
    PREPR_GRAPHQL: process.env.NEXT_PUBLIC_PREPR_GRAPHQL as string,
    MANAGER_NEXT: process.env.NEXT_PUBLIC_MANAGER_NEXT as string,
    CORPORATE_IMAGE_URL: process.env.NEXT_PUBLIC_CORPORATE_IMAGE_URL as string,
    GEOLOCATION_URL: process.env.NEXT_PUBLIC_GEOLOCATION_URL as string,
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT as string,
    INTEGROMAT_SAVE_CRM_DATA: process.env
      .NEXT_PUBLIC_INTEGROMAT_SAVE_CRM_DATA as string,
  };
}
