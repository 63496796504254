import { Typography } from '@innovamat/glimmer-components';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MockExamsDistributionChart } from '../../../../components/mock-exams-distribution-chart';
import { mockExamsStudentRowsToChartData } from '../../../../modules/mock-exams/mock-exams';
import type { MockExamsStudentRow } from '../../../../types/mock-exams';
import {
  ChartContainer,
  Content,
  LandscapePage,
  Subtitle,
  Text,
  Title,
} from '../common';

type Props = {
  totalQuestions: number;
  students: MockExamsStudentRow[];
};

export function ResultsStudentOverallScore({
  totalQuestions,
  students,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    return mockExamsStudentRowsToChartData(students, t);
  }, [students, t]);

  return (
    <LandscapePage id="page-3-landscape">
      <Content>
        <Title>{t('print-tests-and-results.results.title')}</Title>
        <Subtitle>{t('print-tests-and-results.results.subtitle')}</Subtitle>
        <Text>
          <Typography.Body2>
            <Trans
              i18nKey={'print-tests-and-results.results.description'}
              values={{ totalQuestions }}
              components={{ br: <br /> }}
            />
          </Typography.Body2>
        </Text>
        <ChartContainer>
          <MockExamsDistributionChart
            data={chartData}
            columnWidth={'90%'}
            minWidth={'100%'}
          />
        </ChartContainer>
      </Content>
    </LandscapePage>
  );
}
