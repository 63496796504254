import { ReactComponent as AppStoreBadge } from '../assets/images/app-store-badge.svg';
import { ReactComponent as BlocksPreferences } from '../assets/images/blocks-preferences.svg';
import { ReactComponent as CleverImg } from '../assets/images/clever.svg';
import { ReactComponent as ElectrifiedCat } from '../assets/images/electrified-cat.svg';
import { ReactComponent as EmptyClassroomShadow } from '../assets/images/empty-classroom-shadow.svg';
import { ReactComponent as EmptyClassroom } from '../assets/images/empty-classroom.svg';
import { ReactComponent as EmptyFamilyPayments } from '../assets/images/empty-family-payments.svg';
import { ReactComponent as EmptyReport } from '../assets/images/empty-report.svg';
import { ReactComponent as EmptySession } from '../assets/images/empty-session.svg';
import { ReactComponent as EmptyTestsAndResults } from '../assets/images/empty-tests-and-results.svg';
import { ReactComponent as GooglePlayBadge } from '../assets/images/google-play-badge.svg';
import { ReactComponent as GoogleImg } from '../assets/images/google.svg';
import { ReactComponent as HappyStudentsWithTeacher } from '../assets/images/happy-students-with-teacher.svg';
import { ReactComponent as IconsFaces } from '../assets/images/icons-faces.svg';
import { ReactComponent as LogoInnovamat } from '../assets/images/login/logo.svg';
import { ReactComponent as LogoInnovamatMobile } from '../assets/images/login/mobile-logo.svg';
import { ReactComponent as WavesBottomBg } from '../assets/images/login/ones_inferior.svg';
import { ReactComponent as WavesTopBg } from '../assets/images/login/ones_superior.svg';
import { ReactComponent as WavesBg } from '../assets/images/login/waves.svg';
import { ReactComponent as MicrosoftImg } from '../assets/images/microsoft.svg';
import { ReactComponent as Superman } from '../assets/images/superman.svg';

import RegisterBg from '../assets/images/login/registration-form-bg.png';
import UEFlag from '../assets/images/login/ue-flag.png';
import MaintenanceImg from '../assets/images/maintenance.png';
import ClassesEmptyStateImage from '../assets/images/reports-error-image.png';

import C1_COUNSELOR_IMG from '../assets/images/counselors/c1_peter_liljedahl.png';
import C2_COUNSELOR_IMG from '../assets/images/counselors/c2_gerald_liebernman.png';
import { ReactComponent as BTC_1_ASKING_QUESTIONS } from '../assets/images/counselors/icon/btc-1-asking-questions.svg';
import { ReactComponent as BTC_10_WHEN_HOW } from '../assets/images/counselors/icon/btc-10-when-how.svg';
import { ReactComponent as BTC_2_AUTONOMY } from '../assets/images/counselors/icon/btc-2-autonomy.svg';
import { ReactComponent as BTC_3_CONSOLIDATE } from '../assets/images/counselors/icon/btc-3-consolidate.svg';
import { ReactComponent as BTC_4_DEFRONTING } from '../assets/images/counselors/icon/btc-4-defronting.svg';
import { ReactComponent as BTC_5_ENGAGING_THINKING_TASK } from '../assets/images/counselors/icon/btc-5-engaging-thinking-task.svg';
import { ReactComponent as BTC_6_HINTS_EXTENSIONS } from '../assets/images/counselors/icon/btc-6-hints-extensions.svg';
import { ReactComponent as BTC_7_TAKE_NOTES } from '../assets/images/counselors/icon/btc-7-take-notes.svg';
import { ReactComponent as BTC_8_VISIBLY_RANDOM_GROUPS } from '../assets/images/counselors/icon/btc-8-visibly-random-groups.svg';
import { ReactComponent as BTC_9_VPNS } from '../assets/images/counselors/icon/btc-9-vpns.svg';

export * from './lib';

export {
  AppStoreBadge,
  BlocksPreferences,
  BTC_10_WHEN_HOW,
  BTC_1_ASKING_QUESTIONS,
  BTC_2_AUTONOMY,
  BTC_3_CONSOLIDATE,
  BTC_4_DEFRONTING,
  BTC_5_ENGAGING_THINKING_TASK,
  BTC_6_HINTS_EXTENSIONS,
  BTC_7_TAKE_NOTES,
  BTC_8_VISIBLY_RANDOM_GROUPS,
  BTC_9_VPNS,
  C1_COUNSELOR_IMG,
  C2_COUNSELOR_IMG,
  ClassesEmptyStateImage,
  CleverImg,
  ElectrifiedCat,
  EmptyClassroom,
  EmptyClassroomShadow,
  EmptyFamilyPayments,
  EmptyReport,
  EmptySession,
  EmptyTestsAndResults,
  GoogleImg,
  GooglePlayBadge,
  HappyStudentsWithTeacher,
  IconsFaces,
  LogoInnovamat,
  LogoInnovamatMobile,
  MaintenanceImg,
  MicrosoftImg,
  RegisterBg,
  Superman,
  UEFlag,
  WavesBg,
  WavesBottomBg,
  WavesTopBg,
};
