import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { refreshResetPasswordToken } from './api';
import type { RefreshResetPasswordToken } from './reset-password';

export function useRefreshResetPasswordToken() {
  const { apiUsers } = useRegionalConfigVariables();
  const mutation = useMutation<void, AxiosError, RefreshResetPasswordToken>({
    mutationFn: async ({ requestId, token }) => {
      await refreshResetPasswordToken({ requestId, token }, apiUsers);
    },
  });

  return { ...mutation, refreshResetPasswordToken: mutation.mutate };
}
