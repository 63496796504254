import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '../icon';
import { IconButton } from '../icon-button';
import { Typography } from '../typography';
import { IconType } from '@innovamat/glimmer-icons';
import { Tooltip } from '../tooltip';

const Wrapper = styled.div<{
  isDisabled?: boolean;
  isOpenable?: boolean;
  hasTooltip?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 4px;

    ${({ isOpenable }) =>
      isOpenable &&
      css`
        &:hover {
          div {
            cursor: pointer;
          }
        }
      `}
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `};
`;

const Text = styled(Typography.Body2)<{
  isOpenable?: boolean;
  hasTooltip?: boolean;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > div {
    display: inline;

    * {
      display: inline;
    }
  }

  > span {
    display: inline;

    * {
      display: inline;
    }
  }

  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  ${({ theme, isOpenable }) =>
    css`
      cursor: ${isOpenable ? 'pointer' : 'default'};
      text-decoration: ${isOpenable ? 'underline' : ''};

      :hover {
        color: ${isOpenable ? theme.tokens.color.alias.cm.text.text.value : ''};
      }
    `}

  a {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
    color: inherit;
  }
`;

const TextWrapper = styled.div<{ isDownloadable?: boolean }>`
  width: 100%;
  overflow: hidden;

  > span {
    overflow: hidden;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

type ResourceElementProps = {
  children: React.ReactNode;
  icon?: IconType;
  isDownloadable?: boolean;
  isOpenable?: boolean;
  isDisabled?: boolean;
  tooltip?: { img?: string; text?: string };
  showOpenButton?: boolean;
  showGoogleDriveButton?: boolean;
  onDownload?: () => void;
  onOpen?: () => void;
  onSaveGoogleDrive?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export function ResourceElement({
  children,
  icon,
  isDownloadable,
  isOpenable,
  onDownload,
  onOpen,
  onSaveGoogleDrive,
  isDisabled,
  tooltip,
  showOpenButton,
  showGoogleDriveButton,
  ...rest
}: ResourceElementProps): JSX.Element {
  return (
    <Wrapper
      isDisabled={isDisabled}
      isOpenable={isOpenable}
      hasTooltip={!!tooltip}
      {...rest}
    >
      <TextWrapper isDownloadable={isDownloadable}>
        {icon && <Icon size="M" icon={icon} />}
        <Tooltip placement="left" content={tooltip?.text}>
          <Text onClick={onOpen} hasTooltip={!!tooltip} isOpenable={isOpenable}>
            {children}
          </Text>
        </Tooltip>
      </TextWrapper>
      <IconsWrapper>
        {showOpenButton && (
          <IconButton
            onClick={onOpen}
            size="S"
            icon="OpenNewTabIcon"
            state={isDisabled ? 'disabled' : undefined}
          />
        )}

        {showGoogleDriveButton && (
          <IconButton
            onClick={onSaveGoogleDrive}
            size="S"
            icon="GoogleDriveIcon"
            state={isDisabled ? 'disabled' : undefined}
          />
        )}

        {isDownloadable && (
          <IconButton
            onClick={onDownload}
            size="S"
            icon="DownloadIcon"
            state={isDisabled ? 'disabled' : undefined}
          />
        )}
      </IconsWrapper>
    </Wrapper>
  );
}
