import styled from '@emotion/styled';
import {
  AnnouncementPanel,
  Checkbox,
  Modal,
  SEMANTIC_TYPE,
  Typography,
} from '@innovamat/glimmer-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SubtleText = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type MoveRTIStudentConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  studentName: string;
  onConfirm: () => void;
};

export function MoveRTIStudentConfirmationModal({
  isOpen,
  onClose,
  studentName,
  onConfirm,
}: MoveRTIStudentConfirmationModalProps): JSX.Element {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  return (
    <Modal
      zIndex={40}
      modalWidth={600}
      isOpen={isOpen}
      onClose={onClose}
      title={t('move-rti-student.modal.title')}
      semantic={{ text: t('semantic.warning'), type: SEMANTIC_TYPE.WARNING }}
      buttons={[
        {
          children: t('common.cancel'),
          onClick: onClose,
        },
        {
          children: t('common.move'),
          onClick: onConfirm,
          variant: 'secondary',
          loading: false,
          disabled: !checked,
        },
      ]}
    >
      <ModalContent>
        <AnnouncementPanel
          type="warning"
          text={t('move-rti-student.modal.disclaimer')}
          canClose={false}
        />
        <Typography.Body1>{t('move-rti-student.modal.body')}</Typography.Body1>
        <Flex>
          <Checkbox checked={checked} onCheck={(value) => setChecked(value)} />
          <SubtleText>
            {t('move-rti-student.modal.check', {
              name: studentName,
            })}
          </SubtleText>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
