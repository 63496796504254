import styled from '@emotion/styled';
import {
  ClassCardHeader,
  Drawer,
  IconButton,
  Typography,
  snack,
} from '@innovamat/glimmer-components';
import type { Classroom, Teacher } from '@innovamat/glow-api-client';
import { useOrganizationQuery } from '@innovamat/glow-api-client';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvatarImage } from '../../../../../../utils';
import { useUser } from '../../../../../user-management';
import { ConfirmationModal } from '../../../../components/confirmation-modal';
import { useConfirmationModal } from '../../../../hooks/use-confirmation-modal';
import { useUnlinkTeacher } from '../../../../hooks/use-unlink-teacher';
import { AssignClassroomToTeacherButton } from '../assign-classroom-to-teacher-button';

const DrawerContent = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Subtitle = styled(Typography.Body3)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const NumberOfClassroom = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const ClassroomsContainer = styled.div`
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 348px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  > div {
    display: flex;
    flex-direction: column;
  }
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

type Props = {
  showDrawer: boolean;
  onClose: () => void;
  teacher: Teacher | undefined;
};

function AssignClassroomsToTeacherDrawer({
  showDrawer,
  onClose,
  teacher,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { user } = useUser();
  const { data: organizationQuery } = useOrganizationQuery(
    { id: user?.organizationId! },
    { staleTime: Infinity, enabled: !!user?.organizationId }
  );

  const [displayClassrooms, setDisplayClassrooms] = useState<Classroom[]>([]);

  const queryClient = useQueryClient();

  const {
    requestConfirmation,
    isConfirmationOpen,
    confirmationModalProps,
    handleCloseConfirmationModal,
  } = useConfirmationModal();

  const { isPending: isTeacherUnlinking, handleUnlinkTeacher } =
    useUnlinkTeacher({
      isPlatformEnabled: !!organizationQuery?.organization?.platformEnabled,
      organizationId: organizationQuery?.organization?.id!,
      onSuccess: ({ classroomId, teacherId }) => {
        const isUnLinkingMyself = teacherId === user?.id;
        snack.success(
          t(
            isUnLinkingMyself
              ? 'teachers.unlinkTeacher-myself.toast.success'
              : 'teachers.unlinkTeacher.toast.success'
          )
        );

        setDisplayClassrooms((prev) =>
          prev.filter((classroom) => classroom.id !== classroomId)
        );

        queryClient.refetchQueries({ queryKey: ['Teachers'] });
        handleCloseConfirmationModal();
      },
    });

  useEffect(() => {
    const classrooms = (teacher?.classrooms || []) as Classroom[];
    if (classrooms) {
      setDisplayClassrooms(classrooms);
    }
  }, [teacher?.classrooms]);

  const confirmModalData = {
    isOpen: isConfirmationOpen,
    isPending: isTeacherUnlinking,
    onClose: handleCloseConfirmationModal,
    ...confirmationModalProps,
  };

  const numberOfClassrooms = displayClassrooms?.length || 0;

  const handleClassroomLeave = (classroom: Classroom): void => {
    requestConfirmation({
      title: t('teachers.unlinkTeacher'),
      message: t('teachers.unlinkTeacher.confirm', {
        name: teacher?.firstName,
        classroom: classroom?.name,
      }),
      onConfirm: () => {
        handleUnlinkTeacher({ classroomId: classroom.id, teacher: teacher! });
      },
      confirmText: t('teachers.unlinktTeacher.confirmUnlink'),
    });
  };

  const handleAddSuccess = (classroom: Classroom): void => {
    setDisplayClassrooms((prev) => [...prev, classroom]);
    queryClient.refetchQueries({ queryKey: ['Teachers'] });
    snack.success(t('manager.teacher.drawer.add-classroom-success'));
  };

  return (
    <Drawer open={showDrawer} customStyles={{ width: '470px' }} roundedBorder>
      <DrawerContent>
        <div>
          <IconButton
            icon="CloseIcon"
            onClick={onClose}
            dataTestId="close-button"
          />
        </div>
        <Header>
          <div>
            <Typography.H4>
              {teacher?.firstName} {teacher?.lastName}
            </Typography.H4>
            <Subtitle>{teacher?.email}</Subtitle>
          </div>
          <NumberOfClassroom>
            {t('manager.teacher.drawer.numOfClassrooms', {
              num: numberOfClassrooms,
            })}
          </NumberOfClassroom>
        </Header>
        <ClassroomsContainer>
          {numberOfClassrooms > 0 ? (
            displayClassrooms.map((classroom) => (
              <ClassCardHeader
                name={classroom.name ?? ''}
                avatar={getAvatarImage({
                  avatar: classroom.avatar,
                  icon: classroom.icon,
                  courseOrder: classroom.courseOrder!,
                })}
                course={classroom.courseName ?? ''}
                rightSide={
                  <IconButton
                    icon="LeaveClassIcon"
                    onClick={() => handleClassroomLeave(classroom)}
                    dataTestId="leave-classroom-button"
                  />
                }
              />
            ))
          ) : (
            <Typography.Body1>
              {t('manager.teacher.drawer.no-classrooms')}
            </Typography.Body1>
          )}
        </ClassroomsContainer>

        <AssignClassroomToTeacherButton
          displayClassrooms={displayClassrooms}
          teacher={teacher}
          onSuccess={handleAddSuccess}
        />
      </DrawerContent>
      <ConfirmationModal {...confirmModalData} />
    </Drawer>
  );
}

export { AssignClassroomsToTeacherDrawer };
