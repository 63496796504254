import { useTranslation } from 'react-i18next';

import { type Column } from '@innovamat/glimmer-components';
import {
  AnswersCol,
  AnswersContainer,
} from '../../../../../components/answers/answers';
import { MockExamsStatementRow } from '../../../../test-and-results/components/mock-exams-drawer/components/mock-exams-drawer-statements-table';

const usePrintMockExamsStatementsConfig = (
  multipleChoiceLabels: string[]
): Column<MockExamsStatementRow>[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'position',
      label: t('mock-exams-drawer.table.statements.label'),
      width: 80,
      minWidth: 80,
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
    },
    {
      id: 'correctlyAnswered',
      label: t('mock-exams-drawer.table.correct-percentage.label'),
      width: 100,
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
    },
    {
      id: 'answers',
      subLabel: () => {
        return (
          <AnswersContainer isSmall>
            {multipleChoiceLabels.map((label, index) => {
              return (
                <AnswersCol key={'answer-label-' + index}>{label}</AnswersCol>
              );
            })}
          </AnswersContainer>
        );
      },
      label: t('mock-exams-drawer.table.answers.label'),
      minWidth: 80,
      width: '100%',
    },
    {
      id: 'separator-3',
      width: 9,
      separator: true,
      stickyPosition: 'right',
    },
    {
      id: 'notAnswered',
      label: t('mock-exams-drawer.table.no-answered-percentage.label'),
      width: 100,
      minWidth: 100,
      align: 'center',
    },
  ];
};

export { usePrintMockExamsStatementsConfig };
