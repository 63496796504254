import { useUser } from '@innovamat/ga-features';
import React from 'react';
import { Navigate } from 'react-router-domv6';

type Props = {
  children: React.ReactNode;
};

function ProtectedRoute({ children }: Props) {
  const { isLoggedIn } = useUser();

  if (!isLoggedIn) {
    const { pathname, search } = window.location;
    const urlBase = encodeURIComponent(`${pathname}${search}`);
    const url = pathname !== '/' ? `/?referral=${urlBase}` : `/`;

    return <Navigate to={url} replace />;
  }

  return children;
}

export { ProtectedRoute };
