import styled from '@emotion/styled';
import { Icon } from '../icon';
import { IconType } from '@innovamat/glimmer-icons';
import { Typography } from '../typography';

const Container = styled.div<{ hasProgressBar: boolean }>`
  position: absolute;
  bottom: ${({ hasProgressBar }) => (hasProgressBar ? '4px' : '0')};
  left: 0;
  padding: 8px;
  padding-right: 12px;
  z-index: 1;
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.element['thumbnail-tag'].value};
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 34px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg path {
    fill: ${({ theme }) =>
      theme.tokens.color.alias.cm.icon['icon-inverted'].value};
  }
`;

const Text = styled(Typography.Subtitle2)`
  display: inline;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.icon['icon-inverted'].value};
`;

type Props = {
  text: string;
  icon: IconType;
  hasProgressBar?: boolean;
};

export function ResourceCardStatusLabel({
  text,
  icon,
  hasProgressBar = false,
}: Props): JSX.Element {
  return (
    <Container hasProgressBar={hasProgressBar}>
      <LabelWrapper>
        <Icon icon={icon} size="S" />
        <Text>{text}</Text>
      </LabelWrapper>
    </Container>
  );
}
