import { useTranslation } from 'react-i18next';
import { ProgressCounter } from '../../../../../../../reports';
import {
  Participation,
  Status,
  TEST_STATUS,
} from '../../../../../../modules/test/test';

type Props = {
  participation: Participation | null | undefined;
  status: Status;
};

function TestParticipationCell({ participation, status }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <div>
      <ProgressCounter
        completed={participation?.completed || 0}
        total={participation?.total || 0}
        text={t('testsAndResultsView.table.total-participation')}
        rowIsNull={
          status !== TEST_STATUS.STARTED && status !== TEST_STATUS.COMPLETED
        }
      />
    </div>
  );
}

export { TestParticipationCell };
