import { useSmartGuides } from '../../../context/SmartGuidesProvider';
import type { MediaType } from '../../../types/Media';
import type { TableContentType } from '../../../types/Table';
import { PreprInnerHtml } from '../../PreprInnerHtml';
import { BaseTip, Text } from '../base-tip/base-tip';

type BaseTipProps = {
  children: string;
  media?: MediaType[] | null;
  isPrintable?: boolean;
  tables?: TableContentType[];
};

type TypedTipProps = BaseTipProps & {
  type: 'ampliation' | 'support';
  summary?: undefined;
};

type NonTypedTipProps = BaseTipProps & {
  type?: undefined;
  summary: string;
};

type Props = TypedTipProps | NonTypedTipProps;

const summaryMapper: Record<TypedTipProps['type'], string> = {
  ampliation: 'digitalguides.ampliation',
  support: 'digitalguides.support',
};

function Tip({
  children,
  isPrintable,
  media,
  summary,
  tables,
  type,
}: Props): JSX.Element {
  const { t } = useSmartGuides();

  return (
    <BaseTip
      summary={type ? t(summaryMapper[type]) : summary}
      isPrintable={isPrintable}
      media={media}
      tables={tables}
    >
      <Text>
        <PreprInnerHtml text={children} />
      </Text>
    </BaseTip>
  );
}

export { Tip };
