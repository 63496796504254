// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P7ntMUw0BUny9tyI{width:fit-content;height:fit-content;cursor:pointer;padding:3px;color:#757575;gap:8px;align-items:center;display:flex;border-radius:16px;border:1px solid #e5e5e5;background-color:#fff}.P7ntMUw0BUny9tyI:hover{background-color:#f5f5f5}.P7ntMUw0BUny9tyI svg rect{fill:#757575}.u4WMbSnyGxcvXTzQ{border:none !important;padding:0 !important}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/Chip/Chip.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,iBAAA,CACA,kBAAA,CACA,cAAA,CACA,WAAA,CACA,aCDO,CDEP,OAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,qBCFM,CDIN,wBACE,wBCNK,CDUL,2BACE,YCfG,CDmBT,kBACE,sBAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chip": "P7ntMUw0BUny9tyI",
	"avatar": "u4WMbSnyGxcvXTzQ"
};
export default ___CSS_LOADER_EXPORT___;
