import type { MediaType, MediaWidth } from '../types/Media';

function extractId(url: string) {
  const regex = /https:\/\/stream\.mux\.com\/([a-zA-Z0-9]+)/;
  const match = url.match(regex);

  return match ? match[1] : null;
}

const getWidth = (width: string): MediaWidth => {
  if (!width) return 50;
  return +width as MediaWidth;
};

const isValidLocale = (itemLocale: string, userLocale: string): boolean => {
  const latamLocales = ['es-CO', 'es-EC', 'es-MX', 'es-CL'];

  if (itemLocale && userLocale === itemLocale) {
    return true;
  }

  if (latamLocales.includes(userLocale) && itemLocale === 'es-LA') {
    return true;
  }

  if (!itemLocale) {
    return true;
  }

  return false;
};

const checkLocalization = (
  item: { [key: string]: any },
  regionParameters: any,
  locale: string
): boolean => {
  const mappedValues = {
    comma: '\u002C',
    point: '\u002E',
    space: '\u202F',
    comma_audio: 'S4665',
    point_audio: 'S4665',
    cross: '\u00D7',
    dot: '\u00B7',
    colon: '\u003A',
    slash: '\u2215',
    division_sign: '\u00F7',
  };

  const localizationFields = [
    'clock',
    'currency',
    'decimalSeparator',
    'metricSystem',
    'numberWriting',
    'shortDivisionSign',
    'temperature',
    'verticalMultiplicationPosition',
    'verticalDivisionAlgorithm',
    'thousandSeparator',
  ];

  if (!isValidLocale(item.locale, locale)) {
    return false;
  }

  for (const field of localizationFields) {
    const lowerCaseField = field.toLowerCase();
    const itemValue = item[lowerCaseField];

    const itemFieldMapped =
      mappedValues[itemValue as keyof typeof mappedValues] || itemValue;
    const regionValue = regionParameters[field];

    if (itemFieldMapped && itemFieldMapped !== regionValue) {
      return false;
    }
  }
  return true;
};

export function mapMedia(
  data: any,
  width: string,
  regionParameters: any,
  locale: string
): MediaType[] {
  return data
    ?.filter((item: any) => checkLocalization(item, regionParameters, locale))
    .map((item: any) => {
      const media: MediaType = {
        id: item._id,
        type: item._type,
        width: getWidth(width),
        name: item.name,
        url: item.url,
        playbackId: extractId(item.url),
      };
      return media;
    });
}
