import styled from '@emotion/styled';

import { PrintableBody1 } from '../../printable-styles/printable-typography';
import type { StepType } from '../../types/SmartGuide';
import { Grouping } from '../Grouping';
import { NumberedStep } from '../NumberedStep';
import { PageBreak } from '../page-break';
import { PartDivider } from '../PartDivider';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { SignedTip } from '../tips/signed-tip';

type Props = {
  step: StepType;
  index: number;
  partNumber: number;
  isPrintable?: boolean;
};

const TextWrapper = styled(PrintableBody1)`
  @media print {
    color: #000000;
  }
`;

export const RenderStep = ({ step, index, partNumber, isPrintable }: Props) => {
  const render = () => {
    switch (step.__typename) {
      case 'Text':
        if (!step.text) return null;
        return typeof step.text === 'string' ? (
          <TextWrapper>
            <PreprInnerHtml key={index} text={step.text} />
          </TextWrapper>
        ) : (
          <TextWrapper key={index}>{step.text}</TextWrapper>
        );
      case 'Agrupacin':
        return <Grouping key={index} grouping={step.text} part={partNumber} />;
      case 'PasoNumerado':
        return (
          <NumberedStep
            evaluable={step.evaluable}
            key={index}
            num={step.stepNumber}
            part={partNumber}
            isPrintable={isPrintable}
            content={step.content}
          />
        );

      case 'Subactividad':
        return <PartDivider part={partNumber} text={step.text} />;
      case 'Signedtip':
        return (
          <SignedTip
            key={`${step.__typename}-${index}`}
            type={step.tip_type}
            counselor={step.counselor}
            icon={step.icon}
            isPrintable={isPrintable}
            media={step.media}
            tables={step.tables}
          >
            {step.text}
          </SignedTip>
        );
      case 'PageBreak':
        return <PageBreak />;
      default:
        return null;
    }
  };

  return <div id={`${step.__typename}-${index}`}>{render()}</div>;
};
