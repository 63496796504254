import styled from '@emotion/styled';
import { EmptyClassroom } from '@innovamat/glimmer-assets';
import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../../user-management';
import { AddClassroomDropdown } from '../../../components/add-classroom-dropdown';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Text = styled.div`
  text-align: center;
`;

const SubtleText = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export function SchoolClassroomsEmptyState() {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <Container>
      <EmptyClassroom />
      <Text>
        <Typography.H4>
          {t(
            'select-classrooms.select-classroom.empty-state.title',
            'Este centro aún no tiene clases creadas'
          )}
        </Typography.H4>
        <SubtleText>
          {t(
            'select-classrooms.select-classroom.empty-state.description',
            'Crea una nueva clase usando el botón de "añadir clase"'
          )}
        </SubtleText>
      </Text>
      <AddClassroomDropdown
        disabled={!user}
        organizationId={user?.organizationId!}
        region={user?.region!}
        variant="accent"
        options={['add', 'import']} // TODO: add csv import
      />
    </Container>
  );
}
