import { useCallback, useState } from 'react';

export type BreakPageMode = 'after' | undefined;

interface UseBreakPageModeProps {
  isEditMode: boolean;
  initialMode?: BreakPageMode;
}

interface UseBreakPageModeReturn {
  breakPageMode: BreakPageMode;
  handleBreakPageClick: () => void;
  setBreakPageMode: (mode: BreakPageMode) => void;
}

export function useBreakPageMode({
  isEditMode,
  initialMode,
}: UseBreakPageModeProps): UseBreakPageModeReturn {
  const [breakPageMode, setBreakPageMode] =
    useState<BreakPageMode>(initialMode);

  const handleBreakPageClick = useCallback(() => {
    if (!isEditMode) return;

    setBreakPageMode((current) => (current === 'after' ? undefined : 'after'));
  }, [isEditMode]);

  return { breakPageMode, handleBreakPageClick, setBreakPageMode };
}
