import styled from '@emotion/styled';

import { Typography } from '@innovamat/glimmer-components';

import { useRegionalNumber } from '@innovamat/ga-features';
import { EMPTY_CELL_VALUE } from '../../utils';

type StatementCellProps = {
  answer?: string;
  isCorrect?: boolean;
  isPercentage?: boolean;
};

const StatementCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled(Typography.Subtitle2)<{ hasNoAnswer: boolean }>`
  color: ${({ hasNoAnswer, theme }) =>
    hasNoAnswer
      ? theme.tokens.color.specific.reports.semantic['no-data'].value
      : 'inherit'};
`;

const BottomLine = styled.div<{ hasNoAnswer: boolean; isCorrect?: boolean }>`
  margin-top: 2px;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: ${({ hasNoAnswer, isCorrect, theme }) =>
    hasNoAnswer
      ? theme.tokens.color.specific.reports['progress-bar'].empty.value
      : theme.tokens.color.specific.reports.semantic[isCorrect ? 'high' : 'low']
          .value};
`;

export function StatementCell({
  answer,
  isCorrect,
  isPercentage,
}: StatementCellProps): JSX.Element {
  const hasNoAnswer = !answer;
  const parsedNumber = !hasNoAnswer ? parseFloat(answer) : null;
  const regionalNumber = useRegionalNumber({
    number: !isNaN(parsedNumber as number) ? parsedNumber : null,
    fixedTo: 2,
  });

  const displayValue = !hasNoAnswer
    ? `${
        !isNaN(parsedNumber as number)
          ? regionalNumber?.toLowerCase()
          : answer.toLowerCase()
      }${isPercentage ? '%' : ''}`
    : EMPTY_CELL_VALUE;

  return (
    <StatementCellContainer>
      <StyledText hasNoAnswer={hasNoAnswer}>{displayValue}</StyledText>
      <BottomLine hasNoAnswer={hasNoAnswer} isCorrect={isCorrect} />
    </StatementCellContainer>
  );
}
