import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { completeInvitation } from './api';
import type { InvitationType, UserInvitation } from './invitation';

type Props = {
  type: InvitationType;
};

export function useCompleteInvitation({ type }: Props) {
  const { apiUsers } = useRegionalConfigVariables();
  return useMutation<void, AxiosError, UserInvitation>({
    mutationFn: (invitationBody: UserInvitation) => {
      return completeInvitation(invitationBody, type, apiUsers);
    },
  });
}
