import styled from '@emotion/styled';
import { useFlag } from '@innovamat/flags';
import { Modal, Select, Typography } from '@innovamat/glimmer-components';
import type { Classroom } from '@innovamat/glow-api-client';
import { useTranslation } from 'react-i18next';
import useModifyDigitalPracticeModal from './use-modify-digital-practice-modal';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Content = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;

  > div {
    flex: 1;
  }
`;

type Props = {
  classroom: Classroom | undefined;
  isOpen: boolean;
  onClose: () => void;
  onConfigurationChange?: () => void;
};

const ModifyDigitalPracticeModal: React.FC<Props> = ({
  classroom,
  isOpen,
  onClose,
  onConfigurationChange,
}) => {
  const { t } = useTranslation();

  const { value: showHourDropdownPractice } = useFlag(
    'showHourDropdownPractice',
    false,
    { identifier: '', custom: { classroomId: classroom?.id || '' } }
  );

  const {
    configuredDate,
    daysOfWeek,
    hours,
    isPending,
    handleOnChangeSelect,
    handleOnClose,
    handleOnSave,
  } = useModifyDigitalPracticeModal({
    classroom,
    onClose,
    onConfigurationChange,
  });

  return (
    <Modal
      title={t('digitalPractice.modifyDigitalPracticeModal.title')}
      buttons={[
        {
          children: t('common.save'),
          disabled: false,
          loading: isPending,
          variant: 'accent',
          onClick: handleOnSave,
        },
        {
          children: t('common.cancel'),
          variant: 'tertiary',
          onClick: handleOnClose,
        },
      ]}
      isOpen={isOpen}
      modalWidth={700}
      semantic={{
        type: 'warning',
        text: t('common.modal.important'),
      }}
      closeButton="inner"
      onClose={handleOnClose}
    >
      <ModalContent>
        <Typography.Body1>
          {t('digitalPractice.modifyDigitalPracticeModal.description')}
        </Typography.Body1>
        <Content>
          <Select
            value={configuredDate.digitalPracticeFirstDayOfWeek}
            labelText={t(
              'digitalPractice.modifyDigitalPracticeModal.firstDayOfWeek'
            )}
            options={daysOfWeek}
            menuPortalTarget={document.body}
            onChange={(day) =>
              handleOnChangeSelect(day?.value, 'digitalPracticeFirstDayOfWeek')
            }
          />
          {showHourDropdownPractice && (
            <Select
              value={configuredDate.digitalPracticeFirstHourOfWeek}
              labelText={t(
                'digitalPractice.modifyDigitalPracticeModal.firstHourOfWeek'
              )}
              options={hours}
              menuPortalTarget={document.body}
              onChange={(hour) =>
                handleOnChangeSelect(
                  hour?.value,
                  'digitalPracticeFirstHourOfWeek'
                )
              }
            />
          )}
        </Content>
      </ModalContent>
    </Modal>
  );
};

export default ModifyDigitalPracticeModal;
