import styled from '@emotion/styled';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { AssessableSummaryPrintable } from '../assessables/assessables-summary';
import { BigIdeasTags } from '../big-ideas-tags';
import { DimensionTags } from '../DimensionTags';
import { Materials } from '../Materials';
import { MathPractices } from '../math-practices';
import { NewStandardContent } from '../new-standard-content';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { SimpleBlock } from '../SimpleBlock';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

export function SummarySectionPrintable(): JSX.Element {
  const {
    t,
    smartGuide: { summary },
    stage,
  } = useSmartGuides();

  return (
    <Section>
      {summary.inThisTaller.text && (
        <SimpleBlock title={t(summary.inThisTaller.titleKey)}>
          <PreprInnerHtml text={summary.inThisTaller.text} />
        </SimpleBlock>
      )}
      {summary.withIntentionOf.text && (
        <SimpleBlock title={t(summary.withIntentionOf.titleKey)}>
          <PreprInnerHtml text={summary.withIntentionOf.text} />
        </SimpleBlock>
      )}
      {summary.vocabularyKey.text && (
        <SimpleBlock title={t(summary.vocabularyKey.titleKey)}>
          <PreprInnerHtml text={summary.vocabularyKey.text} />
        </SimpleBlock>
      )}
      {summary.assessables.length > 0 && <AssessableSummaryPrintable />}

      <BigIdeasTags tags={summary.bigIdeas} />
      <NewStandardContent summary={summary} stage={stage} />
      <MathPractices tags={summary.newMathPractices} />
      <DimensionTags tags={summary.dimensionTags} />

      <Materials {...summary.materials} />
    </Section>
  );
}
