import { usePreferencesQuery } from '@innovamat/glow-api-client';
import { useOrganization } from '../../classrooms-management';
import { useUser } from '../../user-management';

type Standard = {
  standard: string;
};

function useStandard(): Standard {
  const { isRole } = useUser();
  const { organization } = useOrganization();

  const { data } = usePreferencesQuery(
    {},
    {
      enabled: isRole.Lead,
      staleTime: Infinity,
    }
  );

  return {
    standard: isRole.Lead
      ? data?.preferences?.standardStructure
      : organization?.standard,
  };
}

export { useStandard };
