import { useRef, useState } from 'react';
import type { OptionType } from '@innovamat/glimmer-components';
import type { ModeLegend } from '../../../components/legend-fluency/legend-fluency';
import type {
  Card,
  FluencyMultiplicationReportQuery,
  MultiplicationTable,
} from '@innovamat/glow-api-client';
import { CardType } from '@innovamat/glow-api-client';
import type { FluencyStatusTooltipProp } from '../fluency-dashboard';
import { useEventLogging } from '@innovamat/event-logging';

type Student = {
  firstName: string;
  lastName: string;
  uuid: string;
  cards: Card[];
};

type FluencyDashboardReturn = {
  mode: ModeLegend;
  selectedStudent: string;
  handleChange: (option: OptionType | null) => void;
  students: Student[];
  multiplicationTableTitle?: string;
  operator: string;
  tableFluencyData: MultiplicationTable[] | undefined;
  allCellsLocked: boolean | undefined;
  showFluencyStatusTooltip: FluencyStatusTooltipProp;
  tableUpdateLoading: boolean;
};

const FAKE_LOADING_TIME = 500;

export const useFluencyDashboard = (
  fluencyDashboardReport: FluencyMultiplicationReportQuery | undefined
): FluencyDashboardReturn => {
  const fluencyDashboardData =
    fluencyDashboardReport?.fluencyMultiplicationReport;

  const multiplicationTableTitle =
    fluencyDashboardData?.titleTranslationKey ?? undefined;
  const operator = fluencyDashboardData?.operator || '';

  const students = fluencyDashboardData?.students as Student[];

  const [mode, setMode] = useState<ModeLegend>('allClassroomMode');
  const [selectedStudent, setSelectedStudent] = useState<string>('');
  const [tableUpdateLoading, setTableUpdateLoading] = useState(false);

  const { setEventData } = useEventLogging();
  const previousReportRef = useRef(fluencyDashboardReport);

  if (fluencyDashboardReport !== previousReportRef.current) {
    previousReportRef.current = fluencyDashboardReport;
    setSelectedStudent('');
    setMode('allClassroomMode');
  }

  const tableFluencyData = fluencyDashboardData?.multiplicationTables?.map(
    (table) => ({
      ...table,
      cards: table.cards.map((card) => {
        if (mode === 'studentMode' && selectedStudent) {
          const selectedStudentData = students?.find(
            (student) => student.uuid === selectedStudent
          );
          const cardStatus: CardType =
            selectedStudentData?.cards.find(
              (studentCard) => studentCard.uuid === card.uuid
            )?.status || CardType.Locked;
          return {
            ...card,
            status: cardStatus || CardType.Locked,
            studentsFailed: [],
          };
        }
        return {
          ...card,
        };
      }),
    })
  );

  const startTableLoading = (): void => {
    setTableUpdateLoading(true);
    setTimeout(() => {
      setTableUpdateLoading(false);
    }, FAKE_LOADING_TIME);
  };

  const handleChange = (option: OptionType | null): void => {
    startTableLoading();
    if (option?.value === '') {
      setMode('allClassroomMode');
      setSelectedStudent('');
    } else if (option) {
      setMode('studentMode');
      setSelectedStudent(option.value);
      setEventData('report_fluency_select_student', {
        selected_student_user_uuid: option.value,
      });
    }
  };

  const allCellsLocked = tableFluencyData?.every(
    (column: MultiplicationTable) =>
      column.cards.every((card: Card) => card.status === CardType.Locked)
  );
  const allCellsNotSeen = tableFluencyData?.every(
    (column: MultiplicationTable) =>
      column.cards.every((card: Card) => card.status === CardType.NotSeen)
  );

  const allCellsNotSeenAndLocked = tableFluencyData?.every(
    (column: MultiplicationTable) => {
      return column.cards.every(
        (card: Card) =>
          card.status === CardType.Locked || card.status === CardType.NotSeen
      );
    }
  );

  const showFluencyStatusTooltip: FluencyStatusTooltipProp = {
    allGroupsLocked: allCellsLocked,
    noStudentsConnected: allCellsNotSeen || allCellsNotSeenAndLocked,
  };

  return {
    mode,
    selectedStudent,
    handleChange,
    students,
    multiplicationTableTitle,
    operator,
    tableFluencyData,
    showFluencyStatusTooltip,
    allCellsLocked,
    tableUpdateLoading,
  };
};
