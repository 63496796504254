import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { linkSchool } from './api';
import type { LinkSchool } from './link-school';

export function useLinkSchool() {
  const { apiUsers } = useRegionalConfigVariables();
  const mutation = useMutation<void, AxiosError, LinkSchool>({
    mutationFn: async ({ requestId, token }) => {
      await linkSchool({ requestId, token }, apiUsers);
    },
  });

  return { ...mutation, linkSchool: mutation.mutate };
}
