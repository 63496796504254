import { getAcceptLanguage } from '@innovamat/localization';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { PageQuery } from '../../generated/types';
import { PageDocument } from '../../generated/types';
import { useGlowApiClient } from '../../graphql-provider';
import { useCompletedResources } from '../use-completed-resources';
import { mapPageResponse } from './mapper';
import type { Page, PageData } from './types';

type Props = {
  pageId: string;
  academicYearId: string;
  classroomId?: string;
  isStudentView?: boolean;
  paginated?: boolean;
  region: string;
  language: string;
};

type Return = {
  isLoading: boolean;
  isError: boolean;
  data: Page | undefined;
};

export function usePage({
  pageId,
  academicYearId,
  classroomId,
  isStudentView,
  paginated = false,
  region,
  language,
}: Props): Return {
  const completedResources = useCompletedResources({
    classroomId,
    academicYearId,
  });

  const variables = {
    id: pageId!,
    classroomId: classroomId || null,
    paginated,
  };
  const { graphqlClient, getAuthToken } = useGlowApiClient();

  const { data, isLoading, isError } = useQuery<PageQuery>({
    queryKey: ['Page', variables, region, language],
    queryFn: () => {
      const headers = {
        'accept-language': getAcceptLanguage(region, language) ?? '*',
        authorization: getAuthToken() ?? '',
      };
      return graphqlClient.request(PageDocument, variables, headers);
    },
    enabled: !!pageId && !!region && !!language,
    retry: false,
  });

  const pageData = data?.page as PageData;

  const page = useMemo(() => {
    if (!pageData) return undefined;
    return mapPageResponse(
      pageData,
      completedResources,
      Boolean(isStudentView)
    );
  }, [completedResources, isStudentView, pageData]);

  return {
    isLoading,
    isError,
    data: page,
  };
}
