import { useEventLogging } from '@innovamat/event-logging';
import { ResourceCard, Slider } from '@innovamat/glimmer-components';
import { usePaginatedList } from '@innovamat/glow-api-client';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { MarkSessionMethodParams } from '../../../session-done';
import { useUnblockedAppletsDrawerContext } from '../../../unblocked-applets-drawer/context/unblocked-applets-drawer-provider';
import { useCurrentNavigationValues } from '../../hooks';
import type { ContentListType, ContentResource } from '../../types/page';
import {
  ResourceSkeleton,
  useResourceSkeletonHeight,
} from './content-list-skeleton';
import { useResponsiveSlide } from './content-list-slider';
import { ListTitle } from './content-list-title';
import { ResourceSlider } from './content-list.styled';

type ContentListProps = {
  isPaginationEnabled?: boolean;
  classroomId: string | undefined;
  academicYearId: string;
  list: ContentListType;
  onResourceClick: (resource: ContentResource) => void;
  onShowMoreClick?: () => void;
  imgOptimizationUrl?: string;
  onMarkSession?: (params: MarkSessionMethodParams) => void;
  onToggleSolutions?: (params: MarkSessionMethodParams) => void;
  isStudentView?: boolean;
};

export function ContentList({
  isPaginationEnabled,
  list,
  classroomId,
  academicYearId,
  onResourceClick,
  onShowMoreClick,
  imgOptimizationUrl,
  onMarkSession,
  onToggleSolutions,
  isStudentView,
}: ContentListProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { courseOrder } = useCurrentNavigationValues();

  const { setEventData } = useEventLogging();

  const { name, page_id, description } = list;

  const showMoreId = page_id;

  const { onRequestAllList, paginatedList } = usePaginatedList({
    academicYearId,
    list,
    classroomId,
    isStudentView,
    isPaginationEnabled,
  });

  const { resources } = paginatedList;

  const { height } = useResourceSkeletonHeight();

  const { slidesToShow, initialSlide } = useResponsiveSlide({
    resources,
    showMore: showMoreId,
    relatedResources: false,
  });

  const { onOpenDrawer, showLinkToOpenDrawer } =
    useUnblockedAppletsDrawerContext();

  const shouldShowViewActivities = (resource: ContentResource): boolean => {
    return showLinkToOpenDrawer({ courseOrder, resource });
  };

  const handleOnViewActivities = (resource: ContentResource): void => {
    if (shouldShowViewActivities(resource)) {
      onOpenDrawer(resource);
    }
  };

  const handleRequestList = (): void => {
    setEventData('content_list_arrow_click', {});
    onRequestAllList();
  };

  return (
    <ResourceSlider
      ref={ref}
      data-testid="sliderWrapperContentList"
      key={list.id}
      role="list"
    >
      <ListTitle
        name={name}
        description={description}
        onShowMoreClick={onShowMoreClick ? () => onShowMoreClick() : undefined}
      />
      <Slider
        onArrowClick={handleRequestList}
        gap="1rem"
        initialIndex={initialSlide}
        slidesToShow={slidesToShow}
      >
        {resources.map((resource, index) => {
          return (
            <div
              aria-label="resource card slide"
              className="slide"
              id={resource.id}
              key={resource.id + index}
              role="listitem"
            >
              {resource.id === 'fake-resource' ? (
                <ResourceSkeleton key={index} height={height} />
              ) : (
                <ResourceCard
                  key={resource.id}
                  title={resource.name}
                  description={resource.description}
                  thumbnail={resource.thumbnail}
                  onClick={() => onResourceClick(resource)}
                  status={resource.status}
                  enumeration={resource.enumeration}
                  onToggleSolutions={
                    resource.has_solutions && onToggleSolutions
                      ? (value) =>
                          onToggleSolutions({
                            value,
                            resource,
                          })
                      : undefined
                  }
                  onMarkSession={
                    resource.is_completable && onMarkSession && classroomId
                      ? (value) => {
                          onMarkSession({
                            value,
                            resource,
                          });
                        }
                      : undefined
                  }
                  isDone={resource.is_done}
                  areSolutionsEnabled={resource.solutions_enabled}
                  type={resource.type}
                  videoInfo={resource.video_info}
                  t={t}
                  imgOptimizationUrl={imgOptimizationUrl}
                  showViewActivities={shouldShowViewActivities(resource)}
                  onViewActivities={() => {
                    handleOnViewActivities(resource);
                  }}
                />
              )}
            </div>
          );
        })}
      </Slider>
    </ResourceSlider>
  );
}
