/* eslint-disable */
import { useEventLogging } from '@innovamat/event-logging';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { axiosInstance } from '../../../../utils';
import { useEnvVariables } from '../../../env-variables-manager';
import { CrmData, LeadUser, UtmInfo } from './register-lead';

type Body = {
  user: LeadUser;
  utmInfo: UtmInfo;
};

export function useRegisterLead() {
  const { apiUsers } = useRegionalConfigVariables();
  const { setEventData } = useEventLogging();
  const { INTEGROMAT_GET_CRM_SCHOOL, INTEGROMAT_SAVE_CRM_DATA } =
    useEnvVariables();

  const getCrmSchool = async (crmId: string) => {
    const url = `${INTEGROMAT_GET_CRM_SCHOOL}?crm_id=${crmId}`;
    const response = await axiosInstance({ url, method: 'GET' });
    return response;
  };

  const saveCrmData = async ({ crmId, params, dealId }: CrmData) => {
    const getDealId = dealId ? `&deal_id=${dealId}` : '';
    const url = `${INTEGROMAT_SAVE_CRM_DATA}?crm_id=${crmId}${getDealId}`;
    const response = await axiosInstance({ url, method: 'GET', params });
    return response;
  };

  const registerLead = async (data: LeadUser, apiUsers: string) => {
    const url = `${apiUsers}/leads`;
    const response = await axiosInstance({
      url,
      method: 'POST',
      data,
    });
    return response;
  };

  const mutation = useMutation<void, AxiosError, Body>({
    mutationFn: async ({ user }) => {
      if (!user.crmId) {
        throw {
          response: {
            status: 406,
          },
        } as AxiosError;
      }
      await registerLead(user, apiUsers);
    },
    onSuccess: async (_, { utmInfo, user }) => {
      const utmValues: any = {};
      const crmId = user.crmId!;

      Object.keys(utmInfo).forEach((key) => {
        if (utmInfo[key as keyof UtmInfo] !== null) {
          utmValues[key] = utmInfo[key as keyof UtmInfo];
        }
      });

      let crmSchoolName = await getCrmSchool(crmId);

      setEventData(
        'sys_lead_register',
        {
          username: user.email,
          crm_id: user.crmId,
          crm_name: user.firstName,
          crm_surname: user.lastName,
          school_name: crmSchoolName?.data,
          ...utmValues,
        },
        false
      );

      saveCrmData({
        crmId: crmId,
        dealId: user.dealId,
        params: {
          name: user.firstName,
          surname: user.lastName,
          email: user.email,
          phone: user.phoneNumber || 0,
        },
      });
    },
  });

  return mutation;
}
