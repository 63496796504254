import { useTranslation } from 'react-i18next';

import { useDevice, type Column } from '@innovamat/glimmer-components';
import {
  AnswersCol,
  AnswersContainer,
} from '../../../../../components/answers/answers';
import type { MockExamsStatementRow } from '../components/mock-exams-drawer-statements-table';

const useMockExamsDrawerStatementsTableColumnsConf = (
  multipleChoiceLabels: string[]
): Column<MockExamsStatementRow>[] => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  return [
    {
      id: 'position',
      label: t('mock-exams-drawer.table.statements.label'),
      width: 112,
      minWidth: 112,
      sticky: !isMobile,
    },
    {
      id: 'type',
      label: t('mock-exams-drawer.table.type.label'),
      isLargeLabel: false,
      sortable: true,
      sortIgnoreCase: true,
      width: 144,
      minWidth: 144,
      sticky: !isMobile,
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
      sticky: !isMobile,
    },
    {
      id: 'correctlyAnswered',
      label: t('mock-exams-drawer.table.correct-percentage.label'),
      isLargeLabel: false,
      sortable: true,
      sortIgnoreCase: true,
      width: 144,
      minWidth: 144,
      sticky: !isMobile,
      align: 'center',
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
      sticky: !isMobile,
    },
    {
      id: 'answers',
      subLabel: () => {
        return (
          <AnswersContainer>
            {multipleChoiceLabels.map((label, index) => {
              return (
                <AnswersCol key={'answer-label-' + index}>{label}</AnswersCol>
              );
            })}
          </AnswersContainer>
        );
      },
      label: t('mock-exams-drawer.table.answers.label'),
      minWidth: 100,
      width: '100%',
    },
    {
      id: 'separator-3',
      width: 9,
      separator: true,
      sticky: !isMobile,
      stickyPosition: 'right',
    },
    {
      id: 'notAnswered',
      label: t('mock-exams-drawer.table.no-answered-percentage.label'),
      width: 144,
      minWidth: 144,
      sticky: !isMobile,
      stickyPosition: 'right',
      align: 'center',
    },
  ];
};

export { useMockExamsDrawerStatementsTableColumnsConf };
