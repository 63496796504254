import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import {
  Content,
  PortraitPage,
  ScaledChartContainer,
  Subtitle,
  Subtitle2,
  Text,
} from '../common';
import { PrintMockExamsStatementsTable } from './components/print-mock-exams-statements-table';

type Evaluations = {
  type: string;
  position: number;
  notAnswered: number;
  correctlyAnswered: number;
  answers: Array<{
    positionLabel: string;
    answered?: number | null;
    isCorrect: boolean;
  }>;
}[];

type Props = {
  multipleChoicePositionLabels: string[];
  evaluations: Evaluations;
};

export function ResultsClassScoreByQuestion({
  multipleChoicePositionLabels,
  evaluations,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <PortraitPage id="page-4-portrait">
      <Content>
        <Subtitle>
          {t('print-tests-and-results.class-score-by-question.title')}
        </Subtitle>
        <Subtitle2>
          {t('print-tests-and-results.class-score-by-question.subtitle')}
        </Subtitle2>
        <Text>
          <Typography.Body2>
            {t('print-tests-and-results.class-score-by-question.description')}
          </Typography.Body2>
        </Text>
        <ScaledChartContainer>
          <PrintMockExamsStatementsTable
            statements={evaluations}
            multipleChoiceLabels={multipleChoicePositionLabels}
          />
        </ScaledChartContainer>
      </Content>
    </PortraitPage>
  );
}
