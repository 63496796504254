import type { BigIdea, StandardContent } from '@innovamat/resource-viewer';

import type { Printable } from '../types/Materials';
import type {
  PreprModel,
  SmartGuideBase,
  SmartGuidePrimaria,
  SmartGuideSecundaria,
  SmartGuideTaller,
  SmartGuideType,
} from '../types/SmartGuide';
import type { Stage } from '../types/Stage';
import { mapBigIdeas } from './map-big-ideas';
import { mapNewMathPractices } from './map-new-math-practices';
import { mapNewStandardContent } from './map-new-standard-content';
import { mapDimensionTags } from './mapDimensionTags';
import { mapMaterials } from './mapMaterials';
import { groupParts } from './mapPart';

type MapSmartGuideParams = {
  data: any;
  stage: Stage;
  model: PreprModel;
  printables: Printable[];
  regionParameters: any;
  isCaliforniaStandard: boolean;
  isBubbleTreeStandard: boolean;
  assessables?: string[];
  standardContent?: StandardContent[];
  mathPractices?: string[];
  bigIdeas?: BigIdea[];
};

const modelToKey = (model: string) => {
  const modelKey = {
    SmartGuideTaller: 'ey',
    SmartGuidePrimaria: 'pe',
    SmartGuideSecundaria: 'se',
    SmartGuideOpen: 'se',
  };

  return modelKey[model as keyof typeof modelKey];
};

const guideTypeMap = (tipo: string) => {
  const guideType = {
    1: 'lab',
    2: 'adv',
    3: 'us',
    4: '4in1',
  };

  return guideType[+tipo as keyof typeof guideType];
};

export function mapSmartGuide({
  data,
  stage,
  model,
  printables,
  regionParameters,
  isCaliforniaStandard,
  assessables,
  standardContent,
  mathPractices,
  bigIdeas,
  isBubbleTreeStandard,
}: MapSmartGuideParams): SmartGuideType {
  const smartGuide: SmartGuideBase = {
    type: 'smartGuide',
    id: data._id,
    sessionCode: data.session_code,
    summary: {
      inThisTaller: {
        titleKey:
          modelToKey(model) === 'ey'
            ? `digitalguides.${modelToKey(model)}.workshop.summary`
            : `digitalguides.${modelToKey(model)}.summary`,
        text: data.en_este_taller,
      },
      withIntentionOf: {
        titleKey: `digitalguides.${modelToKey(model)}.with_intention_of`,
        text: data.con_intencin_de,
      },
      vocabularyKey: {
        titleKey:
          modelToKey(model) === 'se'
            ? 'digitalguides.to_consider'
            : 'digitalguides.key_vocabulary',
        text: data.vocabulario_clave,
      },
      assessables: assessables || [],
      materials: mapMaterials(data.materiales, printables, stage),
      dimensionTags: mapDimensionTags(data.dimensiones, isBubbleTreeStandard),
      newStandardContent: mapNewStandardContent({
        data,
        stage,
        standardContent,
        isCaliforniaStandard,
        isBubbleTreeStandard,
      }),
      newMathPractices: mapNewMathPractices(mathPractices),
      bigIdeas: mapBigIdeas(bigIdeas),
    },
    subtitle: data.subtitle,
    title: data.title,
    parts: groupParts({
      data,
      modelKey: modelToKey(model),
      guideType: guideTypeMap(data.tipo),
      regionParameters,
      isCaliforniaStandard,
      locale: data._locale,
    }),
  };

  if (model === 'SmartGuidePrimaria') {
    const processedSmartGuide: SmartGuidePrimaria = {
      __typename: 'SmartGuidePrimaria',
      ...smartGuide,
      guideType: data.tipo,
    };
    return processedSmartGuide;
  }

  if (model === 'SmartGuideSecundaria' || model === 'SmartGuideOpen') {
    const processedSmartGuide: SmartGuideSecundaria = {
      __typename: 'SmartGuideSecundaria',
      ...smartGuide,
    };

    return processedSmartGuide;
  }

  const processedSmartGuide: SmartGuideTaller = {
    __typename: 'SmartGuideTaller',
    ...smartGuide,
  };

  return processedSmartGuide;
}
