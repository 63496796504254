import styled from '@emotion/styled';
import { Notification } from '@innovamat/innova-components';

const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: auto;
  background-repeat: no-repeat;
  background-position: right;
  background-color: ${({ theme }) => `${theme.colors.corp}29`};
`;

const FormContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }
`;

const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DropdownContainer = styled.div`
  margin: 24px 24px 0;
  .dropdownButton {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;
  margin: 24px 24px 0;
  max-width: 160px;
`;

const FormBody = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: 64px 180px;
  .goBack {
    margin-bottom: 19px;
    display: flex;
    svg {
      path {
        fill: ${({ theme }) => theme.colors.brand['01']};
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 64px 20%;
  }
`;

const Title = styled.div`
  margin-bottom: 24px;
`;
const Error = styled.div`
  font-size: ${({ theme }) => theme.sizes.small};
  color: ${({ theme }) => theme.colors.semantic['03']};
`;

const StyledNotification = styled(Notification)`
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.red['05']} !important;
  span {
    font-family: ${({ theme }) => theme.fonts.robotoMedium};
    font-size: ${({ theme }) => theme.sizes.small};
  }
`;

const Background = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;

  svg {
    height: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export {
  Background,
  Container,
  DropdownContainer,
  Error,
  FormBody,
  FormContainer,
  FormHeader,
  LogoContainer,
  StyledNotification,
  Title,
};
