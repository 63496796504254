import styled from '@emotion/styled';
import { Icon } from '../icon';
import { useState } from 'react';
import { type ThemeType, useGlimmerTheme } from '../../theme';
import { Typography } from '../typography';
import { css } from '@emotion/react';
import { Dropdown } from '../dropdown';
import { ProfileButtonContent } from './profile-button-content';
import type { IconType } from '@innovamat/glimmer-icons';

const getBorderColor = (theme: ThemeType): string => {
  return theme.tokens.color.alias.cm.border['border-subtle'].value;
};

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-accent'].value};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DesktopContainer = styled.div<{ isPressed: boolean }>`
  width: fit-content;
  height: 32px;
  padding: 4px 8px;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  border-radius: 100px;
  outline: 1px solid ${({ theme }) => getBorderColor(theme)};
  outline-offset: -1px;

  &:hover {
    outline: 2px solid ${({ theme }) => getBorderColor(theme)};
    outline-offset: -2px;
  }

  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      background-color: ${theme.tokens.color.specific['state-layer'][
        'state-press-darker'
      ].value};
    `};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const MobileContainer = styled.div<{ isPressed: boolean }>`
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-accent'].value};

  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      background-color: ${theme.tokens.color.specific['state-layer'][
        'state-press-darker'
      ].value};
    `};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

type ProfileItemState = 'active' | 'disabled' | 'selected';

export type ProfileItem = {
  id: string;
  text: string;
  icon?: IconType;
  rightIcon?: IconType;
  hasDivider?: boolean;
  onClick?: () => void;
  state?: ProfileItemState;
  hidden?: boolean;
};

export type ProfileButton = {
  name: string;
  email: string;
  items: ProfileItem[];
};

export function ProfileButton({
  name,
  email,
  items,
}: ProfileButton): JSX.Element {
  const [isPressed, setIsPressed] = useState(false);
  const theme = useGlimmerTheme();

  return (
    <Dropdown onToggle={setIsPressed}>
      <Dropdown.Toggle dataTestId="profile-button">
        <MobileContainer isPressed={isPressed}>
          <Icon
            icon="UserIcon"
            size="L"
            iconColor={theme.tokens.color.alias.cm.icon['icon-accent'].value}
          />
        </MobileContainer>
        <DesktopContainer isPressed={isPressed}>
          <IconContainer>
            <Icon
              icon="UserIcon"
              size="S"
              iconColor={theme.tokens.color.alias.cm.icon['icon-accent'].value}
            />
          </IconContainer>
          <Typography.Body2>{name}</Typography.Body2>
          <Icon
            size="S"
            icon={isPressed ? 'ExpandLessIcon' : 'ExpandMoreIcon'}
            iconColor={theme.tokens.color.alias.cm.icon['icon-default'].value}
          />
        </DesktopContainer>
      </Dropdown.Toggle>
      <Dropdown.Content
        position="bottomRight"
        borderRadius="8px"
        contentSpace="8px"
      >
        <ProfileButtonContent name={name} email={email} items={items} />
      </Dropdown.Content>
    </Dropdown>
  );
}
