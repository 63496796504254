import { Button, Dropdown } from '@innovamat/glimmer-components';
import { LANGUAGES } from '@innovamat/localization';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-domv6';
import { useResourceParams } from '../../hooks';

function LanguageSelector() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { language, region, urlRegion } = useResourceParams();

  const availableLanguages =
    LANGUAGES.region[region as keyof typeof LANGUAGES.region].locale;

  const locales = Object.keys(availableLanguages).sort((a, b) =>
    a.localeCompare(b)
  );

  const handleLanguageChange = (locale: string) => {
    const params = new URLSearchParams(location.search);
    params.set('language', locale);

    if (!urlRegion) {
      params.set('region', region);
    }

    navigate(`${location.pathname}?${params.toString()}`);
  };

  const languageToDisplay = language?.split('_')[0] || '';

  return (
    <Dropdown>
      <Dropdown.Toggle>
        <Button
          variant="tertiary"
          leftIcon="LanguageIcon"
          rightIcon="ExpandMoreIcon"
        >
          {languageToDisplay}
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Content position="bottomRight">
        {locales.map((locale) => (
          <Dropdown.Item
            state={'active'}
            key={locale}
            onSelectItem={() => handleLanguageChange(locale)}
          >
            {t(`language.${locale}`)}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}
export { LanguageSelector };
