import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import { useGetModulesOfCourseQuery } from '@innovamat/glow-api-client';
import { usePrint } from '@innovamat/hooks';
import type { TFunction } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-domv6';
import { PrintFooter } from '../print-guides';
import { getPersonalizedPracticeFileName } from './get-file-name';
import {
  ActivityCard,
  ActivityGridRow,
  ActivityImage,
  ActivityInfo,
  Bold,
  FileDescription,
  FileTitle,
  GlobalStyle,
  HeaderBar,
  KeepTogether,
  ModuleName,
  StandardInfo,
  StandardsContainer,
  StandardSection,
  Stretch,
  StyledTable,
  TabletTitle,
  TBodyCell,
  THeadCell,
  UnlockedAppletsTitle,
  Wrapper,
} from './print-us-styled';

type Activity = {
  codenamepack: string;
  title: string;
  subcategory?: string | null;
  screenshot: string;
};

type Standard = {
  code: string;
  description: string;
  activities: Activity[];
};

type Module = {
  stretch: string;
  title: string;
  standards: Standard[];
};

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  gap: 0.25rem;
`;

const SubcategoryContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.7rem;
`;

const SubcategoryText = styled.p`
  color: #718482;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 0.85rem;
`;

const Description = `<p>Practice the Mathematical <b>Content Standards of the module</b> to strengthen fluency.</p>
<p>Students work independently, each on their own device, focusing on the content from the module. 
Part of the session is reserved for practice that is adapted and personalized to each student’s individual needs, 
ensuring targeted skill reinforcement.</p>
<p>Remember that you can check your students’ progress in the <b>Weekly Results Report</b>.</p>
<p>Search for this applets in the <a>Applet Library</a>.</p>`;

const groupByRows = (activities: Activity[], n: number): Activity[][] => {
  const rows = [];
  for (let i = 0; i < activities.length; i += n) {
    rows.push(activities.slice(i, i + n));
  }
  return rows;
};

const ActivityCardItem = ({
  activity,
  isFirstLineOfGroup,
  t,
}: {
  activity: Activity;
  isFirstLineOfGroup?: boolean;
  t: TFunction<'translation', undefined, 'translation'>;
}): EmotionJSX.Element => {
  const { codenamepack, screenshot, subcategory, title } = activity;
  return (
    <ActivityCard
      isFirstLineOfGroup={isFirstLineOfGroup}
      className="activity-card"
    >
      <ActivityImage src={screenshot} alt={title} />
      <InfoContainer>
        <ActivityInfo>
          <Bold>{codenamepack}</Bold> - {t(title, { lng: 'en' })}
        </ActivityInfo>
        <SubcategoryContainer>
          <SubcategoryText>{subcategory}</SubcategoryText>
        </SubcategoryContainer>
      </InfoContainer>
    </ActivityCard>
  );
};

const PrintModule: React.FC<Module> = ({ stretch, title, standards }) => {
  const { t } = useTranslation();

  return (
    <StyledTable>
      <thead>
        <tr>
          <THeadCell colSpan={1}>
            <HeaderBar className="header-bar">
              <FileTitle>
                {t('personalizedPractice.title', {
                  defaultValue: 'Personalized Practice',
                })}
              </FileTitle>
              <TabletTitle>
                <Stretch>{t(stretch)}</Stretch>
                <ModuleName> - {t(title)}</ModuleName>
              </TabletTitle>
            </HeaderBar>
          </THeadCell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TBodyCell>
            <FileDescription>
              <Trans
                i18nKey={t('personalizedPractice.description')}
                defaults={Description}
                components={{
                  b: <Bold />,
                  p: <p />,
                  a: (
                    <Typography.Link2
                      href={'https://manager.innovamat.com/applets'}
                      target="_blank"
                    />
                  ),
                }}
              />
            </FileDescription>
            <UnlockedAppletsTitle>
              {t('personalizedPractice.unlockedApplets', {
                defaultValue: 'Some Applets Related to This Module’s Content:',
              })}
            </UnlockedAppletsTitle>
            <StandardsContainer>
              {standards.map(({ code, description, activities }, idx) => {
                if (activities.length === 0) {
                  return null;
                }

                const rows = groupByRows(activities, 2);

                return (
                  <StandardSection key={idx} className="standard-section">
                    <KeepTogether>
                      <StandardInfo>
                        <Bold>{code}</Bold>: {t(description, { lng: 'en' })}
                      </StandardInfo>
                      {rows.length > 0 && (
                        <ActivityGridRow>
                          {rows[0].map((activity, activityIndex) => (
                            <ActivityCardItem
                              key={activityIndex}
                              activity={activity}
                              t={t}
                            />
                          ))}
                        </ActivityGridRow>
                      )}
                    </KeepTogether>
                    {rows.slice(1).map((row, rowIndex) => (
                      <ActivityGridRow key={rowIndex}>
                        {row.map((activity, activityIndex) => (
                          <ActivityCardItem
                            isFirstLineOfGroup={rowIndex === 0}
                            key={activityIndex}
                            activity={activity}
                            t={t}
                          />
                        ))}
                      </ActivityGridRow>
                    ))}
                  </StandardSection>
                );
              })}
            </StandardsContainer>
          </TBodyCell>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export function PrintPersonalizedPractice(): EmotionJSX.Element {
  const { courseId } = useParams();
  const [searchParams] = useSearchParams();
  const stretch = searchParams.get('stretch');
  const module = searchParams.get('module');

  const { data } = useGetModulesOfCourseQuery({
    courseUuid: courseId || '',
    stretch,
    module,
  });

  const { componentRef, onPrint, loading } = usePrint(
    getPersonalizedPracticeFileName({
      courseId: courseId || '',
      stretch,
      module,
    })
  );

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <PrintFooter onPrint={onPrint} loading={loading} />
        <div ref={componentRef}>
          {data?.modulesOfCourse.map((module, moduleIndex) => (
            <PrintModule key={moduleIndex} {...module} />
          ))}
        </div>
      </Wrapper>
    </>
  );
}
