import styled from '@emotion/styled';
import { RadioButton } from '../radio-button';
import { Typography } from '../typography';

type Props = {
  title?: string;
  value?: string | string[];
  handleEvent?: (value: string) => void;
  options: {
    label: string;
    value: string;
    disabled?: boolean;
    groupBy?: string;
  }[];
  groupName: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled(Typography.Subtitle2)``;

export function RadioGroup({
  title,
  value,
  handleEvent,
  options,
  groupName,
}: Props): JSX.Element {
  const selectedValue = value;

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <RadioButtonsContainer>
        {options.map((option) => (
          <RadioButton
            key={option.value}
            groupBy={option.groupBy || groupName}
            label={option.label}
            value={option.value}
            disabled={option.disabled}
            checked={
              selectedValue === option.value ||
              selectedValue?.includes(option.value)
            }
            onEvent={handleEvent}
          />
        ))}
      </RadioButtonsContainer>
    </Container>
  );
}
