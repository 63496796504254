import { ButtonV2 as Button, InputField } from '@innovamat/innova-components';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-domv6';
import {
  AnchorStyled,
  Footer,
  Form,
  KnowMore,
  LinkStyled,
  Separator,
  SeparatorWrapper,
  SignInWrapper,
} from './components/login.styled';

import styled from '@emotion/styled';
import { CleverImg, GoogleImg, MicrosoftImg } from '@innovamat/glimmer-assets';
import { validators } from '@innovamat/radiance-utils';
import { AutoLoginGoogleButton } from '@innovamat/social-login';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import { Helmet } from 'react-helmet';
import { useUser } from '../../../user-management';
import LoginRegisterLayout from '../../components/login-register-layout/login-register-layout';
import { SignInButton } from '../../components/sign-in-button';
import { useLogin } from '../../hooks/use-login';
import WebViewModal from './components/web-view-modal';

const FullScreenOverlayForWebview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
`;

function Login(): JSX.Element {
  const { t } = useTranslation();
  const {
    handleSetCredentials,
    onLogin,
    credentials,
    notification,
    showPassword,
    isLoading,
    handleDisplayError,
    isValid,
    showWebViewModal,
    handleHideWebViewModal,
    handleCheckEmail,
    showEmailError,
    showCleverBtn,
    handleIsUsa,
  } = useLogin();

  const { isLoggedIn } = useUser();

  const { username, password } = credentials;

  const handleLogin = (e: React.FormEvent): void => {
    e.preventDefault();
    onLogin({ type: 'users', payload: { username, password } });
  };

  const onMsalLogin = (): void => {
    onLogin({ type: 'msal' });
  };

  const onGoogleLogin = (): void => {
    onLogin({ type: 'google' });
  };

  const onCleverLogin = (): void => {
    onLogin({ type: 'clever' });
  };

  const handleFocus = (): void => {
    handleDisplayError(false);
  };

  const { isInIOS, isInWebview, isGreenApp } = useWebviewBridge();
  const isInIOSAndInWebview = isInIOS && isInWebview;

  const { i18n } = useTranslation();
  const language = i18n.language;

  if (isLoggedIn) {
    return <Navigate to={`/`} />;
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isInWebview && isGreenApp && <FullScreenOverlayForWebview />}
      <LoginRegisterLayout
        title={t('Login.Accedeix a Innovamat')}
        notification={notification}
        hasLanguageDropdown
        handleIsUSA={handleIsUsa}
      >
        <>
          <Form onSubmit={handleLogin}>
            <InputField
              data-cy="loginEmail"
              data-testid="loginEmail"
              onFocus={handleFocus}
              label={t('Login.Nom d’usuari o email')}
              required
              placeholder={t('Login.Nom d’usuari o email')}
              onChange={(e) => handleSetCredentials(e.target.value, 'username')}
              value={username}
              type="email"
              errorMessage={
                showEmailError && !validators.isEmailValid(username)
                  ? t('common.email.error')
                  : ''
              }
              onBlur={() => handleCheckEmail(username)}
            />
            <InputField
              data-cy="loginPassword"
              data-testid="loginPassword"
              onFocus={handleFocus}
              label={t('Login.Contrassenya')}
              required
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder={t('Login.Contrassenya')}
              onChange={(e) => handleSetCredentials(e.target.value, 'password')}
              description={t(
                'Login.password.descriptionLength',
                'Mínimo 6 caracteres, combinando letras y números.'
              )}
            />
            <Button
              data-cy="loginButton"
              data-testid="loginButton"
              type="submit"
              disabled={!isValid() || isLoading}
              onClick={handleLogin}
              loading={isLoading}
            >
              <strong>{t('Login.Accedir')}</strong>
            </Button>
          </Form>

          <Footer>
            <LinkStyled
              to={{
                pathname: `/${language}/forgot-password`,
              }}
              data-testid="forgotPassword"
            >
              {t('Login.Has oblidat la contrassenya?')}
            </LinkStyled>
            {!isInIOSAndInWebview && (
              <>
                <SeparatorWrapper>
                  <Separator />
                  <span>{t('Login.msal.or')}</span>
                  <Separator />
                </SeparatorWrapper>
                <SignInWrapper>
                  <AutoLoginGoogleButton />

                  <SignInButton
                    dataCy="signInButtonGoogle"
                    dataTestid="signInButtonGoogle"
                    onClick={onGoogleLogin}
                    Logo={GoogleImg}
                    text={t('Login.google.button', 'Inicia sesión con Google')}
                  />
                  <SignInButton
                    dataCy="signInButtonMicrosoft"
                    dataTestid="signInButtonMicrosoft"
                    onClick={onMsalLogin}
                    Logo={MicrosoftImg}
                    text={t('Login.msal.button', 'Inicia sesión con Microsoft')}
                  />
                  {showCleverBtn && (
                    <SignInButton
                      dataCy="signInButtonClever"
                      dataTestid="signInButtonClever"
                      onClick={onCleverLogin}
                      Logo={CleverImg}
                      text={t(
                        'Login.clever.button',
                        'Inicia sesión con Clever'
                      )}
                    />
                  )}
                </SignInWrapper>
              </>
            )}
            <WebViewModal
              show={showWebViewModal}
              onCancel={handleHideWebViewModal}
            />

            {!isInIOSAndInWebview && (
              <KnowMore>
                <span>{t('Login.knowMoreAboutInnovamat')}</span>
                &nbsp;
                <AnchorStyled
                  href={t('common.innovamat_website')}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="knowMore"
                >
                  {t('Login.clickHere')}
                </AnchorStyled>
              </KnowMore>
            )}
          </Footer>
        </>
      </LoginRegisterLayout>
    </>
  );
}

export { Login };
