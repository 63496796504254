import { getAcceptLanguage } from '@innovamat/localization';
import type { TokenData } from '@innovamat/radiance-utils';
import { authService, storage } from '@innovamat/radiance-utils';

export function getAccessToken(): string {
  return storage.tokenInfo.get()?.access_token || '';
}

export const getUserAcceptLanguage = (): string => {
  const language = storage.language.get();
  const region = storage.region.get();

  if (!language) {
    return '*';
  }

  if (!region) {
    return language;
  }

  return getAcceptLanguage(region, language);
};

export async function updateRefreshTokenClient(): Promise<TokenData> {
  try {
    return await authService.refreshToken();
  } catch (error) {
    storage.clear();
    window.location.href = '/';
    throw error;
  }
}
