import { Skeleton } from '@innovamat/glimmer-components';
import {
  ContentContainer,
  Description,
  FlexContainer,
  InfoContainer,
  InfoTagsContainer,
} from './mock-exams-drawer';
import { MockExamsDrawerTableSkeleton } from './components';

export function MockExamsDrawerSkeleton(): JSX.Element {
  return (
    <ContentContainer>
      <FlexContainer>
        <InfoContainer>
          <Description>
            <Skeleton width="100%" height="24" radius="8" />
            <Skeleton width="100%" height="24" radius="8" />
          </Description>
          <InfoTagsContainer>
            <Skeleton width="180px" height="24" radius="8" />
            <Skeleton width="180px" height="24" radius="8" />
            <Skeleton width="180px" height="24" radius="8" />
          </InfoTagsContainer>
        </InfoContainer>

        <Skeleton width="180px" height="24" radius="8" />
      </FlexContainer>
      <MockExamsDrawerTableSkeleton />
    </ContentContainer>
  );
}
