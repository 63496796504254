import { getRegisterLanguagesByRegion } from '@innovamat/localization';
import { onChangeHtmlLanguage } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-domv6';
import { useGetUserRegion } from './use-user-region';

function useLanguage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { lng } = useParams<{ lng: string }>();
  const { i18n } = useTranslation();
  const region = useGetUserRegion();
  const languages = getRegisterLanguagesByRegion(region);

  const setLanguageFromUrl = () => {
    const pathLanguage = location.pathname.split('/')[1];

    const urlLanguage =
      languages.find((l) => l === pathLanguage) || i18n.options.lng || 'es';

    setTimeout(() => {
      i18n.changeLanguage(urlLanguage);
    });
  };

  const changeUrlLanguage = (lang: string) => {
    const { pathname, search } = location;
    if (pathname === '/') return;
    const newPath = lng ? pathname.replace(lng, lang) : `/${lang}${pathname}`;
    navigate(`${newPath}${search}`);
  };

  const setHtmlLanguage = (language: string) => {
    onChangeHtmlLanguage(language);
  };

  const handleChangeTranslations = async (language: string) => {
    await i18n.changeLanguage(language);
  };

  return {
    setLanguageFromUrl,
    changeUrlLanguage,
    setHtmlLanguage,
    handleChangeTranslations,
  };
}

export { useLanguage };
