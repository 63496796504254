import styled from '@emotion/styled';
import { Button, Icon } from '@innovamat/innova-components';

type Props = {
  title?: string;
  body?: string | JSX.Element;
  actionText?: string;
  action?: () => void;
  dataTestId?: string;
};

const SuccessContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
`;

const IconContainer = styled.div`
  margin-bottom: 16px;
  background-color: var(--corp);
  padding: 2px;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: scale(1.3);

    path {
      stroke: white;
      fill: white;
    }
  }
`;

const SuccessTitle = styled.h2`
  font-weight: 600;
  font-size: 32px;
  line-height: 34px;
`;

const SuccessBody = styled.div`
  font-size: var(--normal);
  color: var(--dark02);
  margin-bottom: 32px;
  margin-top: 16px;
`;

export function SuccessMessage({
  title,
  body,
  actionText,
  action,
  dataTestId,
}: Props) {
  return (
    <SuccessContainer data-testid={dataTestId}>
      <IconContainer>
        <Icon icon="Check" />
      </IconContainer>
      <SuccessTitle>{title}</SuccessTitle>
      <SuccessBody>{body}</SuccessBody>
      {actionText && action && <Button onClick={action}>{actionText}</Button>}
    </SuccessContainer>
  );
}
