import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useFlag } from '@innovamat/flags';
import {
  ERROR_TYPE,
  ErrorPage,
  useCurrentNavigationValues,
  useUser,
} from '@innovamat/ga-features';
import { EmptyReport } from '@innovamat/glimmer-assets';
import type { TabType } from '@innovamat/glimmer-components';
import {
  AnnouncementPanel,
  Button,
  Drawer,
  EmptyState,
  Icon,
  InnerHtml,
  snack,
  Tabs,
  Typography,
} from '@innovamat/glimmer-components';
import { useClassroomTestRoundDetailQuery } from '@innovamat/glow-api-client';
import { downloadBase64PdfFile } from '@innovamat/radiance-utils';

import { MockExamsDistributionChart } from '../../../../components/mock-exams-distribution-chart';
import { mockExamsStudentRowsToChartData } from '../../../../modules/mock-exams/mock-exams';
import type { MockExamsStudentRow } from '../../../../types/mock-exams';
import { formatDate } from '../../../../utils/date';
import { DownloadTestQuestionsModal } from './components/download-test-questions-modal/download-test-questions-modal';
import { InfoTag } from './components/info-tag';
import { MockExamsDrawerStatementsTable } from './components/mock-exams-drawer-statements-table';
import { MockExamsDrawerTable } from './components/mock-exams-drawer-table';
import { useMockExams } from './context/mock-exams-provider';
import { useGetTestQuestionsAvailableLanguages } from './hooks/use-get-test-questions-available-languages';
import { useGetTestQuestionsPdf } from './hooks/use-get-test-questions-pdf';
import { MockExamsDrawerHeader } from './mock-exams-drawer-header';
import { MockExamsDrawerSkeleton } from './mock-exams-drawer-skeleton';

type MockExamsDrawer = {
  id: string | undefined;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  onCloseDrawer: () => void;
};

const TABS_ID = {
  STUDENTS: 'students',
  DISTRIBUTION: 'distribution',
  QUESTIONS: 'questions',
};

const Container = styled.div`
  padding: 16px 32px;
  width: 100%;
  overflow-x: hidden;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
`;

export const InfoTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Description = styled(Typography.Body2)`
  margin-top: 8px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const DownloadLink = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  path {
    fill: ${({ theme }) =>
      theme.tokens.color.alias.cm.icon['icon-accent'].value};
  }
`;

export const TabsContent = styled.div``;

export const TabsContainer = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const StyledAnnouncementPanel = styled(AnnouncementPanel)`
  margin-bottom: 16px;
  margin-left: auto;
  width: fit-content;
`;

export function MockExamsDrawer(): JSX.Element {
  const { t, i18n } = useTranslation();
  const { onCloseDrawer, isDrawerOpen, rowSelected } = useMockExams();
  const { currentClassroom } = useCurrentNavigationValues();
  const { user } = useUser();

  const [showDownloadTestQuestionsModal, setShowDownloadTestQuestionsModal] =
    useState(false);
  const [testQuestionsAvailableLangs, setTestQuestionsAvailableLangs] =
    useState<string[] | []>([]);
  const [testQuestionsLanguage, setTestQuestionsLanguage] = useState<
    string | undefined
  >();

  const language = testQuestionsLanguage ?? user?.locale ?? i18n.language;

  const { value: printMockExam } = useFlag('printMockExams', false, {
    identifier: '',
    custom: {
      schoolId: user?.organizationId || '',
    },
    email: user?.email || '',
  });

  const { data, isLoading, isError } = useClassroomTestRoundDetailQuery(
    {
      testRoundId: isDrawerOpen ?? '',
      classroomId: currentClassroom?.id ?? '',
    },
    { enabled: !!isDrawerOpen, staleTime: Infinity }
  );

  const { refetch: refetchAvailableLanguages } =
    useGetTestQuestionsAvailableLanguages({
      testId: rowSelected?.testId ?? '',
      enabled: false,
    });

  const { refetch: refetchQuestionsPdf, isLoading: isDownloadingPdf } =
    useGetTestQuestionsPdf({
      testId: rowSelected?.testId ?? '',
      language: language,
      enabled: false,
    });

  const detailData = data?.classroomTestRoundDetail;
  const chartData = useMemo(() => {
    return mockExamsStudentRowsToChartData(
      detailData?.students as MockExamsStudentRow[],
      t
    );
  }, [detailData, t]);

  const [selectedTab, setSelectedTab] = useState(TABS_ID.STUDENTS);

  const MOCK_EXAMS_TABS: TabType[] = [
    {
      dataInlineManual: `mock-exams-tab-${TABS_ID.STUDENTS}`,
      selected: selectedTab === TABS_ID.STUDENTS,
      text: t(`mock-exams-drawer.tabs.${TABS_ID.STUDENTS}`),
      onClick: () => setSelectedTab(TABS_ID.STUDENTS),
    },
    {
      dataInlineManual: `mock-exams-tab-${TABS_ID.DISTRIBUTION}`,
      selected: selectedTab === TABS_ID.DISTRIBUTION,
      text: t(`mock-exams-drawer.tabs.${TABS_ID.DISTRIBUTION}`),
      onClick: () => setSelectedTab(TABS_ID.DISTRIBUTION),
    },
    {
      dataInlineManual: `mock-exams-tab-${TABS_ID.QUESTIONS}`,
      selected: selectedTab === TABS_ID.QUESTIONS,
      text: t(`mock-exams-drawer.tabs.${TABS_ID.QUESTIONS}`),
      onClick: () => setSelectedTab(TABS_ID.QUESTIONS),
    },
  ];

  const downloadTestQuestionsPdf = async (newWindow = false): Promise<void> => {
    const currentYear = new Date().getFullYear();
    const fileName = `${t('reports.title')}_${t(
      rowSelected?.rowName ?? ''
    )}_${currentYear}.pdf`;

    const testQuestionsPdfResponse = await refetchQuestionsPdf();

    if (testQuestionsPdfResponse.isError) {
      snack.error(t('mock-exams-drawer.error.downloading-statements-pdf'));

      return;
    }

    const windowRef = newWindow
      ? window.open('about:blank', '_blank')
      : undefined;

    await downloadBase64PdfFile({
      base64Content:
        testQuestionsPdfResponse?.data?.testQuestionsPdf?.pdfBase64 ?? '',
      fileName,
      openInNewTab: newWindow,
      windowRef: windowRef ?? undefined,
    })
      .catch(() => {
        snack.error(t('mock-exams-drawer.error.downloading-statements-pdf'));
      })
      .finally(() => {
        setTestQuestionsLanguage(undefined);
        setShowDownloadTestQuestionsModal(false);
      });
  };

  const handleDrawerClose = (): void => {
    onCloseDrawer();
  };

  const handleCloseTestQuestionsModal = (): void => {
    setShowDownloadTestQuestionsModal(false);
    setTestQuestionsAvailableLangs([]);
  };

  const handleTestQuestionsDownload = async (
    language: string
  ): Promise<void> => {
    setTestQuestionsLanguage(language);
  };

  const handleSeeQuestionsClick = async (): Promise<void> => {
    const availableLanguagesResponse = await refetchAvailableLanguages();
    const availableLanguages =
      availableLanguagesResponse.data?.testQuestionsAvailableLanguages || [];
    const showModalWhenUserLangNotAvailable =
      !availableLanguages.includes(language);

    if (showModalWhenUserLangNotAvailable) {
      setTestQuestionsAvailableLangs(availableLanguages);
      setShowDownloadTestQuestionsModal(true);
      return;
    }

    downloadTestQuestionsPdf(true);
  };

  const handlePrintMockExam = () => {
    if (!printMockExam) return;
    window.open(
      `/classroom/${currentClassroom?.id}/print-mock-exams/${isDrawerOpen}/${currentClassroom?.name}/${user?.organizationName}`,
      '_blank'
    );
  };

  useEffect(() => {
    if (detailData?.students?.length === 0) setSelectedTab(TABS_ID.STUDENTS);
  }, [detailData]);

  useEffect(() => {
    if (testQuestionsLanguage && showDownloadTestQuestionsModal) {
      downloadTestQuestionsPdf(false);
    }
  }, [testQuestionsLanguage]);

  const isPrintDisabled =
    !printMockExam || data?.classroomTestRoundDetail?.students.length === 0;

  return (
    <>
      <Drawer
        key="mock-exams-drawer"
        open={Boolean(isDrawerOpen)}
        onClose={handleDrawerClose}
        roundedBorder
        customStyles={{
          width: '90%',
        }}
      >
        <Container>
          <MockExamsDrawerHeader />
          {isLoading ? (
            <MockExamsDrawerSkeleton />
          ) : isError ? (
            <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />
          ) : (
            <ContentContainer>
              <FlexContainer>
                <InfoContainer>
                  {rowSelected?.rowDescription && (
                    <Description>
                      <InnerHtml
                        text={t(
                          rowSelected?.rowDescription || 'mock-exams-generic-desc'
                        )}
                      />
                    </Description>
                  )}
                  <InfoTagsContainer>
                    <InfoTag
                      text={`${formatDate(
                        detailData?.startDate ?? ''
                      )} - ${formatDate(detailData?.endDate ?? '')}`}
                    />
                    <InfoTag
                      title={t('mock-exams-drawer.info-tag.participation')}
                      text={`${detailData?.participation?.completed} / ${detailData?.participation?.total}`}
                    />
                    <DownloadLink onClick={handleSeeQuestionsClick}>
                      <Typography.Link2>
                        {t('mock-exams-drawer.link.see-statements')}
                      </Typography.Link2>
                      <Icon icon="OpenNewTabIcon" size="S" />
                    </DownloadLink>
                  </InfoTagsContainer>
                </InfoContainer>
                <Button
                  variant="secondary"
                  leftIcon="DownloadIcon"
                  oneLine
                  disabled={isPrintDisabled}
                  onClick={handlePrintMockExam}
                >
                  {t('mock-exams-drawer.generate-button')}
                </Button>
              </FlexContainer>
              <TabsContent>
                {detailData?.students?.length === 0 ? (
                  <EmptyState
                    Image={EmptyReport}
                    title={t('mock-exams-drawer.emptyState.title')}
                    subtitle={t('mock-exams-drawer.emptyState.subtitle')}
                  />
                ) : (
                  <>
                    <TabsContainer>
                      <Tabs tabs={MOCK_EXAMS_TABS} />
                    </TabsContainer>

                    {selectedTab === TABS_ID.STUDENTS && (
                      <MockExamsDrawerTable
                        students={
                          (detailData?.students as MockExamsStudentRow[]) ?? []
                        }
                        numStatements={detailData?.numberOfStatements ?? 0}
                        statementsMetadata={detailData?.statementsMeta}
                      />
                    )}

                    {selectedTab === TABS_ID.DISTRIBUTION && (
                      <MockExamsDistributionChart data={chartData} />
                    )}

                    {selectedTab === TABS_ID.QUESTIONS && (
                      <>
                        {detailData?.statements?.evaluations?.length === 0 ? (
                          <EmptyState
                            Image={EmptyReport}
                            title={t(
                              'mock-exams-drawer.statements-table.emptyState.title'
                            )}
                            subtitle={t(
                              'mock-exams-drawer.statements-table.emptyState.subtitle'
                            )}
                          />
                        ) : (
                          <>
                            <StyledAnnouncementPanel
                              type="warning"
                              text={
                                <Trans
                                  i18nKey="mock-exams-drawer.statements-table.announcement"
                                  components={{
                                    b: <b />,
                                  }}
                                />
                              }
                              canClose={false}
                            />
                            <MockExamsDrawerStatementsTable
                              statements={
                                detailData?.statements?.evaluations ?? []
                              }
                              multipleChoiceLabels={
                                detailData?.statements
                                  ?.multipleChoicePositionLabels ?? []
                              }
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </TabsContent>
            </ContentContainer>
          )}
        </Container>
      </Drawer>
      {testQuestionsAvailableLangs.length > 0 && (
        <DownloadTestQuestionsModal
          isOpen={showDownloadTestQuestionsModal}
          isDownloading={isDownloadingPdf}
          languages={testQuestionsAvailableLangs}
          onClose={handleCloseTestQuestionsModal}
          onDownload={handleTestQuestionsDownload}
        />
      )}
    </>
  );
}
