import { useQueryParams } from '@innovamat/hooks';
import { Stage } from '@innovamat/radiance-utils';
import { useEnvVariables } from '../../env-variables-manager';
import { useCurrentNavigationValues } from '../../navigation/hooks/use-current-navigation-values';
import { useUser } from '../../user-management';
import { usePlatformUrl } from './use-platform-url';
import { useRegionParameters } from './use-region-parameters';
import { useSolverUrl } from './use-solver-url';

export function useAppletInfo() {
  const { tokenInfo, user, isRole } = useUser();
  const { regionParameters } = useRegionParameters();
  const { platformUrl } = usePlatformUrl();
  const { educationalLevel } = useCurrentNavigationValues();
  const { solverUrl } = useSolverUrl();
  const {
    USERS_CLIENT_ID,
    UNITY_BUILD,
    STANDALONE_BUILD_DOMAIN,
    WEBCONTENTS_URL,
  } = useEnvVariables();

  const urlStage = useQueryParams().get('stage');

  const getEducationLevel = (): Stage => {
    if (isRole.Student) return Stage.SECUNDARIA;
    if (urlStage === 'secondary') return Stage.SECUNDARIA;
    return (educationalLevel as Stage) || Stage.PRIMARIA;
  };

  return {
    regionParameters,
    unityBuild: UNITY_BUILD,
    unityStandaloneBuildDomain: STANDALONE_BUILD_DOMAIN,
    educationalLevel: getEducationLevel(),
    refreshToken: tokenInfo?.refresh_token!,
    accessToken: tokenInfo?.access_token!,
    clientID: USERS_CLIENT_ID,
    idToken: tokenInfo?.id_token!,
    platformUrl,
    region: user?.region,
    solverUrl,
    webContentsUrl: WEBCONTENTS_URL,
  };
}
