import { axiosInstance } from '@innovamat/ga-features';
import type { LinkSchool } from './link-school';

export const linkSchool = async (
  { requestId, token }: LinkSchool,
  apiUsers: string
) => {
  const url = `${apiUsers}/school-change-request/${requestId}`;
  const data = { token };
  const response = await axiosInstance({
    url,
    method: 'PATCH',
    data,
  });
  return response;
};
