import { useQueryParams } from '@innovamat/hooks';
import { Button, Input, SelectOption } from '@innovamat/innova-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useParams } from 'react-router-domv6';

import styled from '@emotion/styled';
import { InputPhone } from '@innovamat/glimmer-components';
import {
  getRegisterLanguagesByRegion,
  isValidRegion,
  rootRegionToRegion,
} from '@innovamat/localization';
import { getStandardByRegion, validators } from '@innovamat/radiance-utils';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useUser } from '../../../user-management';
import { SuccessMessage } from '../../components/success-message';
import TwoColumnLayout from '../../components/two-columns-layout/two-columns-layout';
import { useRegisterLead } from '../../hooks/use-register-lead';
import { LeadUser } from '../../hooks/use-register-lead/register-lead';
import { useGetUserRegion } from '../../hooks/use-user-region';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

function LeadRegister(): JSX.Element {
  const { isLoggedIn, onClearUserSession } = useUser();
  const { t } = useTranslation();
  const { lng: language } = useParams<{ lng: string }>();
  const { mutate, isPending, isSuccess, isError, error } = useRegisterLead();

  const query = useQueryParams();
  const crmId = query.get('id');
  const standardParam = query.get('standard');

  const urlRegion = useGetUserRegion();
  const utm_source = query.get('utm_source');
  const utm_medium = query.get('utm_medium');
  const utm_campaign = query.get('utm_campaign');

  const languages = getRegisterLanguagesByRegion(urlRegion);
  const region = rootRegionToRegion(urlRegion);

  const defaultUser = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    region: region,
    preferences: {
      language,
      standardStructure: standardParam,
    },
  };

  const [user, setUser] = useState<LeadUser>({ ...defaultUser, crmId } as any);
  const [phoneDialCode, setPhoneDialCode] = useState('');

  const { firstName, lastName, email, phoneNumber, preferences } = user;

  const getStandard = () => {
    if (standardParam) return standardParam;
    return getStandardByRegion(region);
  };

  const handleRegisterUser = (e: React.FormEvent) => {
    e.preventDefault();

    user.preferences.standardStructure = getStandard();

    if (language && !languages.includes(language)) {
      user.preferences.language = languages[0];
    }

    if (!user.phoneNumber || !isValidPhoneNumber(user.phoneNumber)) {
      user.phoneNumber = null;
    }

    if (!validateInputs()) return;
    const utmInfo = {
      utm_source,
      utm_medium,
      utm_campaign,
    };

    mutate({ user, utmInfo });
  };

  useEffect(() => {
    if (isLoggedIn) {
      onClearUserSession();
    }
  }, [isLoggedIn]);

  const getError = () => {
    if (error?.response?.data?.type === 'email.invalid') {
      return t('teachers.inviteModal.error.invalidEmail', { email });
    }

    switch (error?.response?.status) {
      case 410:
        return t(
          'Ha ocurrido un error al completar el registro. Ponte en contacto con tu asesor.'
        );
      case 406:
        return t(
          'Para poder crear un usuario del Gestor de Aula ponte en contacto con Innovamat llamando al (+34) 93 684 58 87'
        );
      default:
        return t(
          'Ha ocurrido un error al completar el registro. Inténtalo de nuevo.'
        );
    }
  };

  const changeInput = (value: string, key: string, subkey?: string) => {
    if (!subkey) setUser({ ...user, [key]: value });
    else {
      setUser((user: any) => ({
        ...user,
        [key]: {
          ...user[key],
          [subkey]: value,
        },
      }));
    }
  };

  const validateInputs = () =>
    !!firstName.trim() &&
    !!lastName.trim() &&
    validators.isEmailValid(email) &&
    isLangValid() &&
    isValidRegion(region) &&
    validators.isPhoneValid(phoneNumber, phoneDialCode);

  const handleChangePhone = (value: string, country: any) => {
    if (country.dialCode !== phoneDialCode) {
      setPhoneDialCode(country.dialCode);
    }
    setUser((prevState) => ({
      ...prevState,
      phoneNumber: `+${value}`,
    }));
  };

  const isLangValid = () => {
    if (!languages) return false;

    return Object.values(languages)
      .flatMap((lang) => lang)
      .some((lang) => lang === preferences.language);
  };

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const langOptions = languages?.map((lang) => ({
    value: lang,
    label: t(`language.${lang}`),
    key: lang,
  }));

  return (
    <TwoColumnLayout
      isSuccess={isSuccess}
      title={t('LeadRegister.title')}
      onSuccessBody={
        <SuccessMessage
          title={t(
            'Hemos mandado un correo a la dirección indicada en el que encontrarás un enlace para generar tu contraseña y finalizar el registro.'
          )}
          body={
            <>
              <span>{t('Si no lo recibes revisa la carpeta de spam.')} </span>
              <span>
                {t('Muchas gracias.')}
                <br />
                {t('El equipo Innovamat.')}
              </span>
            </>
          }
        />
      }
      error={isError ? getError() : undefined}
    >
      <Form onSubmit={handleRegisterUser}>
        <Input
          label={t('Settings.Name')}
          value={firstName}
          onChange={(e) => changeInput(e.target.value, 'firstName')}
        />
        <Input
          label={t('Settings.Surname')}
          value={lastName}
          onChange={(e) => changeInput(e.target.value, 'lastName')}
        />
        <Input
          label={t('Settings.Email')}
          value={email}
          onChange={(e) => changeInput(e.target.value, 'email')}
          error={
            email && !validators.isEmailValid(email)
              ? {
                  message: t('LeadRegister.email.error'),
                }
              : undefined
          }
        />
        <InputPhone
          country={urlRegion.toLocaleLowerCase()}
          label={t('teacher.onboarding.createProfileFilled.field.phone')}
          phone={phoneNumber || ''}
          onChange={handleChangePhone}
          error={!validators.isPhoneValid(phoneNumber, phoneDialCode)}
          description={
            !validators.isPhoneValid
              ? t('teacher.onboarding.createProfileFilled.phoneNumber.error')
              : t('teacher.onboarding.createProfileFilled.phoneNumber.tagline')
          }
          optionalText={t('input.optional')}
        />

        <SelectOption
          value={preferences.language}
          options={langOptions}
          label={t(
            'teacher.onboarding.createProfileFilled.field.platformLanguage'
          )}
          onChange={(opt) => changeInput(opt.value, 'preferences', 'language')}
        />
        <Button
          fill
          onClick={handleRegisterUser}
          disabled={!validateInputs()}
          loading={isPending}
        >
          {t('LandingRegister.Enviar')}
        </Button>
      </Form>
    </TwoColumnLayout>
  );
}

export { LeadRegister };
