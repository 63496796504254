import { Dropdown, Icon } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import {
  CountryLanguage,
  CountryLanguageTexts,
  CountrySelected,
  Language,
} from './language-button.styles';

type LanguageButtonProps = {
  language: string;
  isMobile?: boolean;
  handleLanguageClick: (languageLocale: string) => Promise<void>;
};

function LanguageButton({
  language,
  handleLanguageClick,
}: LanguageButtonProps) {
  const { t } = useTranslation();

  const languageOptions = [
    { code: 'es', testId: 'language-option-es', label: t('language.es') },
    { code: 'ca', testId: 'language-option-ca', label: t('language.ca') },
    { code: 'va', testId: 'language-option-va', label: t('language.va') },
    { code: 'eu', testId: 'language-option-eu', label: t('language.eu') },
    { code: 'en', testId: 'language-option-en', label: t('language.en') },
    {
      code: 'it_IT',
      testId: 'language-option-it_IT',
      label: t('language.it_IT'),
    },
  ];

  return (
    <Dropdown closeOnSelectItem>
      <Dropdown.Toggle>
        <CountryLanguage data-testid="country-selector">
          <CountrySelected>
            <Icon size="S" icon="LanguageIcon" />
            <CountryLanguageTexts>
              <Language>{language}</Language>
              <Icon size="S" icon="ArrangeIcon" />
            </CountryLanguageTexts>
          </CountrySelected>
        </CountryLanguage>
      </Dropdown.Toggle>
      <Dropdown.Content position="bottomLeft">
        {languageOptions.map((option) => (
          <Dropdown.Item
            key={option.code}
            state="active"
            dataTestId={option.testId}
            onSelectItem={() => handleLanguageClick(option.code)}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}

export default LanguageButton;
