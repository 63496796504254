import styled from '@emotion/styled';
import { MaintenanceImg } from '@innovamat/glimmer-assets';
import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { Container, LeftWrapper } from '../layout/header';
import { Logo } from './logo';

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin: 0 30%;
  height: calc(100% - 64px);

  img {
    width: 264px;
    height: auto;
  }

  > div {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
`;

const Description = styled(Typography.Body1)`
  color: ${({ theme }) => theme.tokens.color.global.neutral.neutral400.value};
`;

const Wrapper = styled.div`
  height: 100vh;
`;

export function Maintenance() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <LeftWrapper>
          <Logo onClickGoHome={false} />
        </LeftWrapper>
      </Container>
      <Content>
        <div>
          <Typography.H1>
            {t(
              'Maintenance.Lo sentimos, estamos fuera de servicio por mantenimiento'
            )}
          </Typography.H1>
          <Description>{t('Maintenance.¡Volvemos enseguida!')}</Description>
        </div>
        <img src={MaintenanceImg.src} />
      </Content>
    </Wrapper>
  );
}
