import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { CardSelectStatus } from './card-select';

export const CardWrapper = styled.div<{
  status?: CardSelectStatus;
}>`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  padding: 12px 16px;
  border-radius: 4px;
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: ${(props) =>
    props.theme.tokens.color.alias.cm.surface['surface-primary'].value};

  ${({ status, theme, onClick }) =>
    status === 'selected'
      ? css`
          box-shadow: inset 0px 0px 0px 2px
            ${theme.tokens.color.alias.cm.border['border-accent'].value};

          ${onClick &&
          css`
            &:hover {
              cursor: pointer;
              box-shadow: inset 0px 0px 0px 2.5px
                ${theme.tokens.color.alias.cm.border['border-accent'].value};
            }
          `}
        `
      : css`
          box-shadow: inset 0px 0px 0px 1px
            ${theme.tokens.color.alias.cm.border['border-subtle'].value};

          ${onClick &&
          status !== 'disabled' &&
          css`
            &:hover {
              cursor: pointer;
              box-shadow: inset 0px 0px 0px 2px
                ${theme.tokens.color.alias.cm.border['border-subtle'].value};
            }
          `}
        `}

  ${({ status, theme }) =>
    status === 'disabled' &&
    css`
      .avatar-card-state-layer {
        background-color: ${theme.tokens.color.alias.cm.bg['bg-neutral-subtle']
          .value};
        opacity: 0.5;
        border-radius: 4px;
      }

      &:active {
        pointer-events: none;
      }
    `}
`;
