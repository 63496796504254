import { useStudentsCsvQuery } from '@innovamat/glow-api-client';
import { base64toCSVFile } from '@innovamat/radiance-utils';
import { useState } from 'react';
import { useOrganization } from './use-organization';

type DownloadStudentsCsv = {
  onDownload: () => void;
  isLoading: boolean;
};

function useDownloadStudentsCsv(): DownloadStudentsCsv {
  const { organization } = useOrganization();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { refetch } = useStudentsCsvQuery(
    {},
    {
      enabled: false,
      retry: false,
    }
  );

  const onDownload = (): void => {
    if (!organization) return;
    setIsLoading(true);
    refetch().then((response) => {
      if (response.error) {
        setIsLoading(false);
        return;
      }
      const data = response.data?.studentsCSV?.base64;
      if (data) {
        base64toCSVFile({ base64: data, name: 'students' });
      }
      setIsLoading(false);
    });
  };

  return {
    onDownload,
    isLoading,
  };
}

export { useDownloadStudentsCsv };
