import { Dimensions } from '../types/Dimension';
import type { WhatToObserveItem } from '../types/SmartGuide';
import { mapMedia } from './mapMedia';

export function mapWhatToObserve(
  data: any,
  regionParameters: any,
  locale: string
): WhatToObserveItem[] {
  const problemResolution: WhatToObserveItem = {
    content: data.problem_resolution,
    media: mapMedia(
      data.media1,
      data.ancho_de_imagen_1,
      regionParameters,
      locale
    ),
    dimension: Dimensions.PROBLEM,
    text: 'digitalguides.dimensions.problem_solving',
  };
  const reasoningAndTest: WhatToObserveItem = {
    content: data.reasoning_and_test,
    media: mapMedia(
      data.media2,
      data.ancho_de_imagen_2,
      regionParameters,
      locale
    ),
    dimension: Dimensions.REASONING,
    text: 'digitalguides.dimensions.reasoning_and_proof',
  };
  const connections: WhatToObserveItem = {
    content: data.connections,
    media: mapMedia(
      data.media3,
      data.ancho_de_imagen_3,
      regionParameters,
      locale
    ),
    dimension: Dimensions.CONNECTIONS,
    text: 'digitalguides.dimensions.connections',
  };
  const communicationAndRepresentation: WhatToObserveItem = {
    content: data.communication_and_representation,
    media: mapMedia(
      data.media4,
      data.ancho_de_imagen_4,
      regionParameters,
      locale
    ),
    dimension: Dimensions.COMMUNICATION,
    text: 'digitalguides.dimensions.communication_and_representation',
  };

  const evaluationContents: WhatToObserveItem = {
    content: data.evaluacion_de_contenidos,
    dimension: undefined,
    text: 'session.assessment.contents',
    media: mapMedia(
      data.media5,
      data.ancho_de_imagen_5,
      regionParameters,
      locale
    ),
  };

  return [
    problemResolution,
    reasoningAndTest,
    connections,
    communicationAndRepresentation,
    evaluationContents,
  ];
}
