import { useEventLogging } from '@innovamat/event-logging';
import {
  SessionDoneProvider,
  UnblockedAppletsDrawerProvider,
  useCurrentNavigationValues,
  useEnvVariables,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import { useClassroomsQuery } from '@innovamat/glow-api-client';
import type { Classroom } from '@innovamat/radiance-utils';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';

type Props = {
  children: React.ReactNode;
};

export function LessonsProviderWrapper({ children }: Props): JSX.Element {
  const { INTEGROMAT_SESSION_REVIEW, INTEGROMAT_SESSION_ERROR } =
    useEnvVariables();
  const { t } = useTranslation();
  const { setEventData } = useEventLogging();
  const { classroomId } = useParams<{ classroomId: string }>();
  const { goToResource } = useNavigation();
  const { isInWebview } = useWebviewBridge();
  const { isRole } = useUser();
  const { currentClassroom } = useCurrentNavigationValues();

  const { user } = useUser();
  const { data: classroomsQuery } = useClassroomsQuery(
    { mine: true, orgId: user?.organizationId! },
    {
      enabled: Boolean(user?.organizationId) && !isRole.Student,
      staleTime: Infinity,
    }
  );

  const handleGoToApplet = (appletId: string) => {
    if (isInWebview) return;
    goToResource({
      type: 'applet',
      resourceId: appletId,
      openInNewTab: true,
    });
  };

  return (
    <SessionDoneProvider
      academicYearId={user?.organization?.academicYearId!}
      classroomId={classroomId}
      courseOrder={currentClassroom?.courseOrder!}
      region={user?.region ?? ''}
      locale={user?.locale ?? ''}
      classrooms={classroomsQuery?.classrooms as Classroom[] | undefined}
      courseId={currentClassroom?.courseId!}
      email={user?.email!}
      t={t}
      setEventData={setEventData}
      userRoles={user?.roles ?? []}
      onGoToApplet={handleGoToApplet}
      webhooksInfo={{
        INTEGROMAT_ERROR_WEBHOOK_URL: INTEGROMAT_SESSION_ERROR,
        INTEGROMAT_REVIEW_WEBHOOK_URL: INTEGROMAT_SESSION_REVIEW,
        courseName: currentClassroom?.courseName!,
        lastName: user?.familyName ?? '',
        name: user?.name ?? '',
        organizationId: user?.organizationId ?? '',
        organizationName: user?.organizationName ?? '',
      }}
    >
      <UnblockedAppletsDrawerProvider>
        {children}
      </UnblockedAppletsDrawerProvider>
    </SessionDoneProvider>
  );
}
