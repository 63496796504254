import styled from '@emotion/styled';
import { Icon } from '../icon';

const PlayIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.element['thumbnail-tag'].value};

  svg,
  path {
    fill: ${({ theme }) =>
      theme.tokens.color.alias.cm.icon['icon-inverted'].value};
  }
`;

const PlayIcon = styled(Icon)`
  transform: translateX(2px);
`;

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 2;
`;

const Progress = styled.div<{ progress: string }>`
  width: ${({ progress }) => `${progress}%`};
  height: 4px;
  background: ${({ theme }) =>
    theme.tokens.color.specific.element['video-bar'].value};
`;

const Bar = styled.div<{ progress: string }>`
  width: ${({ progress }) => `${progress}%`};
  height: 4px;
  background: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-neutral-subtle'].value};
`;

const DurationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4px 8px;
  z-index: 2;
  margin: 8px;
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.element['thumbnail-tag'].value};
  border-radius: 5px;

  span {
    display: block;
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-inverted'].value};
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
  }
`;

type Props = {
  progress: number;
  duration: number;
};

export function ResourceCardVideo({ progress, duration }: Props): JSX.Element {
  const hours = Math.floor(duration / 3600);
  const remainedSeconds = duration - hours * 3600;
  const minutes = Math.floor(remainedSeconds / 60);
  const seconds = remainedSeconds - minutes * 60;

  const parseDuration = (timeToParse: string): string => {
    return timeToParse.length === 1 ? `0${timeToParse}` : timeToParse;
  };
  return (
    <>
      <PlayIconWrapper>
        <PlayIcon icon="PlayIcon" size="L" />
      </PlayIconWrapper>
      {progress > 0 && (
        <Wrapper data-testid="progressBar">
          <Progress progress={progress.toString()} />
          <Bar progress={(100 - progress).toString()} />
        </Wrapper>
      )}
      {duration && (
        <DurationWrapper data-testid="videoDuration">
          <span>
            {hours !== 0 && `${hours}:`}
            {parseDuration(minutes.toString())}:
            {parseDuration(seconds.toString())}
          </span>
        </DurationWrapper>
      )}
    </>
  );
}
