import styled from '@emotion/styled';
import { StateLayer } from '../../utils/common.styled';
import { getElevation } from '../elevation';

const ImageContainer = styled.div`
  position: relative;
  /* overflow: hidden; */
  border-radius: 4px;
  max-width: 100%;
  display: flex;

  .image {
    aspect-ratio: 18/10;
    object-fit: cover;
    object-position: top;
    border-radius: 4px;
  }
`;

const CardContainer = styled.div<{
  onClick?: () => void;
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  :hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }

  :hover .img-container {
    ${({ theme, onClick }) =>
      onClick ? getElevation(theme, 'elevation 4') : ''};
  }

  :hover .description,
  :hover .activities-link {
    opacity: 1;
  }

  .img-container,
  .description,
  .activities-link {
    transition: all 0.1s linear;
  }
`;

const NumberWrapper = styled.div`
  z-index: 1;
  width: 32px;
  height: 32px;
  background-color: white;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.dark['02']};
`;

const DarkLayer = styled(StateLayer)`
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.element['thumnail-locked'].value};
  border-radius: 4px;

  z-index: 1;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  border-radius: 0 4px 0 0;

  transition: all 0.1s ease-in-out;
`;

export {
  CardContainer,
  CheckboxContainer,
  DarkLayer,
  ImageContainer,
  NumberWrapper,
};
