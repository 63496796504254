import { type UseQueryResult, useQuery } from '@tanstack/react-query';

import { snack } from '@innovamat/glimmer-components';
import { type Region, getLocalesForPrepr } from '@innovamat/localization';
import type { Option, Session } from '@innovamat/resource-viewer';
import {
  type DigitalGuide,
  type Printable,
  type SmartGuideType,
  type SpaceSession,
  earlyYearClassroomLifeDigitalGuideMapper,
  earlyYearDigitalGuideMapper,
  getData,
  getModel,
  getSmartGuide,
  mapResponseSpace,
  secondaryDigitalGuideMapper,
} from '@innovamat/smart-guides';
import { useEffect } from 'react';
import { useEnvVariables } from '../../env-variables-manager';
import { useUser } from '../../user-management';
import { useRegionParameters } from './use-region-parameters';
import { useStandard } from './use-standard';

type Props = {
  id: string;
  locale: string | undefined;
  enabled: boolean;
  session: Session;
  region: Region;
};

const mapPrintables = (resourcePrintables: Option[]): Printable[] => {
  return resourcePrintables.map((printable) => {
    return {
      url: printable.href,
      value: printable.label,
      isDisabled: printable.isDisabled,
    } as Printable;
  });
};

const BASE_FIELDS = 'items{variations{cdn_files},generic_image{cdn_files}}';

export type PreprResponse =
  | DigitalGuide
  | SpaceSession
  | SmartGuideType
  | undefined;

export default function useGetPreprResource({
  id,
  locale,
  enabled,
  session,
  region,
}: Props): UseQueryResult<PreprResponse> {
  const { user } = useUser();
  const { regionParameters } = useRegionParameters();
  const { PREPR_GRAPHQL: graphEndpoint } = useEnvVariables();
  const { standard } = useStandard();

  const key = ['locale', id, locale];

  const query = useQuery<PreprResponse, Error>({
    queryKey: key,
    queryFn: async () => {
      const model = await getModel(id);
      const locales = getLocalesForPrepr(region, locale!);

      if (
        model === 'SmartGuidePrimaria' ||
        model === 'SmartGuideTaller' ||
        model === 'SmartGuideSecundaria' ||
        model === 'SmartGuideOpen'
      ) {
        const printables = mapPrintables(session.printables);

        return getSmartGuide({
          graphEndpoint,
          locales,
          id,
          model,
          printables,
          regionParameters,
          assessables: session.assessableLists,
          userId: user?.id,
          standardContent: session.standardContent,
          mathPractices: session.mathPractices,
          bigIdeas: session.bigIdeas,
          orgStandard: standard ?? '',
        });
      }

      const response = await getData(id, BASE_FIELDS);

      switch (model) {
        case 'EarlyYearsSpace': {
          return await earlyYearDigitalGuideMapper(
            response,
            locale!,
            region!,
            session.standardContent,
            session.mathPractices,
            session.bigIdeas,
            standard ?? ''
          );
        }
        case 'EarlyYearsClassroomLife': {
          return await earlyYearClassroomLifeDigitalGuideMapper(
            response,
            locale!,
            region!,
            session.standardContent,
            session.mathPractices,
            session.bigIdeas,
            standard ?? ''
          );
        }
        case 'EarlyYearsAllSpace': {
          return await mapResponseSpace(response, locale!, region!);
        }
        case 'SecondaryEducationGuide': {
          return await secondaryDigitalGuideMapper(response, locale!, region!);
        }
        default:
          return undefined;
      }
    },
    enabled,
    gcTime: 0,
    retry: false,
  });

  useEffect(() => {
    if (query.isError) {
      snack.error(query.error?.message);
    }
  }, [query.isError]);

  return query;
}
