import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '@innovamat/innova-components';

export const MIN_HEIGHT = 56;
export const MAX_HEIGHT = 196;

const Sticky = styled.header`
  width: 100%;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark['05']};
  color: ${({ theme }) => theme.colors.dark['01']};
  .typeformButton {
    all: unset;
  }
`;

const InnerWrapper = styled.div<{ hasTabs: boolean; isSticky?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  max-width: 1440px;
  ${({ theme, hasTabs, isSticky }) => {
    let basePadding = '8px 24px 8px 14px';
    let lgPadding = '8px 14px';
    let smPadding = '8px 6px';

    if (hasTabs && isSticky) {
      basePadding = '8px 50px 8px 50px';
    } else if (hasTabs) {
      basePadding = '8px 50px 0 50px';
    }

    if (isSticky) {
      lgPadding = '8px 14px';
      smPadding = '8px 6px';
    } else if (hasTabs) {
      lgPadding = '8px 14px 0 14px';
      smPadding = '8px 6px 0 6px';
    }

    return `
    padding: ${basePadding};

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: ${lgPadding};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: ${smPadding};
    }
  `;
  }};
`;

const DefaultHeader = styled.div<{ isSticky?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ isSticky, theme }) =>
    !isSticky &&
    css`
      @media (max-width: ${theme.breakpoints.lg}) {
        padding-bottom: 16px;
      }
    `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderTitleWrapper = styled.div<{
  isSticky: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    cursor: default;
  }

  ${({ isSticky }) =>
    isSticky
      ? css`
          h3 {
            overflow: hidden;
          }
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 8px;
          }
        `
      : css`
          div {
            white-space: wrap;
          }
        `}
`;

const RightElementsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FullScreenIcon = styled(Icon)`
  cursor: pointer;
`;

const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

const PrintablesWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  margin-right: -2px;
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .buttonText {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-right: 0px;
  }
`;

const NonBreakablePrintablesWrapper = styled(PrintablesWrapper)`
  .buttonText {
    min-width: max-content;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .buttonText {
      display: block;
    }
  }
`;

const ExpandableContent = styled.div<{
  isExpanded: boolean;
  hasBackButton: boolean;
}>`
  padding-left: ${({ hasBackButton }) => (hasBackButton ? '10px;' : '0px')};
  max-height: 0px;
  opacity: 0;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      opacity: 1;
      max-height: 500px; //big number to make sure it's always bigger than the content
    `}

  transition: all 0.3s ease;
`;

const ExpandableContentInnerWrapper = styled.div<{ isExpanded: boolean }>`
  pointer-events: ${({ isExpanded }) =>
    isExpanded
      ? 'auto'
      : 'none'}; //to avoid clicking on the content when it's collapsed
`;

const Description = styled.div<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 100%;
  }
`;

const SecondRowHeader = styled.div`
  width: 100%;
  display: flex;
  padding-top: 8px;
  align-items: center;
`;

export {
  Sticky,
  HeaderWrapper,
  InnerWrapper,
  DefaultHeader,
  TitleWrapper,
  HeaderTitleWrapper,
  RightElementsWrapper,
  ActionsWrapper,
  FullScreenIcon,
  Divider,
  RowWrapper,
  PrintablesWrapper,
  NonBreakablePrintablesWrapper,
  ExpandableContent,
  Description,
  ExpandableContentInnerWrapper,
  SecondRowHeader,
};
