import type { PartVideo } from '../types/SmartGuide';
import { GuideType, type Part, PartType } from '../types/SmartGuide';
import { mapContentBlock } from './mapContentBlock';
import { mapMedia } from './mapMedia';
import { mapPartSteps } from './mapPartSteps';
import { mapSteps } from './mapSteps';
import { mapVideoThemes } from './mapVideoThemes';

type GroupPartsParams = {
  data: any;
  modelKey: string;
  guideType: string;
  regionParameters: any;
  isCaliforniaStandard: boolean;
  locale: string;
};

type MapPartParams = {
  partData: any;
  modelKey: string;
  guideType: string;
  guideTypeNumber: GuideType;
  regionParameters: any;
  isCaliforniaStandard: boolean;
  locale: string;
};

export function groupParts({
  data,
  modelKey,
  guideType,
  regionParameters,
  isCaliforniaStandard,
  locale,
}: GroupPartsParams): (Part & PartVideo)[] {
  const dataParts = data.partes || data.parte;

  let videoPartData: any;
  const numberDataParts = dataParts.filter((part: any) => {
    if (part['video_chapter_content']) {
      videoPartData = part;
      return false;
    }
    return true;
  });

  let activityNumber = 1;
  const parts = numberDataParts.map((partData: any, index: number) => {
    const part = mapPart({
      partData,
      modelKey,
      guideType,
      guideTypeNumber: data.tipo,
      regionParameters,
      isCaliforniaStandard,
      locale,
    });

    if (modelKey === 'pe' || modelKey === 'ey') {
      if (part.partNumber === 2) {
        part.activityNumber = activityNumber++;
      }
    }

    if (modelKey === 'se') {
      part.activityNumber = index + 1;
    }

    return part;
  });

  if (videoPartData) {
    const videoPart = mapVideoPart(videoPartData);
    parts.unshift(videoPart);
  }

  const hasBeforeStart = !!data.antes_de_comenzar?.explicacin;

  if (hasBeforeStart) {
    const beforeStartPart: Part = {
      id: 'beforeStartPart',
      partNumber: PartType.BeforeStart,
      title: 'digitalguides.before_start.title',
      oneLineSummary: 'digitalguides.before_start.one_line',
      beforeStartContent: {
        content: data.antes_de_comenzar.explicacin,
        media: mapMedia(
          data.antes_de_comenzar.media,
          data.antes_de_comenzar.ancho_de_imagen,
          regionParameters,
          locale
        ),
      },
    };
    parts.unshift(beforeStartPart);
  }

  return parts;
}

function mapVideoPart(data: any) {
  return {
    id: 'smartGuidePartVideo',
    chapterName: data.video_series_name.seriename,
    chapterDuration: data.video_series_name.chapter_duration,
    title: data.title,
    should_translate: data.should_translate,

    videoChapterContent: data.video_chapter_content.map(
      (chapterContent: any) => {
        return {
          key: mapVideoThemes(chapterContent.themes).key,
          icon: mapVideoThemes(chapterContent.themes).icon,
          content: chapterContent.content,
        };
      }
    ),
  };
}

export function mapPart({
  partData,
  modelKey,
  guideType,
  guideTypeNumber,
  regionParameters,
  isCaliforniaStandard,
  locale,
}: MapPartParams): Part {
  const steps = mapPartSteps(partData.paso);

  let partNumber = partData.__typename.substring(
    partData.__typename.length - 1,
    partData.__typename.length
  );

  const isFourInOne = guideTypeNumber === GuideType.FourInOne;

  if (isFourInOne) {
    partNumber = 2;
  }

  const getPartTitle = (
    partNumber: string,
    modelKey: string,
    guideType: string
  ): string => {
    switch (modelKey) {
      case 'ey':
        return `digitalguides.ey.moment.title.${partNumber}`;
      case 'pe':
        return `digitalguides.pe.${guideType}.moment.title.${partNumber}`;
      case 'se':
        return `digitalguides.se.moment.title`;
      default:
        return `digitalguides.se.moment.title`;
    }
  };

  const part: Part = {
    id: partData._id,
    title: getPartTitle(partNumber, modelKey, guideType),
    evaluable: {
      dimension: +partData.dimensin_de_evaluacin?.dimension_list,
      isEvaluable: partData.actividad_evaluable,
      linkTo: partData.enlace_al_evaluable,
      tooltipText: partData.nombre_del_evaluable,
    },
    partNumber: +partNumber,
    oneLineSummary: partData.resumen_en_una_lnea,
    contentBlocks: modelKey === 'ey' ? mapContentBlock(partData) : undefined,
    steps: mapSteps(
      steps,
      partNumber,
      regionParameters,
      isCaliforniaStandard,
      locale
    ),
    activityNumber: null,
    keyMoment: partData.momento_clave,
    partIcon: isFourInOne ? 'Part_4in1GuidesIcon' : undefined,
    hasExtension: partData.has_extension,
    hasSupport: partData.has_support,
    hasSupportLink: partData.has_support_player_id,
    hasExtensionLink: partData.has_extension_player_id,
  };

  return part;
}
