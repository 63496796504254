import styled from '@emotion/styled';

import { Typography } from '@innovamat/glimmer-components';

import { useRegionalNumber } from '@innovamat/ga-features';
import type { SemanticColorToken } from '../../utils';
import { EMPTY_CELL_VALUE, getColorToken } from '../../utils';

type Props = {
  score?: number | null;
  decimalPlaces?: number;
  isPercentage?: boolean;
  fitContentHeight?: boolean;
};

const Container = styled.div<{
  colorToken: string;
  fitContentHeight?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, colorToken }) =>
    theme.tokens.color.specific.reports.semantic[
      colorToken as SemanticColorToken
    ].value};
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-inverted'].value};
  border-radius: 4px;
  width: 64px;
  height: ${({ fitContentHeight }) =>
    fitContentHeight ? 'fit-content' : '32px'};
  margin: 0 auto;
`;

function ScoreCell({
  score,
  decimalPlaces = 2,
  isPercentage,
  fitContentHeight,
}: Props): JSX.Element {
  const colorToken = getColorToken(score);
  const regionalNumber = useRegionalNumber({
    number: score,
    fixedTo: decimalPlaces,
  });

  return (
    <Container colorToken={colorToken} fitContentHeight={fitContentHeight}>
      <Typography.Subtitle1>
        {regionalNumber ? regionalNumber : EMPTY_CELL_VALUE}
      </Typography.Subtitle1>
      {!!regionalNumber && isPercentage && (
        <Typography.SuperTitle>{'%'}</Typography.SuperTitle>
      )}
    </Container>
  );
}

export { ScoreCell };
