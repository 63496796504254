// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fwEHQxFKttwHBrb3{position:relative}.acqQSeqD1xMX4t1L{opacity:0;z-index:1;border-radius:100px;width:48px;height:24px}.acqQSeqD1xMX4t1L:checked~.wjET_VCd8sYqvMwO{background:#14a395}.acqQSeqD1xMX4t1L:checked~.wjET_VCd8sYqvMwO::after{content:\"\";display:block;border-radius:50%;width:20px;height:20px;margin-left:26px;transition:.2s}.acqQSeqD1xMX4t1L:disabled~.wjET_VCd8sYqvMwO{background:#e5e5e5;cursor:default}.acqQSeqD1xMX4t1L:checked:disabled~.wjET_VCd8sYqvMwO{background:#d9f0ee}.wjET_VCd8sYqvMwO{position:absolute;top:0;left:0;width:48px;height:24px;border-radius:100px;background:#bebebe;cursor:pointer}.wjET_VCd8sYqvMwO::after{content:\"\";display:block;border-radius:50%;width:20px;height:20px;margin:2px;background:#fff;transition:.2s}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/ToggleButton/ToggleButton.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,iBAAA,CAGF,kBACE,SAAA,CACA,SAAA,CACA,mBAAA,CACA,UAAA,CACA,WAAA,CAEA,4CACE,kBCZM,CDcN,mDACE,UAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,cAAA,CAGJ,6CACE,kBClBK,CDmBL,cAAA,CAEF,qDACE,kBCTU,CDad,kBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CACA,kBAAA,CACA,cAAA,CAEA,yBACE,UAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,eAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleContainer": "fwEHQxFKttwHBrb3",
	"toggleInput": "acqQSeqD1xMX4t1L",
	"toggleLabel": "wjET_VCd8sYqvMwO"
};
export default ___CSS_LOADER_EXPORT___;
