import { axiosInstance } from '@innovamat/ga-features';
import type { InvitationType, UserInvitation } from './invitation';

const completeUserInvitation = async (
  userInvitation: UserInvitation,
  apiUsers: string
) => {
  const url = `${apiUsers}/complete-user-invitation`;
  const response = await axiosInstance({
    url,
    method: 'POST',
    data: { ...userInvitation },
  });
  return response;
};

const completeParentInvitation = async (
  parentInvitation: UserInvitation,
  apiUsers: string
) => {
  const url = `${apiUsers}/complete-parent-invitation`;
  const response = await axiosInstance({
    url,
    method: 'POST',
    data: { ...parentInvitation },
  });
  return response;
};

export const completeInvitation = async (
  body: UserInvitation,
  type: InvitationType,
  apiUsers: string
) => {
  if (type === 'parent') {
    await completeParentInvitation(body, apiUsers);
  } else {
    await completeUserInvitation(body, apiUsers);
  }
};
