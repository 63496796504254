import {
  LineSeparator,
  Tooltip,
  Typography,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { getFixedLocalizedNumber } from '../../../utils/common';
import { dates } from '@innovamat/radiance-utils';
import styled from '@emotion/styled';
import {
  InterventionReportWeeks,
  StudentInterventionWeeks,
} from '@innovamat/glow-api-client';
import { TFunction } from 'i18next';
import { FlexContainerCentered, StyledP } from '../../../utils/render-rows';

export const EllipsableText = styled(Typography.Body2)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompletedContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledSubtitle2 = styled(Typography.Subtitle2)`
  margin-left: 4px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const BarContainer = styled.div`
  display: flex;
  width: 45px;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
`;

const BarFragment = styled.div<{ color: string }>`
  flex-grow: 1;
  background-color: ${({ color }) => color};
  margin: 0 1px;

  &:first-child {
    margin-left: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  &:last-child {
    margin-right: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
`;

const WeekSublabel = styled(Typography.Body3)<{
  isBoldSublabel: boolean;
}>`
  width: 45px;
  height: 16px;
  font-weight: ${({ isBoldSublabel }) => (isBoldSublabel ? 'bold' : 'normal')};
  color: ${({ isBoldSublabel, theme }) =>
    isBoldSublabel
      ? theme.tokens.color.alias.cm.text.text.value
      : theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const renderEllipsableText = (
  value: string,
  dataTestId: string
): JSX.Element => {
  return (
    <EllipsableText data-testid={dataTestId}>
      <p>{value}</p>
    </EllipsableText>
  );
};

const renderCurrentAndInitialNumbers = (
  current: number | null,
  initial: number | null,
  dataTestId: string
): JSX.Element => {
  return (
    <FlexContainerCentered data-testid={dataTestId}>
      <StyledP>
        {current !== null ? getFixedLocalizedNumber(current) : '-'}
      </StyledP>
      <StyledSubtitle2>{`(${
        initial !== null ? getFixedLocalizedNumber(initial) : '-'
      })`}</StyledSubtitle2>
    </FlexContainerCentered>
  );
};

const renderCompletedSessionsSublabels = (
  weeks: InterventionReportWeeks,
  t: TFunction<'translation', undefined, 'translation'>,
  currentWeekNumber: number
): JSX.Element => {
  return (
    <CompletedContainer>
      {weeks.interventionWeeks.map((week, index) => {
        const isBoldSublabel = index + 1 === currentWeekNumber;
        return (
          <Tooltip
            content={
              <div>{`
              ${dates.getFormattedDayMonthDate(
                week.startDate
              )} - ${dates.getFormattedDayMonthDate(week.endDate)}`}</div>
            }
            popperOptions={{ strategy: 'fixed' }}
          >
            <WeekSublabel key={index} isBoldSublabel={isBoldSublabel}>
              {t('reports.catchup-up.subtitle.numberOfTheWeek', {
                numOfTheWeek: index + 1,
              })}
            </WeekSublabel>
          </Tooltip>
        );
      })}
    </CompletedContainer>
  );
};

const CompletedSessions = ({
  completed,
  total,
  notYet,
  id,
  lastWeekInIntervention,
  studentId,
  numberOfTheWeek,
}: {
  completed: number;
  total: number;
  notYet: boolean;
  id: string;
  lastWeekInIntervention: number;
  studentId: number;
  numberOfTheWeek: number;
}): JSX.Element => {
  const showWarning = completed === 0;
  const theme = useGlimmerTheme();

  const getColor = (index: number): string => {
    const { semantic } = theme.tokens.color.specific.reports;
    if (!completed || index > completed) {
      return semantic['no-data'].value;
    }
    const percentage = (completed / total) * 100;
    switch (true) {
      case percentage <= 25:
        return semantic.low.value;
      case percentage <= 50:
        return semantic.medium.value;
      case percentage <= 75:
        return semantic.high.value;
      case percentage <= 100:
        return semantic['super-high'].value;
      default:
        return semantic['no-data'].value;
    }
  };

  const isNotLongerInIntervention = !!lastWeekInIntervention as boolean;
  const prefixDataTestId = isNotLongerInIntervention
    ? 'INTER_DEATABLE'
    : 'INTER_TABLE';

  return (
    <ColumnContainer id={id}>
      <FlexContainerCentered
        isError={showWarning}
        isDisabled={notYet}
        data-testid={`${prefixDataTestId}_WEEKLYFRACT_W${numberOfTheWeek}_S${studentId}`}
      >
        <StyledP>{`${notYet ? '-' : completed}/`}</StyledP>
        <Typography.Subtitle2>{total}</Typography.Subtitle2>
      </FlexContainerCentered>
      <FlexContainerCentered>
        <BarContainer
          data-testid={`${prefixDataTestId}_BARS_W${numberOfTheWeek}_S${studentId}`}
        >
          <BarFragment color={getColor(1)} />
          <BarFragment color={getColor(2)} />
          <BarFragment color={getColor(3)} />
          <BarFragment color={getColor(4)} />
        </BarContainer>
      </FlexContainerCentered>
    </ColumnContainer>
  );
};

const renderCompletedSession = (
  weeks: StudentInterventionWeeks[],
  currentWeek: number,
  lastWeekInIntervention: number,
  studentId: number
): JSX.Element => {
  return (
    <CompletedContainer id="completed-sessions-column">
      {weeks.map((week: StudentInterventionWeeks, index) => {
        const { completedInterventionSessions, availableInterventionSessions } =
          week;
        const isCurrentWeek = index + 1 === currentWeek;
        const weekId = isCurrentWeek
          ? `current-week-${index + 1}`
          : `week-${index + 1}`;
        if (lastWeekInIntervention && index + 1 > lastWeekInIntervention)
          return <></>;
        return (
          <CompletedSessions
            completed={completedInterventionSessions}
            total={availableInterventionSessions}
            notYet={index + 1 > currentWeek}
            id={`${weekId}`}
            lastWeekInIntervention={lastWeekInIntervention}
            studentId={studentId}
            numberOfTheWeek={index + 1}
          />
        );
      })}
    </CompletedContainer>
  );
};

export {
  renderEllipsableText,
  renderCurrentAndInitialNumbers,
  renderCompletedSessionsSublabels,
  renderCompletedSession,
};
