import styled from '@emotion/styled';
import type { ThemeType } from '../../theme';
import { normalizeImgSource } from '../../utils/normalize-image-source';
import { Button } from '../button';
import { IconButton } from '../icon-button';
import { Typography } from '../typography';
import { Grid } from '../grid';
import { ImageComponent } from '../image-component';
import { InnerHtml } from '../inner-html';

const Body = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    align-self: center;
  }

  display: flex;
  gap: 32px;
  flex: 1;

  > button {
    align-self: end;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    gap: 16px;
  }
`;

const Content = styled.div<{ fill?: boolean }>`
  display: flex;
  gap: 40px;
  padding: ${({ fill }) => (fill ? '32px' : '32px 0')};
`;

const Wrapper = styled.div<{ bgColor: Colors; fill?: boolean }>`
  position: relative;
  border-radius: ${({ fill }) => (fill ? '8px' : '0')};
  width: 100%;
  background-color: ${({ bgColor, theme }) =>
    getBackgroundColor(bgColor, theme)};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    img {
      display: none;
    }
  }
`;

const Title = styled(Typography.H4)`
  font-weight: 500;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: 8px;
    right: 8px;
  }

  cursor: pointer;
`;

const CustomButton = styled(Button)<{ bgColor: Colors }>`
  background-color: ${({ bgColor, theme }) => getButtonColor(bgColor, theme)};
`;

enum Colors {
  BRAND = 'brand',
  PINK = 'pink',
  ORANGE = 'orange',
  BLUE = 'blue',
}

const getButtonColor = (color: Colors, theme: ThemeType): string => {
  switch (color) {
    case Colors.BRAND:
      return theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value;
    case Colors.PINK:
      return theme.tokens.color.global.pink.pink700.value;
    case Colors.ORANGE:
      return theme.tokens.color.global.orange.orange700.value;
    case Colors.BLUE:
      return theme.tokens.color.global.blue.blue700.value;
  }
};

const getBackgroundColor = (color: Colors, theme: ThemeType): string => {
  switch (color) {
    case Colors.BRAND:
      return theme.tokens.color.global.teal.teal100.value;
    case Colors.PINK:
      return theme.tokens.color.global.pink.pink100.value;
    case Colors.ORANGE:
      return theme.tokens.color.global.orange.orange100.value;
    case Colors.BLUE:
      return theme.tokens.color.global.blue.blue100.value;
  }
};

const Image = styled(ImageComponent)`
  width: 100px;
  height: 100px;
`;

type BannerProps = {
  bgColor: Colors;
  showBtn: boolean;
  btnText?: string;
  title: string;
  text: string;
  imgSrc: string;
  imgAlt: string;
  isBtnLoading?: boolean;
  canBeClosed?: boolean;
  onBtnClick?: () => void;
  onBtnCloseClick?: () => void;
  fill?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

function GridWrapper({
  fill,
  children,
}: {
  fill?: boolean;
  children: JSX.Element;
}): JSX.Element {
  if (fill) return <>{children}</>;

  return (
    <Grid.Container>
      <Grid.Row justifyContent="center">
        <Grid.Col md={10} lg={8}>
          {children}
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
}

function Banner({
  showBtn,
  bgColor,
  btnText,
  imgSrc,
  imgAlt,
  title,
  text,
  isBtnLoading,
  onBtnClick,
  onBtnCloseClick,
  canBeClosed,
  fill,
  ...rest
}: BannerProps): JSX.Element {
  const handleCloseBanner = () => {
    onBtnCloseClick?.();
  };

  const handleBtnClick = () => {
    onBtnClick?.();
  };

  return (
    <Wrapper bgColor={bgColor} {...rest} fill={fill}>
      <GridWrapper fill={fill}>
        <Content fill={fill}>
          <Image src={normalizeImgSource(imgSrc)} alt={imgAlt} />
          <Body>
            <div>
              <Title>
                <InnerHtml text={title} />
              </Title>
              <Typography.Body1>
                <InnerHtml text={text} />
              </Typography.Body1>
            </div>
            {showBtn && (
              <CustomButton
                role="button"
                loading={isBtnLoading}
                onClick={handleBtnClick}
                bgColor={bgColor}
              >
                {btnText}
              </CustomButton>
            )}
          </Body>
        </Content>
      </GridWrapper>
      {onBtnCloseClick && canBeClosed && (
        <IconContainer>
          <IconButton
            size="M"
            role="button"
            onClick={handleCloseBanner}
            icon="CloseIcon"
          />
        </IconContainer>
      )}
    </Wrapper>
  );
}

export { Banner, Colors };
export type { BannerProps };
