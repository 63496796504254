import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 30.375rem;
`;

type Props = {
  title: string;
  subtitle: string;
};

const EmptyStateText = ({ title, subtitle }: Props): JSX.Element => (
  <TextContainer>
    <Typography.H4>{title}</Typography.H4>
    <Typography.Body2>{subtitle}</Typography.Body2>
  </TextContainer>
);

export default EmptyStateText;
