import styled from '@emotion/styled';
import { snack } from '@innovamat/glimmer-components';
import {
  Button,
  Input,
  Modal,
  ThemeProvider as ThemeProviderInnovaComponents,
} from '@innovamat/innova-components';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-domv6';
import { useUser } from '../../../user-management';
import { SuccessMessage } from '../../components/success-message';
import TwoColumnLayout from '../../components/two-columns-layout/two-columns-layout';
import { useRefreshResetPasswordToken } from '../../hooks/use-reset-password/use-refresh-reset-password-token';
import { useResetPassword } from '../../hooks/use-reset-password/use-reset-password';

type ErrorData = {
  type: string;
  detail: string;
};

const ErrorMessage = styled.div`
  > a {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { refreshResetPasswordToken } = useRefreshResetPasswordToken();
  const { resetPassword, isSuccess, error, isPending } = useResetPassword();

  const { isLoggedIn, onClearUserSession } = useUser();

  const [showPassword, setShowPassword] = useState(false);

  const [credentials, setCredentials] = useState({
    password: '',
    confirmPassword: '',
  });

  const url = new URL(window.location.href);
  const requestId = url.searchParams.get('id');
  const recoveryToken = url.searchParams.get('token');

  const handleChangeInput = (value: string, name: string) => {
    setCredentials((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };

  const handleSendNewRequest = () => {
    if (!requestId || !recoveryToken) return;
    refreshResetPasswordToken(
      { requestId, token: recoveryToken },
      {
        onSuccess: () => navigate('/'),
        onError: (error) => {
          const errorData = error?.response?.data as ErrorData | undefined;
          snack.error(errorData?.detail);
        },
      }
    );
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    resetPassword({
      requestId: requestId!,
      password,
      token: recoveryToken!,
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      onClearUserSession();
    }
  }, [isLoggedIn]);

  const errorData = error?.response?.data as ErrorData | undefined;

  const attemptsError =
    error?.response?.status === 409 &&
    errorData?.type === 'password_request.verification_attempts_exceeded';

  const expiredError =
    error?.response?.status === 410 &&
    errorData?.type === 'password_request.expired';

  useEffect(() => {
    if (attemptsError || expiredError) {
      setShowErrorModal(true);
    }
  }, [error]);

  const { confirmPassword, password } = credentials;

  const validatePassword = () =>
    !!(password && confirmPassword && password === confirmPassword);

  const getErrorMessage = () => {
    if (error?.response?.status === 400) {
      return t(
        'Recovery.La contraseña introducida no cumple la política de seguridad.'
      );
    }
    return t(
      'Recovery.Ha ocurrido un error al crear la contraseña. Por favor, vuelve a intentarlo.'
    );
  };

  return (
    <ThemeProviderInnovaComponents>
      <TwoColumnLayout
        isSuccess={isSuccess}
        onSuccessBody={
          <SuccessMessage
            title={t('Contraseña modificada correctamente.')}
            body={t('Para acceder haz clic en el siguiente enlace:')}
            action={() => navigate('/')}
            actionText={t('Login.Accedir')}
          />
        }
        canGoBack
        error={error ? getErrorMessage() : undefined}
        title={t('Login.Introduce tu nueva contraseña')}
      >
        <Form action="" onSubmit={handleSave}>
          <Input
            label={t('Login.Contrassenya')}
            required
            description={t(
              'Recovery.(Mínimo 6 caracteres, combinando letras y números)'
            )}
            type={showPassword ? 'text' : 'password'}
            value={password}
            placeholder={t('Login.ContrassenyaPlaceholder')}
            onChange={(e) => handleChangeInput(e.target.value, 'password')}
            icon={showPassword ? 'ViewDisabled' : 'View'}
            iconPosition="right"
            iconAction={() => setShowPassword((prevState) => !prevState)}
            iconClickable
          />
          <Input
            label={t('Recovery.Confirmar Contrassenya')}
            required
            type={showPassword ? 'text' : 'password'}
            value={confirmPassword}
            placeholder={t('Login.ContrassenyaPlaceholder')}
            onChange={(e) =>
              handleChangeInput(e.target.value, 'confirmPassword')
            }
            icon={showPassword ? 'ViewDisabled' : 'View'}
            iconPosition="right"
            iconAction={() => setShowPassword((prevState) => !prevState)}
            iconClickable
          />
          <Button
            fill
            disabled={!validatePassword()}
            onClick={handleSave}
            loading={isPending}
          >
            {t('Settings.Guardar')}
          </Button>
        </Form>
      </TwoColumnLayout>
      <Modal
        open={showErrorModal}
        onSubmit={() => setShowErrorModal(false)}
        onClose={() => setShowErrorModal(false)}
        showCancelBtn={false}
        showSubmitBtn={false}
        size="auto"
        customFooter={<></>}
        customTitle={<></>}
      >
        {attemptsError && (
          <ErrorMessage>
            <Trans
              i18nKey="password_request.verification_attempts_exceeded"
              components={{
                clickhere: <a onClick={handleSendNewRequest} />,
                underline: <u />,
              }}
            />
          </ErrorMessage>
        )}
        {expiredError && (
          <ErrorMessage>
            <Trans
              i18nKey="password_request.expired"
              components={{
                clickhere: <a onClick={handleSendNewRequest} />,
                underline: <u />,
              }}
            />
          </ErrorMessage>
        )}
      </Modal>
    </ThemeProviderInnovaComponents>
  );
}

export { ResetPassword };
