import styled from '@emotion/styled';
import { Button } from '@innovamat/glimmer-components';
import { Modal } from '@innovamat/innova-components';
import { useTranslation } from 'react-i18next';
import { useEnvVariables } from '../../../../env-variables-manager';

type Props = {
  show: boolean;
  onCancel: () => void;
};

const ButtonLink = styled.a`
  text-decoration: none;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value};
  color: ${({ theme }) => theme.tokens.color.global.white.value};
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  gap: 8px;
  max-height: 40px;
  cursor: pointer;
  position: relative;
  border: none;
  outline-offset: -2px;
`;

const Footer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.p`
  max-width: 300px;
  margin-bottom: 16px;
`;

const StyledModal = styled(Modal)`
  gap: 0;
`;

export default function WebViewModal({ show, onCancel }: Props) {
  const { t } = useTranslation();
  const { WEBAPP } = useEnvVariables();
  const url = `${WEBAPP}${window.location.search}`;

  return (
    <StyledModal
      open={show}
      showCancelBtn={false}
      showSubmitBtn={false}
      size="auto"
    >
      <Title>
        {t(
          'Login.webviewModal',
          'Vas a ser redirigido a app-student, haz click en el enlace para continuar.'
        )}
      </Title>
      <Footer>
        <Button variant="secondary" onClick={onCancel}>
          {t('Login.webviewModal.cancel', 'Cancelar')}
        </Button>
        <ButtonLink href={url}>
          {t('Login.webviewModal.link', 'Continuar')}
        </ButtonLink>
      </Footer>
    </StyledModal>
  );
}
