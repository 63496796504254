function putNumberedStepInsideStepContent(): void {
  document
    .querySelectorAll<HTMLElement>('.step-container')
    .forEach((stepContainer: HTMLElement) => {
      const stepNumber: HTMLElement | null =
        stepContainer.querySelector('.step-number');
      const stepContent: HTMLElement | null =
        stepContainer.querySelector('.step-content');

      if (!stepNumber || !stepContent) return;

      stepContainer.style.position = 'relative';

      stepContent.style.marginLeft = '28px';

      stepNumber.style.position = 'absolute';
      stepNumber.style.left = '-45px';

      stepContent?.firstChild?.firstChild?.appendChild(stepNumber || '');
    });
}

function groupSubactivitiesWithNextElement(): void {
  document.querySelectorAll('[id^="Subactividad-"]').forEach((subactivity) => {
    if (
      subactivity.parentElement?.classList.contains('subactivity-step-wrapper')
    ) {
      return;
    }

    const nextElement = subactivity.nextElementSibling;

    if (!nextElement) return;

    const wrapper = document.createElement('div');
    wrapper.classList.add('subactivity-step-wrapper');
    wrapper.style.setProperty('display', 'flex');
    wrapper.style.setProperty('flex-direction', 'column');
    wrapper.style.setProperty('gap', '8px');
    wrapper.style.setProperty('page-break-inside', 'avoid');

    subactivity.parentNode?.insertBefore(wrapper, subactivity);

    wrapper.appendChild(subactivity);
    wrapper.appendChild(nextElement);
  });
}

function restoreAllPageBreakEvents(): void {
  document
    .querySelectorAll('div[style*="page-break-before: always"]')
    .forEach((element) => {
      element.addEventListener('click', (e) => {
        (e.target as HTMLElement).parentElement?.remove();
      });
    });
}

export function moveContentToPrint(): void {
  const MAX_ELEMENTS_BEFORE_STEP = 3;

  putNumberedStepInsideStepContent();

  const guides = document.querySelectorAll('[class^="print-guide-"]');

  if (!guides) return;

  guides.forEach((guide) => {
    const partsContainer = guide.querySelectorAll('[class^="part-children-"]');

    if (!partsContainer) return;

    partsContainer.forEach((part) => {
      const partIndex = part?.className?.match(/\d+/)?.[0];
      const summaryContent: HTMLElement | null = guide?.querySelector(
        `.part-summary-content-${partIndex}`
      );

      if (!summaryContent) return;

      const elementsToMove = [];
      let foundNumberedStep = false;
      let count = 0;

      for (const numberedStep of part.children as any) {
        if (numberedStep.id && numberedStep.id.startsWith('PasoNumerado')) {
          const groupedSteps = numberedStep.querySelectorAll('.grouped-step');

          if (groupedSteps.length > 1) {
            const clonedNumberedStep = numberedStep.cloneNode(true);
            const clonedGroupedSteps =
              clonedNumberedStep.querySelectorAll('.grouped-step');

            clonedNumberedStep.id = 'PasoNumerado-cloned';

            const [, ...others] = groupedSteps;
            const [firstCloned] = clonedGroupedSteps;

            firstCloned.remove();

            others.forEach((otherStep: HTMLElement) => {
              otherStep.remove();
            });

            numberedStep.insertAdjacentElement('afterend', clonedNumberedStep);

            if (part.parentElement) {
              part.parentElement.style.gap = '0';
            }
          }

          foundNumberedStep = true;
          elementsToMove.push(numberedStep);
          break;
        }

        if (count < MAX_ELEMENTS_BEFORE_STEP) {
          elementsToMove.push(numberedStep);
          count++;
        }
      }

      if (foundNumberedStep) {
        elementsToMove.forEach((element) =>
          summaryContent.appendChild(element)
        );
        summaryContent.style.marginTop = '12px';

        const wrapper: HTMLElement | null = guide.querySelector(
          `.collapsable-part-wrapper-${partIndex}`
        );

        if (wrapper) {
          wrapper.classList.add('print-guide-part-gap');
        }
      }

      groupSubactivitiesWithNextElement();
      restoreAllPageBreakEvents();
    });
  });
}
