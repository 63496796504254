import { useQueryParams } from '@innovamat/hooks';
import { Stage } from '@innovamat/radiance-utils';
import { useUser } from '../../user-management';

type ResourceParams = {
  educationalLevel: string;
  region: string;
  language: string;
  urlRegion: string | null;
  urlLanguage: string | null;
};

export function useResourceParams(): ResourceParams {
  const language = useQueryParams().get('language');
  const region = useQueryParams().get('region');
  const stage = useQueryParams().get('stage');
  const { user } = useUser();

  const getStage = (): Stage => {
    if (stage === 'secondary') return Stage.SECUNDARIA;
    return (stage as Stage) || Stage.PRIMARIA;
  };

  return {
    educationalLevel: getStage(),
    region: region || user?.region || 'ES',
    language: language || user?.locale || 'es',
    urlRegion: region,
    urlLanguage: language,
  };
}
