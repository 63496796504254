import { Fragment } from 'react';
import { Icon } from '../icon';
import { IconType } from '@innovamat/glimmer-icons';
import styled from '@emotion/styled';
import React from 'react';
import { StateLayer } from '../../utils/common.styled';
import { css } from '@emotion/react';

type SegmentedState = 'active' | 'disabled' | 'selected';

type SegmentedItemPosition = 'left' | 'middle' | 'right';

type SegmentedItemsProps = {
  onClick: () => void;
  icons: IconType[];
  innerRef: React.Ref<HTMLButtonElement>;
  state?: SegmentedState;
  position: SegmentedItemPosition;
};

const IconStyle = styled(Icon)`
  z-index: 3;
`;

const Option = styled.button<{ state?: SegmentedState }>`
  flex: 1;
  text-align: center;
  padding: 10px 16px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  position: relative;

  ${({ state, theme }) =>
    !state &&
    css`
      :hover .option-stateLayer {
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-hover-darker'
        ].value};
        border-radius: 4px;
      }
      :active .option-stateLayer {
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-press-darker'
        ].value};
        border-radius: 4px;
      }
    `}
`;

const IconContainer = styled.div<{ state?: SegmentedState }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  .tabIcon {
    path {
      fill: ${({ theme, state }) => {
        if (state === 'selected') {
          return theme.tokens.color.alias.cm.icon['icon-accent'].value;
        } else if (state === 'disabled') {
          return theme.tokens.color.alias.cm.icon['icon-disabled'].value;
        } else {
          return theme.tokens.color.alias.cm.icon['icon-default'].value;
        }
      }};
    }
  }
`;

const StateLayerStyle = styled(StateLayer)`
  z-index: 1;
`;

const Plus = styled.span<{ state?: SegmentedState }>`
  font-size: 12px;
  z-index: 3;
  color: ${({ theme, state }) => {
    if (state === 'disabled') {
      return theme.tokens.color.alias.cm.text['text-disabled'].value;
    } else if (state === 'selected') {
      return theme.tokens.color.alias.cm.text['text-accent'].value;
    } else {
      return theme.tokens.color.alias.cm.text['text-subtle'].value;
    }
  }};
`;

export function SegmentedItems({
  onClick,
  icons,
  innerRef,
  state,
  position,
}: SegmentedItemsProps): JSX.Element {
  const testId = `SEGMENTEDBUTTON_${position.toUpperCase()}`;
  return (
    <Option
      onClick={onClick}
      ref={innerRef}
      state={state}
      disabled={state === 'disabled'}
      data-testid={testId}
    >
      <StateLayerStyle className="option-stateLayer" />
      <IconContainer state={state} data-testid="segmented-item-icon">
        {icons.map((icon, index) => (
          <Fragment key={index}>
            <IconStyle icon={icon} size="M" className="tabIcon" />
            {index < icons.length - 1 && <Plus state={state}> + </Plus>}
          </Fragment>
        ))}
      </IconContainer>
    </Option>
  );
}
