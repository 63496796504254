import styled from '@emotion/styled';

import { DrawerHeader, Typography } from '@innovamat/glimmer-components';
import { useMockExams } from './context/mock-exams-provider';
import { TestStatusCell } from '../test-and-results-table/components';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  min-width: 0;
`;

const Title = styled(Typography.H3)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export function MockExamsDrawerHeader(): JSX.Element {
  const {
    onCloseDrawer,
    isNextDisabled,
    isPreviousDisabled,
    onClickNext,
    onClickPrevious,
  } = useMockExams();

  return (
    <DrawerHeader
      customTitle={<MockExamsCustomTitle />}
      isNextDisabled={isNextDisabled}
      isPreviousDisabled={isPreviousDisabled}
      onClickNext={onClickNext}
      onClickPrevious={onClickPrevious}
      onCloseDrawer={onCloseDrawer}
    />
  );
}

function MockExamsCustomTitle(): JSX.Element {
  const { rowSelected } = useMockExams();

  if (!rowSelected) return <></>;

  return (
    <TitleWrapper>
      <Title>{rowSelected.rowName}</Title>
      <TestStatusCell
        key={`status_${rowSelected.classroomTestRoundId}`}
        status={rowSelected.status}
      />
    </TitleWrapper>
  );
}
