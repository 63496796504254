import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  AnnouncementPanel,
  GridCol,
  GridContainer,
  GridRow,
  Typography,
} from '@innovamat/glimmer-components';
import { Link } from 'react-router-domv6';

const StyledNotification = styled(AnnouncementPanel)`
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.red['05']} !important;
  span {
    font-family: ${({ theme }) => theme.fonts.robotoMedium};
    font-size: ${({ theme }) => theme.sizes.small};
  }
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.dark['01']} !important;
  }
`;

const GridContainerStyled = styled(GridContainer)`
  color: ${({ theme }) => theme.colors.dark['01']};
  height: 100%;
  overflow-y: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const GridColWithBg = styled(GridCol)<{ isRegisterLayout?: boolean }>`
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme, isRegisterLayout }) =>
    isRegisterLayout ? 'transparent' : theme.colors.brand['03']};
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RegisterImage = styled.div<{ bg: string }>`
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const WavesTop = styled.div`
  flex: 1;
  margin: 0 -16px;
`;

const WavesBottom = styled.div`
  flex: 1;
  position: relative;
  margin: 0 -16px;
  svg {
    position: absolute;
    bottom: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 24px;
`;

const LogoContainer = styled.div`
  cursor: pointer;
  max-width: 160px;
  visibility: visible;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    visibility: hidden;
  }
`;

const GoBackLink = styled(Link)`
  margin-bottom: 19px;
  display: flex;
  color: ${({ theme }) => theme.tokens.color.global.neutral.neutral100.value};
  svg {
    path {
      fill: ${({ theme }) =>
        theme.tokens.color.global.neutral.neutral100.value};
    }
  }
`;

const ErrorContainer = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.semantic['03']};
`;

const Title = styled.h3<{ descriptionExists?: boolean }>`
  font-weight: 600;
  font-size: 32px;
  line-height: 34px;
  margin-bottom: 6px;
`;

const FromDataCenter = styled.div<{
  descriptionExists?: boolean;
}>`
  display: flex;
  margin-bottom: ${({ descriptionExists }) =>
    descriptionExists ? '12px' : '24px'};
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 8px;
  }
`;

const DropdownSelection = styled.div<{ isPressed: boolean }>`
  display: flex;
  padding: 0px 2px;
  align-items: center;
  gap: 2px;
  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      background-color: ${theme.tokens.color.specific['state-layer'][
        'state-press-darker'
      ].value};
    `};
`;

const FromText = styled(Typography.Body2)`
  text-transform: capitalize;
`;

const Description = styled.h4`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px;
`;

const CountryRegionButtonWrapper = styled.div`
  align-self: center;
`;

const MainGridRow = styled(GridRow)<{ headerHeight: number }>`
  margin-top: ${({ headerHeight }) => `calc(100px - ${headerHeight}px)`};
  margin-bottom: ${({ headerHeight }) => `calc(100px - ${headerHeight}px)`};
`;

export {
  CountryRegionButtonWrapper,
  Description,
  DropdownSelection,
  ErrorContainer,
  FromDataCenter,
  FromText,
  GoBackLink,
  GridColWithBg,
  GridContainerStyled,
  Header,
  LogoContainer,
  MainGridRow,
  RegisterImage,
  StyledNotification,
  Title,
  WavesBottom,
  WavesTop,
};
