import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { ReasonForEmptyList } from '../../types';
import { getEmptyStateConfig } from './empty-state-config';
import EmptyStateText from './text';

type Props = {
  reasonForEmptyList: ReasonForEmptyList;
  backgroundImg?: string;
};

const Container = styled.div<{ backgroundImage: string }>`
  display: flex;
  width: 100%;
  min-height: 14.25rem;
  padding: 2rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url(${({ backgroundImage }) => backgroundImage}) lightgray 50% / cover
      no-repeat;
  color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.div`
  display: flex;
  min-height: 10.1875rem;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 3rem;
  border-radius: 0.5rem;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const DigitalPracticeEmptyState = ({
  reasonForEmptyList,
  backgroundImg = '/_next/static/media/02.c567a36c.jpg',
}: Props): EmotionJSX.Element => {
  const { t } = useTranslation();
  const { title, subtitle, renderCards } =
    getEmptyStateConfig(reasonForEmptyList);

  return (
    <Container backgroundImage={backgroundImg}>
      <Wrapper>
        <EmptyStateText title={t(title)} subtitle={t(subtitle)} />
        {renderCards()}
      </Wrapper>
    </Container>
  );
};

export default DigitalPracticeEmptyState;
