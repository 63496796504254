import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Teacher } from '@innovamat/glow-api-client';

import {
  EmptyClassroom,
  getAvatarByClassroom,
} from '@innovamat/glimmer-assets';
import type {
  ClassroomAvatarGroupItem,
  Column,
} from '@innovamat/glimmer-components';
import {
  Button,
  ClassroomAvatarGroup,
  EmptyState,
  TableComponent,
  Typography,
  snack,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import type { Classroom, Roles } from '@innovamat/radiance-utils';
import { hasOwnerRole } from '@innovamat/radiance-utils';

import { useUser } from '../../../../../user-management';
import { useDeleteTeacher } from '../../../../hooks/use-delete-teacher';
import { useUpdateTeacherRoles } from '../../../../hooks/use-update-teacher-roles';

import { EllipsisCell } from '../../../../components/common-table-components/ellipsis-cell';
import { StatusCell } from '../../../../components/common-table-components/status-cell';
import { tableStyles } from '../../../../components/common-table-components/table-styles';
import { ConfirmationModal } from '../../../../components/confirmation-modal';

import { AssignClassroomsToTeacherDrawer } from '../assign-classrooms-to-teacher-drawer';

import { ActionsCell } from './school-teachers-table.actions-cell';
import { SchoolTeachersTableRolesCell } from './school-teachers-table.roles-cell';
import { InvitationEmail } from './school-teachers-table.styles';

type ClassroomTeachersProps = {
  isPlatformEnabled: boolean;
  isUpdatePeriod: boolean;
  organizationId: string;
  teachers: Teacher[];
  onAddTeachers: () => void;
};

function SchoolTeachersTable({
  isPlatformEnabled,
  isUpdatePeriod,
  organizationId,
  teachers,
  onAddTeachers,
}: ClassroomTeachersProps): JSX.Element {
  const { confirmModalData, onDelete } = useDeleteTeacher({
    isPlatformEnabled,
    organizationId,
    onSuccess: () => {
      snack.success(t('teachers.deleteTeacher.toast.success'));
    },
  });

  const [showClassroomsDrawerTeacher, setShowClassroomsDrawerTeacher] =
    useState<Teacher | undefined>(undefined);

  const { user } = useUser();
  const { t } = useTranslation();

  const theme = useGlimmerTheme();

  const handleLeaveClass = (teacher: Teacher): void => {
    onDelete(teacher);
  };

  const { onUpdateRole } = useUpdateTeacherRoles({
    isPlatformEnabled,
    organizationId,
    onSuccess: () => {
      snack.success(t('teachers.updateRoles.toast.success'));
    },
  });

  const handleClickClassrooms = (teacher: Teacher): void => {
    setShowClassroomsDrawerTeacher(teacher);
  };

  const handleUpdateRole = (roles: Roles[], teacherId: string): void => {
    onUpdateRole(roles, teacherId);
  };

  const columns: Column<Teacher>[] = useMemo(
    () =>
      [
        {
          id: 'firstName',
          label: t('common.firstName'),
          width: 100,
          minWidth: '140px',
          render: (_, teacher) => {
            return (
              <Typography.Subtitle2>
                <EllipsisCell
                  text={teacher?.firstName ? teacher.firstName : '-'}
                  dataTestId="teacher-first-name"
                />
              </Typography.Subtitle2>
            );
          },
        },
        {
          id: 'lastName',
          label: t('common.lastName'),
          width: 100,
          minWidth: '140px',
          render: (_, teacher) => {
            return (
              <Typography.Subtitle2>
                <EllipsisCell
                  text={teacher?.lastName ? teacher.lastName : '-'}
                  dataTestId="teacher-last-name"
                />
              </Typography.Subtitle2>
            );
          },
        },
        {
          id: 'email',
          label: t('common.email'),
          width: 300,
          minWidth: '300px',
          render: (_, teacher) => {
            if (teacher?.isSchoolInvitation || teacher?.isInvitation) {
              return (
                <InvitationEmail data-testid="invitation-teacher-email">
                  <EllipsisCell
                    text={teacher?.email ? teacher.email : '-'}
                    dataTestId="teacher-email"
                  />
                </InvitationEmail>
              );
            }

            return (
              <EllipsisCell
                text={teacher?.email ? teacher.email : '-'}
                dataTestId="teacher-email"
              />
            );
          },
        },
        {
          id: 'roles',
          label: t('common.permissions'),
          width: 100,
          minWidth: '140px',
          render: (_, teacher) => {
            return (
              <SchoolTeachersTableRolesCell
                dataTestId="teacher-roles"
                isDisabled={teacher.id === user?.id}
                onUpdateRole={(roles) => handleUpdateRole(roles, teacher.id)}
                teacherRoles={teacher.roles as Roles[]}
              />
            );
          },
        },
        {
          id: 'classrooms',
          label: t('common.classrooms'),
          width: 100,
          minWidth: '120px',
          render: (_, teacher) => {
            if (teacher.isInvitation || teacher.isSchoolInvitation)
              return <div data-testid="teacher-classrooms">-</div>;

            const classrooms: ClassroomAvatarGroupItem[] =
              teacher.classrooms?.map((classroom) => ({
                name: classroom?.name || '-',
                image: getAvatarByClassroom(classroom as Classroom) || '',
              })) || [];

            return (
              <ClassroomAvatarGroup
                dataTestId="teacher-classrooms"
                isUpdatePeriod={isUpdatePeriod}
                classrooms={classrooms}
                onClick={() => handleClickClassrooms(teacher)}
                tooltipText={t('manager.teacher.drawer.numOfClassrooms', {
                  num: classrooms.length,
                })}
                emptyStateText={t('common.orders')}
              />
            );
          },
        },
        {
          id: 'lastLogin',
          label: t('common.status'),
          width: 100,
          minWidth: '140px',
          render: (_, teacher) => {
            return (
              <StatusCell
                dataTestId="teacher-status"
                expiresAt={teacher.expiresAt}
                isInvitation={teacher.isInvitation}
                isSchoolInvitation={teacher.isSchoolInvitation}
                lastLogin={teacher.lastLogin}
              />
            );
          },
        },
        {
          id: 'actions',
          width: 100,
          minWidth: '100px',
          sticky: true,
          stickyPosition: 'right',
          render: (_, teacher) => {
            const isDisabled =
              hasOwnerRole(teacher.roles as Roles[]) && teacher.id !== user?.id;

            return (
              <ActionsCell
                isDeleteOptionDisabled={isDisabled}
                isPlatformEnabled={isPlatformEnabled}
                isUpdatePeriod={isUpdatePeriod}
                onDeleteTeacher={handleLeaveClass}
                onEditClassrooms={handleClickClassrooms}
                organizationId={organizationId}
                teacher={teacher}
              />
            );
          },
        },
      ] satisfies Column<Teacher>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teachers]
  );

  const hasTeachers = teachers.length > 0;

  return hasTeachers ? (
    <>
      <TableComponent
        columns={columns}
        id="school-teachers-table"
        key={`teachers-table-${organizationId}`}
        rows={teachers}
        sx={tableStyles(theme)}
      />
      <ConfirmationModal {...confirmModalData} />
      <AssignClassroomsToTeacherDrawer
        onClose={() => setShowClassroomsDrawerTeacher(undefined)}
        showDrawer={Boolean(showClassroomsDrawerTeacher)}
        teacher={showClassroomsDrawerTeacher}
      />
    </>
  ) : (
    <EmptyState
      Image={EmptyClassroom}
      title={t('teachers.emptyState.title')}
      subtitle={t('teachers.emptyState.subtitle')}
      action={
        <Button onClick={onAddTeachers}>
          {t('teachers.buttons.addTeachers')}
        </Button>
      }
    />
  );
}

export { SchoolTeachersTable };
