import styled from '@emotion/styled';
import ContentBlock from '../../components/content-block';
import DimensionsBlock from '../../components/DimensionsBlock';
import DisclaimerBlock from '../../components/DisclaimerBlock';
import SimpleBlock from '../../components/SimpleBlock';

import { getTypography } from '@innovamat/glimmer-components';
import { Session } from '@innovamat/resource-viewer';
import { TFunction } from 'i18next';
import 'tippy.js/dist/tippy.css';
import { BigIdeasTags } from '../../../components/big-ideas-tags';
import { MathPractices } from '../../../components/math-practices';
import { NewStandardContent } from '../../../components/new-standard-content';
import { TitlePrintable } from '../../../components/TitlePrintable';
import { Stage } from '../../../types/Stage';
import { PrintableChallengesBlock } from '../../components/ChallengesBlock/PrintableChallengesBlock';
import { DigitalGuideProvider } from '../../context';
import EarlyYearGuideProvider from './context/EarlyYearGuideProvider';
import {
  EARLY_YEAR_CONTENT_BLOCKS,
  EARLY_YEAR_CONTENT_BLOCKS_ICONS,
} from './EarlyYear.mappers';
import { ChallengesResponse } from './types';
import { EarlyYearDigitalGuide } from './types/earlyYearDigitalGuide';

const PrintableWrapper = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.dark['02']};
  margin: 0 auto;
  max-width: 1240px;

  @media print {
    padding: 0;
    border: none;
  }
`;

const Printable = styled.div`
  break-before: always;
  display: flex;
  flex-direction: column;
  width: 100%;

  .printableContent {
    column-count: 2;
    column-gap: 62px;

    @media print {
      column-fill: auto;
    }
  }
`;

const Summary = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  > div > p {
    ${({ theme }) => getTypography(theme, 'Subtitle 1')};
    color: ${({ theme }) => theme.colors.dark['01']};

    @media print {
      ${({ theme }) => getTypography(theme, 'Subtitle 2')}

      font-size: 12px;
      line-height: 14px;
    }
  }
`;

type Props = {
  resource: Session;
  guide: EarlyYearDigitalGuide;
  challenges?: ChallengesResponse | undefined;
  onEvent: (key: string, data: any) => void;
  t: TFunction<'translation', undefined, 'translation'>;
  translations: Record<string, string>;
};

export function EarlyYearPrintable(props: Props): JSX.Element {
  const { guide, t, translations, resource } = props;
  return (
    <DigitalGuideProvider t={t} translations={translations}>
      <EarlyYearGuideProvider {...props}>
        <PrintableWrapper>
          <Printable>
            <TitlePrintable
              title={resource.title}
              subtitle={t(guide.subtitle.body)}
            />
            <div className="printableContent">
              <Summary>
                <SimpleBlock
                  title={t('digitalguides.ey.summary')}
                  text={guide.summary}
                />
                <ContentBlock
                  contentIcons={EARLY_YEAR_CONTENT_BLOCKS_ICONS}
                  contentBlocks={guide.contentBlocks}
                  items={EARLY_YEAR_CONTENT_BLOCKS}
                />
                <BigIdeasTags tags={guide?.bigIdeas} />
                <NewStandardContent
                  stage={Stage.EarlyYear}
                  summary={
                    { newStandardContent: guide.newStandardContent } as any
                  }
                />
                <MathPractices tags={guide.newMathPractices} />
                <DimensionsBlock
                  dimensions={guide.dimensions}
                  title={t('digitalguides.processes')}
                />
                <DisclaimerBlock disclaimer={guide.dimensionsDisclaimer} />
              </Summary>
              <PrintableChallengesBlock
                challenges={guide.challenges}
                contentIcons={EARLY_YEAR_CONTENT_BLOCKS_ICONS}
              />
            </div>
          </Printable>
        </PrintableWrapper>
      </EarlyYearGuideProvider>
    </DigitalGuideProvider>
  );
}
