import {
  APP_PATHS,
  ClassroomStudents,
  ClassroomTeachers,
  CloseButtonFullScreen,
  ERROR_TYPE,
  ErrorPage,
  TestsAndResults,
  useCurrentNavigationValues,
  useNavigation,
  useOrganization,
  useReportsFullScreen,
} from '@innovamat/ga-features';
import { useLocation, useNavigate, useParams } from 'react-router-domv6';
import { useSitemapNavigation } from '../../hooks/use-sitemap-navigation';
import { Header } from '../layout/header';
import { MainLayout } from '../layout/main-layout';
import { LessonsProviderWrapper } from './lessons-provider';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Breadcrumb, IconButton } from '@innovamat/glimmer-components';
import { stages } from '@innovamat/radiance-utils';
import { PageWithTableWrapper } from '../layout/page-with-table';
import { ImportsPage } from '../pages/imports-page';
import { ImportCSVWrapper } from './import-csv-wrapper';
import { ReportsNavigationWrapper } from './reports-navigation-wrapper';

type ClassroomStaticMenus =
  | 'teachers'
  | 'students'
  | 'reports'
  | 'imports'
  | 'tests-and-results';

type Params = {
  classroomId: string;
  menu?: ClassroomStaticMenus;
  submenu?: string;
  organizationId: string;
};

const StyledMainLayout = styled(MainLayout)<{ isOverflowVisible: boolean }>`
  ${({ isOverflowVisible }) => {
    if (isOverflowVisible) {
      return css`
        .page-frame {
          overflow-y: visible;
          overflow-x: visible;
        }
      `;
    }
    return ``;
  }};
`;

export function AdminClassroomNavigationWrapper(): JSX.Element {
  const navigate = useNavigate();
  const { organizationId, menu, submenu } = useParams<Params>();
  const { currentClassroom } = useCurrentNavigationValues();
  const { organization } = useOrganization();
  const { sitemapTree, isPending, isError } = useSitemapNavigation();
  const { goBackOrHome } = useNavigation();
  const { state } = useLocation();

  const sitemapTreeError = (!isPending && sitemapTree.length === 0) || isError;

  const shouldHideLateralMenuWithTransition = menu === 'reports';

  const { isFullScreen, closeFullScreen } = useReportsFullScreen();

  const getComponentRoute = (): JSX.Element => {
    switch (menu) {
      case 'teachers':
        return <ClassroomTeachers />;
      case 'students':
        return (
          <ImportCSVWrapper>
            <ClassroomStudents />
          </ImportCSVWrapper>
        );
      case 'reports':
        return <ReportsNavigationWrapper />;
      case 'tests-and-results':
        return (
          <PageWithTableWrapper>
            <TestsAndResults />
          </PageWithTableWrapper>
        );
      case 'imports':
        return <ImportsPage />;
      default:
        return <></>;
    }
  };

  const handleGoBack = (): void => {
    if (state?.from.includes('admin-dashboard')) {
      navigate(state?.from);
    } else {
      navigate(
        `${APP_PATHS.ORGANIZATION_URL}/${organizationId}${
          APP_PATHS.CLASSROOMS_URL
        }?stage=${stages.getStageFromOrder(currentClassroom?.courseOrder!)}`
      );
    }
  };

  if (sitemapTreeError) {
    return <ErrorPage errorType={ERROR_TYPE.NOT_FOUND} />;
  }

  const handleCloseFullScreen = (): void => {
    if (submenu === 'last-year') {
      goBackOrHome();
    }
    closeFullScreen();
  };

  return (
    <>
      <Header
        leftActions={
          isFullScreen ? (
            <CloseButtonFullScreen
              onCloseFullScreen={() => handleCloseFullScreen()}
            />
          ) : (
            <>
              <IconButton icon="BackIcon" onClick={handleGoBack} />
              <Breadcrumb
                goFrom={handleGoBack}
                breadcrumbs={[organization?.name, currentClassroom?.name]}
              />
            </>
          )
        }
        hasBackButton
        sitemapTree={sitemapTree}
      />

      <StyledMainLayout
        sitemapTree={sitemapTree}
        isOverflowVisible={menu === 'reports'}
        hideLateralMenu={
          sitemapTreeError || submenu === 'upload' || isFullScreen
        }
        hideLateralMenuWithTransition={shouldHideLateralMenuWithTransition}
      >
        <LessonsProviderWrapper>
          <PageWithTableWrapper>{getComponentRoute()}</PageWithTableWrapper>
        </LessonsProviderWrapper>
      </StyledMainLayout>
    </>
  );
}
