import styled from '@emotion/styled';

export const AnswersContainer = styled.div<{ isSmall?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  ${({ isSmall }) => (isSmall ? '' : 'gap: 2rem')};
  width: 100%;
`;

export const AnswersCol = styled.div`
  max-width: 55px;
  min-width: 55px;

  flex: 1;
  overflow: hidden;
  text-align: center;
`;
