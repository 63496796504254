import { LogoInnovamat } from '@innovamat/glimmer-assets';
import { useQueryParams } from '@innovamat/hooks';
import { Button, Dropdown, Icon } from '@innovamat/innova-components';
import { getRegisterLanguagesByRegion } from '@innovamat/localization';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-domv6';

import { WavesBg } from '@innovamat/glimmer-assets';
import { useLanguage } from '../../hooks/use-language';
import { useGetUserRegion } from '../../hooks/use-user-region';
import {
  Background,
  Container,
  DropdownContainer,
  Error,
  FormBody,
  FormContainer,
  FormHeader,
  LogoContainer,
  StyledNotification,
  Title,
} from './two-columns-layout.styled';

type NotificationType =
  | {
      message: string;
      type: 'error' | 'warning';
    }
  | undefined;

type Props = {
  isSuccess?: boolean;
  bg?: string;
  title?: string;
  children: JSX.Element;
  error?: string | JSX.Element;
  canGoBack?: boolean;
  onSuccessBody?: JSX.Element;
  notification?: NotificationType;
  titleId?: string;
  dropdownId?: string;
  buttonId?: string;
  goBackId?: string;
};

const TwoColumnLayout = ({
  isSuccess,
  children,
  bg,
  title,
  canGoBack,
  onSuccessBody,
  error,
  notification,
  titleId,
  dropdownId,
  buttonId,
  goBackId,
}: Props) => {
  const { setLanguageFromUrl, setHtmlLanguage } = useLanguage();
  const { lng: language } = useParams<{ lng: string }>();
  const { t, i18n } = useTranslation();
  const params = useQueryParams();
  const l = params.get('l');
  const region = useGetUserRegion();
  const languages = getRegisterLanguagesByRegion(region);
  const languageOptions = languages?.map((lang) => ({
    value: lang,
    key: lang,
    label: t(`language.${lang}`),
  }));

  const handleSelectLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const language = languages?.find((lang) => lang === l);
    if (language) {
      handleSelectLanguage(language);
    } else {
      setLanguageFromUrl();
    }
  }, [l, language]);

  useEffect(() => {
    setHtmlLanguage(i18n.language);
  }, [i18n.language]);

  const selectedLanguage =
    languages?.find((language) => language === i18n.language) || languages[0];

  return (
    <Container>
      <FormContainer>
        <FormHeader>
          <LogoContainer>
            <Link to="/">
              <LogoInnovamat />
            </Link>
          </LogoContainer>
          <DropdownContainer data-testid={dropdownId}>
            <Dropdown
              options={languageOptions}
              element={
                <Button
                  color="secondary"
                  icon="ArrowDown"
                  iconPosition="right"
                  data-testid={buttonId}
                >
                  {t(`language.${selectedLanguage}`)}
                </Button>
              }
              render={(option) => (
                <Button
                  className={'dropdownButton'}
                  {...option}
                  fill
                  color="default"
                  data-testid={`language-option-${option.value}`}
                  onClick={() => handleSelectLanguage(option.value)}
                >
                  {option.label}
                </Button>
              )}
              dropdownTestId={dropdownId}
            />
          </DropdownContainer>
        </FormHeader>
        <FormBody>
          {isSuccess && onSuccessBody ? (
            onSuccessBody
          ) : (
            <>
              {notification && (
                <StyledNotification type={notification.type} canClose={false}>
                  {notification.message}
                </StyledNotification>
              )}
              {canGoBack && (
                <Link to="/" className="goBack" data-testid={goBackId}>
                  <Icon icon="ArrowLeft" /> {t('back.button')}
                </Link>
              )}

              <Title>
                {title && <h2 data-testid={`${titleId}-title`}>{title}</h2>}
                <Error data-testid={`${titleId}-error`}>{error}</Error>
              </Title>

              {children}
            </>
          )}
        </FormBody>
        <div />
      </FormContainer>
      <Background>
        <WavesBg />
      </Background>
    </Container>
  );
};

export default TwoColumnLayout;
