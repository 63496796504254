import { FlagsProvider } from '@innovamat/flags';
import {
  EnvVariablesProvider,
  GoogleClassroomSyncProvider,
  UserManagementProvider,
} from '@innovamat/ga-features';
import { ThemeProvider } from '@innovamat/glimmer-components';
import { GlowApiClientProvider } from '@innovamat/glow-api-client';
import type { Credentials } from '@innovamat/social-login';
import { AuthProvider } from '@innovamat/social-login';
import { WebviewBridgeProvider } from '@innovamat/webview-bridge';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { initEnvVariables } from '../../utils/init-env-variables';
import {
  getAccessToken,
  getUserAcceptLanguage,
  updateRefreshTokenClient,
} from '../../utils/update-refresh-token';

import { EventLoggingProvider } from '@innovamat/event-logging';
import { storage } from '@innovamat/radiance-utils';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { PreprTrackingProvider } from '@innovamat/smart-guides';
import { useEffect, useState } from 'react';
import { authServiceInit } from '../../config/auth-service-init';
import { useRedirectWithReferral } from '../../utils/redirect-with-referral';
import {
  CLEVER_CLIENT_ID,
  CLEVER_DISTRICT_ID,
  CLEVER_SECRET,
  CLIENT_ID,
  CONFIG_CAT_CACHE_TIME,
  CONFIG_CAT_KEY,
  ENVIRONMENT,
  GOOGLE_CLIENT_ID,
  GOOGLE_SECRET,
  ITERABLE_APIKEY,
  MSAL_CLIENT_ID,
  RUDDERSTACK_APIKEY,
  RUDDERSTACK_URL,
} from '../../utils/variables';
import { ErrorBoundary } from './error-boundary';

const initialCredentials = {
  apolloUrl: '',
  usersClientId: CLIENT_ID,
  apiUsersUrl: '',
  msalClientId: MSAL_CLIENT_ID,
  googleClientId: GOOGLE_CLIENT_ID,
  googleSecretId: GOOGLE_SECRET,
  cleverClientId: CLEVER_CLIENT_ID,
  cleverDistrictId: CLEVER_DISTRICT_ID,
  cleverSecret: CLEVER_SECRET,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type Props = {
  children: React.ReactNode;
};

export function Providers({ children }: Props): JSX.Element {
  const regionalConfigVariables = useRegionalConfigVariables();
  const { onRedirect } = useRedirectWithReferral();
  authServiceInit(regionalConfigVariables.apiUsers);

  const [credentials, setCredentials] =
    useState<Credentials>(initialCredentials);

  useEffect(() => {
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      apolloUrl: regionalConfigVariables.apolloServer,
      apiUsersUrl: regionalConfigVariables.apiUsers,
    }));
  }, [regionalConfigVariables]);

  if (credentials.apolloUrl === '' || credentials.apiUsersUrl === '') {
    return <></>;
  }

  return (
    <ThemeProvider type="light">
      <ErrorBoundary>
        <EnvVariablesProvider
          envVariables={initEnvVariables(regionalConfigVariables)}
        >
          <GlowApiClientProvider
            client={queryClient}
            apiUrl={regionalConfigVariables.apolloServer}
            getAuthToken={() => getAccessToken()}
            getUserAcceptLanguage={() => getUserAcceptLanguage()}
            onRefreshToken={async () => await updateRefreshTokenClient()}
            onRequestError={(error) => {
              if (error === 'UNAUTHENTICATED') {
                storage.clear();
                onRedirect();
              }
            }}
          >
            <AuthProvider credentials={credentials}>
              <EventLoggingProvider
                variables={{
                  RUDDERSTACK_APIKEY,
                  RUDDERSTACK_URL,
                  ITERABLE_APIKEY,
                  ENVIRONMENT,
                }}
              >
                <UserManagementProvider>
                  <WebviewBridgeProvider>
                    <FlagsProvider
                      sdkKey={CONFIG_CAT_KEY}
                      cacheTime={CONFIG_CAT_CACHE_TIME}
                    >
                      <GoogleClassroomSyncProvider
                        client={queryClient}
                        organizationsApiUrl={
                          regionalConfigVariables.apiOrganization
                        }
                      >
                        <PreprTrackingProvider>
                          {children}
                        </PreprTrackingProvider>
                      </GoogleClassroomSyncProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </FlagsProvider>
                  </WebviewBridgeProvider>
                </UserManagementProvider>
              </EventLoggingProvider>
            </AuthProvider>
          </GlowApiClientProvider>
        </EnvVariablesProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
