// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LTYEWm5lPToq3WW9{display:flex}.LTYEWm5lPToq3WW9 .wRXmqy0rkv8w1gQX{border:2px solid #fff}.qpcVMm5HZPQPeVMn{cursor:pointer}.tfBZe1mm44UJcxq3 div{margin-left:0}.tfBZe1mm44UJcxq3 div:not(:first-child){margin-left:-20px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/AvatarGroup/AvatarGroup.module.scss"],"names":[],"mappings":"AAAA,kBACE,YAAA,CAEA,oCACE,qBAAA,CAIJ,kBACE,cAAA,CAIA,sBACE,aAAA,CAEA,wCACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarGroup": "LTYEWm5lPToq3WW9",
	"avatar": "wRXmqy0rkv8w1gQX",
	"clickable": "qpcVMm5HZPQPeVMn",
	"overlap": "tfBZe1mm44UJcxq3"
};
export default ___CSS_LOADER_EXPORT___;
