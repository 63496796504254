import { useQueryParams } from '@innovamat/hooks';
import { DEFAULT_REGION } from '@innovamat/localization';
import { useUser } from '../../user-management';

function useGetUserRegion(): string {
  const query = useQueryParams();
  const { user } = useUser();

  const region = user?.region;

  return query.get('r') || query.get('region') || region || DEFAULT_REGION;
}

export { useGetUserRegion };
