import styled from '@emotion/styled';
import { useCallback, useState } from 'react';
import { useCheckOverflow } from '../../hooks/use-check-overflow';

import { getElevation } from '../elevation';
import { StatsCard } from '../stats-card';
import { Tooltip } from '../tooltip';
import { Typography } from '../typography';

type StatsValueLabel = {
  label: string;
  value: number;
};

type SchoolCardProps = {
  classes: StatsValueLabel;
  location?: string;
  schoolName: string;
  students: StatsValueLabel;
  teachers: StatsValueLabel;
  onClick: () => void;
};

const Card = styled.div`
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
  min-height: 5.5rem;
  cursor: pointer;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};

  ${({ theme }) => getElevation(theme, 'elevation 2')};
  transition: box-shadow 0.1s ease-in-out;

  :hover {
    ${({ theme }) => getElevation(theme, 'elevation 4')};
  }
  :active {
    background-color: transparent;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: flex-end;
    gap: 2rem;
  }
`;

const TypographyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const SchoolNameWrapper = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  h5 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CityName = styled(Typography.Body3)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTooltip = styled(Tooltip)`
  overflow: hidden;
  display: block;
`;

const SchoolCard = ({
  classes,
  location,
  schoolName,
  students,
  teachers,
  onClick,
}: SchoolCardProps): JSX.Element => {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);

  const onSetContainerRef = useCallback((ref: HTMLElement | null) => {
    setContainerRef(ref);
  }, []);

  const text = containerRef?.querySelector('div')?.querySelector('h5');
  const container = containerRef?.querySelector('div');

  const { hasOverflow } = useCheckOverflow({
    text,
    container,
    dependencies: [onSetContainerRef],
  });

  return (
    <Card onClick={onClick} role="presentation" data-testid="school-card">
      <TypographyWrapper ref={onSetContainerRef} data-testid="school-card-name">
        <StyledTooltip
          content={hasOverflow ? schoolName : undefined}
          popperOptions={{ strategy: 'fixed' }}
        >
          <SchoolNameWrapper>
            <Typography.Subtitle1>{schoolName}</Typography.Subtitle1>
          </SchoolNameWrapper>
        </StyledTooltip>

        {location && <CityName>{location}</CityName>}
      </TypographyWrapper>
      <StatsWrapper role="list">
        <StatsCard
          icon="TeacherIcon"
          label={teachers.label}
          value={teachers.value}
          dataTestId="school-card-teachers"
        />
        <StatsCard
          icon="ClassIcon"
          label={classes.label}
          value={classes.value}
          dataTestId="school-card-classes"
        />
        <StatsCard
          icon="StudentsIcon"
          label={students.label}
          value={students.value}
          dataTestId="school-card-students"
        />
      </StatsWrapper>
    </Card>
  );
};

export { SchoolCard };
export type { SchoolCardProps };
