import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { InnerHtml } from '@innovamat/glimmer-components';
import { useStretchesOfCourseQuery } from '@innovamat/glow-api-client';
import { usePrint } from '@innovamat/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-domv6';
import styled from 'styled-components';
import { PrintFooter } from '../print-guides';
import { getPacingGuideFileName } from './get-file-name';
import {
  ActivityInfo,
  Bold,
  FileDescription,
  FileTitle,
  GlobalStyle,
  HeaderBar,
  Stretch,
  StyledTable,
  TabletTitle,
  TBodyCell,
  THeadCell,
  Wrapper,
} from './print-us-styled';

type Lesson = {
  title: string;
  type: string;
  duration: number;
  image: string;
};

type ModulePacings = {
  title: string;
  lessons?: Lesson[] | null;
};

type StretchOfCourse = {
  title: string;
  description?: string | null;
  number: number;
  modulePacings: ModulePacings[];
};

const ContentTable = styled.table`
  width: 100%;
  margin-top: 24px;
  border-collapse: separate;
  border: none;
  border-spacing: 7px;
  table-layout: fixed;
`;

const THeadRow = styled.tr<{ hasReducedSize?: boolean }>`
  font-weight: 500;
  text-align: center;

  th {
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    background-color: #f1f3f2;
    text-align: center;
    font-size: ${({ hasReducedSize }) => (hasReducedSize ? '12px' : '13px')};
    line-height: ${({ hasReducedSize }) => (hasReducedSize ? '14px' : '16px')};
    font-weight: 500;
  }
`;

const SpaceDescriptionCell = styled.th`
  width: 128px;
  color: #ffff;
  background-color: #b1bcbb !important;
`;

const TableCell = styled.td`
  border-radius: 4px;
  vertical-align: top;
`;

const LastDayTableCell = styled.td`
  border-radius: 4px;
  background-color: #d5f0f6;
  text-align: center;

  div {
    padding: 0px 10px;
  }
`;

const AssessmentImage = styled.img`
  object-position: bottom;
  width: 100%;
  height: 72px;
  background-color: #ffefcc;
  border-radius: 4px;
  object-fit: contain;
  flex-shrink: 0;
`;

const ActivityImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
  flex-shrink: 0;
`;

const RowHeader = styled(TableCell)<{ hasReducedSize?: boolean }>`
  font-size: ${({ hasReducedSize }) => (hasReducedSize ? '12px' : '13px')};
  line-height: ${({ hasReducedSize }) => (hasReducedSize ? '13px' : '14px')};
  height: 82px;
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  font-weight: 500;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const LessonTitle = styled.div<{ hasReducedSize?: boolean }>`
  font-size: ${({ hasReducedSize }) => (hasReducedSize ? '10px' : '12px')};
  line-height: ${({ hasReducedSize }) => (hasReducedSize ? '12px' : '16px')};
  height: 48px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const SpaceDescriptionText = styled.div`
  margin-top: 0;
  width: 128px;
  height: 117px;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px;
  border-radius: 4px;
  border: 0.5px solid #b1bcbb;

  h3 {
    color: #718482;
    font-family: Roboto;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  h3:not(:first-of-type) {
    margin-top: 5px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;
    padding-left: 5px;
  }

  li::before {
    content: '•';
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const DAYS_OF_WEEK = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'];

const TableHeader = ({
  hasSpaceDescriptions,
}: {
  hasSpaceDescriptions: boolean;
}): EmotionJSX.Element => (
  <thead>
    <THeadRow hasReducedSize={hasSpaceDescriptions}>
      <th style={{ background: 'none', width: '28px' }} />

      {DAYS_OF_WEEK.map((day, index) => (
        <THeadCell key={index}>{day}</THeadCell>
      ))}

      {hasSpaceDescriptions && (
        <SpaceDescriptionCell>Module’s Spaces</SpaceDescriptionCell>
      )}
    </THeadRow>
  </thead>
);

const LessonCell = ({
  duration,
  image,
  title,
  type,
  hasReducedSize,
}: Lesson & { hasReducedSize?: boolean }): EmotionJSX.Element => {
  const isAssessment = type.toLowerCase() === 'assessment';
  const isSpaceDescription = type.toLowerCase() === 'space descriptions';

  if (isSpaceDescription) {
    return (
      <TableCell>
        <SpaceDescriptionText>
          <InnerHtml text={title} />
        </SpaceDescriptionText>
      </TableCell>
    );
  }

  return (
    <TableCell colSpan={duration}>
      {isAssessment ? (
        <AssessmentImage src={image} alt={title} />
      ) : (
        <ActivityImage src={image} alt={title} />
      )}
      <LessonTitle hasReducedSize={hasReducedSize}>{title}</LessonTitle>
    </TableCell>
  );
};

const PrintStretch: React.FC<StretchOfCourse> = ({
  title,
  description,
  modulePacings,
}) => {
  const { t } = useTranslation();

  const hasSpaceDescriptions = modulePacings.some(({ lessons }) =>
    lessons?.some(({ type }) => type.toLowerCase() === 'space descriptions')
  );

  const hasSharedTheFifthDayColumn = modulePacings
    .slice(0, 4)
    .every(
      (module) =>
        module?.lessons?.[4]?.type.toLowerCase() === 'digital practice'
    );

  const individualizedPracticeLesson =
    hasSharedTheFifthDayColumn && modulePacings[0]?.lessons?.[4];

  const sliceColumn = hasSharedTheFifthDayColumn ? 4 : 6;

  return (
    <StyledTable>
      <thead>
        <tr>
          <THeadCell colSpan={1}>
            <HeaderBar className="header-bar">
              <FileTitle>
                {t('pacingGuide.title', { defaultValue: 'Pacing Guide' })}
              </FileTitle>
              <TabletTitle>
                <Stretch>{title}</Stretch>
              </TabletTitle>
            </HeaderBar>
          </THeadCell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TBodyCell>
            {description && (
              <FileDescription>
                <Trans
                  i18nKey={description}
                  components={{
                    b: <Bold />,
                    p: <p />,
                    br: <br />,
                    li: <li />,
                    ul: <ul />,
                  }}
                />
              </FileDescription>
            )}
            <ContentTable>
              <TableHeader hasSpaceDescriptions={hasSpaceDescriptions} />
              <tbody>
                {modulePacings.map((module, rowIndex) => (
                  <tr key={module.title}>
                    <RowHeader hasReducedSize={hasSpaceDescriptions}>
                      {module.title}
                    </RowHeader>
                    {(module.lessons ?? [])
                      .slice(0, sliceColumn)
                      .map(({ title, duration, image, type }, lessonIndex) => (
                        <LessonCell
                          key={`${title}-${lessonIndex}`}
                          title={title}
                          duration={duration}
                          image={image}
                          type={type}
                          hasReducedSize={hasSpaceDescriptions}
                        />
                      ))}

                    {hasSharedTheFifthDayColumn && rowIndex === 0 && (
                      <LastDayTableCell rowSpan={5}>
                        {individualizedPracticeLesson && (
                          <>
                            <ActivityImage
                              src={individualizedPracticeLesson.image}
                              alt={individualizedPracticeLesson.title}
                            />
                            <ActivityInfo>
                              {individualizedPracticeLesson.title}
                            </ActivityInfo>
                          </>
                        )}
                      </LastDayTableCell>
                    )}
                  </tr>
                ))}
              </tbody>
            </ContentTable>
          </TBodyCell>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export function PrintPacingGuide(): EmotionJSX.Element {
  const { courseId } = useParams();
  const [searchParams] = useSearchParams();
  const stretch = searchParams.get('stretch');

  const { data } = useStretchesOfCourseQuery({
    courseUuid: courseId || '',
    stretch,
  });

  const { componentRef, loading, onPrint } = usePrint(
    getPacingGuideFileName({ courseId: courseId || '', stretch })
  );

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <PrintFooter onPrint={onPrint} loading={loading} />
        <div ref={componentRef}>
          {data?.stretchesOfCourse.map((stretch, index) => (
            <PrintStretch key={index} {...stretch} />
          ))}
        </div>
      </Wrapper>
    </>
  );
}
