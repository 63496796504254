import { useTranslation } from 'react-i18next';

import { type Column } from '@innovamat/glimmer-components';

import { StatementsTableHeader } from '../../../../../components/statements-table-header/statements-table-header';
import type {
  MockExamsStudentRow,
  StatementMetadata,
} from '../../../../../types/mock-exams';

const useMockExamsPrintTableColumnsConf = (
  numStatements: number,
  statementsMetadata?: StatementMetadata[],
  statementOffset?: number
): Column<MockExamsStudentRow>[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'firstName',
      label: t('mock-exams-drawer.table.first-name.label'),
      width: 200,
      minWidth: 200,
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
    },
    {
      id: 'score',
      label: t('mock-exams-drawer.table.score.label'),
      width: 76,
      minWidth: 76,
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
    },
    {
      id: 'statements',
      subLabel: () => {
        return (
          <StatementsTableHeader
            numStatements={numStatements}
            statementsMetadata={statementsMetadata}
            statementOffset={statementOffset}
          />
        );
      },
      label: t('mock-exams-drawer.table.statements.label'),
      isLargeLabel: false,
      minWidth: 100,
      width: '100%',
    },
  ];
};

export { useMockExamsPrintTableColumnsConf };
