import { TFunction } from 'i18next';
import type {
  ProgressReportData,
  TextTranslation,
} from '@innovamat/glow-api-client';
import {
  renderBubbles,
  renderBubblesSubLabels,
  renderOtherContents,
  renderReviewSubLabels,
  renderScore,
  renderEllipsableText,
  renderSublabelWeeklyProgress,
  renderWeeklyProgress,
  renderTime,
  renderAppletsWorkedOn,
} from '../../../utils/render-rows';
import { sortByPropertyId } from '../../../utils/preprocess';
import { Column, Row } from '@innovamat/glimmer-components';
import { getDataTestId } from '../../../utils/common';
import { truncateWords } from '@innovamat/radiance-utils';

type Props = {
  t: TFunction<'translation', undefined, 'translation'>;
  handleClickDataPoint: (dataPoint: { from: string; to: string }) => void;
  expandedColumn: string;
  isSecundaria: boolean;
  averageReport: ProgressReportData | undefined;
  handleSendEvent: (event: string, parameters?: {}) => void;
  handleNavigateToResource: (appletId: string) => void;
  isLastYearReport?: boolean;
  isUs?: boolean;
};

export const getGeneralColumns = ({
  t,
  handleClickDataPoint,
  expandedColumn,
  isSecundaria,
  averageReport,
  handleSendEvent,
  handleNavigateToResource,
  isLastYearReport,
  isUs,
}: Props): Column<Row>[] => {
  const {
    blocks: blocksAverage,
    otherContents: otherContentsAverage,
    weekScores,
  } = averageReport || {};

  const columns: Column<Row>[] = [
    {
      id: 'firstName',
      label: t('reports.name.title.column'),
      width: 100,
      sortable: true,
      sticky: true,
      render: (value, row) =>
        renderEllipsableText(
          value,
          row,
          t,
          `GERE_TABLE_STUDENTNAME_S${row.uuid}`
        ),
      sortIgnoreCase: true,
      align: 'left',
      dataTestId: 'GERE_TABLE_NAME',
    },
    {
      id: 'lastName',
      label: t('reports.surname.title.column'),
      width: 100,
      sortable: true,
      sticky: true,
      render: (value, row) =>
        renderEllipsableText(
          value,
          row,
          t,
          `GERE_TABLE_STUDENTSURNAME_S${row.uuid}`
        ),
      sortIgnoreCase: true,
      align: 'left',
      dataTestId: 'GERE_TABLE_SURNAME',
    },
    {
      id: 'separator-1',
      width: 9,
      sticky: true,
      separator: true,
      isDarkSeparator: true,
    },
    {
      id: 'weeklyProgress',
      label: t('reports.general.column.weeklyProgress'),
      width: 200,
      subLabel: () => renderSublabelWeeklyProgress(weekScores, t),
      render: (_, row) =>
        renderWeeklyProgress(
          row.weekScores,
          handleClickDataPoint,
          `GERE_TABLE_WEEKLYPROGRESS_S${row.uuid}`,
          isUs
        ),
      dataTestId: 'GERE_TABLE_WEEKLYPROGRESS',
      isHiddenColumn: isLastYearReport,
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
      isHiddenColumn: isLastYearReport,
    },
    {
      id: 'appletsDone',
      label: t('reports.appletsDone.title.column'),
      width: 105,
      render: (value, row) => renderAppletsWorkedOn(value, row),
      dataTestId: '',
    },
    {
      id: 'timeSpent',
      label: t('reports.time.title.column'),
      width: 90,
      render: (_, row) =>
        renderTime(
          row,
          'all',
          'generalProgress',
          isSecundaria,
          getDataTestId('WERE', 'TIMESPENT', row.uuid, 'AVERAGETIMESPENT')
        ),
      align: 'left',
    },
    {
      id: 'separator-3',
      width: 9,
      separator: true,
    },
    {
      id: 'averageScore',
      label: truncateWords(t('reports.score.course.title.column'), 20),
      width: 102,
      sortable: true,
      render: (value, row) =>
        renderScore(
          value,
          getDataTestId('WERE', 'YEARGRADE', row.uuid, 'AVERAGEYEARGRADE')
        ),
      dataTestId: 'GERE_TABLE_YEARGRADE',
    },
    {
      id: 'separator-4',
      width: 9,
      separator: true,
    },
    {
      id: 'otherContents',
      label: t('reports.review.title.column'),
      width: 105,
      subLabel: () => {
        return renderReviewSubLabels(
          otherContentsAverage,
          'otherContents' === expandedColumn,
          t
        );
      },
      extendable: true,
      render: (_, row) =>
        renderOtherContents(
          row,
          otherContentsAverage!,
          'otherContents' === expandedColumn,
          !!expandedColumn,
          getDataTestId(
            'GERE',
            'OTHERCONTENTS',
            row.uuid,
            'AVERAGEOTHERCONTENTS'
          )
        ),
      numberOfContents: otherContentsAverage?.contents?.length,
      isHiddenColumn: isSecundaria || !otherContentsAverage?.contents?.length,
      dataTestId: 'GERE_TABLE_OTHERCONTENTS',
    },
    // {
    //   id: 'contentAcquired',
    //   label: t('reports.contentAcquired.title.column'),
    //   width: 104,
    //   render: (_, row) => renderContentAcquired(row),
    //   infoColumn: t('reports.information.contents.tooltip'),
    // },
    // {
    //   id: 'separator-2',
    //   width: 9,
    //   render: () => <LineSeparator />,
    //   separator: true,
    // },
    // {
    //   id: 'answeredQuestions',
    //   label: t('reports.appletsDone.title.column'),
    //   width: 96,
    //   infoColumn: '',
    //   render: (value, row) =>
    //     renderCompletedStatements(value, row, isSecundaria),
    //   isHiddenColumn: isSecundaria,
    // },
    // {
    //   id: 'invisibleFinalColumnTrimester',
    //   width: '100%',
    //   isHiddenColumn: !isSecundaria ? false : isAllCourse,
    // },
    // {
    //   id: 'separator-3',
    //   width: 9,
    //   render: () => <LineSeparator />,
    //   isHiddenColumn: !isAllCourse,
    //   separator: true,
    // },
    // {
    //   id: 'practicesDone',
    //   label: t('reports.practicesDone.title.column'),
    //   width: '100%',
    //   isHiddenColumn: !isSecundaria ? true : !isAllCourse,
    //   render: (_, row) => renderPractices(row),
    // },
    {
      id: 'table-end',
      width: '100%',
    },
  ];

  const blocksColumns = blocksAverage
    ?.sort(sortByPropertyId('blockId'))
    .map((block) => {
      const { contents, blockId, blockTitle, blockTooltip } = block!;
      const { text, requiresTranslation } = blockTitle as TextTranslation;
      const {
        text: textTooltip,
        requiresTranslation: requiresTranslationTooltip,
      } = blockTooltip ?? {};
      const id = `${blockId}`;

      const getLabel = (): string => {
        return requiresTranslation ? t(`${text}`) : `${text}`;
      };

      const label = getLabel();

      const getLabelTooltip = (): string => {
        return requiresTranslationTooltip ? t(`${textTooltip}`) : '';
      };

      const blockAverage = blocksAverage?.find(
        (c) => c?.blockId === blockId
      )?.contents;

      return {
        id,
        label,
        labelTooltip: getLabelTooltip(),
        width: 109,
        subLabel: () => {
          return renderBubblesSubLabels({
            applets: contents!,
            isExpanded: id === expandedColumn,
            visibleAppletPreview: '',
            handleShowAppletPreview: () => null,
            t,
            handleSendEvent,
            stickyTable: false,
            isContents: true,
            id: '',
            handleNavigateToResource,
          });
        },
        extendable: true,
        render: (value: any, row: any) => {
          return renderBubbles(
            value,
            blockAverage,
            id === expandedColumn,
            !!expandedColumn,
            getDataTestId(
              'GERE',
              `BLOCK_${id.toUpperCase()}`,
              'S',
              row.uuid,
              `AVERAGEBLOCK_${id.toUpperCase()}`
            )
          );
        },
        isDynamicLabel: true,
        numberOfContents: contents?.length,
        dataTestId: `GERE_TABLE_BLOCK_${id.toUpperCase()}`,
      };
    });
  if (blocksColumns?.length) {
    const index = columns.findIndex((column) => column.id === 'separator-4');
    columns.splice(index + 1, 0, ...blocksColumns);
  }

  return columns;
};
