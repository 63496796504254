import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { Theme } from '@emotion/react';

import { Typography } from '../../typography';

import { ACTION_TYPES } from '../material-card.types';
import type { ActionType } from '../material-card.types';

const getBackgroundColor = (variant: ActionType, theme: Theme) => {
  const colorSemantic = theme.tokens.color.alias.cm.icon;

  switch (variant) {
    case ACTION_TYPES.INCREMENT:
      return colorSemantic['icon-info'].value;
    case ACTION_TYPES.DECREMENT:
      return colorSemantic['icon-warning'].value;
    case ACTION_TYPES.NOTIFICATION:
      return colorSemantic['icon-error'].value;
  }
};

const CounterBadgeWrapper = styled(Typography.Body3)<{
  position: string;
  variant: ActionType;
}>(({ theme, variant, position }) => {
  return css`
    ${position}: -0.5rem;
    ${variant !== ACTION_TYPES.NOTIFICATION && 'min-width: 1.75rem'};
    align-items: center;
    background-color: ${getBackgroundColor(variant, theme)};
    border-radius: 4rem;
    color: ${theme.tokens.color.global['white'].value};
    display: inline-flex;
    height: 1.25rem;
    justify-content: center;
    padding: 0rem 0.375rem;
    position: absolute;
    top: -0.5rem;
  `;
});

export { CounterBadgeWrapper };
