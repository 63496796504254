import { useNavigate } from 'react-router-domv6';

function useRedirectWithReferral(): {
  onRedirect: () => void;
} {
  const navigate = useNavigate();

  const onRedirect = (): void => {
    const { pathname, search } = window.location;
    const urlBase = encodeURIComponent(`${pathname}${search}`);
    const url = pathname !== '/' ? `/?referral=${urlBase}` : `/`;
    navigate(url);
  };
  return { onRedirect };
}

export { useRedirectWithReferral };
