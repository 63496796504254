import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';

import styled from '@emotion/styled';

import { useEventLogging } from '@innovamat/event-logging';
import type { Row } from '@innovamat/glimmer-components';
import {
  AnnouncementPanel,
  AvatarEditor,
  Grounder,
  HeadingSection,
  snack,
  TableComponent,
  Tabs,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';

import type {
  CreateOrUpdateStudentBody,
  Member,
  Rti,
  Student,
  StudentsQuery,
} from '@innovamat/glow-api-client';
import {
  useChangePasswordMutation,
  useDeleteUserMutation,
  useEducationalPlans,
  useResendInvitationMutation,
  useResetTestsMutation,
  useStudentsQuery,
  useUpdateRtiMutation,
  useUpdateStudentAvatarMutation,
} from '@innovamat/glow-api-client';
import { useKeyPress, useQueryParams } from '@innovamat/hooks';
import type { Classroom } from '@innovamat/radiance-utils';
import {
  getRandomNumber,
  newId,
  Stage,
  stages,
} from '@innovamat/radiance-utils';
import { useGoogleImport } from '../../../google-classroom';
import {
  ERROR_TYPE,
  ErrorPage,
  useCurrentNavigationValues,
} from '../../../navigation';

import { useUser } from '../../../user-management';

import {
  UpdatePeriodAnnouncementPanel,
  useCheckIsUpdatePeriod,
} from '../../../components/update-period-announcement-panel';
import { ChangePasswordModal } from '../../components/change-password-modal';
import { tableStyles } from '../../components/common-table-components/table-styles';
import { CompleteIndividualAccess } from '../../components/complete-individual-access';
import { CompleteInvitationModal } from '../../components/complete-invitation-modal';
import { ConfirmDeleteStudentModal } from '../../components/confirm-delete-student.modal';
import { HomeAccessModal } from '../../components/home-access-modal';
import { InviteParentsModal } from '../../components/invite-parents-modal';
import { CompleteUserInvitation } from '../../components/invite-parents-modal/types';
import { InviteStudentsModal } from '../../components/invite-students-modal';
import { MoveStudentToClassroomDrawer } from '../../components/move-student-to-classroom-drawer';
import TableLegend from '../../components/table-legend/table-legend';

import { useDeleteStudent } from '../../hooks/use-delete-student';
import { useOrganization } from '../../hooks/use-organization';
import { usePermissionCourses } from '../../hooks/use-permission-courses';
import { DIGITAL_PRACTICE_ACCESS } from '../../hooks/use-put-classroom';

import useStudentsActions from './hooks/use-students-actions';
import { useStudentsInitSync } from './hooks/use-students-init-sync';
import type { ClassroomStudent } from './types/classroom-student';
import { getStudentsColumns } from './utils/get-students-columns';
import {
  onInviteParentsAction,
  onUnlinkParentAction,
} from './utils/state-actions';

import { deleteStudentInCache } from '../../cache-actions/delete-student';
import { ClassroomStudentsAddDropdown } from './classroom-students-add-dropdown';
import { ClassroomStudentsTableSkeleton } from './classroom-students-table.skeleton';
import { ClassroomCheckConfirmation } from './components/classroom-check-confirmation';
import { ClassroomStudentsEmptyState } from './components/classroom-students-empty-state';
import { ConfirmRtiModal } from './components/confirm-rti-modal';
import { anonymizeStudent } from './utils/anonymize-student';

const TEMPLATE_ID = 'template';

const TABS = {
  STUDENTS: 'students',
  RTI: 'rti',
} as const;
type TabsType = (typeof TABS)[keyof typeof TABS];

export type StudentRow = NonNullable<StudentsQuery['students']>[number];

const TabsPanel = styled.div`
  margin-top: 1rem;
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const CheckClassroomAfterUpdateAnnouncementPanel = styled(AnnouncementPanel)`
  margin-top: 1rem;
`;

function ClassroomStudents(): JSX.Element {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const theme = useGlimmerTheme();

  const { availableLanguages = [], organization } = useOrganization();
  const { currentClassroom } = useCurrentNavigationValues();
  const { setEventData } = useEventLogging();
  const { user } = useUser();

  const classroomId = currentClassroom?.id;

  const [activeTab, setActiveTab] = useState<TabsType>(TABS.STUDENTS);

  const [changeClassroomStudent, setChangeClassroomStudent] = useState<
    ClassroomStudent | undefined
  >(undefined);

  const [currentUploadedImg, setCurrentUploadedImg] = useState<
    File | string | undefined | null
  >();

  const [deleteStudent, setDeleteStudent] = useState<
    ClassroomStudent | undefined
  >(undefined);

  const [inviteParentsModalId, setInviteParentsModalId] = useState<
    string | undefined
  >(undefined);

  const [isHomeAccessDisclaimer, setIsHomeAccessDisclaimer] = useState(false);
  const [isHomeAccessModalOpen, setIsHomeAccessModalOpen] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [openAvatarEditor, setOpenAvatarEditor] = useState(false);
  const [originalRow, setOriginalRow] = useState<StudentRow>();

  const [rtiModal, setRtiModal] = useState<{
    showModal: boolean;
    isRtiEnabled: boolean;
    student?: Student;
  }>({ showModal: false, isRtiEnabled: false, student: undefined });

  const [selectedInputKey, setSelectedInputKey] = useState<string>('');
  const [selectedStudentId, setSelectedStudentId] = useState<string>('');
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<
    string | undefined
  >(undefined);

  const [showCompleteIndividualAccess, setShowCompleteIndividualAccess] =
    useState(false);

  const [showCompleteInvitation, setShowCompleteInvitation] = useState<
    CompleteUserInvitation | undefined
  >(undefined);

  const [showInviteStudentsModal, setShowInviteStudentsModal] = useState(false);
  const [students, setStudents] = useState<StudentRow[]>([]);
  const [userForm, setUserForm] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    language: '',
  });

  const {
    mutate: updateStudentAvatar,
    isPending: isUpdateStudentAvatarPending,
  } = useUpdateStudentAvatarMutation();
  const { mutate: changePassword, isPending: isChangePasswordLoading } =
    useChangePasswordMutation();
  const { mutate: deleteUser } = useDeleteUserMutation();
  const { mutate: resendInvitation } = useResendInvitationMutation();
  const { mutate: resetTests } = useResetTestsMutation();
  const { mutate: updateRti, isPending: isLoadingRti } = useUpdateRtiMutation();

  useStudentsInitSync({ classroom: currentClassroom });

  const queryClient = useQueryClient();

  const { onDeleteStudent, isDeletingLoading } = useDeleteStudent({
    onError: () => {},
    onSuccess: () => {
      snack.success(t('students.toast.success.deleteStudent'));
      deleteStudentInCache({
        classroomId: classroomId!,
        organizationId: organization?.id!,
        queryClient,
        studentId: deleteStudent?.id!,
      });
      setStudents((prev) => {
        if (!prev) return prev;
        return prev.filter((student) => student?.id !== deleteStudent?.id);
      });
      handleCloseConfirmDeleteStudentModal();
    },
  });

  const isUpdatePeriod = useCheckIsUpdatePeriod();
  const { isSuccessInSyncro, onOpenSyncroModal } = useGoogleImport();

  const { createOrUpdateStudent } = useStudentsActions();

  const { permissionsByCurrentCourse: permissions } = usePermissionCourses({
    courseId: currentClassroom?.courseId,
  });

  const courseOrder = currentClassroom?.courseOrder;
  const organizationId = organization?.id ?? '';

  const { educationOptions, getEducationalPlanByCourse } = useEducationalPlans({
    organizationId,
    filterOrderCourse: courseOrder,
  });

  const { data, isPending, isError } = useStudentsQuery(
    { classroomId: classroomId!, organizationId },
    { enabled: !!classroomId }
  );

  const isClassroomImportedFromGoogle = currentClassroom?.provider === 'google';
  const { appLimitsByCourse, trainingUseLimits } = stages;
  const showIntervention = permissions.earlyIntervention;
  const stage = stages.getStageFromOrder(currentClassroom?.courseOrder!);

  const allowChangeRti = useMemo(
    () =>
      Boolean(
        currentClassroom?.pilotStatus?.RTI &&
          showIntervention &&
          stage === Stage.PRIMARIA
      ),
    [currentClassroom?.pilotStatus?.RTI, showIntervention, stage]
  );

  useEffect(() => {
    if (queryParams.get('tab') === TABS.RTI && allowChangeRti) {
      setActiveTab(TABS.RTI);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.get('tab'), allowChangeRti]);

  const handleCloseChangeClassroomDrawer = (): void => {
    setChangeClassroomStudent(undefined);
  };

  const handleMoveStudent = (student?: ClassroomStudent): void => {
    setChangeClassroomStudent(student);
  };

  const handleCloseConfirmDeleteStudentModal = (): void => {
    setDeleteStudent(undefined);
  };

  const validateStudentsLength = (): boolean => {
    if (!students?.length) return true;
    return students.length < 40;
  };

  const isLengthValid = validateStudentsLength();

  const digitalPracticeAccessToKey = (accessType?: string): string => {
    switch (accessType) {
      case DIGITAL_PRACTICE_ACCESS.HOME_ACCESS:
        return 'homeAccess';
      case DIGITAL_PRACTICE_ACCESS.HOME_TRAINING:
        return 'trainingAccess';
      default:
        return 'homeAccess';
    }
  };

  const handleAddEmptyRow = (): void => {
    if (isClassroomImportedFromGoogle) {
      onOpenSyncroModal();
      return;
    }

    if (stage === Stage.SECUNDARIA || stage === Stage.SECONDARY) {
      setShowInviteStudentsModal(true);
      return;
    }

    if (isMutating) return;

    const lang =
      availableLanguages.find((l) => l.value === user?.locale) ||
      availableLanguages[0];

    const newStudent: NonNullable<StudentsQuery['students']>[number] = {
      alias: null,
      avatar: null,
      classroomId: classroomId!,
      courseOrder: currentClassroom?.courseOrder,
      educationalPlanId: getEducationalPlanByCourse(currentClassroom?.courseId!)
        ?.id!,
      email: null,
      firstName: null,
      homeAccess: classroomHasHomeAccess,
      icon: null,
      id: 'template',
      individualAccess: null,
      isInvitation: false,
      isSecondaryStudent: stages.isSecundaria(currentClassroom?.courseOrder),
      language: lang.value,
      lastLogin: null,
      lastName: null,
      parents: null,
      pinCode: null,
      rti: null,
      token: null,
      trainingAccess: classroomHasTrainingAccess,
      trainingTimeLimit: +trainingTimeLimitOptions[0].value,
      weeklyTimeLimit: +weeklyTimeLimitOptions[0].value,
    };
    setSelectedStudentId(TEMPLATE_ID);
    setSelectedInputKey('firstName');
    setStudents((prev) => {
      if (!prev) return [newStudent];
      return [...prev, newStudent];
    });
  };

  const handleOnEnter = (): void => {
    const isDialogOpened = document.querySelector('[role="dialog"]');

    if (isDialogOpened || isUpdatePeriod) return;

    if (selectedStudentId === TEMPLATE_ID) {
      handleCheckRow();
    } else if (!isLengthValid) {
      snack.error(t('students.alert.maxStudentsLength'));
    } else if (selectedStudentId) {
      handleBlurInput();
    } else {
      handleAddEmptyRow();
    }
  };

  const handleCheckRow = (): void => {
    const templateStudent = students?.find(
      (student) => student?.id === TEMPLATE_ID
    );

    if (!templateStudent) return;

    if (
      selectedInputKey === 'firstName' &&
      templateStudent?.firstName &&
      !templateStudent?.lastName
    ) {
      setSelectedInputKey('lastName');
    } else if (!templateStudent?.firstName || !templateStudent?.lastName) {
      setStudents((prev) => {
        if (!prev) return prev;
        return prev.filter((student) => student?.id !== TEMPLATE_ID);
      });
      setSelectedStudentId('');
      setSelectedInputKey('');
      snack.error(t('students.toast.fillAllFields'));
    } else {
      handleCreateOrUpdateStudent(templateStudent);
    }
  };

  useKeyPress({
    Enter: handleOnEnter,
    Escape: handleCheckRow,
  });

  const weeklyTimeLimitOptions = useMemo(() => {
    const data = appLimitsByCourse(courseOrder, organization?.isLATAM);
    return data.map((time) => ({
      value: time,
      label: `${time} min`,
    }));
  }, [courseOrder, organization, appLimitsByCourse]);

  const trainingTimeLimitOptions = trainingUseLimits.map((time) => ({
    value: time,
    label: `${time} min`,
  }));

  const handleInputChange = (
    key: string,
    value: any,
    selectedId?: string
  ): void => {
    const id = selectedId || selectedStudentId;
    if (!id) return;

    let updatedStudent;

    students?.forEach((student) => {
      if (student?.id === id) {
        updatedStudent = { ...student, [key]: value };
      }
    });

    setStudents((prev) => {
      if (!prev) return prev;
      return prev.map((student) => {
        if (student?.id === id) {
          return { ...student, [key]: value };
        }

        return student;
      });
    });

    if (selectedId && updatedStudent) {
      handleCreateOrUpdateStudent(updatedStudent);
    }
  };

  const handleSelect = (studentId: string, inputKey: string): void => {
    setSelectedStudentId(studentId);
    setSelectedInputKey(inputKey);
    setOriginalRow(students?.find((student) => student?.id === studentId));
  };

  const handleCreateOrUpdateStudent = (
    studentDataChange: NonNullable<StudentsQuery['students']>[number]
  ): void => {
    let processedData = studentDataChange;
    if (organization?.anonymized) {
      processedData = anonymizeStudent(studentDataChange);
    }

    const {
      alias,
      avatar,
      educationalPlanId,
      firstName,
      homeAccess,
      id,
      language,
      lastName,
      pinCode,
      trainingAccess,
      trainingTimeLimit,
      weeklyTimeLimit,
    } = processedData!;

    if (!id || !firstName || !lastName || !language || !educationalPlanId) {
      setStudents((prev) => {
        if (!prev) return prev;
        return prev.map((student) => {
          if (student?.id === id) {
            return originalRow!;
          }

          return student;
        });
      });
      snack.error(t('students.toast.fillAllFields'));
      return;
    }

    const body: CreateOrUpdateStudentBody = {
      alias: alias || null,
      classroomId: classroomId!,
      educationalPlanId: educationalPlanId!,
      firstName: firstName!,
      homeAccess: !!homeAccess,
      id: id === TEMPLATE_ID ? newId() : id!,
      language: language!,
      lastName: lastName!,
      organizationId,
      pinCode: pinCode || getRandomNumber(1, 99).toString(),
      studentAvatar: avatar,
      studentIcon: getRandomNumber(1, 30),
      trainingAccess: !!trainingAccess,
      trainingTimeLimit:
        trainingTimeLimit || +trainingTimeLimitOptions[0].value,
      weeklyTimeLimit: weeklyTimeLimit || +weeklyTimeLimitOptions[0].value,
    };

    setIsMutating(true);
    createOrUpdateStudent.mutate(
      {
        body,
        fromGoogleClassroom: isClassroomImportedFromGoogle,
      },

      {
        onSettled: () => {
          setIsMutating(false);
        },
        onError: () => {
          snack.error(t('common.toast.error'));
          if (id === TEMPLATE_ID) {
            setStudents((prev) => {
              if (!prev) return prev;
              return prev.filter((student) => student?.id !== TEMPLATE_ID);
            });
          } else {
            setStudents((prev) => {
              if (!prev) return prev;
              return prev.map((student) => {
                if (student?.id === id) {
                  return originalRow!;
                }

                return student;
              });
            });
          }
        },
        onSuccess: () => {
          snack.success(t('common.toast.success'));
          setStudents((prev) => {
            if (!prev) return prev;
            return prev.map((student) => {
              if (student?.id === id) {
                return { ...student, ...body };
              }

              return student;
            });
          });
        },
      }
    );

    setSelectedStudentId('');
    setSelectedInputKey('');
  };

  const handleBlurInput = (): void => {
    if (selectedStudentId === TEMPLATE_ID) {
      handleCheckRow();
      return;
    }

    const studentData = data?.students?.find(
      (student) => student?.id === selectedStudentId
    );
    const studentDataChange = students?.find(
      (student) => student?.id === selectedStudentId
    );
    if (
      studentDataChange &&
      studentData?.[selectedInputKey as keyof StudentRow] !==
        studentDataChange?.[selectedInputKey as keyof StudentRow]
    ) {
      handleCreateOrUpdateStudent(studentDataChange);
    }
    setSelectedStudentId('');
    setSelectedInputKey('');
  };

  const classroomHasHomeAccess =
    currentClassroom?.digitalPracticeAccess ===
    DIGITAL_PRACTICE_ACCESS.HOME_ACCESS;
  const classroomHasTrainingAccess =
    currentClassroom?.digitalPracticeAccess ===
    DIGITAL_PRACTICE_ACCESS.HOME_TRAINING;
  const classroomHasDigitalPracticeAccess =
    classroomHasHomeAccess || classroomHasTrainingAccess;

  const handleOpenDigitalPracticeAccessModal = (): void => {
    setIsHomeAccessModalOpen(true);
  };

  const handleToggleDigitalPracticeAccess = (
    value: boolean,
    studentId: string
  ): void => {
    if (!classroomHasDigitalPracticeAccess) {
      setSelectedStudentId(studentId);
      setIsHomeAccessDisclaimer(true);
      setIsHomeAccessModalOpen(true);
    } else {
      setOriginalRow(students?.find((student) => student?.id === studentId));
      handleInputChange(
        classroomHasHomeAccess
          ? digitalPracticeAccessToKey(DIGITAL_PRACTICE_ACCESS.HOME_ACCESS)
          : digitalPracticeAccessToKey(DIGITAL_PRACTICE_ACCESS.HOME_TRAINING),
        value,
        studentId
      );
    }
  };

  const handleConfirmDeleteStudent = (student: ClassroomStudent): void => {
    onDeleteStudent(student);
  };

  const handleCloseHomeAccessModal = (accessType?: string): void => {
    if (accessType) {
      if (isHomeAccessDisclaimer) {
        handleInputChange(
          digitalPracticeAccessToKey(accessType),
          true,
          selectedStudentId
        );
        setSelectedStudentId('');
      }
    }

    setIsHomeAccessModalOpen(false);
    setIsHomeAccessDisclaimer(false);
  };

  const handleToggleIndividualAccess = useCallback(
    (open: boolean, id: string) => {
      const student = students?.find((student) => student?.id === id);
      if (open) {
        setShowCompleteIndividualAccess(true);
        handleInputChange('individualAccess', true, id);
        const form = {
          id: student?.id || '',
          firstName: student?.firstName || '',
          lastName: student?.lastName || '',
          email: student?.email || '',
          password: '',
          language: student?.language || '',
        };
        setUserForm({ ...form });
      } else {
        handleInputChange('individualAccess', false, id);
        deleteUser({ userId: id });
        setShowCompleteIndividualAccess(false);
      }
    },
    [students, handleInputChange]
  );

  const handleIndividualAccessSuccess = (id: string, email: string): void => {
    setShowCompleteIndividualAccess(false);

    const updatedStudents = students
      ?.map((student) => {
        if (student?.email === email) {
          return { ...student, individualAccess: false, email: null };
        }
        return student;
      })
      .map((student) => {
        if (student?.id === id) {
          return { ...student, individualAccess: true, email };
        }
        return student;
      });

    setStudents(updatedStudents);
  };

  const handleIndividualAccessCancel = (): void => {
    setShowCompleteIndividualAccess(false);
    handleInputChange('individualAccess', false, userForm.id);
  };

  const handleToggleHomeAccess = (value: boolean, id: string): void => {
    if (value) {
      const student = students.find((student) => student?.id === id);
      if (student?.parents?.length) {
        handleInputChange('homeAccess', value, id);
      } else {
        handleInviteParents(id);
      }
    } else {
      handleInputChange('homeAccess', value, id);
    }
  };

  const handleInviteParents = (studentId: string): void => {
    setInviteParentsModalId(studentId);
  };

  const handleOnInviteParents = (
    parents: Member[],
    studentId: string
  ): void => {
    if (!studentId) return;

    onInviteParentsAction({
      parents,
      studentId,
      setStudents,
    });

    if (stage === Stage.INFANTIL) {
      handleInputChange('homeAccess', true, studentId);
    }
  };

  const handleUnlinkParent = (parent: Member, student: StudentRow): void => {
    const studentId = student?.id!;

    onUnlinkParentAction({
      studentId,
      parentEmail: parent.userInfo?.email || '',
      setStudents,
    });
  };

  const handleSuccessMoveStudent = (student: Student): void => {
    setStudents((prev) => {
      if (!prev) return prev;
      return prev.filter((s) => s?.id !== student.id);
    });
  };

  const handleCompleteInvitationSuccess = (): void => {
    setShowCompleteInvitation(undefined);
  };

  const handleToggleRti = (studentId: string, value: boolean): void => {
    setRtiModal({
      showModal: true,
      isRtiEnabled: value,
      student: students.find((student) => student?.id === studentId)!,
    });
  };

  const handleCloseRtiModal = (): void => {
    setRtiModal({
      showModal: false,
      isRtiEnabled: false,
      student: undefined,
    });
  };

  const handleUpdateRti = (): void => {
    if (!rtiModal.student?.id) return;

    const onUpdateStudentInState = (rti: Rti): void => {
      setStudents((prevState) => {
        return prevState?.map((student) => {
          if (student?.id === rtiModal.student?.id) {
            return {
              ...student,
              rti,
            };
          }
          return student;
        });
      });
    };

    updateRti(
      {
        body: {
          studentId: rtiModal.student?.id,
          enabled: rtiModal.isRtiEnabled,
        },
      },
      {
        onError: () => {
          handleCloseRtiModal();
        },
        onSuccess: (response) => {
          const event = rtiModal.isRtiEnabled
            ? 'rti_activate'
            : 'rti_deactivate';

          setEventData(event, {
            classroom_id: classroomId,
            course: currentClassroom?.courseOrder,
            student_id: rtiModal.student?.id,
            school_id: organizationId,
          });

          handleCloseRtiModal();
          onUpdateStudentInState({
            enabled: response.updateRti?.enabled,
            startDate: response.updateRti?.startDate,
            endDate: response.updateRti?.endDate,
          });
        },
      }
    );
  };

  const handleResetTests = (student: ClassroomStudent | undefined): void => {
    resetTests(
      {
        body: {
          studentId: student?.id || '',
          classroomId: classroomId!,
        },
      },
      {
        onError: () => {
          snack.error(t('resetTests.error'));
        },
        onSuccess: () => {
          snack.success(t('resetTests.success'));
        },
      }
    );
  };

  const handleChangePassword = (password: string): void => {
    const userId = showChangePasswordModal;

    changePassword(
      { id: userId!, password },
      {
        onSuccess: () => {
          snack.success(t('common.toast.success.updatePassword'));
          setShowChangePasswordModal(undefined);
        },
      }
    );
  };

  const handleOpenChangePasswordModal = (studentId: string): void => {
    setShowChangePasswordModal(studentId);
  };

  const handleUploadImg = (imageFile: File | string | undefined): void => {
    setCurrentUploadedImg(imageFile);
  };

  const handleOpenAvatarEditor = (
    id: string,
    img: string | null | undefined
  ): void => {
    handleSelect(id, 'avatar');
    setCurrentUploadedImg(img);
    setOpenAvatarEditor(true);
  };

  const handleCloseAvatarEditor = (): void => {
    setCurrentUploadedImg(undefined);
    setOpenAvatarEditor(false);
  };

  const handleSaveImg = (image: string): void => {
    updateStudentAvatar(
      {
        body: {
          id: selectedStudentId,
          image,
        },
      },
      {
        onSuccess: () => {
          handleInputChange('avatar', image);
          snack.success(t('common.toast.success'));
        },
        onError: () => {
          snack.error(t('common.toast.error'));
        },
        onSettled: () => {
          handleCloseAvatarEditor();
        },
      }
    );
  };

  const handleResendInvitation = (studentId: string): void => {
    const student = students.find((student) => student?.id === studentId);
    if (!student?.email) return;
    resendInvitation(
      { body: { token: student.token!, id: student.id! } },
      {
        onSuccess: () => {
          snack.success(t('userStatus.toast.success.invitationForwarded'));
        },
        onError: () => {
          snack.error(t('students.toast.error'));
        },
      }
    );
  };

  const handleOpenCompleteManuallyModal = (studentId: string): void => {
    const student = students.find((student) => student?.id === studentId);
    if (!student) return;

    setShowCompleteInvitation({
      email: student.email!,
      id: student.id!,
      token: student.token!,
    });
  };

  const filteredAvailableLanguages =
    stage === Stage.SECUNDARIA || stage === Stage.SECONDARY
      ? availableLanguages.filter((lang) => lang.value !== 'va')
      : availableLanguages; // Remove valencian language for secondary

  const columns = getStudentsColumns({
    availableLanguages: filteredAvailableLanguages,
    classroomHasDigitalPracticeAccess,
    classroomHasHomeAccess,
    educationOptions,
    isClassroomImportedFromGoogle,
    isRti: activeTab === TABS.RTI && allowChangeRti,
    isAnonymized: organization?.anonymized!,
    selectedInputKey,
    selectedStudentId,
    stage,
    trainingTimeLimitOptions,
    weeklyTimeLimitOptions,
    handleBlurInput,
    handleDeleteStudent: setDeleteStudent,
    handleInputChange,
    handleInviteParents,
    handleMoveStudent,
    handleOpenAvatarEditor,
    handleOpenChangePasswordModal,
    handleOpenCompleteManuallyModal,
    handleOpenDigitalPracticeAccessModal,
    handleResendInvitation,
    handleResetTests,
    handleSelect,
    handleToggleDigitalPracticeAccess,
    handleToggleHomeAccess,
    handleToggleIndividualAccess,
    handleToggleRti,
    t,
  });

  const isSecundariaCourse = stages.isSecundaria(currentClassroom?.courseOrder);

  useEffect(() => {
    if (queryParams.get('tab') === TABS.RTI && allowChangeRti) {
      setActiveTab(TABS.RTI);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.get('tab'), allowChangeRti]);

  useEffect(() => {
    if (data?.students) {
      setStudents(data.students);
    }
  }, [data]);

  if (!currentClassroom) {
    return <></>;
  }

  if (isError) {
    return <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />;
  }

  return (
    <>
      <HeadingSection
        title={t('students.title')}
        actions={
          <ClassroomStudentsAddDropdown
            isDisabled={isUpdatePeriod}
            handleAddEmptyRow={handleAddEmptyRow}
            isClassroomImportedFromGoogle={isClassroomImportedFromGoogle}
          />
        }
      />

      <UpdatePeriodAnnouncementPanel />

      {isSuccessInSyncro && (
        <CheckClassroomAfterUpdateAnnouncementPanel
          canClose={true}
          type="info"
          text={
            <Trans
              i18nKey="students.syncro.class.correct.banner"
              components={{
                b: <b />,
              }}
            />
          }
        />
      )}

      {!currentClassroom?.checked && (
        <CheckClassroomAfterUpdateAnnouncementPanel
          text={
            <Trans
              i18nKey="classroom.updatePeriod.announcement"
              components={{
                a: <></>,
              }}
            />
          }
          type="imported"
          canClose={false}
        />
      )}

      {allowChangeRti && (
        <TabsPanel>
          <Tabs
            tabs={[
              {
                selected: activeTab === TABS.STUDENTS,
                text: t('students.tabs.personal-information'),
                onClick: () => setActiveTab(TABS.STUDENTS),
              },
              {
                selected: activeTab === TABS.RTI,
                text: t('students.table.labels.intervention'),
                onClick: () => setActiveTab(TABS.RTI),
              },
            ]}
          />
        </TabsPanel>
      )}
      {isPending ? (
        <ClassroomStudentsTableSkeleton
          classroomHasDigitalPracticeAccess={classroomHasDigitalPracticeAccess}
          stage={stage}
          isAnonymized={organization?.anonymized! && stage !== Stage.SECUNDARIA}
        />
      ) : data?.students?.length === 0 && students.length === 0 ? (
        <ClassroomStudentsEmptyState
          isDisabled={isUpdatePeriod}
          handleAddStudent={handleAddEmptyRow}
          isClassroomImportedFromGoogle={isClassroomImportedFromGoogle}
        />
      ) : (
        <>
          <TableComponent
            backgroundColor="transparent"
            columns={columns}
            id="classroom-students-table"
            isDisabled={isUpdatePeriod}
            rows={students as Row[]}
            sx={tableStyles(theme)}
          />
          <TableLegend />
        </>
      )}

      <HomeAccessModal
        classroomId={classroomId!}
        isDisclaimer={isHomeAccessDisclaimer}
        isOpen={isHomeAccessModalOpen}
        onClose={(accessType) => handleCloseHomeAccessModal(accessType)}
        organizationId={organizationId}
      />
      <CompleteIndividualAccess
        handleInput={handleInputChange}
        initForm={userForm}
        onClose={(cancel) =>
          cancel
            ? handleIndividualAccessCancel()
            : setShowCompleteIndividualAccess(false)
        }
        onSuccess={handleIndividualAccessSuccess}
        showModal={showCompleteIndividualAccess}
      />
      <InviteParentsModal
        classroom={currentClassroom as Classroom}
        onClose={(hasParents) => {
          setInviteParentsModalId(undefined);
          if (stage === Stage.INFANTIL && !hasParents) {
            handleInputChange('homeAccess', false, inviteParentsModalId);
          }
        }}
        onCompleteManually={setShowCompleteInvitation}
        onInviteParents={handleOnInviteParents}
        onUnlinkParent={handleUnlinkParent}
        showModal={Boolean(inviteParentsModalId)}
        student={students.find(
          (student) => student?.id === inviteParentsModalId
        )}
      />

      <MoveStudentToClassroomDrawer
        onClose={handleCloseChangeClassroomDrawer}
        onSuccess={handleSuccessMoveStudent}
        showDrawer={Boolean(changeClassroomStudent)}
        student={changeClassroomStudent}
      />

      <ConfirmDeleteStudentModal
        isLoading={isDeletingLoading}
        isOpen={Boolean(deleteStudent)}
        onConfirm={() =>
          deleteStudent && handleConfirmDeleteStudent(deleteStudent)
        }
        onDismiss={handleCloseConfirmDeleteStudentModal}
        student={deleteStudent}
      />

      <CompleteInvitationModal
        invitation={showCompleteInvitation}
        onClose={() => setShowCompleteInvitation(undefined)}
        onSuccess={handleCompleteInvitationSuccess}
        showModal={Boolean(showCompleteInvitation)}
        type={isSecundariaCourse ? 'user' : 'parent'}
      />
      <ConfirmRtiModal
        isLoadingRti={isLoadingRti}
        isOpen={rtiModal.showModal}
        onClose={handleCloseRtiModal}
        onConfirm={handleUpdateRti}
        rtiEnabled={rtiModal.isRtiEnabled}
      />

      <AvatarEditor
        img={currentUploadedImg}
        isLoading={isUpdateStudentAvatarPending}
        onClose={handleCloseAvatarEditor}
        onSaveImg={handleSaveImg}
        onUploadImg={handleUploadImg}
        open={openAvatarEditor}
        t={t}
      />
      <ChangePasswordModal
        loading={isChangePasswordLoading}
        onClose={() => setShowChangePasswordModal(undefined)}
        onSubmit={handleChangePassword}
        open={Boolean(showChangePasswordModal)}
      />
      <InviteStudentsModal
        classroomId={currentClassroom.id!}
        classroomName={currentClassroom?.name!}
        onClose={() => setShowInviteStudentsModal(false)}
        showModal={showInviteStudentsModal}
      />
      <Grounder />
      {!currentClassroom?.checked && (
        <ClassroomCheckConfirmation classroom={currentClassroom} />
      )}
    </>
  );
}

export { ClassroomStudents };
