import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '../typography';
import { StateLayer } from '../../utils/common.styled';
import { IconType } from '@innovamat/glimmer-icons';
import { Icon } from '../icon';
import { MenuItem, MenuItemProps } from '../menu-item';
import { useEffect, useState } from 'react';

export type MenuSectionItem = string | MenuItemProps;

type Size = 'M' | 'L';

type Props = {
  isOpen: boolean;
  size?: Size;
  icon?: IconType;
  text: string;
  fill?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  customStyles?: string;
  dataTestId?: string;
  rightIcon?: IconType;
  content: MenuSectionItem[];
};

const ExpandableIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease-in-out;
`;

const Container = styled('button', {
  shouldForwardProp: (prop) =>
    !['contentIsSelected', 'fill', 'size'].includes(prop),
})<{ fill: boolean; contentIsSelected: boolean; size: Size }>`
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  width: ${({ fill }) => (fill ? '100%' : '')};
  height: ${({ size }) => (size === 'M' ? '40px' : '56px')};
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};

  &:hover .menuItem-stateLayer {
    border-radius: 4px;
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-hover-darker'].value};
  }

  &:active .menuItem-stateLayer {
    border-radius: 4px;
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-press-darker'].value};
  }

  ${({ contentIsSelected, theme }) =>
    contentIsSelected &&
    css`
      .icon path {
        fill: ${theme.tokens.color.alias.cm.icon['icon-accent'].value};
      }
      color: ${theme.tokens.color.alias.cm.text['text-accent'].value};
    `}
`;

const Text = styled(Typography.Body2)`
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ExpandableContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  button + .subtitle {
    margin-top: 8px;
  }
`;

const ExpandableContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean; height: number }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  max-height: ${({ isOpen, height }) => (isOpen ? `${height}px` : '0')};
  transition: max-height 0.3s ease-in-out;
`;

const LineContainer = styled.div`
  width: 32px;
  padding: 8px 0 8px 18px;
`;

const Line = styled.div`
  position: relative;
  width: 1px;
  height: 100%;

  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const Ball = styled.div<{
  position: number;
  contentIsSelected: boolean;
  shouldAnimateTop: boolean;
}>`
  position: absolute;
  top: ${({ position }) => (position === -1 ? '0' : `${position}px`)};
  left: 0.5px;
  transform: translate(-50%, -50%);
  width: ${({ contentIsSelected }) => (contentIsSelected ? '5px' : '0')};
  height: ${({ contentIsSelected }) => (contentIsSelected ? '5px' : '0')};
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.tokens.color.global.teal.teal700.value};

  transition: ${({ shouldAnimateTop, contentIsSelected }) =>
    getTransitionStyles(shouldAnimateTop, contentIsSelected)};
`;

const Subtitle = styled(Typography.Body3)`
  padding: 8px;
  color: ${({ theme }) => theme.tokens.color.global.neutral.neutral400.value};
`;

const getTransitionStyles = (
  shouldAnimateTop: boolean,
  contentIsSelected: boolean
): string => {
  const topTransition = shouldAnimateTop
    ? 'top cubic-bezier(0.5, 0, 0, 1.25) 0.3s'
    : 'top 0s';

  const widthTransition = contentIsSelected
    ? 'width 0.1s ease-in-out, height 0.1s ease-in-out'
    : 'width 0.s, height 0s';

  return `${widthTransition}, ${topTransition}`;
};

const renderContentItem = (
  item: MenuSectionItem,
  index: number,
  size: Size
): JSX.Element => {
  if (typeof item === 'string') {
    return (
      <Subtitle className="subtitle" key={`subtitle-${index}`}>
        {item}
      </Subtitle>
    );
  } else {
    return (
      <MenuItem
        key={`menuitem-${index}`}
        {...item}
        size={size === 'M' ? 'M' : 'XL'}
      />
    );
  }
};

const contentItemHeight = (size: 'M' | 'L'): number => (size === 'M' ? 32 : 48);

const getContentHeight = (content: MenuSectionItem[], size: Size): number => {
  let height = 0;

  if (content) {
    content.forEach((item) => {
      height += contentItemHeight(size);
      if (typeof item === 'string') {
        height += 8;
      }
    });
  }

  return height + contentItemHeight(size) / 2;
};

const calculateBallPosition = (
  content: MenuSectionItem[],
  size: Size
): number => {
  let position = -1;
  let heightAccumulated = 0;

  content.forEach((item, index) => {
    if (typeof item === 'string') {
      heightAccumulated += index === 0 ? 32 : 40;
    } else {
      if (item.state === 'selected') {
        position = heightAccumulated;
        return;
      }
      heightAccumulated += contentItemHeight(size);
    }
  });

  return position + contentItemHeight(size) / 2 - 8;
};

export function MenuSection({
  isOpen,
  icon,
  text,
  fill,
  onClick,
  dataTestId = 'menu-section',
  content,
  size = 'M',
}: Props): JSX.Element {
  const [contentIsSelected, setContentIsSelected] = useState(false);
  const [lastBallPosition, setLastBallPosition] = useState(-1);
  const [ballPosition, setBallPosition] = useState(-1);

  useEffect(() => {
    const isSelected = content.some(
      (item) => typeof item !== 'string' && item.state === 'selected'
    );
    setContentIsSelected(isSelected);
    setLastBallPosition(ballPosition);
    setBallPosition(calculateBallPosition(content, size));
  }, [content]);

  return (
    <>
      <Container
        contentIsSelected={contentIsSelected}
        fill={fill || false}
        onClick={onClick}
        data-testid={dataTestId}
        size={size}
      >
        <StateLayer className="menuItem-stateLayer" />
        {icon && <Icon className="icon" icon={icon} />}
        <Text>{text}</Text>
        {<ExpandableIcon icon="ExpandMoreIcon" size="S" isOpen={isOpen} />}
      </Container>
      <ExpandableContainer
        isOpen={isOpen}
        height={getContentHeight(content, size)}
      >
        <LineContainer>
          <Line>
            <Ball
              data-testid="ball"
              position={ballPosition}
              contentIsSelected={contentIsSelected}
              shouldAnimateTop={ballPosition >= 8 && lastBallPosition >= 8}
            />
          </Line>
        </LineContainer>
        <ExpandableContent>
          {content.map((item, index) => renderContentItem(item, index, size))}
        </ExpandableContent>
      </ExpandableContainer>
    </>
  );
}
