import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import type { AppletsOfTheWeek } from '@innovamat/glow-api-client';
import { dates } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  height: 1.75rem;
  padding: 0.5rem;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background: #f3f7f6;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }
`;

type Props = {
  weekToShow: AppletsOfTheWeek['weekToShow'];
  startDate: AppletsOfTheWeek['startDate'];
  endDate: AppletsOfTheWeek['endDate'];
};

const formattedDate = (date: string, timezone: string): string =>
  dates.getFormattedWeekdayDate(
    dates.getDateTimeAccordingToTimeZone({
      date,
      zone: timezone,
    })
  );

const WeekInformation: React.FC<Props> = ({
  weekToShow,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();

  const weekTitle =
    weekToShow === 'current'
      ? 'homeWeeklyPractice.currentWeek'
      : 'homeWeeklyPractice.nextWeek';

  return (
    <Container>
      <Typography.Body2>{t(weekTitle)}</Typography.Body2>
      <Typography.Subtitle2>{`${formattedDate(
        startDate.date,
        startDate.timezone
      )} - ${formattedDate(
        endDate.date,
        endDate.timezone
      )}`}</Typography.Subtitle2>
    </Container>
  );
};

export default WeekInformation;
