import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { getTypography } from '../typography';
import { StateLayer } from '../../utils/common.styled';

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledLabel = styled.label`
  ${({ theme }) => getTypography(theme, 'Body 2')};
`;

const StyledRadioButton = styled.div<{
  isDisabled?: boolean;
  checked?: boolean;
}>`
  position: relative;
  * {
    cursor: pointer;
    user-select: none;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.7 : 1)};
  }

  input[type='radio'] {
    opacity: 0;
    position: absolute;
  }

  .custom-radio {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ theme }) =>
      theme.tokens.color.alias.cm.surface['surface-primary'].value};
    outline: 1px solid
      ${({ theme }) =>
        theme.tokens.color.alias.cm.border['border-default'].value};
    outline-offset: -1px;
    position: relative;

    &:after {
      content: '';
      display: ${({ checked }) => (checked ? 'block' : 'none')};
      position: absolute;
      top: 2px;
      left: 2px;
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) =>
        theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value};
      border-radius: 50%;
    }

    .radioButtonStateLayer {
      border-radius: 50%;
    }

    :hover .radioButtonStateLayer {
      background-color: ${({ theme }) =>
        theme.tokens.color.specific['state-layer']['state-hover-darker'].value};
    }
  }
`;

type RadioButtonProps = {
  groupBy: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  onEvent?: (value: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const RadioButton: React.FC<RadioButtonProps> = ({
  groupBy,
  label,
  value,
  disabled,
  checked,
  onEvent,
  ...inputProps
}) => {
  const radioRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (radioRef.current) {
      radioRef.current.checked = !!checked;
    }
  }, [checked]);

  const handleClick = (): void => {
    if (!disabled && onEvent && value) {
      onEvent(value);
      if (radioRef.current) {
        radioRef.current.checked = true;
      }
    }
  };

  return (
    <StyledRadioButton
      onClick={handleClick}
      isDisabled={disabled}
      checked={checked}
    >
      <Row>
        <input
          ref={radioRef}
          name={groupBy}
          id={groupBy}
          value={value}
          disabled={disabled}
          type="radio"
          {...inputProps}
        />
        <div className="custom-radio" onClick={() => radioRef.current?.click()}>
          <StateLayer className="radioButtonStateLayer" />
        </div>
        {label && <StyledLabel htmlFor={groupBy}>{label}</StyledLabel>}
      </Row>
    </StyledRadioButton>
  );
};

export default RadioButton;
