import {
  useResendInvitationMutation,
  useResendParentsInvitationMutation,
} from '@innovamat/glow-api-client';
import { InvitationType } from '../use-complete-invitation/invitation';

type ResendInvitationBody = {
  token: string;
  id: string;
};

type Body = ResendInvitationBody & {
  type: InvitationType;
};

function useResendRegisterInvitation() {
  const {
    mutate: resendParentInvitation,
    isSuccess: isSuccessResendParentInvitation,
    isPending: isPendingParent,
  } = useResendParentsInvitationMutation();
  const {
    mutate: resendInvitation,
    isSuccess: isSuccessResendInvitation,
    isPending: isPending,
  } = useResendInvitationMutation();

  const resendRegisterInvitation = (invitationBody: Body) => {
    const { type, ...body } = invitationBody;
    if (type === 'parent') {
      return resendParentInvitation({ body });
    } else {
      return resendInvitation({ body });
    }
  };

  return {
    resendRegisterInvitation,
    isSuccessResendInvitation:
      isSuccessResendParentInvitation || isSuccessResendInvitation,
    isPendingResendInvitation: isPendingParent || isPending,
  };
}
export { useResendRegisterInvitation };
