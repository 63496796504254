import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { getElevation } from '../elevation';
import {
  fadeIn,
  fadeOut,
  wipeInLeft,
  wipeInRight,
  wipeOutLeft,
  wipeOutRight,
} from './drawer.animations';

type Props = {
  open?: boolean;
  closeOnClickOutside?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  position?: Position;
  onClickOverlay?: () => void;
  roundedBorder?: boolean;
  customStyles?: any;
  dataTestIdOverlay?: string;
};

type Position = 'left' | 'right';

const getAnimation = (open: boolean | undefined, position: Position) => {
  if (open) {
    return position === 'left' ? wipeInLeft : wipeInRight;
  }
  return position === 'left' ? wipeOutLeft : wipeOutRight;
};

const ContainerDrawer = styled.div<{
  position: Position;
  open?: boolean;
  roundedBorder?: boolean;
  customStyles?: any;
}>`
  position: fixed;
  height: 100%;
  top: 0;
  background-color: #fff;
  z-index: 30;
  width: fit-content;
  overflow: auto;
  border-radius: ${({ roundedBorder, position }) => {
    if (!roundedBorder) return undefined;
    return position === 'left' ? '0 16px 16px 0' : '16px 0 0 16px';
  }};
  overflow-x: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100vw !important;
    border-radius: 0;
  }

  ${({ theme }) => getElevation(theme, 'elevation 2')};
  ${({ position }) => (position === 'left' ? 'left: 0' : 'right: 0')};

  animation: 0.5s ${({ open, position }) => getAnimation(open, position)}
    cubic-bezier(0, 0, 0.3, 1) both;

  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `};
`;

const Overlay = styled.div<{ open?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 30;
  animation: 0.4s ${({ open }) => (open ? fadeIn : fadeOut)} both;
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
`;

export function Drawer({
  open,
  children,
  closeOnClickOutside,
  position = 'right',
  onClose,
  roundedBorder = false,
  customStyles,
  dataTestIdOverlay,
}: Props): JSX.Element | null {
  const drawerRoot = document.getElementById('drawer') || document.body;
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (open) {
      setShowDrawer(true);
    }
  }, [open]);

  useLayoutEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  return showDrawer
    ? createPortal(
        <>
          <Overlay
            open={open}
            onClick={closeOnClickOutside ? onClose : () => {}}
            data-testid={dataTestIdOverlay}
          />
          <ContainerDrawer
            position={position}
            open={open}
            roundedBorder={roundedBorder}
            customStyles={customStyles}
          >
            {children}
          </ContainerDrawer>
        </>,
        drawerRoot
      )
    : null;
}
