import type { Resource, Session } from '@innovamat/resource-viewer';
import { useState } from 'react';
import type { ImageModal } from '../components/ImagesModal/ImagesModal';
import type { EarlyYearDigitalGuide } from '../templates/EarlyYear/types/earlyYearDigitalGuide';
import type { DigitalGuide } from '../types/digital-guide';
import type { Moment } from '../types/moments';
import useBoxes from './useBoxes';

type Props = {
  resource: Resource;
  guide: Exclude<DigitalGuide, EarlyYearDigitalGuide>;
  onEvent: (key: string, data: any) => void;
};

type MomentState = Moment & {
  momentNumber: number;
};

export default function useMoments({ resource, guide, onEvent }: Props) {
  const { getIsOpen, onSelectBox } = useBoxes();
  //TODO: current moment when scroll follows moments and player change of resource
  const [, setCurrentMoment] = useState<MomentState | undefined>(undefined);
  const [defaultImage, setDefaultImage] = useState<ImageModal | null>(null);

  const onClickImage = (image: ImageModal | null) => {
    setDefaultImage(image);
  };

  const onSelectMoment = (moment: Moment, index: number) => {
    const isOpen = getIsOpen(index);
    onSelectBox(index);

    const key = isOpen ? 'close_moment' : 'open_moment';
    const isSession = 'resources' in resource;
    const guideId = (resource as Session).guide?.id!;

    const data = {
      moment_id: moment.id,
      moment_name: moment.title.body,
      moment_order: index,
      moment_total: guide?.moments.items.length,
      secondary_guide_id: guideId,
      secondary_guide_name: guide.title.body,
      session_name: resource.title,
      [isSession ? 'session_id' : 'resource_id']: resource.id,
    };
    onEvent(key, data);
  };

  const setMoment = (index: number | undefined) => {
    if (index === undefined) {
      setCurrentMoment(undefined);
      return;
    }
    const moment = guide?.moments.items[index];
    if (moment) {
      setCurrentMoment({
        ...moment,
        momentNumber: index,
      });
    }
  };

  const onScrollMoment = (index: number | undefined) => {
    setMoment(index);
  };

  return {
    getIsOpen,
    onScrollMoment,
    onClickImage,
    onSelectMoment,
    defaultImage,
  };
}
