import { useMemo } from 'react';
import {
  CompletedResource,
  useCompletedResourcesQuery,
} from '../../generated/types';

type Props = {
  classroomId?: string;
  academicYearId?: string;
  isStudent?: boolean;
};

export function useCompletedResources({
  classroomId,
  academicYearId,
  isStudent,
}: Props) {
  const { data } = useCompletedResourcesQuery(
    {
      classroomId: classroomId!,
      academicYearId: academicYearId!,
    },
    {
      enabled: !!classroomId && !!academicYearId,
      staleTime: Infinity,
    }
  );
  const completedResources = useMemo(() => {
    if (!isStudent) {
      return classroomId && academicYearId
        ? (data?.getCompletedResourcesByClassroomId as CompletedResource[])
        : ([] as CompletedResource[]);
    }
    return classroomId
      ? (data?.getCompletedResourcesByClassroomId as CompletedResource[])
      : undefined;
  }, [
    academicYearId,
    classroomId,
    data?.getCompletedResourcesByClassroomId,
    isStudent,
  ]);
  return completedResources;
}
