import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { StatementMetadata } from '../../types/mock-exams';

export const StatementsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StatementsCol = styled.div<{
  width: number;
}>`
  ${({ width }) =>
    css`
      width: ${width * 3 + 30}px;
    `}

  overflow: hidden;
`;

type Props = {
  numStatements: number;
  statementsMetadata?: StatementMetadata[];
  statementOffset?: number;
};

function StatementsTableHeader({
  numStatements,
  statementsMetadata,
  statementOffset,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <StatementsContainer>
      {Array.from({ length: numStatements }).map((_, index) => {
        const statementIndex = index + (statementOffset ?? 0);
        const statementMetadata = statementsMetadata?.find(
          (statement) => statement.position === index
        );

        const width =
          statementMetadata?.answersMetrics?.largestAnswerWidth ?? 0;

        return (
          <StatementsCol width={width} key={'statement-' + statementIndex}>
            {`${t('mock-exams-drawer.table.statements.sublabel')}${
              statementIndex + 1
            }`}
          </StatementsCol>
        );
      })}
    </StatementsContainer>
  );
}

export { StatementsTableHeader };
