import styled from '@emotion/styled';
import { ERROR_TYPE, ErrorPage } from '@innovamat/ga-features';
import { Accordion } from '@innovamat/glimmer-components';
import { Component, ErrorInfo, ReactNode } from 'react';
import { Container, LeftWrapper } from '../layout/header';
import { Logo } from './logo';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

const AccordionErrorDescription = styled.div`
  white-space: pre-wrap;
  overflow: auto;
  height: 600px;
`;

const Header = styled(Container)``;

const ErrorContent = styled(ErrorPage)`
  margin-top: 0;
  flex: 1;
`;

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error capturado por ErrorBoundary:', error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <Header>
            <LeftWrapper>
              <Logo />
            </LeftWrapper>
          </Header>
          <ErrorContent errorType={ERROR_TYPE.ERROR_500} />
          <Accordion summary={''}>
            <AccordionErrorDescription onClick={(e) => e.stopPropagation()}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo?.componentStack}
            </AccordionErrorDescription>
          </Accordion>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
