import {
  PreprResource,
  SessionResource,
  SessionSectionProvider,
  SingleResource,
  useCurrentNavigationValues,
  useNavigation,
  useRegionParameters,
  useResourceParams,
  useStandard,
  useUpdateI18nLanguage,
  useUser,
} from '@innovamat/ga-features';
import { useResource } from '@innovamat/glow-api-client';
import { useQueryParams } from '@innovamat/hooks';
import {
  type ScrollPosition,
  SmartGuideSkeleton,
} from '@innovamat/smart-guides';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-domv6';
import { LessonsProviderWrapper } from '../lessons-provider';
import { ResourceSkeleton } from '../resource-skeleton';
import { useDownloadOption } from './use-download-option';
import { useFakeLoading } from './use-fake-loading';
import { useResourceEvents } from './use-resource-events';

type I18NTranslations = {
  translations: Record<string, string>;
};

export function ResourceRouter(): JSX.Element {
  const { classroomId, type, id } = useParams();
  const { i18n } = useTranslation();
  const { goBack } = useNavigation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const { user, isRole, isLoggedIn } = useUser();
  const { region, language, educationalLevel } = useResourceParams();
  const { courseOrder, isNavigationForbidden } = useCurrentNavigationValues();
  const { regionParameters } = useRegionParameters();

  useUpdateI18nLanguage({ language });

  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
    scrollHeight: 0,
    scrollTop: 0,
    clientHeight: 0,
  });

  const { standard } = useStandard();

  const { resource, status } = useResource({
    academicYearId: user?.organization?.academicYearId,
    classroomId,
    courseOrder: courseOrder ?? undefined,
    id: id!,
    isLoggedIn,
    isStudent: isRole.Student,
    language,
    region,
    standard: standard,
    type: type!,
  });

  useResourceEvents({ resource });
  const { onDownloadOption } = useDownloadOption({ resource });

  useEffect(() => {
    if (isNavigationForbidden) {
      navigate(
        `/general/${type}/${id}?region=${region}&language=${language}&stage=${educationalLevel}`
      );
    }
  }, [isNavigationForbidden]);

  const handleChangeScrollPosition = (position: ScrollPosition) => {
    setScrollPosition(position);
  };

  const i18nTranslations =
    i18n.getDataByLanguage(i18n.language) || i18n.getDataByLanguage('es');

  const { translations } = i18nTranslations as I18NTranslations;

  const isFakeLoading = useFakeLoading<string | undefined>({ trigger: id });

  if (isFakeLoading || status === 'pending' || !resource || !regionParameters) {
    if (queryParams.has('isDigitalGuide')) {
      return <SmartGuideSkeleton />;
    } else {
      return <ResourceSkeleton />;
    }
  }

  const isSession = resource && 'resources' in resource;

  const renderResource = () => {
    if (!isSession) {
      return (
        <SingleResource
          translations={translations}
          setScrollPosition={handleChangeScrollPosition}
          resource={resource}
          onGoBack={goBack}
        />
      );
    }

    if (resource.guide?.type === 'prepr') {
      return (
        <PreprResource
          region={region}
          language={language}
          setScrollPosition={handleChangeScrollPosition}
          scrollPosition={scrollPosition}
          session={resource}
          onDownloadOption={onDownloadOption}
          translations={translations}
          onGoBack={goBack}
        />
      );
    }

    return (
      <SessionResource
        session={resource}
        onDownloadOption={onDownloadOption}
        translations={translations}
        setScrollPosition={handleChangeScrollPosition}
        onGoBack={goBack}
        language={language}
        region={region}
      />
    );
  };

  return (
    <LessonsProviderWrapper>
      <SessionSectionProvider resource={resource}>
        {renderResource()}
      </SessionSectionProvider>
    </LessonsProviderWrapper>
  );
}
