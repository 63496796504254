// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CEoWUN4kcsxlAz77{display:flex;justify-content:center}.sHmmSkcmiZ4Y8YuK{margin-left:.5rem}.Pt4_0y_g59oQ9YtI{margin-bottom:.5rem}", "",{"version":3,"sources":["webpack://./../../libs/ga-features/src/lib/orders-returns/views/orders-returns-history/components/sub-row/sub-row.module.scss"],"names":[],"mappings":"AAAA,kBACE,YAAA,CACA,sBAAA,CAEF,kBACE,iBAAA,CAEF,kBACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "CEoWUN4kcsxlAz77",
	"loaderText": "sHmmSkcmiZ4Y8YuK",
	"subRowTitle": "Pt4_0y_g59oQ9YtI"
};
export default ___CSS_LOADER_EXPORT___;
